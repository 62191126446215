(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('EODProcess', EODProcess);

    EODProcess.$inject = ['$resource','DateUtils'];

    function EODProcess ($resource, DateUtils) {
        var resourceUrl = 'api/eod-process/';
        return $resource(resourceUrl, {}, {
            
            'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/eod-process',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
            'shiftDateInSystem' : {
				method : 'PUT',
				params : {
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
				},
				url : 'api/eod-process/new-system-settings',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
            
            'putEODProcessStatusLog' : {
				method : 'PUT',
				params : {
					currentBusinessDate : '@currentBusinessDate',
					nextBusinessDate : '@nextBusinessDate',
					isEodDone:'@isEodDone',
					errorMessage :'@errorMessage'
				},
				url : 'api/eod-process/update-eodProcess-status-log',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getNextValidBusinessDate' : {
				method : 'GET',
				params : {
					
					currentBusinessDate : '@currentBusinessDate'
					
				},
				url : 'api/eod-process/get-next-system-date',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'generateEODEmails' : {
				method : 'GET',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/generateEODEmail',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'makeUsersDormant' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/makeUsersDormant',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'autoWithdrwaPendingCustomers' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/autoWithdrawalOfPendingCustomers',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'processEOD' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
					status : '@status',
					nextBusinessDate : '@nextBusinessDate'
					
				},
				url : 'api/eod-process/updateProcessStatus',
			},
			'logOffUsersForcibly' : {
				method : 'POST',
				params : {
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
				},
				url : 'api/eod-process/forcibleLogOff',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'processLimits' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/process-limits',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'archiveTables' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/archive-tables',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'prepareNextBusinessDateData' : {
				method : 'POST',
				params : {
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/prepare-next-business-date-data',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'updateEODPRocessLog' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
				},
				url : 'api/eod-process/updateProcessStatusLog',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'validateEOD' : {
				method : 'POST',
				url : 'api/eod-process/validateEOD',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'validateDBProcessing' : {
				method : 'POST',
				url : 'api/eod-process/validate-db-processing',
				params : {
					serverName : '@serverName'
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'initiateEODInOrderService' :{
				
				method : 'POST',				
				url : 'order/process/eod',
				/*params : {
					
					executionCount : '@executionCount',
					businessDate : '@businessDate',
					processId : '@revokeProcessId'
				},*/
				transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                },
				transformResponse : function(data) {
					if(data!=null && data!=undefined && data !=''){
						if(data.substring(0,1)=='<'){
				
						}else {
							data = angular.fromJson(data);
							return data;
						}
					
					}
				}
				
			},
			'getEODProcessStatusLog' : {
				method : 'GET',
				params : {
					
					businessDate : '@businessDate'
					
				},
				url : 'api/eod-process/getEODProcessStatusLog',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'computeDailyTransactions' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',					
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/compute-daily-transaction-charges',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'generateMonthEndBillingReport' : {
				method : 'POST',
				params : {
					
									
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/generate-month-end-billing-report',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'generateYearEndBillingReport' : {
				method : 'POST',
				params : {
					
									
					processId : '@processId',
					executionCount : '@executionCount'
					
				},
				url : 'api/eod-process/generate-year-end-billing-report',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			/*'stopEODForcibly' :{
				method : 'POST',
				url : 'api/eod-process/stop-eod-forcibly',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},*/
			'insertProcessLog' :{
				method: 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount',
					errorMessageCode : '@errorMessageCode',
					isSuccess : '@isSuccess'
				},
				url : 'api/eod-process/insert-process-log',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},'validateSessionClose' : {
				method : 'POST',
				url : 'api/eod-process/validate-session-close',
				params : {
					currentBusinessDate : '@currentBusinessDate'
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			
			'validateResponseReceived' : {
				method : 'POST',
				url : 'api/eod-process/validate-recon-response',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			
			
			
			'generateInvoice' : {
				method : 'GET',
				url : 'api/eod-process/generateTransactionInvoice',
				params : {
					billingDate : '@billingDate'
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'aysncgenerateInvoice' : {
				method : 'GET',
				url : 'api/eod-process/async-generateInvoice',
				params : {
					billingDate : '@billingDate'
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'updateNewTradingBranchForCustomers' : {
				method : 'POST',
				params : {					
									
					processId : '@processId',
					executionCount : '@executionCount',
					currentBusinessDate : '@currentBusinessDate'
					
				},
				url : 'api/eod-process/updateNewTradingBranchForCustomers',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			
			/*,
			'eodStatusUpdateOnRefresh' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
					status : '@status',
					nextBusinessDate : '@nextBusinessDate'
					
				},
				url : 'api/eod-process/check-update-eod-status-on-refresh',
			},*/
			'initiateEODProcess' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
					status : '@status',
					nextBusinessDate : '@nextBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount',
					
				},
				url : 'api/process-eod',
			},
			'getAllEODProcessStatusLog' : {
				method : 'GET',
				isArray: true,
				params : {
					businessDate : '@businessDate'
				},
				url : 'api/eod-process/getAllEODProcessStatusLog',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
			
            
        });
    }
})();
