(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'OrderUpload'
        })
        
        .controller('OrderUploadController', OrderUploadController);

    OrderUploadController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    				'MessageBox','$rootScope','OrderUpload','AlertService','$filter', '$window','FileUtil','OrderUtil','CustomerBankRelationshipReport','MarketWatch',
					'ExposureType','ExposureSubType','DefaultMarket','DateUtils','StaticLookUp','HolidayReport','IntradayMarket','Principal','SwapQuotesForTenorDates','$timeout',
					'TACViewForEntitiesService','UserProfile','Market'];

    function OrderUploadController($scope, $state , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    				MessageBox,$rootScope,OrderUpload, AlertService, $filter, $window,FileUtil,OrderUtil,CustomerBankRelationshipReport,MarketWatch,
					ExposureType,ExposureSubType,DefaultMarket,DateUtils,StaticLookUp,HolidayReport,IntradayMarket,Principal,SwapQuotesForTenorDates,$timeout,
					TACViewForEntitiesService,UserProfile,Market) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDER_UPLOAD;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.clicked = false;
        vm.organisation = vm.globalValues.getOrganisation().organisationType.shortName;
        vm.uploadOrderFile = uploadOrderFile;
        vm.downloadOrderTemplate = downloadOrderTemplate;
        workflowSharedService.viewFrom = "OrderUpload";
		vm.invalidFileText = 'Invalid File.!';
		vm.invalidFile = false;
		vm.orderUploadList = [];
		$rootScope.maxForwardTenorDate = null;
		vm.exposureType = null;
		vm.exposureSubType = null;
		vm.configSettings = vm.globalValues.getConfigSettings();
		$rootScope.noOfDecimalsForRateForOrder = vm.globalValues.getNumberOfDecimalsForRate();
        $rootScope.noOfDecimalsForAmountForOrder = vm.globalValues.getNumberOfDecimalsForAmount();
        $rootScope.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
		$rootScope.tradeSettlementDateFrom = "";
		$rootScope.tradeSettlementDateFororder = "";
		const buySellMap = {
			'BUY': 'BUYY',
			'SELL': 'SELL'
		};
		const typeOfOrders = {
			"LIMIT":"LIMT",
			"MARKET":"MRKT"
		};
		const timeLimitIndicators = {
			"DAY" : "DAYY",
			"IOC" : "IOCC",
			"GTT" : "GTTM"
		};
		IntradayMarket.getCurrentBusinessDate(function(result){
			vm.currentDate = result.businessDate;
			vm.currentDate = DateUtils.convertLocalDateToServer(vm.currentDate);
			IntradayMarket.getMarketSessionSettings({"currentBusinessDate":vm.currentDate}, function(result){
				$rootScope.marketSessionSettings = result;
				
				setMarketSettings();
			});
		});

		setUsers();
        function setUsers() {
            if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
                vm.loggedInCustomer = vm.globalValues.getCustomer();
				
           }
           if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
               vm.bank = vm.globalValues.getBank();
           }
           if(vm.entityOwnerType=='Branch'){
               vm.branch = vm.globalValues.getBranch();
           }
            
        }
		vm.allTradingBanks=CustomerBankRelationshipReport.query();
		

		DefaultMarket.marketInstruments(function(result) {
			vm.instrumentTypes = result;
			angular.forEach(vm.instrumentTypes, function (value) {
				if(value.instrumentCode=='SPOT'){
        			$rootScope.tickSize.spot = value.tickSize;
                    var spotNextDate = new Date(value.settlementDate);
                    spotNextDate.setDate(spotNextDate.getDate()+1);
                    $rootScope.spotNextDate =  spotNextDate;
                    vm.maxFromSettlementDate = moment(spotNextDate).add(1, 'days');
                    
        		}else if(value.instrumentCode=='CASH'){
        			$rootScope.tickSize.cash = value.tickSize;
                    vm.holidayListAfterBusinessDate = HolidayReport.getHolidayListAfterGivenDate({'givenDate': value.settlementDate});
                    $rootScope.holidayListAfterBusinessDate = vm.holidayListAfterBusinessDate;
        		}else if(value.instrumentCode=='TOM'){
        			$rootScope.tickSize.tom = value.tickSize;
        		}else if(value.instrumentCode=='InterbankSPOT'){
        			$rootScope.tickSize.ibSpot = value.tickSize;
        		}else if(value.instrumentCode=='Forward'){
                    $rootScope.tickSize.forward = value.tickSize;
                }
						 
			});
			       
		});
		ExposureType.getAllExposureTypes(function(result) {
			vm.exposureTypes = result;                
		});
		ExposureSubType.getAllExposureSubTypes(function(result) {
			vm.exposureSubTypes = result;                
		});
		StaticLookUp.getEventTypeList(function(result){
			$rootScope.eventTypes = result;
		});
		Principal.identity().then(function(account) {
			vm.account =  account;
		if(vm.account.organisation.organisationType.shortName=='BANK'){
			SwapQuotesForTenorDates.getSwapQuotesForGivenBank({bankId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
		}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
			SwapQuotesForTenorDates.getSwapQuotesForGivenCustomer({customerId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
			TACViewForEntitiesService.getCustomerAgreedTACList({customerId : account.organisation.id}, function(result){
				$rootScope.agreedTACList = result;
			});
		}
		UserProfile.get({userId: vm.account.id}, onGetUserSpecSettingSuccess, onGetUserSpecSettingFailure)
	});
		function onGetSwapQuotesSuccess(result){
			if(result!=null && result!=undefined && result.swapQuotesTenorDateDTOList!=null && result.swapQuotesTenorDateDTOList!=null 
                && result.swapQuotesTenorDateDTOList.length>0){
        		
        		$rootScope.bankComparativeDTO = result;
				prepareSwapQuotes();
				}

		}
		function onGetSwapQuotesFailure(){
			
		}
		function prepareSwapQuotes(){
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length==1){
                $rootScope.defaultBankSpread = $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList[0].swapQuotesForTenorDatesList;
            }
        	
        }
		
		// set market settings
        function setMarketSettings(){
        	angular.forEach($rootScope.marketSessionSettings, function(value){
        		if(value.instrumentCode == 'SPOT'){
        			$rootScope.retailSpotMarketSession = value;
        		}else if(value.instrumentCode == 'TOM'){
        			$rootScope.tomMarketSession = value;	
        		}else if(value.instrumentCode == 'CASH'){
        			$rootScope.cashMarketSession = value;
        		}else if(value.instrumentCode == 'Forward'){
                    $rootScope.forwardMarketSession = value;
                }
        	})
        }

		StaticLookUp.getTenorsForLookup(onGetTenorLookupSuccess, onGetTenorLookupFailure);
		StaticLookUp.getTenorsWithBrokenDate(onGetTenorSuccess, onGetTenorFailure);
		Market.getMarketDepth(onGetMarketDepthSuccess, onGetMarketDepthFailure);
		Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
		function onGetTenorLookupSuccess(result){  
			vm.tenorsLookup = result;            
            vm.tenor = result[0];
            $timeout(function() {
                vm.tradeSettlementDate = new Date(vm.tenor.settlementDate);
				$rootScope.tradeSettlementDateFororder = vm.tradeSettlementDate;
            }, 100);
            $rootScope.tenor = vm.tenor;
        }

        function onGetTenorLookupFailure(){

        }
		function onGetTenorSuccess(result){
            vm.tenors = result;
            $rootScope.tenors = result;
            if(vm.tenors!=null && vm.tenors!=undefined && vm.tenors.length>0){
                vm.maxTenorDate = new Date(vm.tenors[vm.tenors.length-1].settlementDate);
                $rootScope.maxTenorDate = vm.maxTenorDate;
            }
        }

        function onGetTenorFailure(){

        }
		function onGetUserSpecSettingSuccess(result){
        	vm.userPreference = result;
        	$rootScope.userPreference = result;
		}
		function onGetUserSpecSettingFailure(){

		}
		function onGetMarketDepthSuccess(result){
        	if($rootScope.marketDepth==null || $rootScope.marketDepth==undefined){
            	$rootScope.marketDepth = result;
        	}
        	// get market depth for IB SPOT
			$rootScope.ibSpotMarketDepth = MarketWatch.getIBSpotMarketDepth($rootScope.marketDepth);

        }
		function onGetMarketDepthFailure(){
        	
        }
		function onGetLtpForLimitSuccess(result){
        	vm.ltpForLimitsForAllInstrument = result;
        	angular.forEach(vm.ltpForLimitsForAllInstrument, function(value){
        		if(value.instrument.instrumentCode=='SPOT'){
        			$rootScope.previousDayClosingPriceForSpot = value.lastTradeExchangeRate;
        		}else if(value.instrument.instrumentCode=='InterbankSPOT'){
        			$rootScope.previousDayClosingPriceForIbSpot = value.lastTradeExchangeRate;
        		}
        	})
        	
        }
        
        function onGetLtpForLimitFailure(){
        	
        }

		
        logScreenAccess();
   
        function getExpirtyTime(){
           Market.getCurrentDateTime(function (result){
			vm.currentDateTime = moment.parseZone(result.currentDateTime);
		});
	}
      
       function uploadOrderFile() {
		vm.clicked=true;
		Market.getCurrentDateTime(function (result){
			vm.currentDateTime = moment.parseZone(result.currentDateTime);
			//once get the real time then validate the order
			prepareAndValidateOrderUpload(vm.orderUploadList);
		});
		
       }
       
       
       function downloadOrderTemplate() {
    	   var mimeType= '';
    	   var baseString =   'Bulk_Order_'+  moment().format( 'DD_MMM_YYYY_HH_mm') + '.xlsx';   
    	   OrderUpload.downloadOrderTemplate(function(result){
    		   
    		   if(result.data != null && result.data != undefined && result.data.byteLength != 0){
    			   
    			   vm.certificateFile = result.data;
    	   			 
    				      var IEFile = new Blob([result.data], {type: mimeType});
    				      
    				     if(window.navigator.msSaveOrOpenBlob) {
    		                   window.navigator.msSaveOrOpenBlob(IEFile, baseString );
    		               }
    				      
    				      else{
    				    	  
    				    	  var link = document.createElement("a");   
    	    	   			  var bytes = new Uint8Array( vm.certificateFile );   
    	    	   			      			  
    	    				      var blob = new Blob([bytes], {type: mimeType});
    	    				      link.href =  window.URL.createObjectURL(blob);
    	    				      
    	    				      link.download = baseString;
    	        	   		   	  document.body.appendChild(link);
    	        	   		   	  link.click();
    	        	   			  document.body.removeChild(link);
    	    				      
    				      }
    				    
    		   }
    		   else{
    			   AlertService.error($filter('translate')('gatewayApp.orderUpload.fileNotExists'));
    		   }
    		  
   			  
   			 
    	   })
       }
        
       $scope.$watch('vm.orderUpload', function(value){
    	   if(vm.orderUpload != null && vm.orderUpload != undefined){
    		   if(vm.orderUpload.name != null && vm.orderUpload.name != undefined){
    			   vm.inputFile = vm.orderUpload.name;
    	    		 vm.fileExtension =  vm.inputFile.substring(vm.orderUpload.name.lastIndexOf(".") + 1);
    	    		 if(vm.fileExtension  != 'xls' && vm.fileExtension  != 'xlsx'){
						MessageBox.openMessageBox('Alert', $filter('translate')('gatewayApp.orderUpload.invalidFile'), 'Ok', 'fileInvalid');
    	    		 }else{
						var reader = new FileReader();
						reader.readAsArrayBuffer(value);
						reader.onload = function(e) {
						 var data = new Uint8Array(e.target.result);
						 vm.orderUploadList  = FileUtil.parseExcelFile(data);
						 // check column count
						if(validateExistColumnCount(vm.orderUploadList[0]) < 21){
							MessageBox.openMessageBox('Alert', $filter('translate')('gatewayApp.orderUpload.invalidFile'), 'Ok', 'fileInvalid');
							
						}
						// check row count
						else if(vm.orderUploadList.length > 101){
							MessageBox.openMessageBox('Alert', $filter('translate')('gatewayApp.orderUpload.fileRowCountInvalid'), 'Ok', 'fileInvalid');
						}  	
						};
                }
    	    		 
    		   }else{
				        vm.orderUpload = null;
    	    			 vm.inputFile = null;
						 vm.invalidFile = false;
			   }  
    	   }
		   else{
			vm.orderUpload = null;
			 vm.inputFile = null;
			 vm.invalidFile = false;
   }
       })
	   function validateExistColumnCount(columns){
		return columns.filter(Boolean).length;
	   }

	   $rootScope.$on('gatewayApp.messagebox.ok.fileInvalid', function (event) {
				angular.element("input[type='file']").val(null);
    	    	vm.orderUpload = null;
    	    	vm.inputFile = null;
	  });
     
        	
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }


	   function prepareAndValidateOrderUpload(orderDTOList){
		vm.orderDTOList = [];
    	for(var i=1;i<orderDTOList.length;i++){
			vm.orderRequestDTO = orderDTOList[i];
			vm.importErrorString = "";
			if(vm.orderRequestDTO!=null && vm.orderRequestDTO!='' && vm.orderRequestDTO!=""){
				vm.orderDTO={};
				vm.orderDTO.SystemUniqueSNo = i;
				if(validateExistColumnCount(vm.orderRequestDTO)>=2){
					/// validation 1
				validateDataFields();
				 if(vm.importErrorString==""){
					setSpreadValues();
					/// validation 2
					validateOrder();
				 }
				}else{
					vm.importErrorString += "Invalid column count";
				}
				
				vm.orderDTO.exceptionMessage = vm.importErrorString;
				
				vm.importErrorString=="" ? vm.orderDTO.uploadStatus = 'SUCCESS' : vm.orderDTO.uploadStatus = 'FAILED' ;
				prepareOrderDTOAfterValidation();
				// clear values after each order validation
				clearValues();
			}
			vm.orderDTOList.push(vm.orderDTO);
		}
		// dialog screen show after order validation
		if(vm.userPreference.showOrderConfirmationForBulkOrder=='no'){
			$state.go("order-upload-status",{orderDTOList:vm.orderDTOList});
			angular.element("input[type='file']").val(null);
			vm.orderUpload = null;
    	    vm.inputFile = null;
			vm.clicked=false;
		}else if(vm.userPreference.showOrderConfirmationForBulkOrder=='yes'){
			$state.go("order-upload-confirmation",{orderDTOList:vm.orderDTOList});
			angular.element("input[type='file']").val(null);
			vm.orderUpload = null;
    	    vm.inputFile = null;
			vm.clicked=false;
		}
	   }

		function clearValues() {
			$rootScope.defaultBankSpread = "";
			$rootScope.tradeSettlementDateFrom = "";
			$rootScope.tradeSettlementDateFororder = "";
			$rootScope.tenor = null;
			vm.exposureSubType = null;
			vm.exposureType = null;

		}

		function validateOrder() {
			$rootScope.configurationSettingsForOrder = angular.copy(vm.configSettings);
			$rootScope.userPreferenceForOrder = angular.copy(vm.userPreference);
			validateMarketOrder(vm.orderDTO.typeOfOrder);
			const validateSettlementDate = OrderUtil.validateSettlementDate(vm.orderDTO.instrumentCode, vm.orderDTO.isOptionPeriod, vm.orderDTO.tradeSettlementDate, 'Settlement');
			const validateOptionStartSettlementDate = OrderUtil.validateOptionSettlementDate(vm.orderDTO.instrumentCode, vm.orderDTO.isOptionPeriod, vm.orderDTO.tradeSettlementDateFrom, 'Start');
			const validateOptionEndSettlementDate = OrderUtil.validateOptionSettlementDate(vm.orderDTO.instrumentCode, vm.orderDTO.isOptionPeriod, vm.orderDTO.tradeSettlementDateTo, 'End');
			const validateOptionPeriods = OrderUtil.validateOptionPeriods(vm.orderDTO.instrumentCode, vm.orderDTO.isOptionPeriod, vm.orderDTO.tradeSettlementDateFrom, vm.orderDTO.tradeSettlementDateTo);
			const vadliteSessionStatus = OrderUtil.validateSessionStatus(vm.orderDTO.instrumentCode);
			const validateAgreedTAC = OrderUtil.validateAgreedTAC(vm.bankForTrading);
			const validateOrderAmount = OrderUtil.validateOrderAmount(vm.orderDTO.orderAmount);
			const validateLimitExchangeRateHardCheck = OrderUtil.validateLimitExchangeRateHardCheck(vm.orderDTO.buySellIndicator, vm.orderDTO.typeOfOrder, null, vm.orderDTO.instrumentCode, vm.orderDTO.activityIndicator,
				vm.orderDTO.orderAmount, null, vm.orderDTO.limitExchangeRate, vm.orderDTO.limitExchangeRateInSpot, null, null, null, null);
			//validate spread uses if only settlement dates are valid
			if(validateSettlementDate.isValid && validateOptionStartSettlementDate.isValid && validateOptionEndSettlementDate.isValid){
				const validateSpreadUsed = OrderUtil.validateSpreadUsed($rootScope.defaultBankSpread, vm.orderDTO.instrumentCode, vm.orderDTO.buySellIndicator);
				if (!validateSpreadUsed.isValid) {
					vm.importErrorString += validateSpreadUsed.errorString + ";";
				}
			}
			if (!validateSettlementDate.isValid) {
				vm.importErrorString += validateSettlementDate.errorString + ";";
			}
			if (!validateOptionStartSettlementDate.isValid) {
				vm.importErrorString += validateOptionStartSettlementDate.errorString + ";";
			}
			if (!validateOptionEndSettlementDate.isValid) {
				vm.importErrorString += validateOptionEndSettlementDate.errorString + ";";
			}
			if (!validateOptionPeriods.isValid) {
				vm.importErrorString += validateOptionPeriods.errorShortString + ";";
			}
			if (!vadliteSessionStatus.isValid) {
				vm.importErrorString += vadliteSessionStatus.errorString + ";";
			}
			if (!validateAgreedTAC.isValid) {
				vm.importErrorString += validateAgreedTAC.errorString + ";";
			}
			if (!validateOrderAmount.isValid) {
				vm.importErrorString += validateOrderAmount.errorShortString + ";";
			}
			if (!validateLimitExchangeRateHardCheck.isValid) {
				vm.importErrorString += validateLimitExchangeRateHardCheck.errorShortString + ";";
			}
			
		}

		function validateDataFields() {
			validateSerialNumber(vm.orderRequestDTO[0]);
			validateCustomer(vm.orderRequestDTO[1]);
			validateRelationshipBank(vm.orderRequestDTO[2]);
			validateAndSetInstrument(vm.orderRequestDTO[3]);
			vm.orderDTO.activityIndicator = 'PLAC';
			validateBuySellIndicator(vm.orderRequestDTO[4]);
			vm.orderDTO.currencyPair = vm.orderRequestDTO[5];
			if (vm.orderDTO.currencyPair == "") {
				vm.importErrorString += "Currency Pair cannot be empty;";
			}
			validateOrderType(vm.orderRequestDTO[6]);
			validateOrderAmount(vm.orderRequestDTO[7]);
			validateLimitExchangeRate(vm.orderRequestDTO[8]);
			validateTimeLimitIndicator(vm.orderRequestDTO[9]);
			vm.orderDTO.tradeSettlementDate = vm.orderRequestDTO[11];
			vm.orderDTO.forwardType = vm.orderRequestDTO[14];
			vm.orderDTO.tradeSettlementDateFrom = vm.orderRequestDTO[15];
			vm.orderDTO.tradeSettlementDateTo = vm.orderRequestDTO[16];
			validateForwardInstrument(vm.orderDTO.forwardType);
			vm.orderDTO.userId = vm.globalValues.getUserId();
			vm.orderDTO.retailUserCode = vm.globalValues.getUser().login;
			vm.orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
			vm.orderDTO.stopExchangeRate = 0;
			validateRemarks(vm.orderRequestDTO);
		}

		function validateMarketOrder(typeOfOrder){
			if(typeOfOrders.MARKET.match(typeOfOrder)){
				if(vm.configSettings.isAllowMarketOrder!='Yes'){
					vm.importErrorString += "Market Order is not allowed;";
				}
			}
		}
		function setSpreadValues() {
			if (vm.orderDTO.instrumentCode != 'SPOT') {
				var tickSize = OrderUtil.gettickSizeForGivenInstrument(vm.orderDTO.instrumentCode);

				vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.orderDTO.instrumentCode, vm.orderDTO.buySellIndicator,
					DateUtils.convertLocalDateToServer(getSettlementDate(vm.orderDTO.instrumentCode, vm.orderDTO.buySellIndicator, vm.orderDTO.tradeSettlementDateFrom, vm.orderDTO.tradeSettlementDateTo)), $rootScope.retailSpotMarketSession.settlementDate,
					tickSize);
				if (vm.orderDTO.instrumentCode != 'Forward') {
					vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.spreadUsed);
				} else {
					vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, (vm.spreadUsed * -1));
				}
				vm.orderDTO.spreadUsed = vm.spreadUsed;
			} else {
				vm.orderDTO.limitExchangeRateInSpot = vm.orderDTO.limitExchangeRate;
			}
		}

		function validateForwardInstrument(forwardType) {
			if (vm.orderDTO.instrumentCode == 'Forward') {

				validateAndSetExposures();
				// check the forwardtype is empty (broken date)
				if (forwardType == "") {
					validateSettlementDate(vm.orderDTO.tradeSettlementDate, "Settlement Date");
					vm.orderDTO.isOptionPeriod = false;
					vm.orderDTO.tradeSettlementDateFrom = null;
				// check the forwardtype is option period
				} else if (forwardType.toUpperCase() == "OPTION PERIOD") {
					vm.orderDTO.isOptionPeriod = true;
					var filteredTenor = $filter('filter')(vm.tenorsLookup, { tenorName: 'Option Period' });
					if (filteredTenor != null && filteredTenor != undefined) {
						vm.tenor = filteredTenor[0];
						$rootScope.tenor = vm.tenor;
					}
					validateSettlementDate(vm.orderDTO.tradeSettlementDateFrom, "Start Date");
					validateSettlementDate(vm.orderDTO.tradeSettlementDateTo, "End Date");
				} else {
					vm.importErrorString += "Invalid Forward Type;";
				}
			} else {
				vm.orderDTO.isOptionPeriod = false;
				vm.orderDTO.tradeSettlementDateFrom = null;
			}
		}

		function validateAndSetExposures() {
			if (vm.orderRequestDTO[12] == "") {
				vm.orderDTO.exposureTypeName = "-";
			} else {
				vm.orderDTO.exposureTypeName = vm.orderRequestDTO[12];
			}
			if (vm.orderRequestDTO[13] == "") {
				vm.orderDTO.exposureSubTypeName = "-";
			} else {
				vm.orderDTO.exposureSubTypeName = vm.orderRequestDTO[13];
			}
			validateAndSetExposureType(vm.orderDTO.exposureTypeName);
			validateAndSetExposureSubType(vm.orderDTO.exposureSubTypeName);
		}

		function validateTimeLimitIndicator(timeLimitIndicator) {
			vm.orderDTO.timeLimitIndicator = timeLimitIndicator;
			timeLimitIndicator = timeLimitIndicator.toUpperCase();
			if (timeLimitIndicator == "") {
				vm.importErrorString += "Order Time Indicator  cannot be empty;";
			} else if (timeLimitIndicators.hasOwnProperty(timeLimitIndicator))  {
				vm.orderDTO.timeLimitIndicator = timeLimitIndicators[timeLimitIndicator];
			} else {
				vm.importErrorString += 'Invalid Order Time Indicator;';
			}
			// validate expiry time
			validateExpiryTime();
		}

		function validateExpiryTime() {
			if (vm.orderDTO.timeLimitIndicator == "GTTM") {
				vm.orderDTO.expiryTime = vm.orderRequestDTO[10];
				vm.orderDTO.expiryTimeString = vm.orderRequestDTO[10];
				if (vm.orderDTO.expiryTime == "") {
					vm.importErrorString += "GTT Time cannot be empty;";
				} else {
					vm.serverDateUTCOffset =  vm.currentDateTime.utcOffset();
         			vm.currentTime = moment.parseZone(vm.currentDateTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
					  vm.closeTime = OrderUtil.getCloseTimeForInstrumentCode(vm.orderDTO.instrumentCode);
					 if(vm.serverDateUTCOffset == moment().utcOffset()){
						vm.expiryTime = moment(vm.orderDTO.expiryTime, 'HH:mm').format( 'HH:mm');
					}else{
						vm.expiryTime = moment(vm.orderDTO.expiryTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
					}
					if (vm.expiryTime == "Invalid date") {
						vm.importErrorString += "Invalid Time format;";
					} else 
					//validate close time
					if(vm.expiryTime.split(':')[0] > vm.closeTime.split(':')[0]){
						vm.importErrorString += 'Expiry time cannot be greater than ' + vm.orderDTO.instrumentCode +  ' session close time '+ vm.closeTime + ';';
						
					}else if((vm.expiryTime.split(':')[0] == vm.closeTime.split(':')[0]) && (vm.expiryTime.split(':')[1] >= vm.closeTime.split(':')[1])){
						vm.importErrorString += 'Expiry time cannot be greater than ' + vm.orderDTO.instrumentCode +  ' session close time '+ vm.closeTime + ';';
						
					} else
					// validate open time
					 if (vm.expiryTime.split(':')[0] < vm.currentTime.split(':')[0]) {
						vm.importErrorString += "GTT Time cannot be lesser than current time;";
					} else if ((vm.expiryTime.split(':')[0] == vm.currentTime.split(':')[0]) && (vm.expiryTime.split(':')[1] < vm.currentTime.split(':')[1])) {
						vm.importErrorString += "GTT Time cannot be lesser than current time;";
					}
					formatExpiryTime();
					
				}
			}
		}
		function formatExpiryTime(){
        	var expiryTime;
			
        	if(vm.serverDateUTCOffset == moment().utcOffset()){
        		expiryTime = moment(vm.expiryTime, 'HH:mm').format( 'HH:mm');
         	}else{
         		expiryTime = moment(vm.expiryTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
         	}
        	var expiryTimeInString = "";
        	if(expiryTime.split(':').length>1){
            	expiryTimeInString = expiryTime.split(':')[0] + expiryTime.split(':')[1] + '00';
            	var todayDateString = moment(vm.currentDate).utcOffset(vm.serverDateUTCOffset).format( 'YYYYMMDD');
            	expiryTimeInString = todayDateString + expiryTimeInString;
        	}
			vm.orderDTO.expiryTime = expiryTimeInString;
			vm.orderDTO.expiryTimeString = expiryTime;
        }

		function validateLimitExchangeRate(limitExchangeRate) {
			vm.orderDTO.limitExchangeRate = limitExchangeRate;
			if (vm.orderDTO.typeOfOrder == "LIMT") {
				if (limitExchangeRate === "") {
					vm.importErrorString += "Limit price cannot be empty;";
				} else {
					var splitLimitRate = limitExchangeRate.toString().split(".");
					if (splitLimitRate.length > 1 && splitLimitRate[1].length > $rootScope.noOfDecimalsForRateForOrder) {
						vm.importErrorString += "Limit price should have " + $rootScope.noOfDecimalsForRateForOrder + " decimals digits;";
					}
				}
			}
		}

		function validateOrderType(typeOfOrder) {
			vm.orderDTO.typeOfOrder = typeOfOrder;
			typeOfOrder = typeOfOrder.toUpperCase();
			if (typeOfOrder == "") {
				vm.importErrorString += "Order type cannot be empty;";
			} else if (typeOfOrders.hasOwnProperty(typeOfOrder)) {
				vm.orderDTO.typeOfOrder = typeOfOrders[typeOfOrder];
			} else {
				vm.importErrorString += "Order type should be Limit or Market;";
			}
		}

		function validateOrderAmount(orderAmount) {
			vm.orderDTO.orderAmount = orderAmount;
			if (vm.orderDTO.orderAmount === "") {
				vm.importErrorString += "Order Quantity cannot be empty;";
			} else {
				var splitOrderAmount = vm.orderDTO.orderAmount.toString().split(".");
				if (splitOrderAmount.length > 1 && splitOrderAmount[1].length > $rootScope.noOfDecimalsForAmountForOrder) {
					vm.importErrorString += "Order quantity should have " + $rootScope.noOfDecimalsForAmountForOrder + " decimals digits;";
				}
			}
		}

		function validateBuySellIndicator(buySellIndicator) {
			vm.orderDTO.buySellIndicator = buySellIndicator;
			buySellIndicator = buySellIndicator.toUpperCase();
			if (vm.orderDTO.buySellIndicator == "") {
				vm.importErrorString += "Buy/Sell cannot be empty;";
			} else if (buySellMap.hasOwnProperty(buySellIndicator)) {
				vm.orderDTO.buySellIndicator = buySellMap[buySellIndicator];
			} else {
					vm.importErrorString += "Invalid Buy/Sell;";
				}
			
		}

		function validateSerialNumber(serialNumber) {
			if (serialNumber == "") {
				vm.importErrorString += "Serial Number cannot be empty;";
			} else {
				//vm.orderDTO.SystemUniqueSNo = serialNumber;
				vm.orderDTO.serialNumber = serialNumber;
				//validateSerialNumber(serialNumber);
			}
		}

		function checkAndvalidateSerialNumber(serialNumber) {
			if (isNaN(vm.orderDTO.SystemUniqueSNo)) {
				vm.importErrorString += "Serial Number is Invalid;";
			} else {
				var duplicateOrders = vm.orderUploadList.filter(function (orderDTO) {
					return orderDTO[0] === serialNumber;
				});

				if (duplicateOrders.length > 1) {
					vm.importErrorString += "Serial Number cannot be duplicate;";
				}
			}
		}

	   function prepareOrderDTOAfterValidation() {
		setEventType();
		
		if(vm.orderDTO.instrumentCode!='Forward'){
			vm.orderDTO.tradeSettlementDate = null;
			vm.orderDTO.tradeSettlementDateFrom = null;
		}
		if(vm.orderDTO.tradeSettlementDateTo!=""){
			vm.orderDTO.tradeSettlementDate = vm.orderDTO.tradeSettlementDateTo;
		}
		
		if(vm.orderDTO.timeLimitIndicator!='GTTM'){
			vm.orderDTO.expiryTime = 0;
		}
		if(vm.orderDTO.typeOfOrder == "MRKT"){
            vm.orderDTO.limitExchangeRate=0;
        }

		//vm.orderDTO.limitExchangeRate = $filter('number')(vm.orderDTO.limitExchangeRate, $rootScope.noOfDecimalsForRateForOrder);
		
	   }

		function validateSettlementDate(settlementDate,fieldName) {
			if (settlementDate == "") {
				vm.importErrorString += fieldName+" cannot be empty;";
			} else {
				var settlemendDateServerFormat = DateUtils.convertLocalDateToServer(settlementDate);
				var holidayList = _.filter(vm.holidayListAfterBusinessDate, function(s){return s.indexOf(settlemendDateServerFormat)!==-1;});
           		if(holidayList!=null && holidayList!=undefined && holidayList.length>0){
					vm.importErrorString += fieldName+" cannot be a holiday;";
				}
				var dateRegex = /^\d{2}-\d{2}-\d{4}$/;
				var date =$filter('date')(settlementDate, 'dd-MM-yyyy');
				if (!dateRegex.test(date)) {

					vm.importErrorString += "Invalid "+fieldName+".Format should be in 'dd-mm-yyyy';";
				}
				else if (moment(vm.currentDate).isAfter(settlemendDateServerFormat)) {

					vm.importErrorString += fieldName+" cannot be past date;";
				}else{
					if(fieldName=='Settlement Date' || fieldName=='End Date'){
						$rootScope.tradeSettlementDateFororder = settlementDate;
					}else{
						$rootScope.tradeSettlementDateFrom = settlementDate;
					}
					
				}
				
			}
		}

	// validate customer 
	   function validateCustomer(customerCode){
		if(customerCode==""){
			vm.importErrorString += "Customer ID cannot be empty;";
		}else{
			vm.orderDTO.customerCode=customerCode;
			const isInvalidCustomer = ((vm.entityOwnerType === 'MultiUserCustomer' || vm.entityOwnerType === 'SingleUserCustomer') && customerCode !== vm.loggedInCustomer.customerCode);
				if(isInvalidCustomer){
					vm.importErrorString += "Customer ID does not belong to the uploading user;";
				}
           }
		
	   }
	   // validate relationship bank
	   function validateRelationshipBank(ngCode){
		vm.orderDTO.bankNegotiationCode = ngCode;
		if(ngCode==""){
			vm.importErrorString += "Bank Negotiation Code cannot be empty;";
		}else{
			validateTradingBankRelationship(vm.orderRequestDTO[2],vm.orderDTO.customerCode);
		}
	   }

	   //need to common
	   function getSpotRateForGivenRateAndSpread(rate, spread){
		if(rate>0){
			var spotRate = Number(rate) + Number(spread)/100;
			return spotRate;
		}else{
			return null;
		}
	}

		function validateAndSetInstrument(instrumentCode) {
			vm.orderDTO.instrumentCode = instrumentCode;
			instrumentCode = instrumentCode.toUpperCase();
			vm.orderDTO.instrumentId = null;
			if(instrumentCode==""){
				vm.importErrorString += "Instrument Code cannot be empty;";
			}else{
				if(instrumentCode=="FORWARD"){
					instrumentCode = "Forward";
				}
				angular.forEach(vm.instrumentTypes, function (value) {
					if (value.instrumentCode == instrumentCode) {
						vm.orderDTO.instrumentId = value.id;
						vm.orderDTO.instrumentCode=value.instrumentCode;
					}         
				});
				if(vm.orderDTO.instrumentId==null){
					vm.importErrorString += "Invalid Instrument Code;";
				}
			}
			

			
		}
		function validateAndSetExposureType(exposureType){
            if(exposureType==null || exposureType==undefined || exposureType==''){
                exposureType = $rootScope.userPreference.userExposureType;
            }
            angular.forEach(vm.exposureTypes, function(value){
                if(value.name.toUpperCase()==exposureType.toUpperCase()){
                    vm.exposureType = value;
                }
            });
			if(vm.exposureType==null){
				vm.importErrorString += "Invalid exposure type;";
			}else{
				vm.orderDTO.exposureTypeId = vm.exposureType.id;
				vm.orderDTO.exposureTypeName = vm.exposureType.name;
			
			}
        }

        function validateAndSetExposureSubType(exposureSubType){
            if(exposureSubType==null || exposureSubType==undefined || exposureSubType==''){
                exposureSubType = $rootScope.userPreference.userExposureSubType;
            }
            angular.forEach(vm.exposureSubTypes, function(value){
                if(value.name.toUpperCase()==exposureSubType.toUpperCase()){
                    vm.exposureSubType = value;
                }
            });
			if(vm.exposureSubType==null){
				vm.importErrorString += "Invalid sub exposure type;";
			}else{
				vm.orderDTO.exposureSubTypeId = vm.exposureSubType.id;
				vm.orderDTO.exposureSubTypeName = vm.exposureSubType.name;
			}
        }

	   function validateRemarks(orderRequestDTO){
			var remarkRegex = /[^a-zA-Z0-9-\s.]/g;

			vm.orderDTO.remarks   = orderRequestDTO[17].toString();
			vm.orderDTO.remarks2  = orderRequestDTO[18].toString();
			vm.orderDTO.remarks3  = orderRequestDTO[19].toString();
			vm.orderDTO.remarks4  = orderRequestDTO[20].toString();
			if(vm.orderDTO.remarks.match(remarkRegex)){
				vm.importErrorString += "Remarks 1 Special characters other than - and . are not allowed;";
			}
			if(vm.orderDTO.remarks2.match(remarkRegex)){
				vm.importErrorString += "Remarks 2 Special characters other than - and . are not allowed;";
			}
			if(vm.orderDTO.remarks3.match(remarkRegex)){
				vm.importErrorString += "Remarks 3 Special characters other than - and . are not allowed;";
			}
			if(vm.orderDTO.remarks4.match(remarkRegex)){
				vm.importErrorString += "Remarks 4 Special characters other than - and . are not allowed;";
			}
			
	   }

	   function setEventType(){
		var eventType = OrderUtil.getEventTypeForShortName('Booking');
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;
                vm.orderDTO.settlementLegId = null;
	   }



	   function validateTradingBankRelationship(ngCode,customerCode){
		        	
		angular.forEach(vm.allTradingBanks, function(value){
			   if(value.bank.ngCode==ngCode.toUpperCase()  && value.customer.customerCode==customerCode ){
				   vm.orderDTO.branchId=value.tradingBranch.id;
				   vm.orderDTO.branchName=value.tradingBranch.branchName;
				   vm.orderDTO.branchCode=value.tradingBranch.branchId;
				   vm.orderDTO.customerId=value.customer.id;
				   vm.orderDTO.customerName=value.customer.customerFirstName;
				   vm.orderDTO.customerCode=value.customer.customerCode;
				   vm.orderDTO.bankName=value.bank.bankName;
				   vm.orderDTO.bankId=value.bank.id;
				   vm.orderDTO.bankMemberNumber = value.bank.bankMemberNo;
				   vm.bankForTrading=value.bank;
				   if($rootScope.bankComparativeDTO!=null){
					$rootScope.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, value.bank.id);
				   }
				   
			   }
			   
		   });
		if(vm.orderDTO.bankId==null || vm.orderDTO.bankId==undefined || vm.orderDTO.bankId==""){
				   vm.importErrorString = vm.importErrorString + "There is no trading relationship for the customer with the bank or branch."
			   }
   }
   /* get settlemnt date based on the order type, instrument and tenor name*/
   function getSettlementDate( instrumentCode, orderType, tradeSettlementDateFrom,tradeSettlementDateTo){
	var settlementDate = angular.copy($rootScope.tradeSettlementDateFororder);        
	if(instrumentCode == 'Forward' && $rootScope.tenor !=null && $rootScope.tenor != undefined && $rootScope.tenor.tenorName == 'Option Period'){
		if( orderType =="SELL" ){
			settlementDate = angular.copy(tradeSettlementDateFrom);
		}else
		{
			settlementDate = angular.copy(tradeSettlementDateTo);
		}
	}
	return settlementDate;
}
       
     
    }
})();
