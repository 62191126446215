(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('BankRegistrationDialogController', BankRegistrationDialogController);

    BankRegistrationDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','BankRegistration','entity', 'DateUtils','MessageBox','AlertService','$filter'];

     function BankRegistrationDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,BankRegistration,entity,DateUtils,MessageBox,AlertService,$filter) {
        var vm = this;
        vm.bankRegistration=entity;   
        vm.bankDTO = {};
        vm.user1={};
        vm.user2={};
        vm.ExpandOrCollapseUser1 = false;
        vm.ExpandOrCollapseUser2 = false;
        vm.ExpandOrCollapseBankDetails = true;
        vm.user1.mobileCountryCode = 91;
        vm.user2.mobileCountryCode = 91;
        vm.mobileCountryCode = 91;
        vm.user1.gender= "M";
        vm.user2.gender= "M";
                
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.resubmitBankRegistration =resubmitBankRegistration;
        vm.globalValues = GlobalValues;
       // vm.bankRegistration.country=vm.globalValues.getCountry();
        vm.user1.country=vm.globalValues.getCountry();
        vm.user2.country=vm.globalValues.getCountry();
        vm.country = vm.globalValues.getCountry();
        $scope.showButton = false;
        vm.todayDate=new Date();
        
       /* $scope.$watch('vm.bankRegistration.dateOfJoining', function(value){
        	if(value!=null && value!=undefined){
        		
        			if(moment(value).isAfter(vm.todayDate,'day')){
                		$scope.clicked=false;
                		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futureDateOfJoining'));
                		 
                	 }
        		}
        	
        })*/
        
        /*$scope.$watch('vm.bankRegistration.dateOfJoining', function(newValue, oldValue){
        	if(newValue!=null && newValue!=undefined){
        		if(moment(newValue).isSame(oldValue,'day')){
            		if(moment(newValue).isAfter(vm.todayDate,'day')){
            			AlertService.error($filter('translate')('gatewayApp.AuditLog.futureFromDate'));
            			vm.valid=false;
            		}
        		}
        	}
        })*/
                
        if(workflowSharedService.viewFrom == 'bankregistration'){
        	vm.bankRegistration=entity;
        	 vm.bankRegistration.country=vm.globalValues.getCountry();
        	if(vm.bankRegistration.mobileCountryCode!=null || vm.bankRegistration.mobileCountryCode!=undefined){
        		vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
        	}
        	        	
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'bank-registrationtodolist_resubmit') {
        	vm.bankDTO =  angular.fromJson($rootScope.payload);
        	vm.bankRegistration = vm.bankDTO.bank;
        	//vm.bankRegistration.mobileCountryCode = 91;
        	
        	if(vm.bankRegistration == undefined){
        		vm.bankRegistration = vm.bankDTO;
        		
        	}else{
        		vm.user1 = vm.bankDTO.user1;
 		        vm.user2 = vm.bankDTO.user2;
 		        vm.user1.dob=new Date(vm.bankDTO.user1.dob);
 	  			vm.user2.dob=new Date(vm.bankDTO.user2.dob);
        	}
        	
        	
        	vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
		    vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'bank-registration_resubmit_delete') {
        	vm.bankRegistration = angular.fromJson($rootScope.payload); 
        	vm.disableForm = true;
        	$scope.showButton=true;
        }
		vm.bankRegistration.dateOfJoining = new Date(vm.bankRegistration.dateOfJoining);
		
	
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	if(moment(vm.bankRegistration.dateOfJoining).isAfter(vm.todayDate,'day')){
        		
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futureDateOfJoining'));
        		 
        	 }else if(moment(vm.user1.dob).isAfter(vm.todayDate,'day')){
        		
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futuredobUser1'));
        	 }else if(moment(vm.user2.dob).isAfter(vm.todayDate,'day')){
        		 
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futuredobUser2'));
        	 }
        	else{
				vm.clicked=true
        		vm.bankRegistration.aliasName = vm.bankRegistration.bankName;
            	vm.bankRegistration.shortName = vm.bankRegistration.bankName;
            	vm.bankRegistration.name = vm.bankRegistration.bankName;
            	vm.bankRegistration.mobileCountryCode=vm.mobileCountryCode;
            	vm.bankRegistration.legalName = vm.legalName;
            	vm.bankRegistration.tradeName = vm.tradeName;
            	
            	vm.bankRegistration.dateOfJoining = DateUtils.convertLocalDateToServer(vm.bankRegistration.dateOfJoining);
            	 if (vm.bankRegistration.id == null) {
            		 vm.bankDTO.bank = vm.bankRegistration;
                 	vm.bankDTO.user1 = vm.user1;
                 	vm.bankDTO.user2 = vm.user2;
                    	 vm.bankDTO.user1.entityOwnerTypeShortName = 'Bank'
                 		 vm.bankDTO.user2.entityOwnerTypeShortName = 'Bank'
                 	vm.user1.dob = DateUtils.convertLocalDateToServer(vm.bankDTO.user1.dob);
                 	vm.user2.dob = DateUtils.convertLocalDateToServer(vm.bankDTO.user2.dob);
            	 }
            	    	
            	
            	//vm.bankRegistration.getEntityRestrictionStatus() = "Active";
            	 if (vm.bankRegistration.id !== null) {
                     BankRegistration.update({ "corpId": GlobalValues.getCorpId() },vm.bankRegistration, onSaveSuccess, onSaveError);
                 } else {
                	 BankRegistration.save({ "corpId": GlobalValues.getCorpId() },vm.bankDTO, onSaveSuccess, onSaveError);           
                 }    
        	}
        	   	
         }
      
        vm.memberNo = '';
        vm.getUserNo = function getUserNo(MemberNo){
        	 
        	 if (MemberNo!=vm.memberNo && MemberNo!=null && MemberNo!="" && (/^\d+$/.test(MemberNo))){
        		 vm.memberNo = MemberNo;
        		 vm.flag = 1;
        		//vm.bankRegistration.userNo = BankRegistration.getUserNo({"MemberNo":MemberNo});
        		BankRegistration.getUserNo({"MemberNo":MemberNo}, function(result) {
        			vm.bankRegistration.userNo = result.userNo;
        			//vm.bankRegistration.ngCode = result.ngCode;
        			if(result == null){
        				MessageBox.openMessageBox("Alert", "Invalid Member No", "Ok", "invalidmember");
        			}
                });
        		//vm.UserNo  = vm.bankRegistration.userNo;
        			
        	}
        }
        
       
        
        $scope.$watch('vm.bankRegistration.state', function(value){
           	if(value!=null && value!=undefined){
           		vm.stateCode = vm.bankRegistration.state.tinNumber;
           	}
           });
        
        $scope.$watch('vm.bankRegistration.bankName', function(value){
           	if(value!=null && value!=undefined){
           		vm.legalName=value;
           		vm.tradeName=value;
           	}
           });
        
        $rootScope.$on('gatewayApp.messagebox.ok.invalidmember', function (event, data) {
        	//do nothing
        });
     
        function onSaveSuccess (result) {
			vm.clicked=false;
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	vm.bankRegistration.dateOfJoining = DateUtils.convertDateTimeFromServer(vm.bankRegistration.dateOfJoining);
        	vm.clicked = false;
        	    	vm.user1.dob = DateUtils.convertDateTimeFromServer(vm.bankDTO.user1.dob);
        	vm.user2.dob = DateUtils.convertDateTimeFromServer(vm.bankDTO.user2.dob);
        	
        }
        function resubmitBankRegistration() {
        	
        	if(moment(vm.bankRegistration.dateOfJoining).isAfter(vm.todayDate,'day')){
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futureDateOfJoining'));
        		 
        	 }else if(moment(vm.user1.dob).isAfter(vm.todayDate,'day')){
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futuredobUser1'));
        	 }else if(moment(vm.user2.dob).isAfter(vm.todayDate,'day')){
        		 AlertService.error($filter('translate')('gatewayApp.bankRegistration.futuredobUser2'));
        	 }
        	else{
				vm.clicked = true;
        		vm.bankRegistration.aliasName = vm.bankRegistration.bankName;
            	vm.bankRegistration.shortName = vm.bankRegistration.bankName;
            	vm.bankRegistration.name = vm.bankRegistration.bankName;
            	vm.bankRegistration.mobileCountryCode=vm.mobileCountryCode;
            	vm.bankRegistration.legalName = vm.legalName;
            	vm.bankRegistration.tradeName = vm.tradeName;
            	
            	vm.bankRegistration.dateOfJoining = DateUtils.convertLocalDateToServer(vm.bankRegistration.dateOfJoining);
            	 if (vm.bankRegistration.id == null) {
            		 vm.bankDTO.bank = vm.bankRegistration;
                 	vm.bankDTO.user1 = vm.user1;
                 	vm.bankDTO.user2 = vm.user2;
                 
                 	 vm.bankDTO.user1.entityOwnerTypeShortName = 'Bank'
                 		 vm.bankDTO.user2.entityOwnerTypeShortName = 'Bank'
                 	vm.bankDTO.user1.dob = DateUtils.convertLocalDateToServer(vm.user1.dob);
                 	vm.bankDTO.user2.dob = DateUtils.convertLocalDateToServer(vm.user2.dob);
            	 }
            	 
            	 if (vm.bankRegistration.id == null) {
            		 BankRegistration.resubmitForCreate({"taskId" : $rootScope.taskID}, vm.bankDTO, onResubmitSuccess, onResubmitError);	 
            	 }else{
            		 BankRegistration.resubmitForEdit({"taskId" : $rootScope.taskID}, vm.bankRegistration, onResubmitSuccess, onResubmitError);
            	 }
        	}
        	
        }
        
        function onResubmitSuccess(result) {
			vm.clicked = false;
        	$rootScope.removeRow($rootScope.taskID);
        	$uibModalInstance.dismiss('cancel');
        }        
        function onResubmitError(error) {
			vm.bankDTO.user1.dob = DateUtils.convertLocalDateFromServer(vm.bankDTO.user1.dob);
			vm.bankDTO.user2.dob = DateUtils.convertLocalDateFromServer(vm.bankDTO.user2.dob);
			vm.bankDTO.bank.dateOfJoining = DateUtils.convertLocalDateFromServer(vm.bankDTO.bank.dateOfJoining);
        	vm.clicked = false;
        }

     }   
})();
