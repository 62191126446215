(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changemobile'
	})

	.controller('ChangeMobileController', ChangeMobileController);

	ChangeMobileController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'ChangeMobile', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', '$stateParams',
			'$uibModalInstance', '$rootScope', 'MessageBox' ,'globalValue', 'AlertService', '$filter'];

	function ChangeMobileController($scope, $state, workflowSharedService,
			ChangeMobile, GlobalValues, EntityPermissions, ScreenLog,
			$stateParams, $uibModalInstance, $rootScope, MessageBox,globalValue, AlertService, $filter) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.userProfile = {};
		vm.user = {};
		vm.changeMobile = changeMobile;
		vm.sendOTP = sendOTP;
		vm.changeUserMobile = changeUserMobile;
		vm.showChangeMobile = false;
		vm.isOTPSend = false;
		vm.clear = clear;
		vm.checkPassword = checkPassword;
		vm.currentMobile = $stateParams.mobile;
		vm.currentMobileCountryCode = $stateParams.countryCode;
		vm.userId = $stateParams.userId;
		vm.customerId = $stateParams.customerId;
		vm.mode = $stateParams.mode;
		vm.userProfile.confirmMobileCountryCode = 91;
		vm.userProfile.newMobileCountryCode = 91;
		vm.disableGenerateOTP= false;
		$scope.clicked=false;
		vm.otpClicked = false;
		vm.password=null;
		vm.allowedPasswordCharacters=/^[!@#^&*()\-_.:;a-zA-Z0-9]/;
		vm.wrongOTPAttemptCount = 0;
		vm.resendOTPAttemptCount = 0;

		$scope.otpInput = {
            size : globalValue.defaultOTPLength,
			type : "password",
			onDone : function(value) {
				vm.otp = value;
			},
			onChange : function(value) {
				console.log(value);
			}
		};

		vm.launch = launch;
		function launch() {
			$state.go("user-profile");
		}

		function sendOTP() {
			vm.disableGenerateOTP= true;
			if(vm.mode == 'customerinfo'){
				ChangeMobile.SendOTPSMSForCustomer({
					"mobile" : vm.currentMobile,
					"newMobile" : vm.userProfile.confirmMobile,
					"newMobileCountryCode": vm.userProfile.confirmMobileCountryCode
				}, onOTPSendSuccess, onSendOTPError);
			}else{
				ChangeMobile.SendOTPSMS({
					"userId" : vm.userId,
					"mobile" : vm.currentMobile,
					"newMobile" : vm.userProfile.confirmMobile,
					"newMobileCountryCode": vm.userProfile.confirmMobileCountryCode
				}, onOTPSendSuccess, onSendOTPError);
			}
				
			
		}

		function changeMobile() {
			
				$rootScope.mobile = vm.userProfile.confirmMobile;
				$rootScope.mobileCountryCode = vm.userProfile.confirmMobileCountryCode;
				$scope.$emit('changeMobileRefresh');
				$uibModalInstance.close();
			

		}

		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		
		vm.resendPrimaryOTP = function resendPrimaryOTP(){
        	var email = '0';
        	if(vm.userProfile.confirmMobileCountryCode==null || vm.userProfile.confirmMobileCountryCode == undefined){
        		vm.userProfile.confirmMobileCountryCode = '';
        	}
        	var mobileNo = vm.userProfile.confirmMobile;
        	var mobileCountryCode = vm.userProfile.confirmMobileCountryCode;
        	if(vm.resendOTPAttemptCount>=globalValue.maximumOTPResendAttemptCount){
        		//AlertService.error("Maximum request for resend OTP is exceeded!<br>Please try again later.")
        		MessageBox.openMessageBox('Alert', 'Maximum request for resend OTP is exceeded! Please try again.', 'Ok', 'resendotpchangemobile');
        	}else{
            	ChangeMobile.resendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode, email: email}, onResendPrimaryOTPSuccess, onResendPrimaryOTPError);
        	}
        }
		
		function onResendPrimaryOTPSuccess(){
			vm.wrongOTPAttemptCount = 0;
			vm.resendOTPAttemptCount = vm.resendOTPAttemptCount + 1;
			AlertService.info("OTP has been resent to your mobile");
		}
		
		function onResendPrimaryOTPError(){
			
		}
		
		function changeUserMobile() {
			vm.otpClicked = true;
			if(vm.otp == null || vm.otp == undefined || vm.otp==''){
				vm.otpClicked = false;
				AlertService.error($filter('translate')('error.InvalidOTP'));
						
			}
			else if(vm.mode == 'customerinfo'){
				$rootScope.mobile = vm.userProfile.confirmMobile;
				ChangeMobile.updateForCustomer({
					"customerId" : vm.customerId,
					"newMobile" : vm.userProfile.confirmMobile,
					"newMobileCountryCode": vm.userProfile.confirmMobileCountryCode,
					"otp" : vm.otp
				}, onMobileChangeSuccess, onMobileChangeError);
			}else {
				ChangeMobile.update({
					"userId" : vm.userId,
					"newMobile" : vm.userProfile.confirmMobile,
					"newMobileCountryCode": vm.userProfile.confirmMobileCountryCode,
					"otp" : vm.otp
				}, onMobileChangeSuccess, onMobileChangeError);	
			}
		}
		
        $rootScope.$on('gatewayApp.messagebox.ok.invalidotpchangemobile', function (event, data) {
        	$scope.$emit('gatewayApp.messagebox.ok.sessionexpired');        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.invalidotpchangemobile', function (event, data) {
        	$scope.$emit('gatewayApp.messagebox.ok.sessionexpired');      	      
        });

        $rootScope.$on('gatewayApp.messagebox.ok.resendotpchangemobile', function (event, data) {
        	clear();        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.resendotpchangemobile', function (event, data) {
        	clear();      	      
        });

		function checkPassword() {
			if(vm.mode == 'customerinfo'){
				var userCredentialsForCustomer = {};
				userCredentialsForCustomer.userId = vm.userId;
				userCredentialsForCustomer.password = vm.password;
				userCredentialsForCustomer.customerId = vm.customerId;
				ChangeMobile.checkPasswordForCustomer(userCredentialsForCustomer, onSaveSuccess, onSaveError);	
			}else{
				var userCredentials = {};
				userCredentials.userId = vm.userId;
				userCredentials.password = vm.password;
				ChangeMobile.checkPassword(userCredentials, onSaveSuccess, onSaveError);
			}
			
		}

		function onSaveSuccess(result) {
			if (result.hasOwnProperty('IsPendingEmailChange') && result.IsPendingEmailChange != null && result.IsPendingEmailChange == true) {
				MessageBox.openMessageBox("Alert", "Change of email request is already pending for verification. If you proceed with change of mobile number, then the pending email change request will be discarded automatically. Do you still want to proceed?", "ProceedCancel", "changemobilerequest");
			 }else{
				 vm.showChangeMobile = true;
			 }
			
		}
		
		function onOTPSendSuccess(result) {
			vm.wrongOTPAttemptCount = 0;
			vm.resendOTPAttemptCount = 0;
			AlertService.info("OTP has been sent to your mobile");
			vm.isOTPSend = true;
		}

		function onSaveError() {
			
			$scope.clicked = false;
		}
		
		
		function onSendOTPError() {
			vm.disableGenerateOTP= false;
			
		}

		function onMobileChangeSuccess(result) {
			vm.wrongOTPAttemptCount = 0;
			$scope.$emit('changeMobileRefresh');
			$uibModalInstance.close(result);
			

		}
		 /*
         * allow only 30 characters in password and only some special characters
         */
		 $scope.$watch('vm.password', function(newVal, oldVal) {
			 if(newVal!=null||newVal!=undefined)
	        	{
			       	  if(newVal.length > 30) {       
			       		  vm.password = oldVal;
			       	  }
			       	  if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
			   		  {
			       		  vm.password = oldVal;
			   		  }
	        	}
       	});
		
		$rootScope.$on('gatewayApp.messagebox.proceed.changemobilerequest', function (event, data) {
			vm.showChangeMobile = true;
			ChangeMobile.activatePendingEmailChangeRequest({"userId" : vm.userId}, onCancelSuccess, onCancelError);
    	});
		
		$rootScope.$on('gatewayApp.messagebox.cancel.changemobilerequest', function (event, data) {
			vm.showChangeMobile = false;
			$uibModalInstance.close();
    	});

		function onMobileChangeError() {
			vm.wrongOTPAttemptCount = vm.wrongOTPAttemptCount + 1;
			if(vm.wrongOTPAttemptCount >= globalValue.maximumOTPWrongAttemptCount){
				//AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.")
				MessageBox.openMessageBox('Alert', 'Maximum wrong OTP attempt count is exceeded! Session expired. You will be redirected to login page...!', 'Ok', 'invalidotpchangemobile');
			}
			vm.otpClicked = false;
		}


		function onCancelSuccess() {

			//vm.clicked = false;
		}
		
		function onCancelError() {

			//vm.clicked = false;
		}
	}
})();
