(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'userprofile'
	})

	.controller('UserProfileController', UserProfileController);

	UserProfileController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'UserProfile', 'GlobalValues', 'globalValue',
			'EntityPermissions', 'ScreenLog', 'DateUtils','$uibModalInstance','$stateParams',
			'$rootScope', 'CustomerBankRelationshipReport', 'User','AlertService','MessageBox',
			'$filter', 'entityConstants', 'CustomerInfoUtil','BankRegistration', '$timeout', 
			'CustomerRegistration', 'FileUtil', 'customerTypeWiseInputDetails', 'ExposureType',
			'ExposureSubType'];

	function UserProfileController($scope, $state, workflowSharedService,
			UserProfile, GlobalValues, globalValue, 
			EntityPermissions, ScreenLog, DateUtils,$uibModalInstance,$stateParams,
			$rootScope, CustomerBankRelationshipReport, User, AlertService ,MessageBox, 
			$filter,entityConstants, CustomerInfoUtil,BankRegistration, $timeout, 
			CustomerRegistration,FileUtil, customerTypeWiseInputDetails, ExposureType,
			ExposureSubType) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.userprofile = {};
		vm.user = {};
		vm.clear = clear;
		vm.userprofile.gender = "male";
		vm.save = save;
		vm.userId = $stateParams.userId;
		vm.ExpandOrCollapseChangePassword = false;
		vm.ExpandOrCollapseLoginInfo = false;
		vm.ExpandOrCollapseMyProfileDetails = false;
		vm.ExpandOrCollapseCustomerInfo=true;
		vm.restoreDefault = restoreDefault;
		vm.savePreferences = savePreferences;
		vm.isShowPreferences = true;
		var customerPreviousData  = {};
		var fd = new FormData();
		vm.isEnableTradingBranch = false;
		vm.password = "TestPass";
		$scope.showMyProfile=true;
		vm.currentMode = 'myprofile';
		$scope.showMyPreferences=false;
		$scope.showCustomerInfo=false;
		vm.invalidPanImageSize = false;
		vm.invalidPanImage = false;
		vm.invalidPanFileName = false;
		var Extensions = ["pdf","png","jpg","jpeg"];
		var imageExtensions = ["png","jpg","jpeg"];
		$scope.clicked=false;
		vm.launch = launch;
		vm.checkForChange = checkForChange;
		vm.tradingBank ={};
		vm.tradingBranch={};
		vm.tradingCustomer = {};
		vm.exposureType = null;
		vm.exposureSubType = null;
		vm.configSettings = globalValue;
		vm.minimumOrderValue = vm.configSettings.minimumOrderValue;
        vm.maximumOrderValueHardCheck = vm.configSettings.maximumOrderValueHardCheck;
        vm.allowedOrderPriceDeviationFromMarketPriceInPercentage = vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage;
        vm.allowedOrderPriceDeviationFromMarketPriceInPaise = vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise;
        vm.isAllowStopLoss = vm.configSettings.isAllowStopLossOrder;
        vm.isAllowTakeProfit = vm.configSettings.isAllowTakeProfitOrder;
        vm.isAllowMarketOrder = vm.configSettings.isAllowMarketOrder;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
		vm.noOfdecimalsForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
        vm.minimumFileSizeInKB = vm.configSettings.uploadedFileMinimumSize;
        vm.maximumFileSizeinKB = vm.configSettings.uploadedFileMaximumSize;
        vm.invalidPanImageSixeText = "";
        vm.invalidPanImageFileNameText = "";
        vm.invalidPanImageText = "";;
        vm.maxFileNameLength = (vm.configSettings.uploadedFileNameLength != null && vm.configSettings.uploadedFileNameLength != undefined && vm.configSettings.uploadedFileNameLength != '') ?vm.configSettings.uploadedFileNameLength : 150 ;
        var minFileSizeinBytes = 0;
        var maxFileSizeinBytes = 100;
        if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
        	minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
        }
        if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){
        	maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
        }
        vm.errorText1 = '';
        vm.errorText2 = '';
        vm.errorText3 = '';
        vm.errorText4 = '';
        vm.errorText5 = '';
        vm.todayDate = new Date();
        vm.profileImageAction = '';
        var UPDATE_ACTION = 'update';
        var REMOVE_ACTION = 'remove';
        var userimageCopy = null;
      
        //intialize customer type wise input details
        vm.customerTypeWiseInputDetails = customerTypeWiseInputDetails;
        
        //Indices of input details in customer type wise input details array
        $scope.firstNameIndex = 0;
        $scope.middleNameIndex = 1;
        $scope.lastNameIndex = 2;
        $scope.entityNameIndex = 3;
        $scope.panNoIndex = 4;
        $scope.panImageIndex = 5;
        $scope.legalEntityIdentifierIndex = 6;
        $scope.leiExpiryDateIndex = 7;
        $scope.cinIndex = 8;
        $scope.gstinIndex = 9;
        $scope.stateCodeIndex = 10;
        $scope.dobDoiIndex = 11;
		$scope.customerTypeSolePropId = 27;
        vm.validateOrderValue = function validateOrderValue(value){
        	vm.errorText1 = '';
        	if(value!=null && value!=undefined && value!=''){
        		if(Number(value) < Number(vm.minimumOrderValue) || Number(value) > Number(vm.userprofile.userMaximumOrderValueHardCheck)){
        			vm.errorText1 = 'Value should be in between ' + $filter('number')(vm.minimumOrderValue,vm.noOfDecimalsForAmount) + ' and ' + $filter('number')(vm.userprofile.userMaximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
        		}
        	}
        }
        
        vm.validateAllowedOrderPriceDeviationFromMarketPriceInPercentage = function validateAllowedOrderPriceDeviationFromMarketPriceInPercentage(value){
        	vm.errorText2 = '';
        	if(Number(value) > Number(vm.allowedOrderPriceDeviationFromMarketPriceInPercentage)){
        		vm.errorText2 = "Value should be less than or equal to Clearcorp's hard check value " + $filter('number')(vm.allowedOrderPriceDeviationFromMarketPriceInPercentage,vm.noOfDecimalsForPercent) + '.';
        	}else if(Number(value) <= 0){
        		vm.errorText2 = "Value should be greater than 0.";
        	}
        }
        
        vm.validateMaximumOrderValueHardCheck = function validateMaximumOrderValueHardCheck(value){
        	vm.errorText3 = '';
        	if(value!=null && value!=undefined && value!=''){
        		if(Number(value) > Number(vm.maximumOrderValueHardCheck)){
        			vm.errorText3 = "Value should be less than or equal to Clearcorp's maximum order value hard check " + $filter('number')(vm.maximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
        		}else if(Number(value) <= Number(vm.minimumOrderValue)){
        			vm.errorText3 = "Value should be greater than Clearcorp's minimum order value " + $filter('number')(vm.minimumOrderValue,vm.noOfDecimalsForAmount) + '.';
        		}else if(Number(value) <= 0){
        			vm.errorText3 = "Value should be greater than 0.";
        		}
        	}
        }
        
        vm.validateMaximumOrderValueSoftCheck = function validateMaximumOrderValueSoftCheck(value){
        	vm.errorText4 = '';
        	if(vm.userprofile!=null && vm.userprofile!=undefined){
        		if(value!=null && value!=undefined && value!=''){
		        	if(Number(value) > Number(vm.maximumOrderValueHardCheck)){
        				vm.errorText4 = "Value should be less than or equal to Clearcorp's maximum order value hard check " + $filter('number')(vm.maximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
        			}else if(Number(value) > Number(vm.userprofile.userMaximumOrderValueHardCheck)){
		        		vm.errorText4 = "Value should be less than or equal to user level maximum order value hard check " + $filter('number')(vm.userprofile.userMaximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
		        	}else if(Number(value) <= 0){
		        		vm.errorText4 = "Value should be greater than 0.";
		        	}
        		}
        	}
        }
        
        vm.validateAllowedOrderPriceDeviationFromMarketPriceInPaise = function validateAllowedOrderPriceDeviationFromMarketPriceInPaise(value){
        	vm.errorText5 = '';
        	if(Number(value) > Number(vm.allowedOrderPriceDeviationFromMarketPriceInPaise)){
        		vm.errorText5 = "Value should be less than or equal to Clearcorp's default value " + $filter('number')(vm.allowedOrderPriceDeviationFromMarketPriceInPaise,vm.noOfDecimalsForPaise) + '.';
        	}else if(Number(value) <= 0){
        		vm.errorText5 = "Value should be greater than 0.";
        	}
        }
        
        function launch() {
			$state.go("user-profile");
		}
		
		logScreenAccess();
		loadAll();

		function loadAll() {
			UserProfile.get({
				"userId" : vm.userId
			}, onGetUserProfileSuccess, onGetUserProfileError);
			
			/*if(vm.globalValues.getEntityOwnerType().shortName == "Clearcorp"){
				vm.isShowPreferences = false;
				vm.isShowCustomer= false;
				vm.isShowTradingBranch = true;
				vm.isEnableTradingBranch = false;
			} else if(vm.globalValues.getEntityOwnerType().shortName == "Bank" || vm.globalValues.getEntityOwnerType().shortName == "Branch"){
				vm.isShowCustomer= false;
				vm.isShowTradingBranch = true;
				vm.isEnableTradingBranch = false;
			}else if(vm.globalValues.getEntityOwnerType().shortName == "SingleUserCustomer" ){
				loadCustomer();
				vm.hasCustomerInfoPermission=true;
				vm.isShowCustomer= false;
				vm.isShowTradingBranch = true;
				vm.isEnableTradingBranch = false;
				vm.isupdated = true;
				
			}else if(vm.globalValues.getEntityOwnerType().shortName == "MultiUserCustomer"){
				loadCustomer();
				vm.hasCustomerInfoPermission= EntityPermissions.hasPermission('Customer.EDIT');
				vm.isShowCustomer= false;
				vm.isShowTradingBranch = true;
				vm.isEnableTradingBranch = false;
				vm.isupdated = true;
				
			}			
			if(vm.globalValues.getEntityOwnerType().shortName == "Bank") {
				vm.isShowTradingBranch = false;
			}*/
			
		}

		function loadCustomer(){
			UserProfile.getCustomer({
				"customerId" : vm.globalValues.getOrganisation().id
			}, onGetCustomerSuccess, onGetCustomerError);
			
			
		}
		
		function checkForChange(mode){
			
			if(vm.currentMode != mode){
				$scope.mode = mode;
				var isChanged = true;
				if(vm.currentMode=='myprofile'){
					isChanged = $scope.userprofileForm.$pristine;
				}else if(vm.currentMode=='mypreference'){
					isChanged = $scope.myPreferencesForm.$pristine;
				}else if(vm.currentMode=='customerinfo'){
					isChanged = !CustomerInfoUtil.isCustomerProfileFormValuesChanged(customerPreviousData,vm.customerInfo,vm.image);
				}
				if(isChanged == false){
					MessageBox.openMessageBox("Alert", "Discard the changes?", "ProceedCancel", "userprofile");
				}else{
					if($scope.mode == 'myprofile'){
						$scope.showMyProfile=true;
						$scope.showMyPreferences=false;
						$scope.showCustomerInfo=false;
						vm.currentMode = ' 	';
					}else if($scope.mode == 'mypreference'){
						$scope.showMyProfile=false;
						$scope.showMyPreferences=true;
						$scope.showCustomerInfo=false;
						vm.currentMode = 'mypreference';
					}else if($scope.mode == 'customerinfo'){
						$scope.showMyProfile=false;
						$scope.showMyPreferences=false;
						$scope.showCustomerInfo=true;
						vm.currentMode = 'customerinfo';
					}
				}
			}
		}
		
		$rootScope.$on('gatewayApp.messagebox.proceed.userprofile', function (event, data) {
			loadAll();
			loadCustomer();
			vm.currentMode = $scope.mode;
			if($scope.mode == 'myprofile'){
				$scope.showMyProfile=true;
				$scope.showMyPreferences=false;
				$scope.showCustomerInfo=false;
				
			}else if($scope.mode == 'mypreference'){
				$scope.showMyProfile=false;
				$scope.showMyPreferences=true;
				$scope.showCustomerInfo=false;
			}else if($scope.mode == 'customerinfo'){
				$scope.showMyProfile=false;
				$scope.showMyPreferences=false;
				$scope.showCustomerInfo=true;
			}
			$scope.userprofileForm.$setPristine();
			$scope.myPreferencesForm.$setPristine();
			$scope.customerInfo.$setPristine();
    	});
		
		$rootScope.$on('gatewayApp.messagebox.cancel.userprofile', function (event, data) {
    	});
		
		function clear() {
			User.get({userId: vm.globalValues.getUser().id}, function(result){
				vm.globalValues.setSelectedUser(result);
			});
			$uibModalInstance.dismiss('cancel');
		}
		
		function onGetUserProfileSuccess(result) {
				
			
			vm.userprofile = result;
			
			if(vm.userprofile.userPriceCondition=='STLS' && vm.isAllowStopLoss!='Yes'){
        		vm.userprofile.userPriceCondition = 'LIMT';
        	}
        	if(vm.userprofile.userPriceCondition=='TKPR' && vm.isAllowTakeProfit!='Yes'){
        		vm.userprofile.userPriceCondition = 'LIMT';
        	}
			//need to check
			if(vm.isShowCustomer && vm.userprofile.userTradingBank!=null && vm.userprofile.userTradingBank!=" "){
				BankRegistration.getBankForShortName({bankName: vm.userprofile.userTradingBank},function(result){
					vm.userprofile.userTradingBank = result;
				});
			}
			vm.tradingBank = vm.userprofile.tradingBank;
			$timeout(function(){
				vm.tradingBranch = vm.userprofile.tradingBranch;
			}, 1000);
			$timeout(function(){
				vm.tradingCustomer = vm.userprofile.tradingCustomer;
			}, 1000);
			console.log(vm.userprofile);
			vm.exposureType = vm.userprofile.userExposureType;
			vm.exposureSubType = vm.userprofile.userExposureSubType;
			vm.user = vm.userprofile.userDTO;
			vm.displayFirstName = vm.user.firstName;
			vm.displayMiddleName = vm.user.middleName;
			vm.displayLastName = vm.user.lastName;
			userimageCopy = vm.user.image;
			
			//vm.user.dob = new Date(vm.user.dob);
			

            if(vm.displayMiddleName == null){
                vm.displayMiddleName = '';
            }
            vm.userprofile.lastSuccessfulLogin = DateUtils.convertDateTimeFromServer(vm.userprofile.lastSuccessfulLogin);
            vm.userprofile.lastUnsuccessfulLogin = DateUtils.convertDateTimeFromServer(vm.userprofile.lastUnsuccessfulLogin);
			vm.user.dob = DateUtils.convertDateTimeFromServer(vm.user.dob);
			
			
			if(vm.globalValues.getEntityOwnerType().shortName == "Clearcorp"){
				vm.isShowPreferences = false;
				vm.isShowCustomer= false;
				vm.isShowTradingBranch = true;
				vm.isTradingBranchDisabled = true;
			} else if(vm.globalValues.getEntityOwnerType().shortName == "Bank" ){
				vm.isShowTradingBranch = false;
				vm.isShowTradingBank = false;
				vm.isTradingBranchDisabled = false;
				
				vm.isShowBank = true;
				vm.isShowBranch = true;
				vm.isShowCustomer= true;
				vm.isBankDisabled = true;
				
			} else if (vm.globalValues.getEntityOwnerType().shortName == "Branch") {
				vm.isShowTradingBank = false;	
				vm.isShowTradingBranch = false;
				
				vm.isBankDisabled = true;
				vm.isTradingBranchDisabled = true;
				
				vm.isShowBank = true;
				vm.isShowBranch = true;
				vm.isShowCustomer= true;
				
			}else if(vm.globalValues.getEntityOwnerType().shortName == "SingleUserCustomer" ){
				loadCustomer();
				vm.hasCustomerInfoPermission=true;
				vm.isShowCustomer= false;
				vm.isShowBank = false;
				vm.isShowBranch = false;
				
				vm.isShowTradingBranch = true;
				vm.isShowTradingBank = true;
				vm.isTradingBranchDisabled = false;
				vm.isupdated = true;
				
			}else if(vm.globalValues.getEntityOwnerType().shortName == "MultiUserCustomer"){
				loadCustomer();
				vm.hasCustomerInfoPermission= EntityPermissions.hasPermission('Customer.EDIT');
				vm.isShowCustomer= false;
				vm.isShowBank = false;
				vm.isShowBranch = false;
				
				vm.isShowTradingBank = true;
				vm.isShowTradingBranch = true;
				vm.isTradingBranchDisabled = false;
				vm.isupdated = true;
				
			}			
	        
	        //load lookup and values for exposure type and exposure sub type
			loadLookupAndValues(vm.userprofile.userExposureType, vm.userprofile.userExposureSubType);

	        console.log(vm.userprofile);
		
		}

		function onGetUserProfileError(error) {
			 AlertService.error(error.data.message);
		}
		
		
		function onGetCustomerSuccess(result) {
			// clear the image file after successfully updating
			
			$('#panImageFile').val(null);
			vm.image = null;
			fd = new FormData();
			customerPreviousData = angular.copy(result);
			vm.customerInfo={};
			vm.customerInfo.customer = result;
			vm.customerInfo.dobOrDoi = DateUtils.convertDateTimeFromServer(vm.customerInfo.customer.dobOrDoi);
			if( vm.customerInfo.customer.leiExpiryDate !=null && vm.customerInfo.customer.leiExpiryDate!=undefined 
       			 && vm.customerInfo.customer.leiExpiryDate!= ''){
				vm.customerInfo.leiExpiryDate = DateUtils.convertDateTimeFromServer(vm.customerInfo.customer.leiExpiryDate);
			}
			vm.customerInfo.isSuspendableAction = false;
    		vm.customerInfo.isApprovalRequired = false;
    		    		//console.log("result" + angular.toJson(customerPreviousData));
    		if(vm.customerInfo.customer.customerType!=null && vm.customerInfo.customer.customerType!=undefined){
                vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == vm.customerInfo.customer.customerType.id;
                });
				if(vm.customerInfo.customer.customerType.id == $scope.customerTypeSolePropId){
					vm.inputDetailsForSelectedCustType = [
						{customerType: null, fieldName: 'FirstName', display: false, isMandatory: false},
						{customerType: null, fieldName: 'MiddleName', display: false, isMandatory: false},
						{customerType: null, fieldName: 'LastName', display: false, isMandatory: false},
						{customerType: null, fieldName: 'EntityName', display: true, isMandatory: true},
						{customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
						{customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
						{customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
						{customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
						{customerType: null, fieldName: 'CIN', display: false, isMandatory: false},
						{customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
						{customerType: null, fieldName: 'StateCode', display: true, isMandatory: false},
						{customerType: null, fieldName: 'DOBDOI', display: true, isMandatory: true}
					]
				}
            }else{
                vm.inputDetailsForSelectedCustType = [
                    {customerType: null, fieldName: 'FirstName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'MiddleName', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LastName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'EntityName', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'CIN', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'StateCode', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'DOBDOI', display: true, isMandatory: true}
                ]
            }
					
		}

		function onGetCustomerError(error) {
			// AlertService.error(error.data.message);
		}
		
		//load lookup and values for exposure type and exposure sub type
		function loadLookupAndValues(userExposureType, userExposureSubType){
			loadExposureTypeLookup(userExposureType);
			loadExposureSubTypeLookup(userExposureSubType);
		}
		
		//load lookup and value for exposure type 
		function loadExposureTypeLookup(userExposureType){
			ExposureType.getEnabledExposureTypes(function(result){
				vm.exposureTypes = result;
				angular.forEach(vm.exposureTypes, function(value){
                    if(value.name==userExposureType){
                    	vm.exposureType = value;
                    }
                })
                if(vm.exposureType.id==null || vm.exposureType.id==undefined){
                	vm.exposureType = null;
                }
			})
		}
			
		//load lookup and value for exposure sub  type 
		function loadExposureSubTypeLookup(userExposureSubType){
			ExposureSubType.getEnabledExposureSubTypes(function(result){
				vm.exposureSubTypes = result;
				angular.forEach(vm.exposureSubTypes, function(value){
                    if(value.name==userExposureSubType){
                    	vm.exposureSubType = value;
                    }
                })
                if(vm.exposureSubType.id==null || vm.exposureSubType.id==undefined){
                	vm.exposureSubType = null;
                }
			})
		}

		function restoreDefault() {
			if(vm.tradingBank!=null||vm.tradingBank!=undefined){
				vm.userprofile.userTradingBank = vm.tradingBank.bankName;
			}
			if(vm.tradingBranch!=null||vm.tradingBranch!=undefined){
				vm.userprofile.userTradingBranch = vm.tradingBranch.branchName;
			}

			if(vm.tradingCustomer!=null||vm.tradingCustomer!=undefined ){
				vm.userprofile.userCustomer = vm.tradingCustomer.customerCode;
			}

			if(vm.exposureType!=null||vm.exposureType!=undefined ){
				vm.userprofile.userExposureType = vm.exposureType.name;
			}

			if(vm.exposureSubType!=null||vm.exposureSubType!=undefined ){
				vm.userprofile.userExposureSubType = vm.exposureSubType.name;
			}

            UserProfile.restoreDefault(vm.userprofile, onSaveSuccess, onSaveError);

		}
		
		function fileSelected(input) {
		    $('#profile').val(input.files[0]);
		}
		
		function savePreferences() {
			if( vm.tradingBank!=null||vm.tradingBank!=undefined){
				vm.userprofile.userTradingBank = vm.tradingBank.bankName;
			}
			if( vm.tradingBranch!=null||vm.tradingBranch!=undefined){
				vm.userprofile.userTradingBranch = vm.tradingBranch.branchName;
			}
			
			if(vm.tradingCustomer!=null||vm.tradingCustomer!=undefined ){
				vm.userprofile.userCustomer = vm.tradingCustomer.customerCode;
			}

			if(vm.exposureType!=null||vm.exposureType!=undefined ){
				vm.userprofile.userExposureType = vm.exposureType.name;
			}

			if(vm.exposureSubType!=null||vm.exposureSubType!=undefined ){
				vm.userprofile.userExposureSubType = vm.exposureSubType.name;
			}

			UserProfile.updatePreferences(vm.userprofile, onSaveSuccess, onSaveError);
		}

		function launch() {
			$state.go("user-profile");
		}
		
			    
		vm.uploadImage = function uploadImage(){
			document.getElementById('txtpanCardUpload').click();
			
		}	
		
		vm.removeImage = function removeImage(){
			vm.removeButtonClicked = true;
			MessageBox.openMessageBox("Alert", "Are you sure you want to remove this profile picture?", "ProceedCancel", "userprofileremoveimage");
			vm.removeButtonClicked =false;	
			
		}	
		
	 	var  userprofileImageProceedAlert = $rootScope.$on('gatewayApp.messagebox.proceed.userprofileremoveimage', function (event, data) {
	 		vm.profileImageAction = REMOVE_ACTION;	
	 		vm.user.image = null;
	 		angular.element("input[type='file']").val(null);
        });
		
		var userprofileImageCancelAlert = $rootScope.$on('gatewayApp.messagebox.cancel.userprofileremoveimage', function (event, data) {
			//do nothing
			vm.profileImageAction = '';	
			if(vm.user.image == null){
				vm.user.image = userimageCopy;
			}
			
		});
		
		$scope.$watch('vm.panCardUpload', function(value){
			panCardUploadChange(value);
		});
		
		function panCardUploadChange(value){
			if(value!=null && value!=undefined){
							
					vm.inputImage=value.name;
					vm.panCardUpload = value;
					vm.fileExtension = vm.inputImage.substring(value.name.lastIndexOf(".") + 1);
					var fileNameWithoutExtension  =  vm.inputImage.substring(0,value.name.lastIndexOf("."));
					vm.profileImageAction = UPDATE_ACTION;
					if(imageExtensions.indexOf(vm.fileExtension.toLowerCase()) <= -1 ) {
			        	angular.element("input[type='file']").val(null);
			        	vm.panCardUpload=null;
			        	vm.profileImageAction = '';
			        	AlertService.error($filter('translate')('gatewayApp.userProfile.invalidImage'));
	    			 }else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.panCardUpload=null;
				        vm.profileImageAction = '';
				        AlertService.error("Uploaded file name should not exceed " + vm.maxFileNameLength+ " charaters.");
	    			 }
					else{
	    				 if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
	    					 angular.element("input[type='file']").val(null);
	    			        	vm.panCardUpload=null;
	    			        	vm.profileImageAction = '';
	    			        	AlertService.error("The size of the image should be in between "+ vm.minimumFileSizeInKB +"KB and " + vm.maximumFileSizeinKB +" KB"  );
	    				 }else{
	    					 var reader = new FileReader();
	    					 reader.readAsArrayBuffer(value);
	    					 reader.onloadend = function(e){
	    						 if (e.target.readyState === FileReader.DONE) {
		    						  var arrayBuffer = e.target.result ;
		    					      var binary = '';
		    					      var bytes = new Uint8Array( arrayBuffer );
		    					      if(!FileUtil.checkImageFileExtension(bytes.subarray(0, 4))){
		    					    	  angular.element("input[type='file']").val(null);
		    					        	vm.panCardUpload=null;
		    					        	vm.profileImageAction = '';
		    					        	AlertService.error($filter('translate')('gatewayApp.userProfile.invalidImage'));
		    					      }else{
		    					    	  //var len = bytes.byteLength;
										  var len = bytes.byteLength;
    	            				       for (var i = 0; i < len; i++) {
    	            				           binary += String.fromCharCode( bytes[ i ] );
    	            				       }
    	            				      var data = window.btoa( binary );
		    					    	// var data = btoa(String.fromCharCode.apply(null, bytes)); 
		    					    	  vm.user.image =  data
		    					      }
		    					      $scope.$digest();
	    						 }
	    					    };
	    					 
	    					    
	    				 }
	    			 }
			
				}
			}
		
			function save() {
			if(moment(vm.user.dob).isAfter(vm.todayDate,'day')){
        		$scope.clicked=false;
        		 AlertService.error($filter('translate')('userManagement.futuredob'));
        		 
        	 }else{
        		 var fd1 = new FormData();
     			
     			vm.user.dob = DateUtils.convertLocalDateToServer(vm.user.dob);
     			fd1.append('user', JSON.stringify(vm.user));
                 
     			if(vm.profileImageAction == UPDATE_ACTION ){
     				if(vm.panCardUpload != undefined){
     					fd1.append('file', vm.panCardUpload);
     					UserProfile.updateWithImage( fd1, onSaveSuccess, onSaveError);	
     				}else{
     					UserProfile.update( fd1, onSaveSuccess, onSaveError);
     				}
     			}else if(vm.profileImageAction == REMOVE_ACTION ){
     				
     				UserProfile.updateUserProfileRemoveImage( fd1, onSaveSuccess, onSaveError);
     			}     			
     			else{
     				
     				UserProfile.update( fd1, onSaveSuccess, onSaveError);
     			}
        	 }
			
				
			
		}

		/*$rootScope.$on('changeMobileRefresh', function(event, result) {
			loadAll();
		});*/
		
		function onSaveSuccess(result) {
			$scope.clicked=false;
			//refresh data
			loadAll();
			$scope.userprofileForm.$setPristine();
			$scope.myPreferencesForm.$setPristine();
			$scope.customerInfo.$setPristine();
			// relaod the user profile image in home screen when save is success and action is update or remove
            if(vm.profileImageAction == REMOVE_ACTION || vm.profileImageAction == UPDATE_ACTION){
                vm.globalValues.getUser().image = vm.user.image;
            }
			$uibModalInstance.close(result);
		}

		function onSaveError(error) {
			$scope.clicked=false;

		}
		
		 vm.viewPanImage = function viewPanImage(){
			 CustomerRegistration.getPANImage({customerCode: vm.customerInfo.customer.customerCode}, function(result){
     			vm.customerInfo.customer.customerPanImage = result;
     			openPanImage();
     		})
	    }
		 
        function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
    	}
        
		 function openPanImage(){
			 if(vm.customerInfo.customer.customerPanImage.fileExtension=='pdf'){
        		var contentByteArray = base64ToArrayBuffer(vm.customerInfo.customer.customerPanImage.image);
	    		  
	    		var file = new Blob([contentByteArray], { type: 'application/pdf' });
	            var fileURL = URL.createObjectURL(file);
	              
	            if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
	            }
	            else {
	                   window.open(fileURL, "PAN.pdf");    
	            }
		        	//var pdfWindow = window.open("");
	        		//pdfWindow.document.write("<iframe width='100%' height='100%'title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.customerInfo.customer.customerPanImage.image)+"' ></iframe>")
		     }else{
		        $state.go('view-panImage-customer-info-update', {image:vm.customerInfo.customer.customerPanImage.image});	
		     }
		 }
		 function perpareDataToBeSaved(){
			
			 vm.customerInfo.isSuspendableAction=false ;
			 vm.customerInfo.isApprovalRequired = false;
			
			 CustomerInfoUtil.checkForNameChangeWrokFlow(customerPreviousData , vm.customerInfo);
			// vm.customerInfo.customer.dobOrDoi = DateUtils.convertLocalDateToServer(vm.customerInfo.dobOrDoi);
			 
			 if( vm.customerInfo.leiExpiryDate !=null && vm.customerInfo.leiExpiryDate!=undefined 
	       			 && vm.customerInfo.leiExpiryDate!= ''){
					vm.customerInfo.customer.leiExpiryDate = DateUtils.convertLocalDateToServer(vm.customerInfo.leiExpiryDate);
				}
			 
			 if( vm.image === undefined || vm.image == ""){
				 vm.image = null;
			 }
			 fd = new FormData();
			 fd.append('pan', vm.image);
			 fd.append('customer', JSON.stringify( vm.customerInfo));
		 }
		 vm.saveCustomerInfo = function saveCustomerInfo(){
			
			 vm.screenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.CUSTOMER,  vm.customerInfo.customer.id,  entityConstants.ENTITY_ACTION_TYPE.EDIT);
			 if(CustomerInfoUtil.isCustomerProfileFormValuesChanged(customerPreviousData,vm.customerInfo,vm.image )){
				 
				 perpareDataToBeSaved();
				 if(vm.customerInfo.customer.customerFirstName.includes(',')){
					 if(vm.inputDetailsForSelectedCustType!=null 
	                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
	                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
						 AlertService.error($filter('translate')('gatewayApp.userProfile.commaInEntityName'));
					 }else{
						 AlertService.error($filter('translate')('gatewayApp.userProfile.commaInFirstName'));
					 }
				 }else if(vm.customerInfo.isSuspendableAction==true){
					vm.isupdated = false;
					MessageBox.openMessageBox("Alert", $filter('translate')('gatewayApp.userProfile.suspendAlert'), "YesNo", "customerprofile");
				 }else{
					$scope.clicked = true;
					 UserProfile.updateCustomerInfo(fd, onCustomerSaveSuccess, onCustomerSaveError);
				 }
				 
			 }else{
				 AlertService.error($filter('translate')('gatewayApp.userProfile.noChangesMade'));
			 }
			
			
		 }
			 
		
		var customerProfileUpdate =	$rootScope.$on('gatewayApp.messagebox.yes.customerprofile', function (event, data) {
		 		if($state.current.name === 'user-profile' && !vm.isupdated){
		 			vm.isupdated = true;
					 $scope.clicked = true;
			 		UserProfile.updateCustomerInfo(fd, onCustomerSaveSuccess, onCustomerSaveError);
			 		
		 		}
		 		

	        });
			
			$rootScope.$on('gatewayApp.messagebox.no.customerprofile', function (event, data) {
				//do nothing
				if($state.current.name === 'user-profile')
					vm.isupdated = true;
			});
			function onCustomerSaveSuccess(result) {
				
				$scope.clicked = false;
				vm.customerInfo.isSuspendableAction=false;
				vm.customerInfo.isApprovalRequired = false;
				fd= new FormData();
				loadCustomer();
				
				
			}

			function onCustomerSaveError(error) {
				vm.customerInfo.isSuspendableAction=false;
				vm.customerInfo.isApprovalRequired = false;
				fd= new FormData();
				$scope.clicked = false;
				
				//AlertService.error(error.data.message);

			}
		vm.openChangeEmail = function openChangeEmail(){
			$state.go('change-email', {userId: vm.userId,email: vm.user.email, mode: 'userProfile', customerId: 0 });
		}
		
		vm.openChangeMobile = function openChangeMobile(){
			$state.go('change-mobile', {userId: vm.userId,mobile: vm.user.mobile,countryCode:vm.user.mobileCountryCode, mode: 'userProfile',customerId: 0});
		}
		
		vm.openChangeEmailForCustomer = function openChangeEmail(){
			console.log(vm.globalValues.getOrganisation().id);
			$state.go('change-email', {userId: vm.userId,email: vm.customerInfo.customer.primaryEmail, mode: 'customerinfo',customerId: vm.globalValues.getOrganisation().id});
		}
		
		vm.openChangeMobileForCustomer = function openChangeMobile(){
			$state.go('change-mobile', {userId: vm.userId,mobile: vm.customerInfo.customer.primaryMobile,countryCode:vm.customerInfo.customer.primaryMobileCountryCode, mode: 'customerinfo',customerId: vm.globalValues.getOrganisation().id});
		}
		
		$rootScope.$on('changeEmailRefresh', function(event, result) {
			vm.customerInfo.customer.primaryEmail = $rootScope.email;
		});

		$rootScope.$on('changeMobileRefresh', function(event, result) {
			vm.customerInfo.customer.primaryMobile = $rootScope.mobile;
		});
		
	$scope.$watch('vm.tradingBank', function(value){
			if(value!=null && value!=undefined && value.id!=null && value.id!=undefined){
				CustomerBankRelationshipReport.getValidTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
					if(result!=null && result!=undefined){
						//if(result.entityRestrictionStatus.shortName!='Suspended' && result.entityRestrictionStatus.shortName!='Terminated'){
							vm.tradingBranch = result;
						//}
					}
				})	
			}
		});
		$scope.$watch('vm.customerInfo.customer.state', function(value){
			if(value!=null && value!=undefined && value.id!=null && value.id!=undefined){
				
				}else{
					
				}
			}
		);
		
		   //validate pan image size
        $scope.$watch('vm.image', function(value){
        	
        	if(value!= undefined && value!=null){
        		if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
        			vm.invalidPanImageSize = true;
        			vm.invalidPanFileName = false;
        			vm.invalidPanImageSixeText = "PAN image size should be in between "+ vm.minimumFileSizeInKB +"KB and " + vm.maximumFileSizeinKB +" KB";
        			vm.invalidPanImage = false;
        			angular.element("input[type='file']").val(null);
        			
        		}else{
        			vm.inputPanImage=vm.image.name;
        			vm.invalidPanImageSize = false;
        			vm.invalidPanImage = false;
        			vm.fileExtension = vm.inputPanImage.substring(vm.image.name.lastIndexOf(".") + 1);
        			var fileNameWithoutExtension  =  vm.inputPanImage.substring(0,vm.image.name.lastIndexOf("."));
        			//check for extension
        			 if(Extensions.indexOf(vm.fileExtension.toLowerCase()) <= -1) {
			        	angular.element("input[type='file']").val(null);
			        	vm.image=null;
			        	vm.inputPanImage=null;
			        	vm.invalidPanImage = true;
			        	 vm.invalidPanFileName = false;
			        	 vm.invalidPanImageSize = false;
			        	 vm.invalidPanImageText = "Invalid file format";
        			 }
        			 else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.image=null;
				         vm.inputPanImage=null;
				         vm.invalidPanImage = false;
				         vm.invalidPanImageSize = false;
				         vm.invalidPanFileName = true;
				         vm.invalidPanImageFileNameText = "Uploaded pan file name should not exceed " + vm.maxFileNameLength+ " charaters.";
				         
	    			 }
        			 else{ // check for malicious file with correct extension
        				 var reader = new FileReader();
    					 reader.readAsArrayBuffer(value.slice(0,4));
    					 reader.onloadend = function(e){
    						 if (e.target.readyState === FileReader.DONE) {
    						  var arrayBuffer = e.target.result;
    					      var bytes = new Uint8Array( arrayBuffer );
    					      if(!FileUtil.checkPanImageFileExtension(bytes)){
    					    	  angular.element("input[type='file']").val(null);
    					        	vm.image=null;
    					        	vm.inputPanImage=null;
    					        	vm.invalidPanImage = true;
    					        	vm.invalidPanImageSize = false;
    							    vm.invalidPanFileName = false; 
    							    vm.invalidPanImageText = "Invalid file format";
    					      }
    					      else{
    					    	  vm.invalidPanImageSize = false;
  							      vm.invalidPanFileName = false; 
  							      vm.invalidPanImage =false;
    					      }
    					      $scope.$digest();
    						 }
    					    }; 
        			 }
        		}
        	}
        	else{
        		angular.element("input[type='file']").val(null);
        		
        		vm.inputPanImage=null;
        	}
        });
        
        function logScreenAccess(){
           	ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.USER_PROFILE,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        $scope.$on('$destroy', function() {
        	if(userprofileImageProceedAlert!=null){
        		userprofileImageProceedAlert();
        	}
        	if(userprofileImageCancelAlert!=null){
        		userprofileImageCancelAlert();
        	}
        	if(customerProfileUpdate!=null){
        		customerProfileUpdate();
        	}
        });

	}
})();
