(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$rootScope', 'Principal', 'LoginService', '$sessionStorage', '$state', 'GlobalValues', 
    		'EntityPermissions', 'Notifications', '$sce', '$filter', 'Branch', 'StaticLookUp', 'BankRegistration','Country', 'workflowSharedService','$window',
    		'UserProfile', 'CurrencyPair', 'MarketWatch', 'Market', 'SwapQuotes', 'DefaultMarket', 'CustomerRegistration', 'CustomerBankRelationshipReport',
    		'$timeout', 'OrderStatusDetails', 'LimitSettings', 'IntradayMarket', 'DateUtils', 'MessageBox','User','ErrorMessages', 'WebSocket','GenerateDealTicket', 
    		'Idle','ServerComponentStatus', 'Markup', '$interval', 'entityConstants','TradesDownload','DealingAdminApplicationWideSettings', 'EODProcess', '$document',
            'HolidayReport', 'SwapQuotesForTenorDates', 'TACViewForEntitiesService', 'ExposureType', 'ExposureSubType', '$fileLogger', 'OrderUtil', 'CreditFactorSettings'];

    function HomeController ($scope, $rootScope, Principal, LoginService, $sessionStorage, $state, GlobalValues, 
    			EntityPermissions, Notifications, $sce, $filter, Branch, StaticLookUp, BankRegistration, Country, workflowSharedService,$window,
    			UserProfile, CurrencyPair, MarketWatch, Market, SwapQuotes, DefaultMarket, CustomerRegistration, CustomerBankRelationshipReport,
    			$timeout, OrderStatusDetails, LimitSettings, IntradayMarket, DateUtils, MessageBox,User,ErrorMessages, WebSocket,GenerateDealTicket, 
    			Idle,ServerComponentStatus, Markup, $interval, entityConstants,TradesDownload, DealingAdminApplicationWideSettings,EODProcess,$document,
                HolidayReport, SwapQuotesForTenorDates, TACViewForEntitiesService, ExposureType, ExposureSubType, $fileLogger, OrderUtil,CreditFactorSettings) {
					var vm = this;
        // window.onpopstate = function (e) { history.pushState(null, null,
		// window.location.href); }
        $fileLogger.setTimestampFormat('dd-MMM-yyyy HH:mm:ss:sss', '+0530');
		if($rootScope.eodInProgress!=null && $rootScope.eodInProgress!=undefined && $rootScope.eodInProgress==true){
			$fileLogger.info('Home page controller loaded')
		}
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.hasPermission = EntityPermissions.hasPermission;
        //rootScope.notificationsDTO = {};
        vm.globalValues = GlobalValues;
        $rootScope.globalValues = GlobalValues;
        vm.screenName = "Home";
        vm.screenType = "Home";
        vm.setFromIndex = setFromIndex;
        vm.isFromIndex = false;
        var isSubscribed = false;
        $rootScope.tradingRelationsForCustomer=null;
        vm.executeplaceOrderShortcut = executeplaceOrderShortcut;
        vm.executeValueHistoryShortcut = executeValueHistoryShortcut;
        vm.checkOrUncheckPendingOrders = checkOrUncheckPendingOrders;  
        vm.checkOrUncheckPendingOrderItem = checkOrUncheckPendingOrderItem;
        vm.broadcastMessage = broadcastMessage;
        $rootScope.orderServerDown = false;
        vm.expandTradePosition = false;
        vm.exportTradesToCsvFile = exportTradesToCsvFile ;
        vm.exportTradesToExcelFile = exportTradesToExcelFile;
        vm.clicked = false;
        vm.sampleAmount = 72.3212;
        vm.showMWData = showMWData;
        vm.addRow = addRow;
        vm.deleteRow =deleteRow; 
        vm.updateRow = updateRow;
        vm.resetUserMarketWatch = resetUserMarketWatch;
        vm.bankQuotesPaginationNoOfRecordsPerPage = 3;
        vm.bootupCompleted = false;
        //vm.selectedInstrumentDate = new Date();
        vm.getOutstandingTrades = getOutstandingTrades;
        vm.prepareOutrightComparision = prepareOutrightComparision;
        vm.outrightView = 'Price';
        $rootScope.maximumOptionPeriodDuration = 30;
        $rootScope.tenor=null;
        
                
        vm.value = 70.1234;
        vm.clickUpdateRow = false;
        vm.regularAndSplitOrder='Regular';    
        vm.noOfSplits='';
        vm.decimalValue=false;
        $scope.errorTextOrderAmount="";
        $scope.errorTextNoOfSplits = "";
        vm.minNoOfSplit=0;
        vm.maxNoOfSplit=0;
       
        
        getMinAndMaxNoOfSplit();
 
        function updateRow(index){
        	if(vm.clickUpdateRow == false){
        		vm.clickUpdateRow = true;
        		if(vm.selectedInstrumentDate!=null && vm.selectedInstrumentDate!=undefined){
        			var settlementDate = DateUtils.convertLocalDateToServer(angular.copy(vm.selectedInstrumentDate));
        			if(validateSettlementDate(settlementDate)){
        				var userMarketWatch = prepareUserMarketWatchToUpdate(settlementDate);
        				$rootScope.userMarketWatchList[index] = userMarketWatch;
        				//save to db
        				UserProfile.saveUserMarketWatchList($rootScope.userMarketWatchList);
                        //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
        				//vm.selectedInstrumentDate = vm.tenors[0].settlementDate;
        				vm.clickUpdateRow = false;
        			}
        		}else{
        			MessageBox.openMessageBox("Alert", "Invalid date...!", "Ok", "errorInSettlementDate");
        			
        		}
        	}
        }

        function validateSettlementDate(settlementDate){
            //validate sett. date should be fall in holiday
            var isValid = true;
            var holidayList = _.filter(vm.holidayListAfterBusinessDate, function(s){return s.indexOf(settlementDate)!==-1;});
            if(holidayList!=null && holidayList!=undefined && holidayList.length>0){
                isValid = false;
                MessageBox.openMessageBox("Alert", "Instrument cannot be added as selected date is a Holiday. For Holiday List, kindly refer to Holiday View screen in General Reports menu", "Ok", "errorInSettlementDate");
            }else{
                //validate sett. date should not be spot date. since, spot mkt watch is in separate section.
                //and sett. date should not be less than current business date.
                if(settlementDate==$rootScope.retailSpotMarketSession.settlementDate){
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "Date should not be a spot settlement date...!", "Ok", "errorInSettlementDate");
                }else if(settlementDate<vm.currentDate){
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "Date should not be less than current business date...!", "Ok", "errorInSettlementDate");
                }else if(vm.tenors!=null && vm.tenors!=undefined && vm.tenors.length>0 && settlementDate > vm.tenors[vm.tenors.length-1].settlementDate){
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "Date should not be greater than the last standard tenor date " + $filter('date')(new Date(vm.tenors[vm.tenors.length-1].settlementDate), 'dd-MMM-yyyy') + "...!", "Ok", "errorInSettlementDate");
                }else{ //sett. date should not already be exists in user mkt watch
                    var existingMktWatch = _.filter(angular.copy($rootScope.userMarketWatchList), { 'settlementDate': settlementDate });
                    if(existingMktWatch!=null && existingMktWatch!=undefined && existingMktWatch.length>0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "The instrument is already available for the given date...!", "Ok", "errorInSettlementDate");
                    }
                }
            }
            return isValid;
        }
        
        
        function validateOptionSettlementDate(instrumentCode ,isOptionPeriod ,  settlementDate, dateFromOrTo){
            // do validateion only if forwards and option period is true else skip validation
        	if(instrumentCode != 'Forward' ||  (instrumentCode == 'Forward' && isOptionPeriod != true ) ){
         	   return true;
            }
            var isValid = true;
            var selectedDateText = "Selected Forward Option Period " + dateFromOrTo + " date ";
           // var errorText = "errorInOptionPeriod" + dateFromOrTo;
            var serverSettlemntDate = DateUtils.convertLocalDateToServer(settlementDate);
           
            var holidayList = _.filter(vm.holidayListAfterBusinessDate, function(s){return s.indexOf(serverSettlemntDate)!==-1;});
           
            
            if(settlementDate == null || settlementDate == undefined ){
            	isValid = false;
            	vm.errorString = "Forward Option Period " + dateFromOrTo + " date is mandatory..!";
            	//MessageBox.openMessageBox("Alert", "Option Period " + dateFromOrTo + " date is mandatory", "Ok", errorText);
            
            }else if(holidayList!=null && holidayList!=undefined && holidayList.length>0){
             
            	isValid = false;
                vm.errorString = selectedDateText +"is a Holiday. For Holiday List, kindly refer to Holiday View screen in General Reports menu...!";
               //MessageBox.openMessageBox("Alert", , "Ok", errorText);
               
            }else{
            	 //validate sett. date should not be greater than the maximum renor/value defined in the limits
            	if($rootScope.maxForwardTenorDate !=null && $rootScope.maxForwardTenorDate != undefined && moment(settlementDate).isAfter($rootScope.maxForwardTenorDate, 'day')){
                   
            		isValid = false;
                    vm.errorString = selectedDateText +"is greater than the" + " maximum tenor/value date defined by the Relationship Bank. Please refer the Limit Tab for more info on maximum tenor/value date allowed...!";
                    // MessageBox.openMessageBox("Alert", , "Ok", errorText);
                }else if( moment(settlementDate).isBefore( moment(vm.spotNextDate  ), 'day') || moment(settlementDate).isAfter( moment(vm.maxTenorDate), 'day')){
                	 //validate sett. date should not be less than spot next date. and should not be greater than last tenor value 
                	isValid = false;
                	vm.errorString = selectedDateText +"should be between Spot Next Date and maximum tenor date...!";
                    //MessageBox.openMessageBox("Alert", , "Ok", errorText);
                            
                }
            }
            return isValid;
        }
        function validateOptionPeriods(instrumentCode, isOptionPeriod, from,to){
        	// do validateion only if forwards and option period is true else skip validation     
        	if(instrumentCode != 'Forward' ||  (instrumentCode == 'Forward' && isOptionPeriod != true ) ){
          	   return true;
             }
        	//validate sett. date should be fall in holiday
            var isValid = true;
            //var errorText = "errorInOptionPeriodFRomAndTo";
                //validate sett. date should not be spot date. since, spot mkt watch is in separate section.
                //and sett. date should not be less than current business date.
                if( moment(from).isSame(moment(to), 'day') ){
                    isValid = false;
                    vm.errorString = "Forward Option Period Start date and End date cannot be same...!";
                   // MessageBox.openMessageBox("Alert", "Option Period From and To cannot be same...!", "Ok", errorText);
                    
                }else if(moment(from).isAfter(moment(to), 'day')){
                	isValid = false;
                	vm.errorString = "Forward Option Period Start date cannot be greater than End date...!";
                	//MessageBox.openMessageBox("Alert", "Option Period From is greater than To...!", "Ok", errorText);                                   
                }
                else if(moment(to).diff(moment(from) ,'day') > $rootScope.maximumOptionPeriodDuration){
                	isValid = false;
                	vm.errorString = "Forward Option Period date range cannot be more than "+ $rootScope.maximumOptionPeriodDuration+" days...!";
                	//MessageBox.openMessageBox("Alert", , "Ok", errorText);                                   
                }
           
            return isValid;
        }

        $rootScope.$on('gatewayApp.messagebox.clear.errorInSettlementDate',function (event, data){
        	vm.clickUpdateRow = false;
        	vm.selectedInstrumentDate = null;
        });
        
        $rootScope.$on('gatewayApp.messagebox.ok.errorInSettlementDate', function (event, data) {
            //set default value in settlement date
            vm.selectedInstrumentDate = null;
            vm.clickUpdateRow = false;
        });

        $rootScope.$on('gatewayApp.messagebox.ok.hasAtleastOneRow', function (event, data) {
            //do nothing
        	$rootScope.clickDeleteRow = true;
        });

        //prepare user market watch for newly added settlement date
        function prepareUserMarketWatchToUpdate(settlementDate){
            var userMarketWatch = {};
            //get tenor name
            userMarketWatch.tenorName = MarketWatch.getTenorNameForSettlementDateInUserMarketWatch(settlementDate, vm.tenors, $rootScope.cashMarketSession, $rootScope.tomMarketSession);
            userMarketWatch.editable = false;
            userMarketWatch.settlementDate = settlementDate;
            //set instrument
            if(userMarketWatch.tenorName=='CASH' || userMarketWatch.tenorName=='TOM'){
                userMarketWatch.instrument = userMarketWatch.tenorName;
            }else{
                userMarketWatch.instrument = 'Forward';
            }
            var tickSize = gettickSizeForGivenInstrument(userMarketWatch.instrument);
            userMarketWatch.sessionStatus = getSessionStatusForInstrument(userMarketWatch.instrument);
            //get bid offer spread and best bid/offer flag
            userMarketWatch = MarketWatch.getAndFillBidOfferSpreadForDateInUserMarketWatch(userMarketWatch, $rootScope.bankComparativeDTO, $rootScope.defaultBankSpread, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
            //fill bid/offer price
            userMarketWatch = MarketWatch.fillBidOfferPriceInUserMarketWatch(userMarketWatch, $rootScope.retailSpotMarketWatch);
            return userMarketWatch;
        }
        
        function showMWData(){
        	console.log($rootScope.userMarketWatchList);
        }
        
        function addRow(index){
            vm.selectedInstrumentDate = null;
            angular.forEach($rootScope.userMarketWatchList, function(value, index1){
                if(value.tenorName==null || value.tenorName==undefined || value.tenorName==''){
                    if(index1<index){
                        index = index - 1;
                    }
                    $rootScope.userMarketWatchList.splice(index1, 1);
                }
            })
        	$rootScope.userMarketWatchList.splice(index, 0, {'id' : 0, 'tenorName' : '', 'bidPrice': 0, 'offerPrice': 0, 'settlementDate': '', 'isBestBuy': false, 'isBestSell': false, 'editable': true});
            if(($rootScope.userMarketWatchList.length-1)==index){//if it is last row
                setTimeout(function(){
                    document.getElementById('userMarketWatch').scrollTop = document.getElementById('userMarketWatch').scrollHeight;
                },10);
            }
            //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
        }
        
        $rootScope.clickDeleteRow = true
        
        function deleteRow(index){
        	if($rootScope.clickDeleteRow == true){
        		if($rootScope.userMarketWatchList[index].tenorName==null || $rootScope.userMarketWatchList[index].tenorName==undefined || $rootScope.userMarketWatchList[index].tenorName=='' 
                    || $rootScope.userMarketWatchList[index].settlementDate=='' || $rootScope.userMarketWatchList[index].settlementDate==null || $rootScope.userMarketWatchList[index].settlementDate==undefined){
        			$rootScope.userMarketWatchList.splice(index, 1);
                    //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
        		}else{
        			$rootScope.clickDeleteRow = false;
        			var validUserMarketWatch = angular.copy($rootScope.userMarketWatchList).map(function(item){return item.tenorName;}).filter(function(val){return (val !== null && val !== undefined && val !== '')});
        			if(validUserMarketWatch.length>1){
        				$rootScope.indexToBeDeleted = angular.copy(index);
        				
        				MessageBox.openMessageBox("Alert", 'Are you sure to remove the selected instrument?', "YesNo", "continuedeletemktwatch");
        			}else{
        				
        				MessageBox.openMessageBox("Alert", "Cannot be removed, the market watch should have at least one instrument.", "Ok", "hasAtleastOneRow");
        			}
        		}
        	}
        }

        $rootScope.$on('gatewayApp.messagebox.yes.continuedeletemktwatch', function (event, data) {
            if($rootScope.indexToBeDeleted!=null && $rootScope.indexToBeDeleted!=undefined){
                $rootScope.userMarketWatchList.splice($rootScope.indexToBeDeleted, 1);
                UserProfile.saveUserMarketWatchList($rootScope.userMarketWatchList);
                //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
                $rootScope.indexToBeDeleted = null; //to prevent duplicate execution 
            }
            $rootScope.clickDeleteRow = true;
        });

        $rootScope.$on('gatewayApp.messagebox.no.continuedeletemktwatch', function (event, data) {
            //do nothing
        	$rootScope.clickDeleteRow = true;
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.continuedeletemktwatch',function (event, data){
        	$rootScope.clickDeleteRow = true;
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.hasAtleastOneRow',function (event, data){
        	$rootScope.clickDeleteRow = true;
        });
        
        $scope.sortableOptions = {
                containment: '#items',
                orderChanged: function(event){
                	//Things to do on order changed
                    UserProfile.saveUserMarketWatchList($rootScope.userMarketWatchList);
                    //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
                },
                placeholder: function (itemScope) {
                    var tr = itemScope.element[0];
                    var placeholder = $document[0].createElement("tr");
                    placeholder.style.display = 'table-row';
                    placeholder.style.backgroundColor = '#000';

                    var cell = placeholder.insertCell(0);
                    cell.innerHTML = tr.cells[0].innerHTML;
                    cell.style.fontSize = '17px';
                    cell.style.color = '#f0cba6';
                    
                    return placeholder;
                }
            };
        
        function resetUserMarketWatch(){
            $rootScope.resetDone=false;
            MessageBox.openMessageBox("Alert", 'Are you sure to restore the market watch?', "YesNo", "continueresetmarketwatch");
        }

        $rootScope.$on('gatewayApp.messagebox.yes.continueresetmarketwatch', function (event, data) {
            if($rootScope.resetDone==false){
                UserProfile.resetUserMarketWatchList(onResetUserMarketWatchSuccess, onResetUserMarketWatchFailure);
                $rootScope.resetDone=true; //to prevent duplicate execution 
            }
        });

        $rootScope.$on('gatewayApp.messagebox.no.continueresetmarketwatch', function (event, data) {
            //do nothing
        });

        function onResetUserMarketWatchSuccess(result){
            //do what done in get user market watch list success
            onGetUserMarketWatchSuccess(result);
        }

        function onResetUserMarketWatchFailure(){

        }
        
        // $.growl({ title: "Order Confirmation", message: "Your order for 100
		// USD @ 65.45 is placed successfully with the host." });
       /*
		 * ---- Get pending task --- $rootScope.pendingTasksCount ="";
		 * UserProfile.getPendingTaskCount(onGetCountSuccess,onGetCountFailure);
		 * 
		 * function loadPeningTaskCount(){ console.log("get pending task ");
		 * if(vm.globalValues!=null && vm.globalValues!=undefined &&
		 * vm.globalValues.getUser() !=null && vm.globalValues!=undefined){
		 * UserProfile.getPendingTaskCount(onGetCountSuccess,onGetCountFailure); } }
		 * 
		 * $interval( loadPeningTaskCount , 60000); function
		 * onGetCountSuccess(result){ $rootScope.pendingTasksCount =
		 * result.count; } function onGetCountFailure(error){ console.log("error
		 * in getting the pending task count" + error); }
		 */
        
        function setFromIndex(){
        	console.log('Set Index From')
        	vm.isFromIndex = true;
        }
        
        function executeplaceOrderShortcut(value, orderVolume, instrumentType, instrumentId, bidPrice, offerPrice){
        	if(value == '+' && offerPrice>0 && orderVolume!=null && orderVolume!=undefined && orderVolume!=''){
        		vm.placeMarketOrder('BUYY', orderVolume, instrumentType, instrumentId, offerPrice)
        	}
        	else if(value == '-' && bidPrice>0 && orderVolume!=null && orderVolume!=undefined && orderVolume!=''){
        		vm.placeMarketOrder('SELL', orderVolume, instrumentType, instrumentId, bidPrice)
        	}
        }
        
        function executeValueHistoryShortcut(value, type){
// alert(value);
        	if(value=='UpArrow'){
        		getPreviousValueAndSet(type);
        	}else if(value=='DownArrow'){
        		getNextValueAndSet(type);
        	}
        }
        
        function getPreviousValueAndSet(type){
        	if(type=='limit'){
            	vm.currentLimitPriceIndex = vm.currentLimitPriceIndex - 1;
            	if(vm.currentLimitPriceIndex < 0){
        			vm.currentLimitPriceIndex = vm.limitPriceArray.length - 1;
        		}
        	}else if(type=='buylimit'){
            	vm.currentBuyLimitPriceIndex = vm.currentBuyLimitPriceIndex - 1;
            	if(vm.currentBuyLimitPriceIndex < 0){
        			vm.currentBuyLimitPriceIndex = vm.buyLimitPriceArray.length - 1;
        		}
        	}else if(type=='selllimit'){
            	vm.currentSellLimitPriceIndex = vm.currentSellLimitPriceIndex - 1;
            	if(vm.currentSellLimitPriceIndex < 0){
        			vm.currentSellLimitPriceIndex = vm.sellLimitPriceArray.length - 1;
        		}
        	}
        	getValueFromPriceIndex(type);
        }
        
        function getNextValueAndSet(type){
        	vm.currentLimitPriceIndex = vm.currentLimitPriceIndex + 1;
    		if(vm.currentLimitPriceIndex > vm.limitPriceArray.length - 1){
    			vm.currentLimitPriceIndex = 0;
    		}
    		getValueFromPriceIndex(type);
        }
        
        function getValueFromPriceIndex(type){
        	if(type=='limit'){
        		vm.orderDTO.limitExchangeRate = vm.limitPriceArray[vm.currentLimitPriceIndex];
        	}else if(type=='buylimit'){
        		vm.orderDTO.buyLimitExchangeRate = vm.buyLimitPriceArray[vm.currentBuyLimitPriceIndex];
        	}else if(type=='selllimit'){
        		vm.orderDTO.sellLimitExchangeRate = vm.sellLimitPriceArray[vm.currentSellLimitPriceIndex];
        	}
        }
        
        function addLimitPriceToArray(limitPrice){
        	vm.limitPriceArray.push(limitPrice);
        	vm.currentLimitPriceIndex = vm.limitPriceArray.length;
        }
        
        function addSellLimitPriceToArray(limitPrice){
        	vm.sellLimitPriceArray.push(limitPrice);
        	vm.currentSellLimitPriceIndex = vm.limitPriceArray.length;
        }
        
        function addBuyLimitPriceToArray(limitPrice){
        	vm.buyLimitPriceArray.push(limitPrice);
        	vm.currentBuyLimitPriceIndex = vm.limitPriceArray.length;
        }
        
        console.log("INSIDE CONTROLLER - " + vm.isFromIndex);
        
        if ($sessionStorage.isAuth == true) {
            getAccount();            
            $timeout(function(){
            	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": "MinimumIdleTimeToRestrictOrderActivity"}, function(result){
            		Idle.unwatch();
                	Idle.setIdle(Number(result.value)*60);
                    Idle.watch();
            	})
            },100);
        }
        
        $scope.$on('authenticationSuccess', function() {
        	$sessionStorage.isAuth = true;
        	EntityPermissions.clearEntityPermission();
            getAccount();
            // getNotifications();
            $timeout(function(){
            	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": "MinimumIdleTimeToRestrictOrderActivity"}, function(result){
            		Idle.unwatch();
                	Idle.setIdle(Number(result.value)*60);
                    Idle.watch();
            	})
            },100)
        });
        
        $scope.$on('IdleStart', function() {
        });

        $scope.$on('IdleWarn', function(e, countdown) {

        });

        $scope.$on('IdleTimeout', function() {
        	console.log('Timed out');
        	vm.globalValues.setSessionTimedOut(true);
        	console.log(vm.globalValues.getSessionTimedOut());
        	// write your code here
        });

        $scope.$on('IdleEnd', function() {
           
        });

        $scope.$on('Keepalive', function() {

        });

        $rootScope.$on('gatewayApp.entityPermissionFetched', function(event, result) {
            clearAndSetPermission();
        });
        
        vm.openUserProfile = function openUserProfile(){
        	$state.go('user-profile', {userId: vm.account.id})
        }
        
        vm.openHelp = function openHelp(){
        	  /*
				 * var day = new Date(); var id = day.getTime(); var popup =
				 * $window.open("help.html", id,
				 * "width=500,height=500,left=400,top=150"); popup.screenName =
				 * vm.screenName; popup.screenType = vm.screenType;
				 * if($state.current.name=='home'){ popup.screenName = "Home";
				 * popup.screenType = "Home"; }
				 */
            if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
                CustomerRegistration.getHelpContent({type : 'User_Manual_Clearcorp'}, onGetHelpContentsSuccess, onGetHelpContentsFailure);
            }else if(vm.account.organisation.organisationType.shortName=='BANK'){
                CustomerRegistration.getHelpContent({type : 'User_Manual_Bank'}, onGetHelpContentsSuccess, onGetHelpContentsFailure);
            }else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
                CustomerRegistration.getHelpContent({type : 'User_Manual_Customer'}, onGetHelpContentsSuccess, onGetHelpContentsFailure);
            }
        }
        
        function base64ToArrayBuffer(base64) {
            var binary_string =  window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array( len );
            for (var i = 0; i < len; i++)        {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }
        
        function onGetHelpContentsSuccess(result){
          if(result.contents != null && result.contents != undefined){
              var contentByteArray = base64ToArrayBuffer(result.contents);
              
              var file = new Blob([contentByteArray], { type: 'application/pdf' });
              var fileURL = URL.createObjectURL(file);
              
              if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "FXRetail_User_Manual.pdf");
              }
              else {
                     window.open(fileURL, "FXRetail_User_Manual.pdf");    
              }
          }
        }
        
        function onGetHelpContentsFailure(){
            
        }
        
        vm.openApplicationWideSettings = function openApplicationWideSettings(){
        	$state.go('application-wide-settings', {userId: vm.account.id})
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                $rootScope.account = account;
                if (account != null) {
                	if(account.entityOwnerType.shortName=='Branch'){
                		Branch.getBranchForUser({userId: account.id}, function(result){
                    		GlobalValues.checkAndSetSelectedBranch(result);
                		})
                	}
                	if(account.entityOwnerType.shortName=='Bank' || account.entityOwnerType.shortName=='Branch'){
                		BankRegistration.get({id : account.organisation.id}, function(result){
                    		GlobalValues.checkAndSetSelectedBank(result);
                            if(!vm.isFromIndex){
                                $timeout(function(){bootUp();},50);
                                //getNotifications();
                            }else{
                            	//Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
                            	//CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
                            	loadPageHeader();
                            }
                		})
                	}
                	if(account.entityOwnerType.shortName=='MultiUserCustomer' || account.entityOwnerType.shortName=='SingleUserCustomer'){
                		CustomerRegistration.getCustomer({id : account.organisation.id}, function(result){
                    		GlobalValues.checkAndSetSelectedCustomer(result);
                            if(!vm.isFromIndex){
                                $timeout(function(){bootUp();},50);
                                //getNotifications();
                                // set tradingbank to rootscope
                                CustomerBankRelationshipReport.getCustomerTradingBanks({customerId : account.organisation.id}, function(result){
                                    $rootScope.tradingRelationsForCustomer = result;
                                })
                                // get customer agreed tac list
                                TACViewForEntitiesService.getCustomerAgreedTACList({customerId : account.organisation.id}, function(result){
                                    $rootScope.agreedTACList = result;
                                })
                            }else{
                            	//Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
                            	//CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
                            	loadPageHeader();
                            }
                		})
                	}
                	if(account.entityOwnerType.shortName=='Clearcorp'){
                        if(!vm.isFromIndex){
                            $timeout(function(){bootUp();},50);
                            //getNotifications();
                        }else{
                        	//Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
                        	//CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
                        	loadPageHeader();
                        }
                	}
                    if($rootScope.lookupFilled==null && $rootScope.lookupFilled==undefined){
                        GlobalValues.checkAndSetSelectedUser(account);
                        GlobalValues.checkAndSetSelectedOrganisation(account.organisation);
                        GlobalValues.checkAndSetSelectedEntityOwnerType(account.entityOwnerType);
                        //GlobalValues.checkAndSetSelectedConfigurationSettings(StaticLookUp.getConfigSettings());
                        StaticLookUp.getConfigSettings(function(result){
                            GlobalValues.checkAndSetSelectedConfigurationSettings(result);
                        })
                        GlobalValues.checkAndSetSelectedSystemSettings(IntradayMarket.getCurrentBusinessDate());
                        GlobalValues.checkAndSetSelectedCountry(Country.getCountryForUser({shortName: 'INDIA'}));
                        GlobalValues.checkAndSetSelectedFinancialYear(StaticLookUp.getFinancialYear());
                        GlobalValues.setSessionTimedOut(false);
                        //clearAndSetPermission();
                        // DefaultMarket.marketInstruments(onGetInstrumentSuccess, onGetInstrumentFailure);
                        // StaticLookUp.getTenorsWithBrokenDate(onGetTenorSuccess, onGetTenorFailure);
                        // StaticLookUp.getTenorsForLookup(onGetTenorLookupSuccess, onGetTenorLookupFailure);

                        // vm.isAuthenticated = Principal.isAuthenticated;
                        console.log('subscribe websocket from home controller');

                        subscribeWebSocket();
                        $rootScope.lookupFilled = 'yes';
                    }
                    clearAndSetPermission();
                    StaticLookUp.getConfigSettings(function(result){
                       onGetConfigSuccess(result);
                    })
                    DefaultMarket.marketInstruments(onGetInstrumentSuccess, onGetInstrumentFailure);
                    StaticLookUp.getTenorsWithBrokenDate(onGetTenorSuccess, onGetTenorFailure);
                    StaticLookUp.getTenorsForLookup(onGetTenorLookupSuccess, onGetTenorLookupFailure);
                    ExposureType.getAllExposureTypes(onGetAllExposureLookupSuccess, onGetAllExposureLookupFailure);
                    ExposureType.getEnabledExposureTypes(onGetEnabledExposureLookupSuccess, onGetEnabledExposureLookupFailure);
                    ExposureSubType.getAllExposureSubTypes(onGetAllSubExposureLookupSuccess, onGetAllSubExposureLookupFailure);
                    ExposureSubType.getEnabledExposureSubTypes(onGetEnabledSubExposureLookupSuccess, onGetEnabledSubExposureLookupFailure);

                    vm.isAuthenticated = Principal.isAuthenticated;
                    
                }
            });
        }
        /*
         * load page header for page refresh JIRA 1065
         */
        function loadPageHeader(){       	
        	
        		// get server status
    	       	//ServerComponentStatus.getServerComponentStatus(onGetServerStatusSuccess, onGetServerStatusFailure);            
        		//market status
    	       	setIntradayMarketSessionDetails();  
            	//daily closing price - Not required since the ticker price is not shown in index.html top and only showed in home page
            	// Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
            	//currency pair
            	CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
            	//get notification
            	getNotifications();

                //get user preference on refresh from any other pages rather than home page
                UserProfile.get({userId: vm.account.id}, onGetUserPreferenceSuccess, onGetUserPreferenceFailure);
        	
        }

        function onGetUserPreferenceSuccess(result){
            vm.userPreference = result;
        	$rootScope.userPreference = result;
        }
        
        function onGetUserPreferenceFailure(){

        }

        function subscribeWebSocket(){
        	WebSocket.subscribeWebSocket(vm.account.id);
        }
        
        
        function register () {
            $state.go('register');
        }

        function clearAndSetPermission() {
            clearPermission();
            setPermission();
        }
        
        function clearPermission() {
            vm.permitdashboardToDo = false;
            vm.permitsecurityPolicyRoles = false;
            vm.permitsecurityPolicyUsers = false;
            vm.permitsecurityPolicyClearance = false;
            vm.permitsecurityPolicySecurity = false;
            vm.permitsecurityPolicyApproval = false;
            vm.permitglobalSecurityPolicySecurity = false;
            vm.permitglobalSecurityPolicyApproval = false;
            vm.permitorganisationOrgProfile = false;
            vm.permitdashboardToDo = false;
            vm.permitAlertConfiguration = false;
            vm.permitManageFAQ = false;
            vm.permitExecuteAlertNotifications = false;
            vm.permitUserManagement = false;
            vm.permitBankRegistration = false;
            vm.permitBranchRegistration = false;
            vm.permitcustomerViewReport=false;
            vm.permitsmsFailureReport=false;
            vm.permitUserAdminApplicationWideSettings=false;
            vm.permitDealingAdminApplicationWideSettings=false;
            vm.permitSMSSettings=false;
            vm.permitAuditLogReport=false;
            vm.permitHistoryReport=false;
            vm.permitCustomerOnBoardingExceptionReport=false;
            vm.permitcustomerToDo=false;
            vm.permitCustomerBankRelationshipReport=false;
            vm.permitCustomerSpecificSettings=false;
            vm.permitAddBankRegistration = false;
            vm.permitAddBranchRegistration = false;
            vm.permitSessionLogReport=false;
            vm.permitCustomerInfoUpdateStatus=false;
            vm.permitEODProcess=false;
            vm.permitRegularHoliday=false;
            vm.permitSpecialHoliday=false;
            vm.permitSwapQuotes=false;
            vm.permitSwapQuotesEditEntityAction = false;
            vm.permitDefaultMarkupSettings=false;
            vm.permitMarkupSettings = false;
            vm.permitHeConnectivitySettings = false;
            vm.permitInstrumentView = false;
            vm.permitIntradayMarketSessionSettings=false;
            vm.permitDefaultMarketSessionSettings=false;
            vm.permitEmailSettings = false;
            vm.permitUserRestriction = false;
            vm.permitLimitSettings = false;
            vm.permitCustomerLimitSettings = false;
            vm.permitMarkupReport = false;
            vm.permitOrderStatusReport = false;
            vm.permitSettlementReport = false;
            vm.permitEntityRestriction = false;
            vm.permitMarketMovement = false;
            vm.permitTradingDashboard=false;
            vm.permitNewOrderEntry = false;
            vm.permitModifyOrderEntry = false;
            vm.permitCancelOrderEntry = false;
            vm.permitSODProcess = false;
            vm.permitUserwiseTurnoverReport=false;
            vm.permitOrderHistory=false;
            vm.permitTradeHistory=false;
            vm.permitAMCSettings = false;
            vm.permitNoOfAvailalbeUsersReport = false;
            vm.permitNoOfLoggedInUsersReport = false;
            vm.permitCustomerChargesSettings = false;
            vm.permitGSTSettings=false;
            vm.permitInvoiceView = false;
            vm.permitTransactionCharges = false;
            vm.permitBroadcastMessages = false;
            vm.permitServerComponentStatusView = false;
            vm.permitGenericUsers = false;
            vm.permitTransactionChargesSettings = false;
            vm.permitUploadParticipantCertificates = false;
            vm.permitTradesDownload = false;
            vm.permitHomeBranchUpload = false;
            vm.permitPendingOrderView = false;
            vm.permitSwapQuotesView = false;
            vm.permitTradesForTheDay = false;
            vm.permitLimitUtilisationReport = false;
            vm.permitCustomerMarkupView = false;
            vm.permitStandardTenorView = false;
            vm.permitSystemAmendedTrades  = false;
            vm.permitClearcorpTAC = false;
            vm.permitCustomerTACUpload = false;
            vm.permitTermsAndConditionsView = false;
            vm.permitEInvoiceUpload = false;
            vm.permitOTRSummary = false;
            vm.permitTransactionChargesSummary = false;
            vm.permitAMCSummary = false; 
            vm.permitLoggedInUsersForBank = false;
            vm.permitUserAccessView = false;
            vm.permitExposureType = false;
            vm.permitExposureSubType = false;
            vm.permitCreditFactorSettings = false;
            vm.permitLimitUtilisationSplitupReport = false;
            vm.permitOutstandingTradesReport = false;
            vm.permitPendingLimitRequestsView = false;
            vm.permitCreditFactorRequestsView = false;
            vm.permitMemberFeesAndCharges = false;
            vm.permitFeesAndChargesUpload = false;
            vm.relationshipBankFeesAndCharges = false;
            vm.permitCancelledOrdersReport =false;
            vm.permitOTRSummaryForBank=false;
            vm.permitOrderActivityLog=false;
            vm.permitOrderUpload = false;
            vm.permitRefreshLimit = false;
        }
        
        function setPermission() {
            vm.permitdashboardToDo = vm.hasPermission('ToDo');
            vm.permitsecurityPolicyRoles = vm.hasPermission('OrganisationRole');
            vm.permitsecurityPolicyUsers = vm.hasPermission('UserRole');
            vm.permitsecurityPolicyClearance = vm.hasPermission('UserClearance');
            vm.permitsecurityPolicySecurity = vm.hasPermission('SecurityPolicy');
            vm.permitsecurityPolicyApproval = vm.hasPermission('ApprovalProcess');
            vm.permitglobalSecurityPolicySecurity = vm.hasPermission('GlobalSecurityPolicy');
            vm.permitglobalSecurityPolicyApproval = vm.hasPermission('GlobalApprovalProcess');
            vm.permitorganisationOrgProfile = vm.hasPermission('Organisation');
            vm.permitdashboardToDo = vm.hasPermission('ToDo');
            vm.permitAlertConfiguration = vm.hasPermission('AlertConfiguration');
            vm.permitManageFAQ = vm.hasPermission('FAQ');
            vm.permitExecuteAlertNotifications = vm.hasPermission('ExecuteAlertNotifications');
            vm.permitUserManagement = vm.hasPermission('UserManagement');
            vm.permitBankRegistration = vm.hasPermission('BankRegistration');
            vm.permitBranchRegistration = vm.hasPermission('BranchRegistration');
            vm.permitcustomerViewReport=vm.hasPermission('CustomerViewReport');
            vm.permitsmsFailureReport=vm.hasPermission('SMSFailureReport');
            vm.permitUserAdminApplicationWideSettings=vm.hasPermission('UserAdminApplicationWideSettings');
            vm.permitDealingAdminApplicationWideSettings =vm.hasPermission('DealingAdminApplicationWideSettings');
            vm.permitSMSSettings=vm.hasPermission('SMSSettings');
            vm.permitAuditLogReport=vm.hasPermission('AuditLogReport');
            vm.permitHistoryReport=vm.hasPermission('HistoryReport');
            vm.permitCustomerOnBoardingExceptionReport=vm.hasPermission('CustomerOnboardingExceptionReport');
            vm.permitcustomerToDo=vm.hasPermission('CustomerApproval');
            vm.permitCustomerBankRelationshipReport=vm.hasPermission('TradingBankList');
            vm.permitCustomerSpecificSettings=vm.hasPermission('CustomerSpecificSettings');
            vm.permitAddBankRegistration=vm.hasPermission('BankRegistration.CREATE');
            vm.permitAddBranchRegistration=vm.hasPermission('BranchRegistration.CREATE');
            vm.permitSessionLogReport=vm.hasPermission('SessionLogReport');
            vm.permitCustomerInfoUpdateStatus=vm.hasPermission('Customer');
            vm.permitEODProcess= vm.hasPermission('EODProcess');
            vm.permitRegularHoliday=vm.hasPermission('RegularHolidays');
            vm.permitSpecialHoliday=vm.hasPermission('SpecialHoliday');
            vm.permitSwapQuotes=vm.hasPermission('SwapQuotes');
            vm.permitSwapQuotesEditEntityAction = vm.hasPermission("SwapQuotes.EDIT");
            vm.permitDefaultMarkupSettings=vm.hasPermission('DefaultMarkupSettings');
            vm.permitMarkupSettings = vm.hasPermission('MarkupSettings');
            vm.permitHeConnectivitySettings = vm.hasPermission('HEConnectivitySettings');
            vm.permitInstrumentView = vm.hasPermission('InstrumentView');
            vm.permitIntradayMarketSessionSettings = vm.hasPermission('IntradayMarketSessionSettings');
            vm.permitDefaultMarketSessionSettings = vm.hasPermission('DefaultMarketSessionSettings');
            vm.permitEmailSettings = vm.hasPermission('EmailSettings');
            vm.permitUserRestriction = vm.hasPermission('UserRestriction');
            vm.permitLimitSettings = vm.hasPermission('LimitSettings');
            vm.permitCustomerLimitSettings = vm.hasPermission('CustomerLimitChangeRequest');
            vm.permitMarkupReport = vm.hasPermission('MarkupReport');
            vm.permitOrderStatusReport = vm.hasPermission('OrderStatusReport');
            vm.permitSettlementReport = vm.hasPermission('TradesToBeSettledReport');
            vm.permitEntityRestriction = vm.hasPermission('EntityRestriction');
            vm.permitMarketMovement = vm.hasPermission('MarketMovement');
            vm.permitTradingDashboard = vm.hasPermission('TradeOrderManagementDashboard');
            vm.permitNewOrderEntry = vm.hasPermission('OrderEntry.CREATE');
            vm.permitModifyOrderEntry = vm.hasPermission('OrderEntry.EDIT');
            vm.permitCancelOrderEntry = vm.hasPermission('OrderEntry.DELETE');
            vm.permitSODProcess = vm.hasPermission('SODProcess');
            vm.permitUserwiseTurnoverReport = vm.hasPermission('UserwiseTurnoverReport');
            vm.permitOrderHistory = vm.hasPermission('OrderHistory');
            vm.permitTradeHistory = vm.hasPermission('TradeHistory');
            vm.permitAMCSettings = vm.hasPermission('AMCSettings');
            vm.permitNoOfAvailalbeUsersReport = vm.hasPermission('NoOfAvailableUsers');
            vm.permitNoOfLoggedInUsersReport = vm.hasPermission('NoOfLoggedInUsers');
            vm.permitCustomerChargesSettings = vm.hasPermission('CustomerCharges');
            vm.permitGSTSettings = vm.hasPermission('GSTSettings');
            vm.permitInvoiceView = vm.hasPermission('InvoiceView');
            vm.permitTransactionCharges = vm.hasPermission('TransactionChargesReport');
            vm.permitBroadcastMessages = vm.hasPermission('BroadcastMessages');
            vm.permitServerComponentStatusView = vm.hasPermission('ServerComponentStatus');
            vm.permitTransactionChargesSettings = vm.hasPermission('TransactionChargesSettings');
            vm.permitUploadParticipantCertificates = vm.hasPermission('UploadDownloadParticipantCertificate');
            vm.permitTradesDownload = vm.hasPermission('TradesDownload');
 	   		vm.permitHomeBranchUpload =  vm.hasPermission('HomeBranchIFSCUpload');
            vm.permitPendingOrderView = vm.hasPermission('PendingOrderReport');
            vm.permitTradesForTheDay = vm.hasPermission('TradesForTheDay');
            vm.permitSwapQuotesView = vm.hasPermission('SwapQuotesView');
            vm.permitLimitUtilisationReport = vm.hasPermission('LimitUtilisationReport');
            vm.permitCustomerMarkupView = vm.hasPermission('CustomerMarkupView');
            vm.permitStandardTenorView = vm.hasPermission('StandardTenorView');/**/
            vm.permitSystemAmendedTrades = vm.hasPermission('SystemAmendedTradesReport');
            vm.permitCustomerTACUpload = vm.hasPermission('CustomerTermsAndConditionsUpload');
            vm.permitTermsAndConditionsView = vm.hasPermission('TermsAndConditionsView');
            vm.permitEInvoiceUpload = vm.hasPermission('eInvoiceUpload');
            vm.permitOTRSummary = vm.hasPermission("OTRSummary");
            vm.permitTransactionChargesSummary =  vm.hasPermission("TransactionChargesSummary");
            vm.permitAMCSummary = vm.hasPermission("AMCSummary");
            vm.permitLoggedInUsersForBank = vm.hasPermission("NoOfLoggedInUsersForBank");
            vm.permitUserAccessView = vm.hasPermission("UserAccessView");
            vm.permitExposureType = vm.hasPermission("ExposureType");
            vm.permitExposureSubType = vm.hasPermission("ExposureSubType");
            vm.permitCreditFactorSettings = vm.hasPermission("CreditFactorSettings");
            vm.permitLimitUtilisationSplitupReport = vm.hasPermission("LimitUtilisationSplitupReport");
            vm.permitOutstandingTradesReport = vm.hasPermission("OutstandingTradesReport");
            vm.permitPendingLimitRequestsView = vm.hasPermission("PendingLimitRequestsView");
            vm.permitCreditFactorRequestsView = vm.hasPermission("CreditFactorSettingsView");
           vm.permitMemberFeesAndCharges = vm.hasPermission("BankFeesAndChargesView");
           vm.permitFeesAndChargesUpload = vm.hasPermission("BankFeesChargesUpload");
          
           vm.relationshipBankFeesAndCharges = true; //always true since all customers users can view it vm.hasPermission("BankFeesAndChargesView");
           vm.permitCancelledOrdersReport = vm.hasPermission('CancelledOrdersReport');
           vm.permitOTRSummaryForBank = vm.hasPermission('OTRSummaryForBank');
           vm.permitOrderActivityLog=vm.hasPermission('OrderActivityLog');
           vm.permitOrderUpload = vm.hasPermission('OrderUpload');
           vm.permitRefreshLimit = vm.hasPermission('RefreshLimit');
 	    	//if(vm.globalValues.getConfigSettings().showGenericUsers == 'Yes')
 	    	//	vm.permitGenericUsers = true;
        }

        function onGetConfigSuccess(result){
            if(result.showGenericUsers == 'Yes'){
                vm.permitGenericUsers = true;
            }

            if(result.allowOrderImport=='Yes'){
                vm.allTradingBanks=CustomerBankRelationshipReport.query();
            }

           if(result.negligibleExceptionNumbers!=undefined && result.negligibleExceptionNumbers!=''){
               $rootScope.negligibleExceptionNumberList = result.negligibleExceptionNumbers.split(',');
           }
           if(result.maximumOptionPeriodDuration != undefined && result.maximumOptionPeriodDuration != null &&  result.maximumOptionPeriodDuration !=''){
        	   $rootScope.maximumOptionPeriodDuration = parseInt(result.maximumOptionPeriodDuration,10);
           }

        }
        
        // getNotifications();
        
        vm.getNotifications = function getNotifications(){
        	Notifications.getPopupNotificationsByUserId(onGetNotificationSuccess, onGetNotificationFailure);
        }
        
        vm.getNotificationsFromIndex = function getNotificationsFromIndex(event){
        	 $scope.toggle = false;
             $scope.refreshFlag = false;
         	Notifications.getPopupNotificationsByUserId(onGetNotificationSuccess, onGetNotificationFailure);
             if ($scope.toggle == false) {
                 $timeout(function() {
                     $scope.toggle = true;
                 }, 2000);
                 $scope.refreshFlag = false;
             }
        }

        function getNotifications() {
             Notifications.getPopupNotificationsByUserId(onGetNotificationSuccess, onGetNotificationFailure);
        }

        function onGetNotificationSuccess(result){
        	$rootScope.notificationsDTO = result;
        	console.log($rootScope.notificationsDTO);
        	if($rootScope.notificationsDTO!=null){
        		$rootScope.orderServerNotificationLastId = angular.copy($rootScope.notificationsDTO.orderServerNotificationLastId);
        		$rootScope.pushNotificationLastId = angular.copy($rootScope.notificationsDTO.pushNotificationLastId);
        		$rootScope.queryServerNotificationLastId = angular.copy($rootScope.notificationsDTO.queryServerNotificationLastId);
        	}
        }
        
        function onGetNotificationFailure(){
        	
        }
        
        vm.getMessage = function getMessage(notificationList) {
            var messageArr = {};
            
            switch (angular.lowercase(notificationList.entity.shortName)) {
            
            	case 'termsandconditionsupload':
            		var notificationArray = notificationList.message.split("~~");
            		var message = notificationArray[1];
            		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158a8d;font-size:12px"><b>'+notificationArray[0] +'</b></span>' + 
            				'<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br>' +
                            '<div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;margin-bottom:10px">' + message +
                            '</span></div></div>');
            	break;
            	case 'customertermsandconditionsupload':
            		var notificationArray = notificationList.message.split("~~");
            		var message = notificationArray[1];
            		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158a8d;font-size:12px"><b>'+notificationArray[0] +'</b></span>' + 
            				'<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br>' +
                            '<div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;margin-bottom:10px">' + message +
                            '</span></div></div>');
            	break;
                case 'customer':
                	messageArr = JSON.parse(notificationList.message);
                    return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#7F55FF;font-size:12px"><b>Customer Address Change</b></span>' 
            				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><div style="padding-top:5px"><span">' + $filter('date')(new Date(notificationList.runDate), 'dd-MMM-yyyy') + 
                            '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>Customer Info Update' +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>' + messageArr.customer.customerCode +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;</br><span">' + messageArr.customer.customerType.shortName +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>' + messageArr.customer.customerFirstName + 
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span >' + messageArr.customer.panNumber +
                        '</span></div></div>');
                    	break;
                case 'tradeordermanagementdashboard':
                	var notificationArray = notificationList.message.split("~~");
                	var message = notificationArray[1];
                	if(notificationArray[0]=="Order Confirmation" || notificationArray[0]=="Order Acceptance" || notificationArray[0]=="Order Cancellation-by Admin/Session close" ||
                			 notificationArray[0]=="Order Server Manual Login Completed" || notificationArray[0]=="HE Status Update Completed" ){
                		
                		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#49993C;font-size:12px"><b>' + notificationArray[0]+'</b></span>' 
                				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><span>'  +
                                '</span><div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;margin-bottom:10px">' + message +
                                '</span></div></div>');
                	}else if(notificationArray[0]=="Trade Confirmation" ||  notificationArray[0]=="CASH Session Close Intimation"
                		 ){
                		
                		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#D54C0F;font-size:12px"><b>' + notificationArray[0]+'</b></span>' 
                				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><span>'  +
                                '</span><div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;">' + message +
                                '</span></div></div>');
                	}else if(notificationArray[0]=="Order Rejection"  || notificationArray[0]=="HE Status Update Failed" || notificationArray[0]=="Order Server Manual Login Failed" ){
                		
                		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#CD0000;font-size:12px"><b>' + notificationArray[0]+'</b></span>' 
                				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><span>'  +
                                '</span><div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;">' + message +
                                '</span></div></div>');
                	}else if(notificationArray[0]=="Clearcorp Broadcast"){
                		
                		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158A8D;font-size:12px"><b>' + notificationArray[0]+'</b></span>' 
                				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><span>'  +
                                '</span><div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;">' + message +
                                '</span></div></div>');
                	}else if(notificationArray[0]=="Breached Member Details" ){
                		//
                		return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158A8D;font-size:12px"><b>' + notificationArray[0]+'</b></span>' 
                				+ '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br><span>'  +
                                '</span><div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;">' + message +
                                '</span></div></div>');
                	}else if(notificationArray[0]=="Others"){
                        return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158a8d;font-size:12px"><b>'+notificationArray[0] +'</b></span>' + 
                            '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br>' +
                            '<div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;margin-bottom:10px">' + message +
                            '</span></div></div>');
                    }else if(notificationArray[0]=="Terms & Conditions"){
                        return $sce.trustAsHtml('<div style="padding:5px"><span style="font-weight:bold;color:#158a8d;font-size:12px"><b>'+notificationArray[0] +'</b></span>' + 
                            '<span style="float:right;color:#989898">' + $filter('date')(new Date(notificationList.createdDateTime), 'HH:mm:ss') + '</span><br>' +
                            '<div style="padding-top:5px"><span style="word-wrap:break-word;white-space:normal;margin-bottom:10px">' + message +
                            '</span></div></div>');
                    }
                	
                	
                	break;
                default:
                	messageArr = JSON.parse(notificationList.message);
                    return $sce.trustAsHtml('<span style="margin-left:15px;">' + messageArr.bank +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>' + messageArr.currency +
                        '</span>&nbsp;&nbsp;<span>' + $filter('number')(messageArr.amount, messageArr.numofDecimals) +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>for ' +
                        $filter('date')(new Date(messageArr.dueDate), 'dd-MMM-yyyy') +
                        '</span>&nbsp;&nbsp;|&nbsp;&nbsp;</br><span style="margin-left:15px;">' + messageArr.tradeRefNo +
                        '</span>');

            }

        }

        vm.notify = function notify(notification) {
            if(notification.status!='VISITED'){
            	notification.status = "VISITED";
                Notifications.updatePopupNotificationStatus({
                    'notificationId': notification.notificationId
                });
                $rootScope.notificationsDTO.popupNotificationsCount-=1;
            }
            switch (angular.lowercase(notification.entity.shortName)) {
            case 'customer':
            	$rootScope.payload = JSON.parse(notification.message);
            	workflowSharedService.viewFrom = "customerinfoupdateintimationview";
            	$state.go('customer-info-update-intimation-view');
            }
            
        }

        $scope.setIndicator = function(eventType) {
            if (eventType.nature == "Upcoming") {
                return "Notification_Blue.png"
            } else if (eventType.nature == "Overdue") {
                return "Notification_Yellow.png"
            } else {
                return "Notification_Red.png"
            }
        }

        $scope.setBgColor = function(notification) {
        	 
        	if (notification.status == "PENDING" && (notification.entity.shortName=="TradeOrderManagementDashboard" || notification.entity.shortName=="Customer" || notification.entity.shortName=="TermsAndConditionsUpload" ||notification.entity.shortName== "CustomerTermsAndConditionsUpload") ) {
                return {
                    background: '#212121',
                	color: '#FFFFFF',
                	marginLeft:'3px',
                	borderLeft : '3px solid #A6A6A6'
                }
            }else if (notification.status == "VISITED" && (notification.entity.shortName=="TradeOrderManagementDashboard" || notification.entity.shortName=="Customer" || notification.entity.shortName=="TermsAndConditionsUpload" ||notification.entity.shortName== "CustomerTermsAndConditionsUpload")) {
                return {
                    background: '#000000',
                	color: '#FFFFFF'
                }
            }
        	else if (notification.status == "PENDING") {
                return {
                    background: '#1FA9E1',
                	color: '#000000'
                }
            } else if (notification.status == "VISITED") {
                return {
                    background: '#F7FAFF'
                }
            } 
            
           
        }        
        
        vm.setScreenName = function setScreenName(screenName){
        	vm.screenName = screenName;
        	vm.screenType = "Individual";
        }
        
        vm.today = new Date();
        vm.formattedDate = $filter('date')(vm.today, 'ddMMyyyyHHmm');
        vm.downloadData = downloadData;
        
		 // vm.allTradingBanks=CustomerBankRelationshipReport.query();
	        
	        function convertArrayOfObjectsToCSV(args) {  
	            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

	            data = args.data || null;
	            if (data == null || !data.length) {
	                return null;
	            }

	            columnDelimiter = args.columnDelimiter || ',';
	            lineDelimiter = args.lineDelimiter || '\n';

	            keys = Object.keys(data[0]);

	            result = '';
	            result += keys.join(columnDelimiter);
	            result += lineDelimiter;

	            data.forEach(function(item) {
	                ctr = 0;
	                keys.forEach(function(key) {
	                    if (ctr > 0) result += columnDelimiter;

	                    result += item[key];
	                    ctr++;
	                });
	                result += lineDelimiter;
	            });

	            return result;
	        }
	        
	        $scope.$watch('vm.content', function(value){
	           	if(value!= undefined && value!=null){
	           		
	           		var array = vm.content.split(/\n/);
	           		var tempHeader = array[0].trim();
	           		var headerErr = "Error Message";
	           		var tempHeaderErr = headerErr.trim();
	           		vm.newArray = tempHeader + "," + tempHeaderErr;
	           		
	           		for(var i=1;i<array.length;i++){
	           			
	           			if(array[i]!=""){
	           			vm.orderRequestDTO = array[i].split(',');
	           			if(vm.orderRequestDTO!=null && vm.orderRequestDTO!='' && vm.orderRequestDTO!=""){
	           				vm.orderImport={};
	           				
	           				
	           				vm.importErrorString="";
	           				vm.bankForTrading="";
	           				
	           				validateTradingBankRelationship(vm.orderRequestDTO[13],vm.orderRequestDTO[14],vm.orderRequestDTO[15]);
	           				
	           				if(vm.importErrorString==""){
	           					
	           					vm.orderImport.buySellIndicator=vm.orderRequestDTO[2];
	               				vm.orderImport.activityIndicator=vm.orderRequestDTO[0];
	               				vm.orderImport.typeOfOrder=vm.orderRequestDTO[6];
	               				vm.orderImport.instrumentCode=vm.orderRequestDTO[12];
	               				vm.orderImport.orderAmount = vm.orderRequestDTO[3];
	               				if(vm.orderImport.typeOfOrder!="MRKT"){
	               					vm.orderImport.limitExchangeRate = vm.orderRequestDTO[7];
	               				}
	               				if(vm.orderImport.typeOfOrder=="STLS" || vm.orderImport.typeOfOrder=="TKPR"){
	               					vm.orderImport.stopExchangeRate = vm.orderRequestDTO[8];
	               				}
	               				
	               				if(vm.orderImport.activityIndicator!="PLAC"){
	               					vm.orderImport.orderSequenceNumber=vm.orderRequestDTO[1];
	               					vm.orderImport.oldOrderAmount=vm.orderRequestDTO[19];
	               				}
	               				
	               				
	               				angular.forEach(vm.instrumentTypes, function(value){
	               	        		if(value.instrumentCode==vm.orderImport.instrumentCode){
	               		               	vm.orderImport.instrumentId=value.id;
	               	        		}
	               	        	});
	               				fillUploadDTO(vm.orderImport.buySellIndicator,vm.orderImport.activityIndicator,vm.orderImport.typeOfOrder,vm.orderImport.instrumentCode,
	               							vm.orderImport.orderAmount,vm.orderImport.limitExchangeRate,vm.orderImport.stopExchangeRate, vm.orderImport.oldOrderAmount);
	               				
	           				}
	               				
	               				if(vm.importErrorString!=""){
	               					
	               					
	               					
	               					var tempArr = array[i].trim();
	               					var tempErr = vm.importErrorString.trim();
                                    vm.newArray+="\n" + tempArr + "," + tempErr;

	               					
	               				}
	           				
	           			}
	           		}
	           		}
	           		if(vm.importErrorString!=""){
	           			downloadCSV({ filename: "order-import.csv" , input : vm.newArray});
	           		}
	           		
	           		angular.element("input[type='file']").val(null);
	           		vm.content=null;
	           		
	           	}
	           	else{
	           		angular.element("input[type='file']").val(null);
	           		
	           	}
	           })
	           
	           
	           function downloadCSV(args) {  
	        	
			        var data, filename, link;
			        
			        if(args.input==vm.newArray){
			        	  var csv = args.input;
			        }else{
			        	
			        	 var csv = convertArrayOfObjectsToCSV({
					            data: args.input
					        });
			        }
			       
			      
			        if (csv == null)
			        	return;

			        	filename = args.filename || 'export.csv';

			        	var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

			        	if (navigator.msSaveBlob)
			        	{ 
			        		navigator.msSaveBlob(blob, filename)
			        	}
			        	else
			        	{
				        	var link = document.createElement("a");
				        	if (link.download !== undefined)
				        	{
		
				        	
					        	var url = URL.createObjectURL(blob);
					        	link.setAttribute("href", url);
					        	link.setAttribute("download", filename);
					        	link.style = "visibility:hidden";
					        	document.body.appendChild(link);
					        	link.click();
					        	document.body.removeChild(link);
				        	}
			        	}
			        	
	        	}
	           
	           
		        function validateTradingBankRelationship(bankMemberNumber, branchCode, customerCode){
		        	
		        	 angular.forEach(vm.allTradingBanks, function(value){
		 		   		if(value.bank.bankMemberNo==bankMemberNumber && value.tradingBranch.branchId==branchCode && value.customer.customerCode==customerCode ){
		 		       		vm.orderImport.branchId=value.tradingBranch.id;
		 		       		vm.orderImport.branchName=value.tradingBranch.branchName;
		 		       		vm.orderImport.branchCode=value.tradingBranch.branchId;
		 		       		vm.orderImport.customerId=value.customer.id;
		 		       		vm.orderImport.customerName=value.customer.customerFirstName;
		 		       		vm.orderImport.customerCode=value.customer.customerCode;
		 		       		vm.orderImport.bankName=value.bank.bankName;
		 		       		vm.orderImport.bankId=value.bank.id;
		 		       		vm.orderImport.bankMemberNumber = value.bank.bankMemberNo;
		 		       		vm.bankForTrading=value.bank;
		 		   		}
		 		   		
		 		   	});
		        	 if(vm.bankForTrading==null || vm.bankForTrading==undefined || vm.bankForTrading==""){
		 		   			vm.importErrorString = vm.importErrorString = "There is no trading relationship for the customer with the bank or branch."
		 		   		}
		        }
	        
	           function fillUploadDTO(buySellFlag, activityIndicator,typeOfOrder,instrumentCode,orderAmount,limitExRate,stopExRate, oldOrderAmount){

		        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.bankForTrading.id );
		        	if(validateOrderUpload(buySellFlag, typeOfOrder, selectedBankSpread, instrumentCode,orderAmount,limitExRate,stopExRate, activityIndicator, oldOrderAmount)){
		        		
		        		
		    	       	
		    	       	
		    	       	vm.orderImport.dqAmount=vm.orderRequestDTO[4];
		    	       	vm.orderImport.mfAmount=vm.orderRequestDTO[5];
		    	       	
		    	       	vm.orderImport.timeLimitIndicator=vm.orderRequestDTO[9];
		    	       	if(vm.orderImport.timeLimitIndicator=="GTTM"){
		    	       		vm.orderImport.expiryTime=vm.orderRequestDTO[10];
		    	       	}
		    	       	
		    	       	if(vm.orderImport.activityIndicator!="PLAC"){
		    	       		vm.orderImport.revisionTimeStamp=vm.orderRequestDTO[11];
		    	       	}
		    	    	
		    	        vm.orderImport.userId=vm.orderRequestDTO[16];
		    	    	vm.orderImport.retailUserCode = vm.orderRequestDTO[17];
		    	    	vm.orderImport.userEntityOwnerId = vm.orderRequestDTO[18];
		    	    	
		         /*    	vm.orderImport.remarks1 = vm.remarks1;
                        vm.orderImport.remarks2=  vm.remarks2;
                        vm.orderImport.remarks3= vm.remarks3;
                        vm.orderImport.remarks4= vm.remarks4; */
		            	vm.orderImport.spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
		            	if(vm.orderImport.typeOfOrder=="MRKT" && vm.orderImport.activityIndicator=="MDFY"){
		            		vm.orderImport.typeOfOrder="LIMT";
		            	}
		
		            	
		            		if(activityIndicator=='PLAC'){
		                		placeOrderRequest(vm.orderImport)
		            		}else if(activityIndicator=='MDFY'){
                                Market.modifyOrder(vm.orderImport, onOrderSuccess, onOrderFailure);
		            		}else if(activityIndicator=='CNCL'){
                                Market.cancelOrder(vm.orderImport, onOrderSuccess, onOrderFailure);
		            		}
		            		
		        	}else{
		        		vm.importErrorString = vm.importErrorString + vm.errorString;
		        	}       	
	        }
	           
	        
	           
	           function validateOrderUpload(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode,orderAmount,limitExRate,stopExRate,activityIndicator, oldOrderAmount){

	           vm.configSettings = vm.globalValues.getConfigSettings();
        	   if(vm.defaultInstrumentCode=='CASH'){
            		if(buySellFlag=='SELL' || buySellFlag=='MODIFYSELL'){
                    	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.cashSpotOfferSpread); 
                    		
            		}else if(buySellFlag=='BUYY' || buySellFlag=='MODIFYBUY'){
                    	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.cashSpotBidSpread); 
            		}
            	}else if(vm.defaultInstrumentCode=='TOM'){
                		if(buySellFlag=='SELL' || buySellFlag=='MODIFYSELL'){
                        	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.tomSpotOfferSpread);  
                		}else if(buySellFlag=='BUYY' || buySellFlag=='MODIFYBUY'){
                        	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.tomSpotBidSpread); 
                		}
                }else{
                	limitExRateInSpot = limitExRate;
                }
        	   
	           	if(!validateOrderAmount(orderAmount)){
	           		return false;
	           	}
	           	
	           	if(!validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag, priceCondtion)){
	           		
	           		return false;
	           	}
	           	
	           	// ordertickSize
	           	// ltp
	           	var ordertickSize = gettickSizeForGivenInstrument(instrumentCode);
	           	var ltp = 0
	           	
	           	if(buySellFlag!='SELLBUY'){
	               	ltp = getLtpValue();
	               	console.log(ltp);
	               	if(!validateLimitExchangeRate(buySellFlag, activityIndicator,priceCondtion, limitExRate,limitExRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, buySellFlag, limitExRate, stopExRate, ordertickSize)){
	               		return false;
	               	}
	               	if(vm.errorString!=''){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag)){
	               		return false;
	               	}
	           	}else if(buySellFlag=='SELLBUY'){
	               	var ltp = getLtpValue();
	               	if(!validateLimitExchangeRate('BUYY', activityIndicator,priceCondtion, limitExRate, limitExRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateLimitExchangeRate('SELL', activityIndicator,priceCondtion, limitExRate, limitExRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, 'BUYY', limitExRate, stopExRate, ordertickSize)){
	               		// return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, 'SELL', limitExRate, stopExRate, ordertickSize)){
	               		return false;
	               	}
	               	if(vm.errorString!=''){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY')){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'SELL')){
	               		return false;
	               	}
	           	}
	           	
	           	return true;
	           }
        
	     function downloadData(){
	    	 
	    	 if(vm.orderArr!=null || vm.orderArr!=undefined || vm.orderArr.length==0){
	    		 downloadCSV({filename : "order_activity" + vm.formattedDate +  ".csv", input : vm.orderArr});

	        		vm.orderArr=[];
	    	 }
	    	 	
	    	 if(vm.pendingArr!=null || vm.pendingArr!=undefined || vm.pendingArr.length==0){
        		downloadCSV({filename : "pending_orders" + vm.formattedDate +  ".csv", input : vm.pendingArr});
        		vm.pendingArr=[];
	    	 }
	    	 if(vm.tradeArr!=null || vm.tradeArr!=undefined || vm.tradeArr.length==0){
        		downloadCSV({filename : "trades" + vm.formattedDate +  ".csv", input : vm.tradeArr});
        		vm.tradeArr=[];
	    	 }
	    	 if(vm.limitArr!=null || vm.limitArr!=undefined || vm.limitArr.length==0){
        		downloadCSV({filename : "limits" + vm.formattedDate +  ".csv", input : vm.limitArr});
        		vm.limitArr=[];
	    	 }
        		
        		
        		
	     }
	           
	           
        /* trading home page */
        // initializing
        $rootScope.tickerPrice={};
        $rootScope.tickerPrice.retailSpot = 0;
        $rootScope.tickerPrice.ibSpot = 0;
        $rootScope.tickerPrice.retailSpotDifference = 0;
        $rootScope.tickerPrice.ibSpotDifference = 0;
        $rootScope.tickerPrice.lttIbSpot = '';
        $rootScope.tickerPrice.lttSpot = '';
        vm.orderType='BUYY';
        vm.selectedInstrument = 'SPOT';
        $rootScope.retailSpotMarketWatch = {};
        vm.orderDTO = {};
        vm.remarksDTO={};
        vm.orderFromMarketWatch = false;
        vm.orderDTOList=[];
        //$rootScope.retailSpotMarketWatch.bidPrice = 72.000;
        vm.getPendingOrdersReport = getPendingOrdersReport; 
        vm.getLimitUtilisedReport = getLimitUtilisedReport;
        vm.getTradesReport = getTradesReport;
        vm.getPendingOrders = getPendingOrders;
        vm.getTrades = getTrades;
        vm.getLimitsAvailable = getLimitsAvailable;
        vm.changeInstrument = changeInstrument;
        vm.changeDefaultPriceCondition = changeDefaultPriceCondition;
        vm.modifyOrder = modifyOrder;
        vm.cancelTrade = cancelTrade;
        vm.rolloverTrade = rolloverTrade;
        vm.earlyDeliveryTrade = earlyDeliveryTrade;
        vm.placeOrder = placeOrder; 
        vm.placeOrderForRollover = placeOrderForRollover; 
        vm.placeMarketOrder = placeMarketOrder;
        vm.cancelOrder = cancelOrder;
        vm.cancelModifyOrderAction = cancelModifyOrderAction;
        vm.cancelPendingOrdersSelected = cancelPendingOrdersSelected;
        vm.cancelAllOrders = cancelAllOrders;
        vm.cancelOutStandingTrade = cancelOutStandingTrade;
        vm.showPriceIcon=true;
        vm.generateInvoice = generateInvocie;
        vm.asyncGenerateInvoice = asyncGenerateInvoice;
        $rootScope.invoiceGenerated = false;
        $rootScope.asyncInvoiceGenerated = false;
       
        vm.addRemarks = addRemarks;
        vm.expandMarketDepth = expandMarketDepth;
        vm.expandMarketDepthForBothInstrument = expandMarketDepthForBothInstrument;
        vm.openMarketStatistics = openMarketStatistics;
        vm.expandReports = expandReports;
        vm.expandSwapQuote = expandSwapQuote;
        vm.orderDTO.activityIndicator = 'PLAC';
        vm.openOrderPreference = openOrderPreference;
        vm.limitChangeRequest = limitChangeRequest;
        vm.changeRequest = changeRequest;
        vm.newLimitRequest = newLimitRequest;
        vm.getBuyingPower = getBuyingPower;
        vm.getSellingPower = getSellingPower;
        vm.onChangingBankInSwapQuotes = onChangingBankInSwapQuotes;
        vm.onChangingBankInMarkup = onChangingBankInMarkup;
        vm.softCheckDeviationForLimitExchangeRate = softCheckDeviationForLimitExchangeRate;
        vm.softCheckDeviationForBuyLimitExchangeRate = softCheckDeviationForBuyLimitExchangeRate;
        vm.softCheckDeviationForSellLimitExchangeRate = softCheckDeviationForSellLimitExchangeRate;
        vm.printDealTicket = printDealTicket;
        vm.printDealTicketOutstandingTrade = printDealTicketOutstandingTrade;
        vm.pendingArr=[];
        vm.tradeArr=[];
        vm.limitArr=[];
        vm.orderArr=[];
        vm.pinBoard = pinBoard;
        vm.editSwapQuotes = editSwapQuotes;
        vm.limitPriceArray=[];
        vm.sellLimitPriceArray=[];
        vm.buyLimitPriceArray=[];
        vm.removeOrderEntryFocus = removeOrderEntryFocus;
        vm.setOrderEntryFocus = setOrderEntryFocus;
        $rootScope.broadcastServerDown=false;
        
        // initialiize limits
        $rootScope.retailSpotBuyLimit = 0;
        $rootScope.retailSpotSellLimit = 0;
        $rootScope.cashBuyLimit = 0;
        $rootScope.cashSellLimit = 0;
        $rootScope.tomBuyLimit = 0;
        $rootScope.tomSellLimit = 0;
        $rootScope.toggle = true;
        
        // initialize tick price
        $rootScope.tickSize = {};

        // initialize holiday list
        $rootScope.holidayListAfterBusinessDate={};

        function onGetInstrumentSuccess(result){
        	vm.instrumentTypes = result;
        	// get tick size for each instrument
        	$rootScope.tickSize = {};
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode=='SPOT'){
        			$rootScope.tickSize.spot = value.tickSize;
                    var spotNextDate = new Date(value.settlementDate);
                    spotNextDate.setDate(spotNextDate.getDate()+1);
                    // $timeout(function() {
                    //     vm.tradeSettlementDate = spotNextDate;
                    // }, 100);
                    vm.spotNextDate = spotNextDate;
                    $rootScope.spotNextDate =  vm.spotNextDate;
                    vm.maxFromSettlementDate = moment(spotNextDate).add(1, 'days');
                    //vm.selectedInstrumentDate = vm.spotNextDate;
        		}else if(value.instrumentCode=='CASH'){
        			$rootScope.tickSize.cash = value.tickSize;
                    vm.holidayListAfterBusinessDate = HolidayReport.getHolidayListAfterGivenDate({'givenDate': value.settlementDate});
                    $rootScope.holidayListAfterBusinessDate = vm.holidayListAfterBusinessDate;
        		}else if(value.instrumentCode=='TOM'){
        			$rootScope.tickSize.tom = value.tickSize;
        		}else if(value.instrumentCode=='InterbankSPOT'){
        			$rootScope.tickSize.ibSpot = value.tickSize;
        		}else if(value.instrumentCode=='Forward'){
                    $rootScope.tickSize.forward = value.tickSize;
                }
        	})
        }
        
        function onGetInstrumentFailure(){
        	
        }

        function onGetTenorSuccess(result){
            vm.tenors = result;
            $rootScope.tenors = result;
            // vm.tenor = result[0];
            // $timeout(function() {
            //     vm.tradeSettlementDate = new Date(vm.tenor.settlementDate);
            // }, 100);
            if(vm.tenors!=null && vm.tenors!=undefined && vm.tenors.length>0){
                vm.maxTenorDate = new Date(vm.tenors[vm.tenors.length-1].settlementDate);
                $rootScope.maxTenorDate = vm.maxTenorDate;
            }
        }

        function onGetTenorFailure(){

        }

        function onGetTenorLookupSuccess(result){
            vm.tenorsLookup = result;            
            vm.tenorsLookupWithoutOption = _.filter(angular.copy(result), function(s){return s.id != -1;});
            vm.tenor = result[0];
            $timeout(function() {
                vm.tradeSettlementDate = new Date(vm.tenor.settlementDate);
            }, 100);
            $rootScope.tenor = vm.tenor;
        }

        function onGetTenorLookupFailure(){

        }

        function onGetAllExposureLookupSuccess(result){
        	vm.allExposureLookup = result;
        }
        
        function onGetAllExposureLookupFailure(){
        	
        }

        function onGetEnabledExposureLookupSuccess(result){
        	vm.enabledExposureLookup = result;
        }
        
        function onGetEnabledExposureLookupFailure(){
        	
        }

        function onGetAllSubExposureLookupSuccess(result){
        	vm.allSubExposureLookup = result;
        }
        
        function onGetAllSubExposureLookupFailure(){
        	
        }

        function onGetEnabledSubExposureLookupSuccess(result){
        	vm.enabledSubExposureLookup = result;
        }
        
        function onGetEnabledSubExposureLookupFailure(){
        	
        }

        function setExposureTypeFromAllExposureLookup(exposureType){
            if(exposureType==null || exposureType==undefined || exposureType==''){
                exposureType = vm.userPreference.userExposureType;
            }
            angular.forEach(vm.allExposureLookup, function(value){
                if(value.name==exposureType){
                    vm.exposureType = value;
                }
            })
        }

        function setExposureSubTypeFromAllSubExposureLookup(exposureSubType){
            if(exposureSubType==null || exposureSubType==undefined || exposureSubType==''){
                exposureSubType = vm.userPreference.userExposureSubType;
            }
            angular.forEach(vm.allSubExposureLookup, function(value){
                if(value.name==exposureSubType){
                    vm.exposureSubType = value;
                }
            })
        }

        function setExposureTypeFromEnabledExposureLookup(exposureType){
            if(exposureType==null || exposureType==undefined || exposureType==''){
                exposureType = vm.userPreference.userExposureType;
            }
            angular.forEach(vm.enabledExposureLookup, function(value){
                if(value.name==exposureType){
                    vm.exposureType = value;
                }
            })
            if(vm.exposureType!=null && (vm.exposureType.id==null || vm.exposureType.id==undefined)){
                vm.exposureType = null;
            }
        }

        function setExposureSubTypeFromEnabledSubExposureLookup(exposureSubType){
            if(exposureSubType==null || exposureSubType==undefined || exposureSubType==''){
                exposureSubType = vm.userPreference.userExposureSubType;
            }
            angular.forEach(vm.enabledSubExposureLookup, function(value){
                if(value.name==exposureSubType){
                    vm.exposureSubType = value;
                }
            })
            if(vm.exposureSubType!=null && (vm.exposureSubType.id==null || vm.exposureSubType.id==undefined)){
                vm.exposureSubType = null;
            }
        }

        function editSwapQuotes(){
        	workflowSharedService.viewFrom = "swapQuotes";
        	$state.go('swap-quotes-change', {businessDate: vm.globalValues.getSystemSettings().businessDate});
        }
        
        $scope.$watch('vm.defaultTradingCustomerControl', function(value){
            if(vm.account!=null && vm.account!=undefined && vm.account.organisation.organisationType.shortName=='BANK'){
                vm.defaultTradingCustomer = value;
                pinBoard();
            }
        })

        // pinboard
        function pinBoard(){
            if(vm.account!=null && vm.account!=undefined){
                if(vm.account.entityOwnerType.shortName=='Bank'){
                    if(vm.defaultTradingCustomerControl!=null && vm.defaultTradingCustomerControl!=undefined){
                        CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: vm.globalValues.getOrganisation().id, customerId: vm.defaultTradingCustomerControl.id}, function(result){
                            vm.tradingBranch = result;
                            vm.defaultTradingBranch = result;

                            // fill default values
                            fillDefaultValuesToControlsForPinBoard();
                            // get swap quotes for the logged in bank
                            getSwapQuotes();
                            // get pending orders, trades, limits reports
                            $timeout(function(){
                                getReportsToDisplay();
                            }, 100);
                        })
                    }else{
                        vm.defaultTradingCustomer = null;
                        vm.defaultTradingBranch = null;

                        // fill default values
                        fillDefaultValuesToControlsForPinBoard();
                        // get swap quotes for the logged in bank
                        getSwapQuotes();
                        // get pending orders, trades, limits reports
                        $timeout(function(){
                            getReportsToDisplay();
                        }, 100);
                    }
                }else{
                    // fill default values
                    fillDefaultValuesToControlsForPinBoard();
                    // get swap quotes for the logged in bank
                    getSwapQuotes();
                    // get pending orders, trades, limits reports
                    $timeout(function(){
                        getReportsToDisplay();
                    }, 100);
                }
            }
        }
        
        function fillDefaultValuesToControlsForPinBoard(){
        	vm.defaultTradingBank = vm.globalValues.getBank();
        	vm.tradingBank = vm.defaultTradingBank;
        	$rootScope.defaultTradingBank = vm.globalValues.getBank();
        	//vm.defaultTradingCustomer = vm.defaultTradingCustomerControl;
        	if(vm.account.entityOwnerType.shortName=='Bank'){
        		fillDefaultValuesToReportFilters(vm.defaultTradingBank, vm.defaultTradingBranch, vm.defaultTradingCustomer);
        	}else if(vm.account.entityOwnerType.shortName=='Branch'){
        		fillDefaultValuesToReportFilters(vm.defaultTradingBank, vm.globalValues.getBranch(), vm.defaultTradingCustomer);
        	}
        }
        
        // bootup home page
        function bootUp(){

        	if(vm.account!=null){
        		getConfigForGenerate();
            	// get user specific settings for the logged in user
                // we can get default trading bank, branch and customer for the
				// logged in user from the user preference data.
                // fill the filters of branch and customer with the default
				// trading branch and customer we get it from user preference.
                vm.configSettings = vm.globalValues.getConfigSettings();
        		
        		/*
				 * StaticLookUp.getConfigSettings(function(result){
				 * vm.configSettings = result; })
				 */
                
                // if(vm.configSettings.allowOrderImport=='Yes'){
                //     vm.allTradingBanks=CustomerBankRelationshipReport.query();
                // }

        		UserProfile.get({userId: vm.account.id}, onGetUserSpecSettingSuccess, onGetUserSpecSettingFailure)
                // get currency pair and make USDINR default.
                //CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
                
                Market.getDailyClosingPrice(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
                
                getStaticDataFromDb();
                
                vm.noOfRecordsPerPage = 5;
                vm.page = 1;
                vm.noOfRecordsPerPageOP = 5;
                vm.pageOP = 1;
                vm.noOfRecordsPerPageOT = 5;
                vm.pageOT = 1;
                vm.noOfRecordsPerPageTrade = 5;
                vm.pageTrade = 1;
                vm.noOfRecordsPerPageLimit = 5;
                vm.pageLimit = 1;
                $scope.swapPage = 1;
                
                $rootScope.dashboardEntity = {shortName: entityConstants.ENTITY_SHORT_NAME.TRADEORDERMANAGEMENTDASHBOARD};
                $rootScope.termsAndConditionsUploadEntity = {shortName: entityConstants.ENTITY_SHORT_NAME.TRADEORDERMANAGEMENTDASHBOARD};

                setSwapQuotesView();

        	}
        }

        function setSwapQuotesView(){
            if(vm.account.organisation.organisationType.shortName!='BANK'){
                vm.defaultTab = 'comparitive_tab1';
            }else{
                vm.defaultTab = 'comparitive_tab3';
            }
            vm.reportTab = 'PendingOrders';
            vm.showExpandIcon = true;
            vm.bootupCompleted = true;
        }

        $scope.$watch('vm.defaultTab', function(value){
            $rootScope.defaultTab = value;
            if(value=='comparitive_tab2' || value=='comparitive_tab4'){
                // prepare bank comparativeQuotes in price
                if($rootScope.bankComparativeDTO!=null &&$rootScope.bankComparativeDTO!=undefined
                 && $rootScope.retailSpotMarketWatch!=null && $rootScope.retailSpotMarketWatch!=undefined){
                    prepareBankComparativeQuotesInPrice($rootScope.bankComparativeDTO, $rootScope.retailSpotMarketWatch);    
                }
            }
        })
        
        function onGetUserSpecSettingSuccess(result){
        	vm.userPreference = result;
        	$rootScope.userPreference = result;
        	
        	// fill default values
        	getUserPreferenceAndFillDefaultControls();
        	        	
            // vm.currentDate = new Date();
        	IntradayMarket.getCurrentBusinessDate(function(result){
            	vm.currentDate = result.businessDate;
                vm.currentDate = DateUtils.convertLocalDateToServer(vm.currentDate);
                vm.fromSettlementDate = DateUtils.convertLocalDateFromServer(vm.currentDate);
                var year = vm.fromSettlementDate.getFullYear();
                var month = vm.fromSettlementDate.getMonth();
                vm.toSettlementDate = new Date(year, month +2, 0);
            	// get market session settings. to display status. market
				// session settings will be available for SPOT, cash TOM
            	IntradayMarket.getMarketSessionSettings({"currentBusinessDate":vm.currentDate}, onGetMarketSessionSuccess, onGetMarketSessionFailure);

                if(vm.account.organisation.organisationType.shortName!='CLEARCORP'){
                    // $timeout(function(){
                    //     getReportsToDisplay();
                    // }, 100);
                }
        	})
        	
        }
        function setIntradayMarketSessionDetails(){
        	
        	IntradayMarket.getCurrentBusinessDate(function(result){
            	vm.currentDate = result.businessDate;
                vm.currentDate = DateUtils.convertLocalDateToServer(vm.currentDate);
                vm.fromSettlementDate = DateUtils.convertLocalDateFromServer(vm.currentDate);
                var year = vm.fromSettlementDate.getFullYear();
                var month = vm.fromSettlementDate.getMonth();
                vm.toSettlementDate = new Date(year, month +1, 0);
            	// get market session settings. to display status. market
				// session settings will be available for SPOT, cash TOM
            	IntradayMarket.getMarketSessionSettings({"currentBusinessDate":vm.currentDate}, function(result){
            		$rootScope.marketSessionSettings = result;
                	// store session settings in separate variables for each and every
        			// instrument
                	setMarketSettings();
            	}, onGetMarketSessionFailure);

        	});
        }
        
        function onGetUserSpecSettingFailure(){
        	
        }
        
        function onGetCurrencyPairSuccess(result){
            $rootScope.currencyPairList = result;
            // make USDINR as default. the first record is USDINR.
            $rootScope.currencyPair = result[0];

        }
        
        function onGetCurrencyPairFailure(){
        	
        }
        
        function onGetLtpForLimitSuccess(result){
        	vm.ltpForLimitsForAllInstrument = result;
        	angular.forEach(vm.ltpForLimitsForAllInstrument, function(value){
        		if(value.instrument.instrumentCode=='SPOT'){
        			$rootScope.previousDayClosingPriceForSpot = value.lastTradeExchangeRate;
        		}else if(value.instrument.instrumentCode=='InterbankSPOT'){
        			$rootScope.previousDayClosingPriceForIbSpot = value.lastTradeExchangeRate;
        		}
        	})
        	if(vm.isFromIndex){
        		Market.getMarketDepth(onGetMarketDepthSuccess, onGetMarketDepthFailure);
        	}
        }
        
        function onGetLtpForLimitFailure(){
        	
        }
        
        function getStaticDataFromDb(){
            
            // get order status list from db and stored in array for displaying
			// the order status in pending order.
            OrderStatusDetails.getOrderStatusList(onGetOrderStatusListSuccess, onGetOrderStatusListFailure);
            
           if( vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        	   vm.custCode= vm.globalValues.getCustomer().customerCode;
           }
           
           vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
           vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
           vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
           
           if(vm.account.organisation.organisationType.shortName=='BANK'){
        	   vm.showLA = false;
           }else{
        	   vm.showLA = true;
           }

           // if(vm.globalValues.getConfigSettings().negligibleExceptionNumbers!=undefined && vm.globalValues.getConfigSettings().negligibleExceptionNumbers!=''){
           //     $rootScope.negligibleExceptionNumberList = vm.globalValues.getConfigSettings().negligibleExceptionNumbers.split(',');
           // }
        	
	       	// get server status
	       	ServerComponentStatus.getServerComponentStatus(onGetServerStatusSuccess, onGetServerStatusFailure);
        
	        $rootScope.errorMessageList = ErrorMessages.query();

            //get event types and stored in public variable
            $rootScope.eventTypes = StaticLookUp.getEventTypeList();

            //get trade status list and stored in public variable that will be used in report online update
            $rootScope.tradeStatusList = StaticLookUp.getTradeStatusList();

        }
        
        function onGetOrderStatusListSuccess(result){
        	$rootScope.orderStatusList = result;
        }
        
        function onGetOrderStatusListFailure(){
        	
        }
        
        function onGetServerStatusSuccess(result){
        	if(result!=null && result!=undefined){
        		angular.forEach(result, function(value){
        			if(value.processCode=='PNS'){
        				if(value.isActive==true){
        					$rootScope.broadcastServerDown = false;
        				}else{
        					$rootScope.broadcastServerDown = true;
        				}
        			}else if(value.processCode=='OPS'){
        				if(value.isActive==true){
        					$rootScope.orderServerDown = false;
        				}else{
        					$rootScope.orderServerDown = true;
        				}
        			}
        		})
        	}
        }
        
        function onGetServerStatusFailure(){
        	
        }

        function onGetUserMarketWatchSuccess(result){
            //prepare user market watch list to display
            prepareUserMarketWatchToDisplay(result);

            if($rootScope.userMarketWatchList!=null && $rootScope.userMarketWatchList!=undefined && $rootScope.userMarketWatchList.length>0){
                if($rootScope.userMarketWatchList[0]!=null && $rootScope.userMarketWatchList[0]!=undefined && $rootScope.userMarketWatchList[0].sessionStatus!=null && $rootScope.userMarketWatchList[0].sessionStatus=='OPEN'){
                    prepareOutrightComparision($rootScope.userMarketWatchList[0].settlementDate, $rootScope.userMarketWatchList[0].instrument);
                }else if($rootScope.userMarketWatchList[1]!=null && $rootScope.userMarketWatchList[1]!=undefined && $rootScope.userMarketWatchList[1].sessionStatus!=null && $rootScope.userMarketWatchList[1].sessionStatus=='OPEN'){
                    prepareOutrightComparision($rootScope.userMarketWatchList[1].settlementDate, $rootScope.userMarketWatchList[1].instrument);
                }
            }
        }

        //prepare user market watch to display when loading from database
        function prepareUserMarketWatchToDisplay(userMarketWatchListFromDB){
            $rootScope.userMarketWatchList = [];

            angular.forEach(userMarketWatchListFromDB, function(value){
                var userMarketWatch = {};
                userMarketWatch = value;
                userMarketWatch.bidPrice = null;
                userMarketWatch.offerPrice = null;
                userMarketWatch.bidSpread = null;
                userMarketWatch.offerSpread = null;
                userMarketWatch.user = null;
                userMarketWatch = MarketWatch.fillTenorNameAndSettlementDate(userMarketWatch, value, vm.tenors, $rootScope.cashMarketSession, $rootScope.tomMarketSession);
                userMarketWatch.sessionStatus = getSessionStatusForInstrument(userMarketWatch.instrument);
                var tickSize = gettickSizeForGivenInstrument(userMarketWatch.instrument);
                userMarketWatch = MarketWatch.fillBidOfferSpreadInUserMarketWatch(userMarketWatch, $rootScope.bankComparativeDTO, $rootScope.defaultBankSpread, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
                userMarketWatch = MarketWatch.fillBidOfferPriceInUserMarketWatch(userMarketWatch, $rootScope.retailSpotMarketWatch);
                if(value.tenorSettlementDateDetails!=null && value.tenorSettlementDateDetails!=undefined){
                    userMarketWatch.isSettlementHoliday = !(value.tenorSettlementDateDetails.active);
                }else{
                    userMarketWatch.isSettlementHoliday = false;
                }
                $rootScope.userMarketWatchList.push(userMarketWatch);
            })
            //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
            console.log('mkt watch list');
            console.log($rootScope.userMarketWatchList);
        }

        //prepare user market watch online
        function prepareUserMarketWatchOnline(){
            angular.forEach($rootScope.userMarketWatchList, function(userMarketWatch){
                var tickSize = gettickSizeForGivenInstrument(userMarketWatch.instrument);
                userMarketWatch.sessionStatus = getSessionStatusForInstrument(userMarketWatch.instrument);
                userMarketWatch = MarketWatch.fillBidOfferSpreadInUserMarketWatch(userMarketWatch, $rootScope.bankComparativeDTO, $rootScope.defaultBankSpread, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
                userMarketWatch = MarketWatch.fillBidOfferPriceInUserMarketWatch(userMarketWatch, $rootScope.retailSpotMarketWatch);
            })
            //$rootScope.userMarketWatchListMemory = $rootScope.userMarketWatchList;
        }

        function prepareOutrightComparision(settlementDate, instrument){
            $rootScope.outrightRatesList = [];
            $rootScope.outrightRateSettlementDate = settlementDate;
            $rootScope.outrightRateInstrument = instrument;
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined){
                angular.forEach($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                    var outrightRates = {};
                    outrightRates.bank = swapQuotesTenorDateDTO.bank;
                    outrightRates.instrument = instrument;
                    outrightRates.settlementDate = settlementDate;
                    var tickSize = gettickSizeForGivenInstrument(outrightRates.instrument);
                    outrightRates.sessionStatus = getSessionStatusForInstrument(outrightRates.instrument);
                    outrightRates = MarketWatch.fillBidOfferSpreadInUserMarketWatch(outrightRates, $rootScope.bankComparativeDTO, swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
                    outrightRates = MarketWatch.fillBidOfferPriceInUserMarketWatch(outrightRates, $rootScope.retailSpotMarketWatch);
                    $rootScope.outrightRatesList.push(outrightRates);
                });
            }
            console.log('outright');
            console.log($rootScope.outrightRatesList);
            $rootScope.outrightRatesList = MarketWatch.sortOutrightRatesList($rootScope.outrightRatesList);
            //$rootScope.outrightRatesListMemory = $rootScope.outrightRatesList;
        }

        function onGetUserMarketWatchFailure(){

        }

        // fill default values to variables and controls with user preference
        function getUserPreferenceAndFillDefaultControls(){
        	vm.configSettings = vm.globalValues.getConfigSettings();
        	// filling rootscope variables, so that when feed arives, web socket
			// service can use those variables.
        	if(vm.account.organisation.organisationType.shortName!='CLEARCORP'){
            	vm.defaultTradingBank = vm.userPreference.tradingBank;
            	$rootScope.defaultTradingBank = vm.userPreference.tradingBank;
                vm.defaultTradingBankControl = vm.userPreference.tradingBank;
            	vm.defaultTradingBranch = vm.userPreference.tradingBranch;
            	$rootScope.defaultTradingBranch = vm.userPreference.tradingBranch;
        	}else{
        		// $timeout(function(){
        			BankRegistration.getBankLookup(onGetBankListSuccess, onGetBankListFailure);
        		// }, 100)
        	}
        	
        	$timeout(function(){
            	if(vm.account.organisation.organisationType.shortName!='CUSTOMER'){
                	vm.defaultTradingCustomer = vm.userPreference.tradingCustomer;
                	$rootScope.defaultTradingCustomer = vm.userPreference.tradingCustomer;
                    if(vm.account.organisation.organisationType.shortName=='BANK'){
                        vm.defaultTradingCustomerControl = vm.userPreference.tradingCustomer;
                    }
            	}else{
                	vm.defaultTradingCustomer = vm.globalValues.getCustomer();
                	$rootScope.defaultTradingCustomer = vm.globalValues.getCustomer();
            	}
			}, 100);
        	
        	// fill default values to order value, price condition, instrument
			// to the controls.
        	if(vm.userPreference.userPriceCondition=='STLS' && vm.configSettings.isAllowStopLossOrder!='Yes'){
        		vm.userPreference.userPriceCondition = 'LIMT';
        	}
        	if(vm.userPreference.userPriceCondition=='TKPR' && vm.configSettings.isAllowTakeProfitOrder!='Yes'){
        		vm.userPreference.userPriceCondition = 'LIMT';
        	}
        	if(vm.userPreference.userPriceCondition=='MRKT' && vm.configSettings.isAllowMarketOrder!='Yes'){
        		vm.userPreference.userPriceCondition = 'LIMT';
        	}
        	fillDefaultValuesToControls();
        }
        
        function onGetBankListSuccess(result){
        	if(result.length>0){
        		vm.defaultTradingBank = result[0];
            	$rootScope.defaultTradingBank = result[0];
            	vm.tradingBank = result[0];
            	
            	vm.bankFilterForPO = result[0];
        		vm.bankFilterForLimit = result[0];
        		vm.bankFilterForTrade = result[0];
        		vm.bankFilterForOT = result[0];
            	$rootScope.bankFilterForPO = result[0];
        		$rootScope.bankFilterForLimit = result[0];
        		$rootScope.bankFilterForTrade = result[0];
        		$rootScope.bankFilterForOT = result[0];
            	//getReportsToDisplay();
        	}
        }
        
        function onGetBankListFailure(){
        	
        }
        
        // fill default values to controls and variables
        function fillDefaultValuesToControls(){
        	// fill order value, price condition, instrument type, order type to
			// controls
        	if(vm.userPreference!=null && vm.userPreference!=undefined){
				vm.orderVolumeSpotMkt = vm.userPreference.userOrderValue;
				vm.orderVolumeCashMkt = vm.userPreference.userOrderValue;
				vm.orderVolumeTomMkt = vm.userPreference.userOrderValue;
				vm.defaultTimeCondition = vm.userPreference.userTimeCondition;

				vm.defaultInstrumentCode = vm.userPreference.userInstrumentCode;
				fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);

				vm.loadBoardInstrumentCode = vm.userPreference.userInstrumentCode;
				$rootScope.loadBoardInstrumentCode = vm.userPreference.userInstrumentCode;
				
				vm.tradingBank = vm.defaultTradingBank;
				//vm.selectedInstrument = vm.userPreference.instrument;
				fillInstrumentTypeFiltersForReports();

                //set exposure type and exposure sub type 
                setExposureTypeFromEnabledExposureLookup(vm.userPreference.userExposureType);
                setExposureSubTypeFromEnabledSubExposureLookup(vm.userPreference.userExposureSubType);

				if(vm.account.organisation.organisationType.shortName=='BANK' && vm.account.entityOwnerType.shortName=='Bank'){
					fillDefaultValuesToReportFilters(vm.globalValues.getBank(), vm.userPreference.tradingBranch, vm.userPreference.tradingCustomer);
				}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.account.entityOwnerType.shortName=='Branch'){
					fillDefaultValuesToReportFilters(vm.globalValues.getBank(), vm.globalValues.getBranch(), vm.userPreference.tradingCustomer);
				}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
					fillDefaultValuesToReportFilters(vm.userPreference.tradingBank, vm.userPreference.tradingBranch, vm.globalValues.getCustomer());
				}

				defaultOrderEntry();
				
				//vm.remarks = "";
                setDefaultRemark();
        	}
        }
        
        function fillDefaultInstIdForInstCode(instCode){
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode==instCode){
	               	vm.defaultInstrumentId=value.id;
        		}
        	})
        }

        function getInstIdForInstCode(instCode){
            var instId = null;
            angular.forEach(vm.instrumentTypes, function(value){
                if(value.instrumentCode==instCode){
                    instId=value.id;
                }
            });
            return instId;
        }

        function fillInstrumentTypeFiltersForReports(){
			vm.instrumentFilterForPO = null;
			vm.instrumentFilterForTrade = null;
			vm.instrumentFilterForLimit = null;
			vm.instrumentFilterForOT = null;
			$rootScope.instrumentFilterForPO = null;
			$rootScope.instrumentFilterForTrade = null;
			$rootScope.instrumentFilterForLimit = null;
			$rootScope.instrumentFilterForOT = null;

        }
        
        function fillDefaultValuesToReportFilters(bank, branch, customer){
        	vm.tradingBank = bank;
        	vm.tradingBranch = branch;
        	
        	$timeout(function(){
        		vm.tradingCustomer =customer;
    			vm.customerFilterForPO = customer;
    			vm.customerFilterForTrade = customer;
    			vm.customerFilterForLimit = customer;
    			vm.customerFilterForOT = customer;
    			$rootScope.customerFilterForPO = customer;
    			$rootScope.customerFilterForOT = customer;
    			$rootScope.customerFilterForTrade = customer;
    			$rootScope.customerFilterForLimit = customer;
    			//vm.defaultTradingCustomerControl = customer;
			}, 100);
        	vm.bankFilterForPO = bank;
			vm.branchFilterForPO = branch;
			vm.bankFilterForTrade = bank;
			vm.branchFilterForTrade = branch;
			vm.bankFilterForLimit = bank;
			vm.branchFilterForLimit = branch;
        	$rootScope.bankFilterForPO = bank;
			$rootScope.branchFilterForPO = branch;
			$rootScope.bankFilterForTrade = bank;
			$rootScope.branchFilterForTrade = branch;
			$rootScope.bankFilterForLimit = bank;
			$rootScope.branchFilterForLimit = branch;
			
			
			vm.bankFilterForOT = bank;
			vm.branchFilterForOT = branch;
			$rootScope.bankFilterForOT = bank;
			$rootScope.branchFilterForOT = branch;
			
        }
        
        // fill default values to order entry screen
        function defaultOrderEntry(){
        	if(vm.userPreference!=null && vm.userPreference!=undefined){
            	vm.orderDTO = {};
            	vm.orderDTO.orderAmount = vm.userPreference.userOrderValue;
    			vm.orderDTO.timeLimitIndicator = vm.userPreference.userTimeCondition;
    			vm.orderDTO.typeOfOrder = vm.userPreference.userPriceCondition;
    			vm.orderDTO.instrumentCode = vm.defaultInstrumentCode;
                vm.orderDTO.contractCancellation = false;
    			vm.showPreOrderConfirmation = vm.userPreference.showPreOrderConfirmation;
            	vm.orderDTO.activityIndicator = 'PLAC';
                vm.orderDTO.currencyPair='USD/INR'
                vm.orderDTO.SystemUniqueSNo=null;
                vm.noOfSplits='';
                vm.showSplitedOrderAmount=null;
    			// vm.orderType = 'SELL';
                //vm.remarks = "";
    			setDefaultRemark();
                vm.regularAndSplitOrder='Regular';
    			vm.submitting = false;
                //added this for JIRA #1405, suspects that, vm.clicked is not set after order success/failure. 
                //hence added this order entry section refresh 
                vm.clicked = false; 
                vm.orderDTO.forwardType='';

                // if(vm.tenors!=null && vm.tenors!=undefined && vm.tenors.length>0){
                //     vm.tradeSettlementDate = new Date(vm.tenors[0].settlementDate);
                //     setTenorForSettlementDate(vm.tradeSettlementDate);  
                // }
            	changeOrderType(vm.orderType, vm.orderDTO.activityIndicator);
            	//added for preventing vm.defaultTimeCondition no to be undefined
            	vm.defaultTimeCondition = vm.userPreference.userTimeCondition;
            	setDefaultPriceCondition(vm.userPreference.userPriceCondition);
                //set exposure type and exposure sub type 
                setExposureTypeFromEnabledExposureLookup(vm.userPreference.userExposureType);
                setExposureSubTypeFromEnabledSubExposureLookup(vm.userPreference.userExposureSubType);
                vm.orderFromMarketWatch = false;
                $rootScope.orderDTO = vm.orderDTO;
                if(vm.regularAndSplitOrder=='Regular'){
                    vm.orderDTO.isSplitOrder=false;
                }else{
                    vm.orderDTO.isSplitOrder=true;
                }
        	}
        }

        function changeOrderType(buySellFlag, activityIndicator){
        	if(buySellFlag=='BUYY' && activityIndicator=='PLAC'){
            	vm.orderType = 'BUYY';
            	setTimeout(function(){document.getElementById('sellbuyTab').click()});
            	setTimeout(function(){document.getElementById('buyTab').click()});
        		// document.getElementById('buyTab').click();
        	}else if(buySellFlag=='SELL' && activityIndicator=='PLAC'){
        		vm.orderType = 'SELL';
            	setTimeout(function(){document.getElementById('sellbuyTab').click()});
            	setTimeout(function(){document.getElementById('sellTab').click()});
        		// document.getElementById('sellTab').click();
        	}else if(buySellFlag=='BUYY' && activityIndicator=='MDFY'){
            	vm.orderType = 'MODIFYBUY';
            	setTimeout(function(){document.getElementById('modifybuyTab').click()},50);
        		// document.getElementById('buyTab').click();
        	}else if(buySellFlag=='SELL' && activityIndicator=='MDFY'){
        		vm.orderType = 'MODIFYSELL';
            	setTimeout(function(){document.getElementById('modifysellTab').click()},50);
        		// document.getElementById('sellTab').click();
        	}else if(buySellFlag=='BUYY' && activityIndicator=='TRAD'){
                vm.orderType = 'BUYY';
                setTimeout(function(){document.getElementById('tradebuyTab').click()},50);
                // document.getElementById('buyTab').click();
            }else if(buySellFlag=='SELL' && activityIndicator=='TRAD'){
                vm.orderType = 'SELL';
                setTimeout(function(){document.getElementById('tradesellTab').click()},50);
                // document.getElementById('sellTab').click();
            }else if(buySellFlag=='BUYY' && activityIndicator=='ROLL'){
                vm.orderType = 'BUYY';
                setTimeout(function(){document.getElementById('rolloverbuyTab').click()},50);
            }else if(buySellFlag=='SELL' && activityIndicator=='ROLL'){
                vm.orderType = 'SELL';
                setTimeout(function(){document.getElementById('rolloversellTab').click()},50);
            }else if(buySellFlag=='BUYY' && activityIndicator=='EARL'){
                vm.orderType = 'BUYY';
                setTimeout(function(){document.getElementById('earlydeliverybuyTab').click()},50);
            }else if(buySellFlag=='SELL' && activityIndicator=='EARL'){
                vm.orderType = 'SELL';
                setTimeout(function(){document.getElementById('earlydeliverysellTab').click()},50);
            }else if(buySellFlag=='SELLBUY' && activityIndicator=='PLAC'){
        		vm.orderType = 'SELLBUY';
        		setTimeout(function(){document.getElementById('buyTab').click()});
            	setTimeout(function(){document.getElementById('sellbuyTab').click()});
        		// document.getElementById('sellTab').click();
        	}
        	setTimeout(function(){document.getElementById("marketwatchdiv").click();},50);
        }

        function setDefaultPriceCondition(priceCondition){
        	vm.defaultPriceCondition = priceCondition;
        	// if(!$scope.$$phase){
            	if(priceCondition=='LIMT'){
                	setTimeout(function(){document.getElementById('limitTab').click()},50);
                	// document.getElementById('limitTab').click();
            	}else if(priceCondition=='STLS'){
                	setTimeout(function(){document.getElementById('stoplossTab').click()},50);
                	// document.getElementById('stoplossTab').click();
            	}else if(priceCondition=='TKPR'){
                	setTimeout(function(){document.getElementById('takeprofitTab').click()},50);
                	// document.getElementById('takeprofitTab').click();
            	}else if(priceCondition=='MRKT'){
            		setTimeout(function(){document.getElementById('stoplossTab').click()},50);
            		setTimeout(function(){document.getElementById('takeprofitTab').click()},50);
            		setTimeout(function(){document.getElementById('limitTab').click()},50);
                	setTimeout(function(){document.getElementById('marketTab').click()},50);
                	// document.getElementById('marketTab').click();
            	}
            	setTimeout(function(){document.getElementById("marketwatchdiv").click();},50);
        	// }
        }
        
        function getReportsToDisplay(){
        	// get limits available for customer and bank and branch
        	// we can derive limits available for each and every instruments
			// here and stored in separate variables to display in market
			// watch..
            var bankId = getBankIdForLoggedInUser();
            var customerId = getCustomerIdForLoggedInUser();
        	if(bankId!=0 && customerId!=0){
        		getLimitsAvailableForAllInstruments(bankId, customerId);
        	}
            	
        	// get report details for selected instrument(to display in report
			// section)
	        // For customer user, get limits available of the customer and
			// default/selected bank. we can get default bank/branch from user
			// specific settings.
	        // For bank/branch user, get limits available of the
			// default/selected customer for the bank. we can get default
			// customer for bnak/branch user from user specific settings
        	getReportDetailsForSelectedInstrument();

        }
        
        function getBankIdForLoggedInUser(){
        	var bankId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		if(vm.defaultTradingBank!=null){
            		bankId = vm.defaultTradingBank.id;
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
        		bankId = vm.globalValues.getOrganisation().id;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		if(vm.defaultTradingBank!=null){
            		bankId = vm.defaultTradingBank.id;
        		}
        	}
        	return bankId;
        }
        
        function getCustomerIdForLoggedInUser(){
        	var customerId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP' || vm.account.organisation.organisationType.shortName=='BANK'){
        		if(vm.defaultTradingCustomer!=null){
            		customerId = vm.defaultTradingCustomer.id;
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
           		customerId = vm.globalValues.getOrganisation().id;
        	}
        	return customerId;
        }
        
        function getLimitsAvailableForAllInstruments(bankId, customerId){
            // For customer user, get limits available of the customer and
			// default/selected bank.
            // For bank/branch user, get limits available of the
			// default/selected customer for the bank.
            LimitSettings.getLimitsAvailableForAllInstruments({bankId: bankId, customerId: customerId}, onGetLimitsAvailableSuccess, onGetLimitsAvailableFailure);
        }
        
        function onGetLimitsAvailableSuccess(result){
        	// this is for market watch section
        	vm.limitsAvailableForAllInstrument = result;
        	fillZeroIfLimitIsNotDefined();
        	if(result!=null && result!=undefined){
        		if(result.length>0){
        			$rootScope.limitNotDefined = false;
        			if(result[0].instrumentCode=='ALL'){
        				vm.limitInstrumentCode = 'ALL';
        			}else{
        				vm.limitInstrumentCode = 'INDIVIDUAL';
        			}
        		}else{
        			fillZeroIfLimitIsNotDefined();
        		}
        	}else{
        		fillZeroIfLimitIsNotDefined();
    		}
        	// we can get limits for the instrument(spot, cash, tom or all) and
			// limit type(gross, gross buy, gross sell, net).
        	angular.forEach(vm.limitsAvailableForAllInstrument, function(value){
        		// get limits for each instrument and assign it to separate
				// variables to display in market watch
        		getLimitsForEachInstrument(value);
        	})	

            //get credit factor for settlement date apply with limit
            getCreditFactorAndApplyOnLimits();

        }
        
        function onGetLimitsAvailableFailure(){
        	
        }
        
        function fillZeroIfLimitIsNotDefined(){
        	$rootScope.limitNotDefined = true;
        	$rootScope.retailSpotBuyLimit = 0;
			$rootScope.retailSpotSellLimit = 0;
			$rootScope.cashBuyLimit = 0;
			$rootScope.cashSellLimit = 0;
			$rootScope.tomBuyLimit = 0;
			$rootScope.tomSellLimit = 0;
            $rootScope.forwardBuyLimit = 0;
            $rootScope.forwardSellLimit = 0;
            $rootScope.forwardBuyLimitWithCF = 0;
            $rootScope.forwardSellLimitWithCF = 0;
            $rootScope.maxForwardTenorDate = null;
			// if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		fillLimitsInMarketWatch();
        	// }
        }
        
        function getLimitsForEachInstrument(limit){
    		$rootScope.limitType = limit.limitType;
    		$rootScope.limitNotDefined = false;
        	if(limit.instrumentCode=='SPOT'){
    			$rootScope.retailSpotBuyLimit = getBuyingPower(limit);
    			$rootScope.retailSpotSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='CASH'){
    			$rootScope.cashBuyLimit = getBuyingPower(limit);
    			$rootScope.cashSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='TOM'){
    			$rootScope.tomBuyLimit = getBuyingPower(limit);
    			$rootScope.tomSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='Forward'){
                $rootScope.forwardBuyLimit = getBuyingPower(limit);
                $rootScope.forwardSellLimit = getSellingPower(limit);
                $rootScope.maxForwardTenorDate = MarketWatch.getMaxForwardTenorDate(limit, new Date($rootScope.retailSpotMarketSession.settlementDate));
            }
        	
        	if(limit.instrumentCode=='ALL' || vm.limitInstrumentCode=='ALL'){
        		var buyingPower = getBuyingPower(limit);
        		var sellingPower =  getSellingPower(limit);
    			$rootScope.retailSpotBuyLimit = buyingPower;
    			$rootScope.retailSpotSellLimit = sellingPower;
    			$rootScope.tomBuyLimit = buyingPower;
    			$rootScope.tomSellLimit = sellingPower;
    			$rootScope.cashBuyLimit = buyingPower;
    			$rootScope.cashSellLimit = sellingPower;
                $rootScope.forwardBuyLimit = buyingPower;
                $rootScope.forwardSellLimit = sellingPower;
                $rootScope.maxForwardTenorDate = MarketWatch.getMaxForwardTenorDate(limit, new Date($rootScope.retailSpotMarketSession.settlementDate));
        	}
            if(limit.instrumentCode=='UptoSPOT'){
                var buyingPower = getBuyingPower(limit);
                var sellingPower =  getSellingPower(limit);
                $rootScope.retailSpotBuyLimit = buyingPower;
                $rootScope.retailSpotSellLimit = sellingPower;
                $rootScope.tomBuyLimit = buyingPower;
                $rootScope.tomSellLimit = sellingPower;
                $rootScope.cashBuyLimit = buyingPower;
                $rootScope.cashSellLimit = sellingPower;
            }
        	if(vm.account.organisation.organisationType.shortName=='CUSTOMER' || (vm.account.organisation.organisationType.shortName!='CUSTOMER' && vm.defaultTradingCustomerControl.id==limit.customerId)){
        		fillLimitsInMarketWatch();
        	}
        }
        
        function fillLimitsInMarketWatch(){
        	$rootScope.retailSpotBuyLimitForMkt = $rootScope.retailSpotBuyLimit;
			$rootScope.retailSpotSellLimitForMkt = $rootScope.retailSpotSellLimit;
			$rootScope.tomBuyLimitForMkt = $rootScope.tomBuyLimit;
			$rootScope.tomSellLimitForMkt = $rootScope.tomSellLimit;
			$rootScope.cashBuyLimitForMkt = $rootScope.cashBuyLimit;
			$rootScope.cashSellLimitForMkt = $rootScope.cashSellLimit;
            $rootScope.forwardBuyLimitForMkt = $rootScope.forwardBuyLimit;
            $rootScope.forwardSellLimitForMkt = $rootScope.forwardSellLimit;
        }
        
        // get report details for given instrument
        function getReportDetailsForSelectedInstrument(){
        	
        	getPendingOrderReportForInstrument();
        	getLimitUtilisedReportForInstrument();
        	getTradesReportForInstrument();
        	getOutstandingTradeReportForInstrument();
        	
    	}
        
        vm.getOutstandingTradeReportForInstrument = getOutstandingTradeReportForInstrument;
        function getOutstandingTradeReportForInstrument(){
        	var bankId = getBankIdForReportFilters('OT');
        	var branchId = getBranchIdForReportFilters('OT');
        	var customerId = getCustomerIdForReportFilters('OT');
        	var instrumentId = getInstrumentIdForReportFilters('OT');
        	
        	
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(customerId!=0){
            		getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
            	}
        	}
        }
        
        
        // get pending orders reports for given instrument
        function getPendingOrderReportForInstrument(){
        	var bankId = getBankIdForReportFilters('PO');
        	var branchId = getBranchIdForReportFilters('PO');
        	var customerId = getCustomerIdForReportFilters('PO');
        	var instrumentId = getInstrumentIdForReportFilters('PO');

        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getPendingOrders(bankId, branchId, customerId,instrumentId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(customerId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentId);
            	}
        	}
        }
        
        function getOutstandingTrades(bankId, branchId, customerId, instrumentId, fromDate, toDate){

        	var fromSettDate = null
        	var toSettDate = null;
        	
        	//if the from date is null then provide the minimum possible date , else send the selected date 
        	if(fromDate == null && fromDate == undefined){
        		fromSettDate = new Date(1900,0,1);
        	}else{
        		fromSettDate = fromDate;
        	}

        	// if to date is null then provide the maximum possible date , else send the selected date 
        	if(toDate == null && toDate == undefined){
        		toSettDate = new Date(9999,11,31); 
        	}else{
        		toSettDate = toDate; 
        	}

            OrderStatusDetails.getTradesOutstandingList({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId, fromSetDate : DateUtils.convertLocalDateToServer(fromSettDate),toSetDate :DateUtils.convertLocalDateToServer(toSettDate) }, onOutstandingTradesSuccess, onGetOutstandingTradesFailure);

        	// if(moment(fromSettDate).isAfter(toSettDate,'day')){
        	// 	MessageBox.openMessageBox("Alert","From date cannot be greater than the To date.", "Ok", "homepage");
        	// }

        	// else{
        	// 	OrderStatusDetails.getTradesOutstandingList({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId, fromSetDate : DateUtils.convertLocalDateToServer(fromSettDate),toSetDate :DateUtils.convertLocalDateToServer(toSettDate) }, onOutstandingTradesSuccess, onGetOutstandingTradesFailure);
        	// }
        }
        	   
        function validateTradesOutstandingDates(fromDate, toDate){
            var validFilterDates = true;
            var fromSettDate = null
            var toSettDate = null;
            
            //if the from date is null then provide the minimum possible date , else send the selected date 
            if(fromDate == null && fromDate == undefined){
                fromSettDate = new Date(1900,0,1);
            }else{
                fromSettDate = fromDate;
            }

            // if to date is null then provide the maximum possible date , else send the selected date 
            if(toDate == null && toDate == undefined){
                toSettDate = new Date(9999,11,31); 
            }else{
                toSettDate = toDate; 
            }

            if(moment(fromSettDate).isAfter(toSettDate,'day')){
                validFilterDates = false;
            }

            return validFilterDates;
        }

        function onOutstandingTradesSuccess(result){
        	
        	angular.forEach(result,function(value){
        		value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        		value.select = false;
        	});
        	
        	$rootScope.outstandingTrades = result;
            //$rootScope.outstandingTradesMemory = result;
            vm.clickedReport=false;
        }
        
        function onGetOutstandingTradesFailure(){
            vm.clickedReport=false;
        }
        
        function getPendingOrders(bankId, branchId, customerId, instrumentId){
        	
    		OrderStatusDetails.getPendingOrders({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetPendingOrdersSuccess, onGetPendingOrdersFailure);
        	
        }
        
        

        function onGetPendingOrdersSuccess(result){
        	$rootScope.pendingOrders = result;
            vm.clickedReport=false;
        	angular.forEach($rootScope.pendingOrders, function(value, index){
        		angular.forEach($rootScope.orderStatusList, function(orderStatus){
        			if(value.orderStatusId == orderStatus.id){
        				$rootScope.pendingOrders[index].orderStatus = orderStatus.name;
        			}
        			value.expiryTimeString=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
        		})
        	})
            //$rootScope.pendingOrdersMemory = $rootScope.pendingOrders;
        }
        
        function onGetPendingOrdersFailure(){
            vm.clickedReport=false;
        }
        
        // get bank, branch and customer id from report filters
        function getBankIdForReportFilters(reportType){
        	var bankId = 0;
        	if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP' || $rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
        		if(reportType=='PO'){
            		if(vm.bankFilterForPO!=null){
            			bankId = vm.bankFilterForPO.id;
            		}
        		}else if(reportType=='Limit'){
            		if(vm.bankFilterForLimit!=null){
            			bankId = vm.bankFilterForLimit.id;
            		}
        		}else if(reportType=='Trade'){
            		if(vm.bankFilterForTrade!=null){
            			bankId = vm.bankFilterForTrade.id;
            		}
        		}
        		else if(reportType=='OT'){
            		if(vm.bankFilterForOT!=null){
            			bankId = vm.bankFilterForOT.id;
            		}
        		}
        	}else if($rootScope.account.organisation.organisationType.shortName=='BANK'){
        		bankId = $rootScope.account.organisation.id;
        	}
        	
        	return bankId;
        }
        
        function getBranchIdForReportFilters(reportType){
        	var branchId = 0;
        	if(reportType=='PO'){
        		if(vm.branchFilterForPO!=null){
        			branchId = vm.branchFilterForPO.id;
        		}
    		}else if(reportType=='Limit'){
        		if(vm.branchFilterForLimit!=null){
        			branchId = vm.branchFilterForLimit.id;
        		}
    		}else if(reportType=='Trade'){
        		if(vm.branchFilterForTrade!=null){
        			branchId = vm.branchFilterForTrade.id;
        		}
    		}
    		else if(reportType=='OT'){
        		if(vm.branchFilterForOT!=null){
        			branchId = vm.branchFilterForOT.id;
        		}
    		}
        	return branchId;
        }
        
        function getInstrumentIdForReportFilters(reportType){
        	var instrumentId = 0;
        	if(reportType=='PO'){
        		if(vm.instrumentFilterForPO!=null){
        			instrumentId = vm.instrumentFilterForPO.id;
        		}
    		}else if(reportType=='Limit'){
        		if(vm.instrumentFilterForLimit!=null){
        			instrumentId = vm.instrumentFilterForLimit.id;
        		}
    		}else if(reportType=='Trade'){
        		if(vm.instrumentFilterForTrade!=null){
        			instrumentId = vm.instrumentFilterForTrade.id;
        		}
    		}
    		else if(reportType=='OT'){
        		if(vm.instrumentFilterForOT!=null){
        			instrumentId = vm.instrumentFilterForOT.id;
        		}
    		}
        	return instrumentId;
        }
        
        function getCustomerIdForReportFilters(reportType){
        	var customerId = 0;
        	if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP' || $rootScope.account.organisation.organisationType.shortName=='BANK'){
        		if(reportType=='PO'){
            		if(vm.customerFilterForPO!=null){
            			customerId = vm.customerFilterForPO.id;
            		}
        		}else if(reportType=='Limit'){
            		if(vm.customerFilterForLimit!=null){
            			customerId = vm.customerFilterForLimit.id;
            		}
        		}else if(reportType=='Trade'){
            		if(vm.customerFilterForTrade!=null){
            			customerId = vm.customerFilterForTrade.id;
            		}
        		}
        		else if(reportType=='OT'){
            		if(vm.customerFilterForOT!=null){
            			customerId = vm.customerFilterForOT.id;
            		}
        		}
        	}else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
        		customerId = getCustomerIdForLoggedInUser();
        	}
        	return customerId;
        }
        
        // get limit utilisation reports for given instrument
        function getLimitUtilisedReportForInstrument(){
        	var bankId = getBankIdForReportFilters('Limit');
        	var branchId = getBranchIdForReportFilters('Limit');
        	var customerId = getCustomerIdForReportFilters('Limit');
        	var instrumentId = getInstrumentIdForReportFilters('Limit');
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getLimitsAvailable(bankId, branchId, customerId,instrumentId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(customerId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentId);
            	}
        	}
        }
        
        function getLimitsAvailable(bankId, branchId, customerId, instrumentId){
        	
        	LimitSettings.getLimitUtilsationDetailsForInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetLimitsSuccess, onGetLimitsFailure);

        }
        
        function onGetLimitsSuccess(result){
        	$rootScope.limitsAvailableForSelectedInstrument = result;
            vm.clickedReport=false;
            //$rootScope.limitsAvailableForSelectedInstrumentMemory = result;
        }
        
        function onGetLimitsFailure(){
            vm.clickedReport=false;
        	
        }
        
        // get trades report for given instrument
        function getTradesReportForInstrument(){
        	var bankId = getBankIdForReportFilters('Trade');
        	var branchId = getBranchIdForReportFilters('Trade');
        	var customerId = getCustomerIdForReportFilters('Trade');
        	var instrumentId = getInstrumentIdForReportFilters('Trade');

        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getTrades(bankId, branchId, customerId, instrumentId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getTrades(bankId, branchId, customerId, instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getTrades(bankId, branchId, customerId, instrumentId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(customerId!=0){
            		getTrades(bankId, branchId, customerId, instrumentId);
            	}
        	}
        }
        
        function getTrades(bankId, branchId, customerId, instrumentId){
        	
        	OrderStatusDetails.getTradesForSelectedInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetTradesSuccess, onGetTradesFailure);
        	
        }
        
        function onGetTradesSuccess(result){
        	angular.forEach(result,function(value){
        		value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        	});
        	
        	 
            vm.summaryBuyTrades = result.filter(function(val){
                return val.buySellIndicator == "BUYY";
            })
            
            vm.summarySellTrades = result.filter(function(val){
                return val.buySellIndicator == "SELL";
            })
            
             vm.totalUSDBought = vm.summaryBuyTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             vm.totalUSDSold = vm.summarySellTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             vm.totalINRSold = vm.summaryBuyTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
             vm.totalINRBought = vm.summarySellTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
             
             
            $rootScope.trades = result;
            vm.clickedReport=false;
            //$rootScope.tradesMemory = result;
            vm.expandTradePosition = false;
            if(vm.instrumentFilterForTrade!=null){
            	$rootScope.totalUSDBought = vm.totalUSDBought;
                $rootScope.totalUSDSold = vm.totalUSDSold;
                $rootScope.totalINRSold = vm.totalINRSold;
                $rootScope.totalINRBought = vm.totalINRBought;
                $rootScope.weightedAverageBuy = Math.abs(vm.totalINRSold/vm.totalUSDBought);
                $rootScope.weightedAverageSell = Math.abs(vm.totalINRBought/vm.totalUSDSold);
                $rootScope.usdNet = vm.totalUSDBought - vm.totalUSDSold;
                $rootScope.inrNet = vm.totalINRBought - vm.totalINRSold;
                $rootScope.netWeightedAveragePrice = Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold));
                
            	if(isNaN(Math.abs(vm.totalINRSold/vm.totalUSDBought)) || Math.abs(vm.totalINRSold/vm.totalUSDBought) == Infinity)
            		$rootScope.weightedAverageBuy = null;
            	if(isNaN(Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold))) ||
            			(Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold))) == Infinity)
            		$rootScope.netWeightedAveragePrice = null;
            	
            	if(isNaN(Math.abs(vm.totalINRBought/vm.totalUSDSold)) || Math.abs(vm.totalINRBought/vm.totalUSDSold) == Infinity)
            		 $rootScope.weightedAverageSell = null;
            }else{
            	$rootScope.totalUSDBought = null;
                $rootScope.totalUSDSold = null;
                $rootScope.totalINRSold = null;
                $rootScope.totalINRBought = null;
                $rootScope.weightedAverageBuy = null;
                $rootScope.weightedAverageSell = null;
                $rootScope.usdNet = null;
                $rootScope.inrNet = null;
                $rootScope.netWeightedAveragePrice = null;
            }
            
        }
        
        function onGetTradesFailure(){
            vm.clickedReport=false;
        }
        
        function onGetMarketSessionSuccess(result){
        	$rootScope.marketSessionSettings = result;
        	// store session settings in separate variables for each and every
			// instrument
        	setMarketSettings();

            //get credit factor settings
            getCreditFactorSettings();
        	
        	// get market depth
        	Market.getMarketDepth(onGetMarketDepthSuccess, onGetMarketDepthFailure);
        	
        	// onGetMarketDepthSuccess(vm.marketDepth);
        	// Market.getMarketDepth(onGetMarketDepthSuccess,
			// onGetMarketDepthFailure);
        }
        
        function onGetMarketSessionFailure(){
        	
        }
        
        // set market settings
        function setMarketSettings(){
        	// loop market session settings and get
			// $rootScope.retailSpotMarketSession, $rootScope.cashMarketSession,
			// $rootScope.tomMarketSession..
        	angular.forEach($rootScope.marketSessionSettings, function(value){
        		if(value.instrumentCode == 'SPOT'){
        			$rootScope.retailSpotMarketSession = value;
        		}else if(value.instrumentCode == 'TOM'){
        			$rootScope.tomMarketSession = value;	
        		}else if(value.instrumentCode == 'CASH'){
        			$rootScope.cashMarketSession = value;
        		}else if(value.instrumentCode == 'Forward'){
                    $rootScope.forwardMarketSession = value;
                }
        	})
        }
        
        function onGetMarketDepthSuccess(result){
        	if($rootScope.marketDepth==null || $rootScope.marketDepth==undefined){
            	$rootScope.marketDepth = result;
        	}
        	
        	prepareMarketDepthDisplay();
        	
        }
        
        function onGetMarketDepthFailure(){
        	
        }

        function getCreditFactorSettings(){
            if(vm.account.organisation.organisationType.shortName=='BANK'){
                CreditFactorSettings.query({orgId: vm.account.organisation.id}, onGetCFSettingsForBankSuccess, onGetCFSettingsForBankFailure);
            }else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
                CreditFactorSettings.getCreditFactorsForCustomer({customerId: vm.account.organisation.id}, onGetCFSettingsForCustomerSuccess, onGetCFSettingsForCustomerFailure);
            }
        }

        function onGetCFSettingsForBankSuccess(result){
            if(result!=null && result!=undefined){
                $rootScope.selectedCreditFactor = MarketWatch.computeTenorDatesForGivenCreditFactorSettingsHeader(result, new Date($rootScope.retailSpotMarketSession.settlementDate), new Date($rootScope.cashMarketSession.settlementDate));
            }
        }

        function onGetCFSettingsForBankFailure(){

        }

        function onGetCFSettingsForCustomerSuccess(result){
            if(result!=null && result!=undefined && result.length>0){
                $rootScope.creditFactorList = MarketWatch.computeTenorDatesForGivenCreditFactorSettingsHeaderList(result, new Date($rootScope.retailSpotMarketSession.settlementDate), new Date($rootScope.cashMarketSession.settlementDate));

                $rootScope.selectedCreditFactor = MarketWatch.getCreditFactorSettingsForGivenBank($rootScope.creditFactorList, vm.defaultTradingBank.id);
            }
        }

        function onGetCFSettingsForCustomerFailure(){

        }
        
        function prepareMarketDepthDisplay(){
        	// get market depth for both retail spot and interbank spot
        	getMarketDepthDetailsForReport();
        	// if(!vm.isFromIndex){
            	setTimeout(function(){getSwapQuotes();});
            	setTimeout(function(){getMarkup();});
        	// }
        	
        	// set previous ticker price
        	/*
			 * var previousTickerPrice = angular.copy($rootScope.tickerPrice);
			 * vm.previousTickerPrice = previousTickerPrice;
			 */
        	// get ticker price
        	if($rootScope.marketDepth!=null && $rootScope.marketDepth!=undefined && $rootScope.marketDepth!='' && $rootScope.marketDepth.length>0){
            	angular.forEach($rootScope.marketDepth, function(value){
            		var previousTickerPrice = angular.copy($rootScope.tickerPrice);
                	$rootScope.previousTickerPrice = previousTickerPrice;
                	$rootScope.tickerPrice = MarketWatch.getTickerPrice(value, previousTickerPrice, $rootScope.previousDayClosingPriceForSpot, $rootScope.previousDayClosingPriceForIbSpot);
                	
            	})
        	}else{
        		$rootScope.tickerPrice.retailSpot = $rootScope.previousDayClosingPriceForSpot;
        		$rootScope.tickerPrice.ibSpot = $rootScope.previousDayClosingPriceForIbSpot;
        		
        	}
        	// setLTPPriceAsDefaultValueForLimitOrder();
        }

        function getMarketDepthDetailsForReport(){
        	
// setTimeout(function(){
// var existingSpotMarketDepth = angular.copy($rootScope.retailSpotMarketDepth);
// //get market depth for SPOT
// existingSpotMarketDepth =
// MarketWatch.getRetailSpotMarketDepth(vm.marketDepth);
            	
            	$rootScope.retailSpotMarketDepth = MarketWatch.getRetailSpotMarketDepth($rootScope.marketDepth);
            	//$rootScope.retailSpotMarketDepthMemory = $rootScope.retailSpotMarketDepth;

            	if($rootScope.retailSpotMarketDepth!=null && $rootScope.retailSpotMarketDepth!=undefined){
            		getRetailSpotMarketWatch();
            	}

            	var existingIbSpotMarketDepth = $rootScope.ibSpotMarketDepth;
            	// get market depth for IB SPOT
            	existingIbSpotMarketDepth = MarketWatch.getIBSpotMarketDepth($rootScope.marketDepth);
            	$rootScope.ibSpotMarketDepth = existingIbSpotMarketDepth;
                //$rootScope.ibSpotMarketDepthMemory = existingIbSpotMarketDepth;
            	
            	if($rootScope.ibSpotMarketDepth!=null && $rootScope.ibSpotMarketDepth!=undefined){
                	// get ibSpotMarketWatch
            		var existingIbSpotMarketWatch = $rootScope.ibSpotMarketWatch;
            		existingIbSpotMarketWatch = MarketWatch.getIBSpotMarketWatch($rootScope.ibSpotMarketDepth);
            		$rootScope.ibSpotMarketWatch = existingIbSpotMarketWatch;
                    //$rootScope.ibSpotMarketWatchMemory = existingIbSpotMarketWatch;
            	}
// $scope.$apply();
// }, 100)
        }

        function getSwapQuotes(){
        	
            // if the user is customer user, get spread for the banks in which
			// the customer has relationship with.
            // if the user is bank/branch user, get spread for that particular
			// bank.
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
            	SwapQuotesForTenorDates.getSwapQuotesForAllBanks(onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
            	SwapQuotesForTenorDates.getSwapQuotesForGivenBank({bankId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	SwapQuotesForTenorDates.getSwapQuotesForGivenCustomer({customerId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}
        }
        
    	function onGetSwapQuotesSuccess(result){
        	// result will be list of swap quotes for the bank(s)
        	// if result length is 1, then that is default bank for that
			// user(customer user) and for bank/branch user, the result length
			// will be 1.
        	if(result!=null && result!=undefined && result.swapQuotesTenorDateDTOList!=null && result.swapQuotesTenorDateDTOList!=null 
                && result.swapQuotesTenorDateDTOList.length>0){
        		// get bank swap quotes array to display in the bank comparative
				// quotes section
        		$rootScope.bankComparativeDTO = result;
        		
        		// $rootScope.userMarketWatchList = [{'id' : 1, 'instrument' : 'Instrument 1', 'buyValue': 0, 'sellValue': 0, 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 2, 'instrument' : 'Instrument 2', 'buyValue': '72.313', 'sellValue': '72.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 3, 'instrument' : 'Instrument 3', 'buyValue': '73.313', 'sellValue': '73.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 4, 'instrument' : 'Instrument 4', 'buyValue': '74.313', 'sellValue': '74.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': true, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 5, 'instrument' : 'Instrument 5', 'buyValue': '75.313', 'sellValue': '75.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 6, 'instrument' : 'Instrument 6', 'buyValue': '76.313', 'sellValue': '76.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 7, 'instrument' : 'Instrument 7', 'buyValue': '77.313', 'sellValue': '77.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 8, 'instrument' : 'Instrument 8', 'buyValue': '78.313', 'sellValue': '78.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 9, 'instrument' : 'Instrument 9', 'buyValue': '79.313', 'sellValue': '79.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': true, 'editable': false}
        		// 	,{'id' : 10, 'instrument' : 'Instrument 10', 'buyValue': '70.313', 'sellValue': '70.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 11, 'instrument' : 'Instrument 11', 'buyValue': '71.313', 'sellValue': '71.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 12, 'instrument' : 'Instrument 12', 'buyValue': '72.313', 'sellValue': '72.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 13, 'instrument' : 'Instrument 13', 'buyValue': '73.313', 'sellValue': '73.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': true, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 14, 'instrument' : 'Instrument 14', 'buyValue': '74.313', 'sellValue': '74.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 15, 'instrument' : 'Instrument 15', 'buyValue': '75.313', 'sellValue': '75.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 16, 'instrument' : 'Instrument 16', 'buyValue': '76.313', 'sellValue': '76.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': true, 'editable': false}
        		// 	,{'id' : 17, 'instrument' : 'Instrument 17', 'buyValue': '77.313', 'sellValue': '77.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 18, 'instrument' : 'Instrument 18', 'buyValue': '78.313', 'sellValue': '78.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 19, 'instrument' : 'Instrument 19', 'buyValue': '79.313', 'sellValue': '79.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}
        		// 	,{'id' : 20, 'instrument' : 'Instrument 20', 'buyValue': '70.313', 'sellValue': '70.333', 'settlementDate': '23-Dec-2020', 'isBestBuy': false, 'isBestSell': false, 'editable': false}];
        		
        		
        		// fill defatult bank for clearcorp users
        		// fillDefaultBankForClearcorpUsers(result);

    			// change swap quotes in home page
        		prepareSwapQuotes();

        	}

            //get user market watch settings list
            UserProfile.getUserMarketWatchSettingsList(onGetUserMarketWatchSuccess, onGetUserMarketWatchFailure);

        }
    	
    	function onGetSwapQuotesFailure(){
    		
    	}
        
        function getMarkup(){
        	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		Markup.getAllMarkupForCustomer({customerId: vm.account.organisation.id}, onGetMarkupSuccess, onGetMarkupFailure);
        	}
        }
        
        function onGetMarkupSuccess(result){
        	if(result.length>0){
        		$rootScope.markupSettings = result;
        	}
        }
        
        function onGetMarkupFailure(){
        	
        }
        
    	function fillDefaultBankForClearcorpUsers(bankComparativeQuotes){
    		
    		vm.defaultTradingBank = bankComparativeQuotes[0].bank;
    		$rootScope.defaultTradingBank = bankComparativeQuotes[0].bank;
    		
    		vm.tradingBank = vm.defaultTradingBank;
    		
    	}
    	
        function getRetailSpotMarketWatch(){
        	var existingSpotMarketWatch = $rootScope.retailSpotMarketWatch;
        	// get retailSpot market watch
        	existingSpotMarketWatch = MarketWatch.getRetailSpotMarketWatch($rootScope.retailSpotMarketDepth);
        	$rootScope.retailSpotMarketWatch = existingSpotMarketWatch;
            //$rootScope.retailSpotMarketWatchMemory = existingSpotMarketWatch;
        	// $rootScope.retailSpotMarketWatch.bidPrice = 72.2500;
        	// $rootScope.retailSpotMarketWatch.offerPrice = 72.3500;
        	// $rootScope.retailSpotMarketWatch.bidVolume = 10000000.00;
        	// $rootScope.retailSpotMarketWatch.offerVolume = 12000000.00;
        }

        $rootScope.$on('onChangingBankInSwapQuotes', function(event, result) {
            if($rootScope.bankFromSwapQuote!=null){
                onChangingBankInSwapQuotes($rootScope.bankFromSwapQuote);
            }
        });

        $rootScope.$on('onChangingBankInMarkup', function(event, result) {
            if($rootScope.bankFromMarkup!=null){
                onChangingBankInMarkup($rootScope.bankFromMarkup);
            }
        });
        
        function onChangingBankInMarkup(bankName){
        	BankRegistration.getBankForShortName({bankName: bankName}, function(result){
        		onChangingBankInSwapQuotes(result);
        	});
        }
        
        function onChangingBankInSwapQuotes(bank){
        	vm.defaultTradingBank = bank;
        	$rootScope.defaultTradingBank = bank;
        	$timeout(function(){
        		if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        			vm.defaultTradingCustomer = vm.globalValues.getCustomer();
        			vm.tradingCustomer = vm.globalValues.getCustomer();
                	$rootScope.defaultTradingCustomer = vm.globalValues.getCustomer();
        		}
        	},100)
            vm.tradingBank = vm.defaultTradingBank;
    //     	if(vm.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
    //     		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: bank.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
				// 	vm.defaultTradingBranch = result;
				// 	if(vm.orderDTO.activityIndicator!='PLAC'){
				// 		fillDefaultValuesToControls();
				// 	}
				// 	vm.tradingBank = vm.defaultTradingBank;
				// 	vm.bankFilterForPO = bank;
				// 	vm.bankFilterForLimit = bank;
				// 	vm.bankFilterForTrade = bank;
				// 	vm.branchFilterForPO = result;
				// 	vm.branchFilterForLimit = result;
				// 	vm.branchFilterForTrade = result;
				// 	vm.customerFilterForPO = vm.globalValues.getCustomer();
				// 	vm.customerFilterForLimit = vm.globalValues.getCustomer();
				// 	vm.customerFilterForTrade = vm.globalValues.getCustomer();
				// 	$rootScope.bankFilterForPO = bank;
				// 	$rootScope.bankFilterForLimit = bank;
				// 	$rootScope.bankFilterForTrade = bank;
				// 	$rootScope.branchFilterForPO = result;
				// 	$rootScope.branchFilterForLimit = result;
				// 	$rootScope.branchFilterForTrade = result;
				// 	$rootScope.customerFilterForPO = vm.globalValues.getCustomer();
				// 	$rootScope.customerFilterForLimit = vm.globalValues.getCustomer();
				// 	$rootScope.customerFilterForTrade = vm.globalValues.getCustomer();
					
				// 	vm.bankFilterForOT = bank;
				// 	vm.branchFilterForOT = result;
				// 	vm.customerFilterForOT = vm.globalValues.getCustomer();
				// 	$rootScope.bankFilterForOT = bank;
				// 	$rootScope.branchFilterForOT = result;
				// 	$rootScope.customerFilterForOT = vm.globalValues.getCustomer();
					
				// 	prepareSwapQuotes();
				// 	getReportsToDisplay();
				// });
    //     	}else if(vm.globalValues.getOrganisation().organisationType.shortName=='CLEARCORP'){
    //     		vm.bankFilterForPO = bank;
    //     		vm.bankFilterForLimit = bank;
    //     		vm.bankFilterForTrade = bank;
    //     		vm.bankFilterForOT = bank;
    //     		vm.tradingBank = bank;
    //     		prepareSwapQuotes();
    //     		$timeout(function(){
    //             	getReportsToDisplay();
    // 			}, 100);
    //     	}
        }

        $scope.$watch('vm.defaultTradingBankControl', function(value){
            if(vm.account!=null && vm.account!=undefined){
                var bank = value;
                vm.defaultTradingBank = bank;
                $rootScope.defaultTradingBank = bank;
                //$timeout(function(){
                    if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
                        vm.defaultTradingCustomer = vm.globalValues.getCustomer();
                        vm.tradingCustomer = vm.globalValues.getCustomer();
                        $rootScope.defaultTradingCustomer = vm.globalValues.getCustomer();
                    }
                //},100)
                if(vm.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
                    vm.defaultTradingBranch = null;
                    if(vm.orderDTO.activityIndicator!='PLAC'){
                        fillDefaultValuesToControls();
                    }
                    vm.tradingBank = vm.defaultTradingBank;
                    vm.bankFilterForPO = bank;
                    vm.bankFilterForLimit = bank;
                    vm.bankFilterForTrade = bank;
                    vm.branchFilterForPO = null;
                    vm.branchFilterForLimit = null;
                    vm.branchFilterForTrade = null;
                    vm.customerFilterForPO = vm.globalValues.getCustomer();
                    vm.customerFilterForLimit = vm.globalValues.getCustomer();
                    vm.customerFilterForTrade = vm.globalValues.getCustomer();
                    $rootScope.bankFilterForPO = bank;
                    $rootScope.bankFilterForLimit = bank;
                    $rootScope.bankFilterForTrade = bank;
                    $rootScope.branchFilterForPO = null;
                    $rootScope.branchFilterForLimit = null;
                    $rootScope.branchFilterForTrade = null;
                    $rootScope.customerFilterForPO = vm.globalValues.getCustomer();
                    $rootScope.customerFilterForLimit = vm.globalValues.getCustomer();
                    $rootScope.customerFilterForTrade = vm.globalValues.getCustomer();
                    
                    vm.bankFilterForOT = bank;
                    vm.branchFilterForOT = null;
                    vm.customerFilterForOT = vm.globalValues.getCustomer();
                    $rootScope.bankFilterForOT = bank;
                    $rootScope.branchFilterForOT = null;
                    $rootScope.customerFilterForOT = vm.globalValues.getCustomer();
                    if(bank!=null && bank!=undefined){
                        //CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: bank.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                            var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==bank.id;});
                            vm.defaultTradingBranch = result[0].tradingBranch;
                            if(vm.orderDTO.activityIndicator!='PLAC'){
                                fillDefaultValuesToControls();
                            }
                            vm.branchFilterForPO = result[0].tradingBranch;
                            vm.branchFilterForLimit = result[0].tradingBranch;
                            vm.branchFilterForTrade = result[0].tradingBranch;
                            vm.customerFilterForPO = vm.globalValues.getCustomer();
                            vm.customerFilterForLimit = vm.globalValues.getCustomer();
                            vm.customerFilterForTrade = vm.globalValues.getCustomer();
                            $rootScope.branchFilterForPO = result[0].tradingBranch;
                            $rootScope.branchFilterForLimit = result[0].tradingBranch;
                            $rootScope.branchFilterForTrade = result[0].tradingBranch;
                            $rootScope.customerFilterForPO = vm.globalValues.getCustomer();
                            $rootScope.customerFilterForLimit = vm.globalValues.getCustomer();
                            $rootScope.customerFilterForTrade = vm.globalValues.getCustomer();
                            vm.branchFilterForOT = result[0].tradingBranch;
                            vm.customerFilterForOT = vm.globalValues.getCustomer();
                            $rootScope.branchFilterForOT = result[0].tradingBranch;
                            $rootScope.customerFilterForOT = vm.globalValues.getCustomer();
                            
                            prepareSwapQuotes();
                            getReportsToDisplay();
                        //});
                    }else{
                        if(vm.bootupCompleted){
                            prepareSwapQuotes();
                            getReportsToDisplay();
                            $rootScope.defaultBankSpread = null;
                        }
                    }
                }else if(vm.globalValues.getOrganisation().organisationType.shortName=='CLEARCORP'){
                    vm.bankFilterForPO = bank;
                    vm.bankFilterForLimit = bank;
                    vm.bankFilterForTrade = bank;
                    vm.bankFilterForOT = bank;
                    vm.tradingBank = bank;
                    prepareSwapQuotes();
                    if(value!=null && value!=undefined){
                        $timeout(function(){
                            getReportsToDisplay();
                        }, 100);
                    }
                }
             
                //get user market watch settings list
                //UserProfile.getUserMarketWatchSettingsList(onGetUserMarketWatchSuccess, onGetUserMarketWatchFailure);
                prepareUserMarketWatchToDisplay($rootScope.userMarketWatchList);
                
                if(value==null || value==undefined){
                    if($rootScope.userMarketWatchList!=null && $rootScope.userMarketWatchList!=undefined && $rootScope.userMarketWatchList.length>0){
                        if($rootScope.userMarketWatchList[0]!=null && $rootScope.userMarketWatchList[0]!=undefined && $rootScope.userMarketWatchList[0].sessionStatus!=null && $rootScope.userMarketWatchList[0].sessionStatus=='OPEN'){
                            prepareOutrightComparision($rootScope.userMarketWatchList[0].settlementDate, $rootScope.userMarketWatchList[0].instrument);
                        }else if($rootScope.userMarketWatchList[1]!=null && $rootScope.userMarketWatchList[1]!=undefined && $rootScope.userMarketWatchList[1].sessionStatus!=null && $rootScope.userMarketWatchList[1].sessionStatus=='OPEN'){
                            prepareOutrightComparision($rootScope.userMarketWatchList[1].settlementDate, $rootScope.userMarketWatchList[1].instrument);
                        }
                    }
                }
            }
        })

        function prepareSwapQuotes(){

            // get best offer and bid spread for both Cash/spot and tom/spot.
            getBestSpreadRates($rootScope.bankComparativeDTO);

    		// prepare bank comparativeQuotes in price
    		//prepareBankComparativeQuotesInPrice($rootScope.bankComparativeDTO, $rootScope.retailSpotMarketWatch);
    		
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length==1){
                $rootScope.defaultBankSpread = $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList[0].swapQuotesForTenorDatesList;
            }else{
                // get default bank swap quotes for the customer user
                console.log('prepare swap quotes in home controller');
                console.log(vm.defaultTradingBank);
                if(vm.defaultTradingBank!=null && vm.defaultTradingBank!=undefined && $rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                    $rootScope.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.defaultTradingBank.id);
                }
            }
        	/*if($rootScope.bankComparativeQuotes.length==1){
            	$rootScope.defaultBankSpread = $rootScope.bankComparativeQuotes[0];
        	}else{
        		// get default bank swap quotes for the customer user
        		console.log('prepare swap quotes in home controller');
        		console.log(vm.defaultTradingBank);
        		if(vm.defaultTradingBank!=null && vm.defaultTradingBank!=undefined){
            		$rootScope.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeQuotes, vm.defaultTradingBank.id);
        		}
        	}
    		if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined){
            	// get cash and tom market watch
    			getCashAndTomMarketWatch($rootScope.retailSpotMarketWatch, $rootScope.defaultBankSpread);
    		}*/
        }
        
    	function prepareBankComparativeQuotesInPrice(bankComparativeDTO, retailSpotMarketWatch){
            console.log('compute swap quote in price start - ' + $filter('date')(new Date(), 'yyyy-MM-ddTHH:mm:ss.sssZ'));
            if(bankComparativeDTO!=null && bankComparativeDTO!=undefined){
                $rootScope.bankComparativeDTOInPrice = {};
                $rootScope.bankComparativeDTOInPrice.header = bankComparativeDTO.header;
                $rootScope.bankComparativeDTOInPrice.swapQuotesTenorDateDTOList = [];
                angular.forEach(bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                    var swapQuotesTenorDateDTOInPrice = {};
                    swapQuotesTenorDateDTOInPrice.bank = swapQuotesTenorDateDTO.bank;
                    swapQuotesTenorDateDTOInPrice.businessDate = swapQuotesTenorDateDTO.businessDate;
                    swapQuotesTenorDateDTOInPrice.swapQuotesForTenorDatesList = [];
                    angular.forEach(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, function(swapQuotesForTenorDates){
                        var swapQuotesForTenorDatesInPrice = {};
                        swapQuotesForTenorDatesInPrice = angular.copy(swapQuotesForTenorDates);
                        // prepare bank quotes in price
                        swapQuotesForTenorDatesInPrice = MarketWatch.getBankSwapQuotesInPrice(swapQuotesForTenorDatesInPrice, retailSpotMarketWatch);
                        swapQuotesTenorDateDTOInPrice.swapQuotesForTenorDatesList.push(swapQuotesForTenorDatesInPrice)
                    })
                    $rootScope.bankComparativeDTOInPrice.swapQuotesTenorDateDTOList.push(swapQuotesTenorDateDTOInPrice);
                })
                //$rootScope.bankComparativeDTOInPriceMemory = $rootScope.bankComparativeDTOInPrice;
            }
            console.log('compute swap quote in price end - ' + $filter('date')(new Date(), 'yyyy-MM-ddTHH:mm:ss.sssZ'));
    	}
    	
    	function getBestSpreadRates(bankComparativeDTO){
    		
            if(bankComparativeDTO!=null && bankComparativeDTO!=undefined && bankComparativeDTO.swapQuotesTenorDateDTOList!=null
                && bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                var swapQuotesForTenorDatesList = [];
                angular.forEach(bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                    swapQuotesForTenorDatesList = swapQuotesForTenorDatesList.concat(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList);
                })

                $rootScope.bestBankQuoteList = [];
                angular.forEach(bankComparativeDTO.header, function(header){
                    var bestBankQuote = {};
                    bestBankQuote.tenorName = header;
                    var bankQuotesForTenorForBid = _.filter(angular.copy(swapQuotesForTenorDatesList), { 'tenorName': header });
                    var bankQuotesForTenorForOffer = _.filter(angular.copy(swapQuotesForTenorDatesList), { 'tenorName': header });
                    bestBankQuote.bestBidSpread = Math.max.apply(Math,bankQuotesForTenorForBid.map(function(item){return item.bidSpread;}).filter(function(val){return val !== null}));
                    bestBankQuote.bestOfferSpread = Math.min.apply(Math,bankQuotesForTenorForOffer.map(function(item){return item.offerSpread;}).filter(function(val){return val !== null}));
                    $rootScope.bestBankQuoteList.push(bestBankQuote);
                })
            }

            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined){
                angular.forEach($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                    angular.forEach(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, function(swapQuotesForTenorDates){
                        swapQuotesForTenorDates.isBestBid = false;
                        swapQuotesForTenorDates.isBestOffer = false;
                        angular.forEach($rootScope.bestBankQuoteList, function(bestBankQuote){
                            if(swapQuotesForTenorDates.tenorName==bestBankQuote.tenorName){
                                if(swapQuotesForTenorDates.bidSpread==bestBankQuote.bestBidSpread){
                                    swapQuotesForTenorDates.isBestBid = true;
                                }
                                if(swapQuotesForTenorDates.offerSpread==bestBankQuote.bestOfferSpread){
                                    swapQuotesForTenorDates.isBestOffer = true;
                                }
                            }
                        })    
                    })
                })
            }
    	}
    	
    	function getCashAndTomMarketWatch(spotMarketWatch, bankSpread){
    		$rootScope.cashMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'CASH');
    		$rootScope.tomMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'TOM');
    	}
    	
        // clear numeric controls
        function clearNumericControls(){
        	//setFieldValues("txtlimitorderprice", "");	
        	// setFieldValues("txtlimitorderprice",
			// $filter('number')($rootScope.tickerPrice.retailSpot,4));
			setFieldValues("txtsltriggerprice", "");
			//setFieldValues("txtsllimitprice", "");
			setFieldValues("txttptriggerprice", "");
			//setFieldValues("txttplimitprice", "");
			//setFieldValues("txtsellbuyslbuylimitprice", "");
			setFieldValues("txtsellbuyslbuytriggerprice", "");
			//setFieldValues("txtsellbuyslselllimitprice", "");
			setFieldValues("txtsellbuyslselltriggerprice", "");
			//setFieldValues("txtsellbuytpbuylimitprice", "");
			setFieldValues("txtsellbuytpbuytriggerprice", "");
			//setFieldValues("txtsellbuytpselllimitprice", "");
			setFieldValues("txtsellbuytpselltriggerprice", "");
			//setFieldValues("txtsellbuylimitbuyorderprice", "");
			// setFieldValues("txtsellbuylimitbuyorderprice",
			// $filter('number')($rootScope.tickerPrice.retailSpot,4));
			//setFieldValues("txtsellbuylimitsellorderprice", "");
			// setFieldValues("txtsellbuylimitsellorderprice",
			// $filter('number')($rootScope.tickerPrice.retailSpot,4));
        }
        
       
        function setFieldValues(fieldName, value){
        	if( document.getElementById(fieldName) !=null && document.getElementById(fieldName)!= undefined){
        		// document.getElementById(fieldName).focus=true;
        		document.getElementById(fieldName).value = value;
        		// document.getElementById(fieldName).blur=true;
        	}
        }
        
        $scope.$watch('loadBoardInstrumentCode', function(value1){
            if($rootScope.orderDTO!=null && $rootScope.orderDTO!=undefined && $rootScope.orderDTO.activityIndicator=='PLAC' && ($rootScope.orderDTO.contractCancellation==false || $rootScope.orderDTO.contractCancellation==undefined)){
                angular.forEach(vm.instrumentTypes, function(value){
                    if(value.instrumentCode==value1 && value.instrumentCode==$rootScope.loadBoardInstrumentCode){
                        // vm.instrumentFilterForPO = value;
                        // vm.instrumentFilterForTrade = value;
                        // vm.instrumentFilterForLimit = value
                        // vm.instrumentFilterForOT = value;;
                        // $rootScope.instrumentFilterForPO = value;
                        // $rootScope.instrumentFilterForTrade = value;
                        // $rootScope.instrumentFilterForLimit = value;
                        // $rootScope.instrumentFilterForOT = value;
                        vm.selectedInstrument = value;
                        vm.defaultInstrumentId=value.id;
                        // change report details according to load board instrument
                        //getReportDetailsForSelectedInstrument("");
                    }
                })
            }
        })
        
        function changeInstrument(instrumentCode){
            $rootScope.loadBoardInstrumentCode = instrumentCode;
            if($rootScope.orderDTO!=null && $rootScope.orderDTO!=undefined && $rootScope.orderDTO.activityIndicator=='PLAC' && ($rootScope.orderDTO.contractCancellation==false || $rootScope.orderDTO.contractCancellation==undefined)){
                // vm.instrumentFilterForPO = instrumentCode;
                selectInstrumentInOrderEntry(instrumentCode);
            }
        }
        
        function selectInstrumentInOrderEntry(instrumentCode){
        	if(instrumentCode=='SPOT'){
            	setTimeout(function(){document.getElementById('spotInstrument').click()});
        	}else if(instrumentCode=='CASH'){
            	setTimeout(function(){document.getElementById('cashInstrument').click()});
            	// document.getElementById('cashInstrument').click();
        	}else if(instrumentCode=='TOM'){
            	setTimeout(function(){document.getElementById('tomInstrument').click()});
            	// document.getElementById('tomInstrument').click();
        	}else if(instrumentCode=='Forward'){
            	setTimeout(function(){document.getElementById('forwardInstrument').click()});
            	// document.getElementById('forwardInstrument').click();
        	}
        }
        
        function selectTimeConditionInOrderEntry(timeCondition){
        	if(timeCondition=='DAYY'){
            	setTimeout(function(){document.getElementById('dayTimeCondition').click()});
        	}else if(timeCondition=='IOCC'){
            	setTimeout(function(){document.getElementById('iocTimeCondition').click()});
        	}else if(timeCondition=='GTTM'){
            	setTimeout(function(){document.getElementById('gttTimeCondition').click()});
        	}
        }
        
        function changeDefaultPriceCondition(priceCondition){
        	vm.defaultPriceCondition = priceCondition;
        }
        
    	function onCashMarketWatchSuccess(result){
    		vm.cashMarketWacth = result;
    	}
    	
    	function onTomMarketWatchSuccess(result){
    		vm.tomMarketWacth = result;
    	}
    	
    	function onTomMarketWatchFailure(){
    		
    	}
    	
        function fillZeroIfLimitIsNotDefinedForInstrumentCode(instrumentCode){
        	if(instrumentCode=='SPOT'){
            	$rootScope.retailSpotBuyLimit = 0;
    			$rootScope.retailSpotSellLimit = 0;
        	}else if(instrumentCode=='CASH'){
    			$rootScope.cashBuyLimit = 0;
    			$rootScope.cashSellLimit = 0;
        	}else if(instrumentCode=='TOM'){
    			$rootScope.tomBuyLimit = 0;
    			$rootScope.tomSellLimit = 0;
        	}else if(instrumentCode=='Forward'){
                $rootScope.forwardBuyLimit = 0;
                $rootScope.forwardSellLimit = 0;
                $rootScope.forwardBuyLimitWithCF = 0;
                $rootScope.forwardSellLimitWithCF = 0;
                $rootScope.maxForwardTenorDate = null;
            }else if(instrumentCode=='ALL'){
            	$rootScope.retailSpotBuyLimit = 0;
    			$rootScope.retailSpotSellLimit = 0;
    			$rootScope.cashBuyLimit = 0;
    			$rootScope.cashSellLimit = 0;
    			$rootScope.tomBuyLimit = 0;
    			$rootScope.tomSellLimit = 0;
                $rootScope.forwardBuyLimit = 0;
                $rootScope.forwardSellLimit = 0;
                $rootScope.forwardBuyLimitWithCF = 0;
                $rootScope.forwardSellLimitWithCF = 0;
                $rootScope.maxForwardTenorDate = null;
        	}else if(instrumentCode=='UPTOSPOT'){
                $rootScope.retailSpotBuyLimit = 0;
                $rootScope.retailSpotSellLimit = 0;
                $rootScope.cashBuyLimit = 0;
                $rootScope.cashSellLimit = 0;
                $rootScope.tomBuyLimit = 0;
                $rootScope.tomSellLimit = 0;
            }
        }
        
        function getPendingOrdersReport(){
            vm.clickedReport=true;
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForPO!=null && vm.instrumentFilterForPO!=undefined){
        		instrumentTypeId = vm.instrumentFilterForPO.id;
                vm.pendingOrderInstrumentCode = 'ALL';
        	}

        	getPendingOrderReportForInstrument(instrumentTypeId);
        	resetPendingOrderCheckboxSelection(false);
        }
        
        function getTradesReport(){
            vm.clickedReport=true;
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForTrade!=null && vm.instrumentFilterForTrade!=undefined){
        		instrumentTypeId = vm.instrumentFilterForTrade.id;
        		vm.tradeInstrumentCode = 'ALL';
        	}
        	
        	getTradesReportForInstrument(instrumentTypeId);
        }
        
        function getLimitUtilisedReport(){
            vm.clickedReport=true;
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForLimit!=null && vm.instrumentFilterForLimit!=undefined){
        		instrumentTypeId = vm.instrumentFilterForLimit.id;
        		vm.limitInstrumentCode = 'ALL';
        	}

        	getLimitUtilisedReportForInstrument(instrumentTypeId);
        	
        }
        
        vm.getOutstandingTradesReport = getOutstandingTradesReport;
        function getOutstandingTradesReport(){
            vm.clickedReport=true;
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForOT!=null && vm.instrumentFilterForOT!=undefined){
        		instrumentTypeId = vm.instrumentFilterForOT.id;
        	}

            if(validateTradesOutstandingDates(vm.fromSettlementDate, vm.toSettlementDate)){
                getOutstandingTradeReportForInstrument(instrumentTypeId);
            }else{
                MessageBox.openMessageBox("Alert","From date cannot be greater than the To date.", "Ok", "homepage");
            }
        }
        
        // place market order - not used
        function placeMarketOrder(buySellFlag, orderVolume, instrumentType, instrumentId, limitExcahngeRate){
        	vm.errorString = '';
        	var orderDTO = {};
        	orderDTO.typeOfOrder = 'LIMT';
        	orderDTO.activityIndicator = 'PLAC';
        	orderDTO.instrumentCode = instrumentType;
        	orderDTO.buySellIndicator = buySellFlag;
        	orderDTO.orderAmount = parseFloat(orderVolume.replace(/,/g, ''));
        	orderDTO.instrumentId = instrumentId;
        	orderDTO.timeLimitIndicator = 'IOCC';
        	orderDTO.expiryTime = 0;

        	// fill bank, branch, customer details into order request dto
        	fillBankBranchCustomerDetailsFromMktWatch(orderDTO)
        	
        	if(vm.account.organisation.organisationType.shortName!='CUSTOMER'){
        		if(vm.defaultTradingCustomerControl!=null && vm.defaultTradingCustomerControl!=undefined){
            		orderDTO.customerId = vm.defaultTradingCustomerControl.id;
                	orderDTO.customerName = vm.defaultTradingCustomerControl.customerFirstName;
                	orderDTO.customerCode = vm.defaultTradingCustomerControl.customerCode;
        		}else{
        			// MessageBox.openMessageBox("Alert", "Please select the
					// trading customer to proceed.", "Ok",
					// "selectdefaultcustomer");
        		}
        	}
        	
        	// vm.orderDTO.customerName =
			// vm.globalValues.getCustomer().customerFirstName;
        	orderDTO.userId = vm.globalValues.getUserId();
        	orderDTO.retailUserCode = vm.globalValues.getUser().login;
        	orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
        	var valuelimitExchangeRate = angular.copy(vm.orderDTO.limitExchangeRate);
        	var valueStopExchangeRate = angular.copy(vm.orderDTO.stopExchangeRate);
        	vm.orderDTO.limitExchangeRate = angular.copy(limitExcahngeRate);
        	orderDTO.limitExchangeRate = angular.copy(limitExcahngeRate);
        	orderDTO.limitExchangeRate = $filter('number')(orderDTO.limitExchangeRate, vm.noOfDecimalsForRate);
        	vm.orderDTO.stopExchangeRate = 0;
        	orderDTO.stopExchangeRate = 0;

        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.tradingBank.id);
        	vm.orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentType);
        	
        	// set rootscope variables to access soft check confirmation if
			// value exceeds soft check validation
        	$rootScope.buySellFlagForOrder = angular.copy(buySellFlag);
        	$rootScope.defaultPriceConditionForOrder = angular.copy(vm.defaultPriceCondition);
        	$rootScope.tradingBankForOrder =angular.copy(vm.tradingBank);
        	$rootScope.tradingBranchForOrder =angular.copy(vm.tradingBranch);
        	$rootScope.defaultInstrumentCodeForOrder = angular.copy(instrumentType);
        	$rootScope.activityIndicatorForOrder = angular.copy(vm.activityIndicator);
        	$rootScope.orderDTOForOrder = angular.copy(orderDTO);
        	$rootScope.tradingCustomerForOrder = angular.copy(vm.tradingCustomer);
        	$rootScope.tickSizeForOrder = angular.copy($rootScope.tickSize);
        	$rootScope.userPreferenceForOrder = angular.copy(vm.userPreference);
        	$rootScope.executeOrderFromMktWatch = true;
        	$rootScope.configurationSettingsForOrder = vm.configSettings;
        	$rootScope.noOfDecimalsForRateForOrder = angular.copy(vm.noOfDecimalsForRate);
        	$rootScope.noOfDecimalsForAmountForOrder = angular.copy(vm.noOfDecimalsForAmount);
        	$rootScope.noOfDecimalsForPercentForOrder = angular.copy(vm.noOfDecimalsForPercent);
           // $rootScope.remarksForOrder = angular.copy(vm.remarks);
            $rootScope.remarksForOrder1 = angular.copy(vm.remarks1);
            $rootScope.remarksForOrder2 = angular.copy(vm.remarks2);
            $rootScope.remarksForOrder3 = angular.copy(vm.remarks3);
            $rootScope.remarksForOrder4 = angular.copy(vm.remarks4);
            $rootScope.clickedForOrder = angular.copy(vm.clicked);

        	if(validateLimitAvailability(buySellFlag, orderDTO.typeOfOrder, orderDTO.activityIndicator, instrumentType, orderDTO.orderAmount, 0, orderDTO.eventType, vm.tradeSettlementDate, orderDTO)){
        		const validateOrderAmount = OrderUtil.validateOrderAmount(vm.orderDTO.orderAmount);
                    if(validateOrderAmount.isValid){
            		if(validateOrderAmountSoftCheck(orderDTO.orderAmount)){
    					if(validateOrder(buySellFlag,orderDTO.typeOfOrder, selectedBankSpread, instrumentType, orderDTO.activityIndicator, orderDTO.orderAmount,0) && validateMarketOrder()){
    		            	if(vm.userPreference.showPreOrderConfirmation=='yes' || vm.globalValues.getSessionTimedOut()){
    		                	$rootScope.orderDTO = orderDTO;
    		                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation, sessionTimedOut: vm.globalValues.getSessionTimedOut()});
    		            	}else{
    		            		// send order request to server
                                //on 18-Nov-2020. db writer null issue - added call back procedures while sending orders
								sendNewOrder(orderDTO);
    		            	}
    		               /*
							 * $timeout(function(){
							 * vm.orderDTO.limitExchangeRate =
							 * valuelimitExchangeRate;
							 * vm.orderDTO.stopExchangeRate =
							 * valueStopExchangeRate; },100)
							 */
    		            	resetPendingOrderCheckboxSelection(false);
                            resetTradesOutstandingCheckboxSelection();
    		    			defaultOrderEntry();			
    		            	// remove foucs on order entry screen
    		            	removeOrderEntryFocus();
    		            	// clear numeric controls
    		            	clearNumericControls();
    		            	
    		            	removeFocusFromMarketWatch();

    		        	}else{
    		        		vm.clicked = false;
    		        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
    		        	}
            		}else{
            			$rootScope.orderExecutedForAmountSoftCheck = false;
            			MessageBox.openAlertMessageBox("Alert", 'Order quantity exceeds the maximum order value soft check </br> <span class="softcheck-value"> (USD ' + $filter('number')(vm.userPreference.userMaximumOrderValueSoftCheck, vm.noOfDecimalsForAmount) + ')</span>  in “My preference” setting.</br> Do you want to proceed anyway?', "YesNo", "continueorderamountsoftcheck");
            		}
            	}else{
            		vm.clicked = false;
            		MessageBox.openAlertMessageBox("Alert", validateOrderAmount.errorString, "Ok", "homepage","Quantity Alert","danger");
            	}
        	}else{
        		vm.clicked = false;
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }

		$rootScope.$on('gatewayApp.messagebox.ok.orderrequestfailure', function (event, data) {
			vm.clicked = false;
		});

		$rootScope.$on('gatewayApp.messagebox.clear.orderrequestfailure', function (event, data) {
			vm.clicked = false;
		});

 		function placeOrderForRollover(){
    		LimitSettings.checkIsExistingTradeConsumesLimit(vm.orderDTO, function(result){
    			vm.orderDTO.isExistingTradeConsumesLimit = result.checkIsExistingTradeConsumesLimit;
    			
    			//place order 
    			placeOrder();
    		})
         }
        
        // place/modify/cancel order
        function placeOrder(){
        	vm.clicked = true;
        	vm.buySellFlag='';
        	vm.activityIndicator='';
        	vm.errorString = '';
        	setBuySellFlagAndActivityIndicator();
            if(vm.orderDTO.contractCancellation!=null && vm.orderDTO.contractCancellation!=undefined && vm.orderDTO.contractCancellation==true){
                var eventType = {};
                //if contract cancellation order is modified or cancelled, event type is filled as contract cancellation
                if(vm.orderDTO.activityIndicator=='TRAD' || vm.orderDTO.activityIndicator=='MDFY'  || vm.orderDTO.activityIndicator=='CNCL'){
                    eventType = getEventTypeForShortName('ContractCancellation');
                }else if(vm.orderDTO.activityIndicator=='ROLL'){
                    eventType = getEventTypeForShortName('Rollover');
                }else if(vm.orderDTO.activityIndicator=='EARL'){
                    eventType = getEventTypeForShortName('EarlyDelivery');
                }
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;
            }else{
                var eventType = getEventTypeForShortName('Booking');
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;
                vm.orderDTO.settlementLegId = null;
            }
            setOptionPeriodForPlaceOrder();
        	// set rootscope variables to access soft check confirmation if
			// value exceeds soft check validation
        	$rootScope.buySellFlagForOrder = angular.copy(vm.buySellFlag);
        	$rootScope.defaultPriceConditionForOrder = angular.copy(vm.defaultPriceCondition);
        	$rootScope.tradingBankForOrder =angular.copy(vm.tradingBank);
        	$rootScope.tradingBranchForOrder =angular.copy(vm.tradingBranch);
        	$rootScope.defaultInstrumentCodeForOrder = angular.copy(vm.defaultInstrumentCode);
        	$rootScope.activityIndicatorForOrder = angular.copy(vm.activityIndicator);
        	$rootScope.orderDTOForOrder = angular.copy(vm.orderDTO);
        	$rootScope.tradingCustomerForOrder = angular.copy(vm.tradingCustomer);
        	$rootScope.tickSizeForOrder = angular.copy($rootScope.tickSize);
        	$rootScope.userPreferenceForOrder = angular.copy(vm.userPreference);
        	$rootScope.executeOrderFromMktWatch = false;
        	$rootScope.configurationSettingsForOrder = angular.copy(vm.configSettings);
        	$rootScope.defaultTimeConditionForOrder = angular.copy(vm.defaultTimeCondition);
        	$rootScope.activityIndicatorForOrder = angular.copy(vm.activityIndicator);
        	$rootScope.noOfDecimalsForRateForOrder = angular.copy(vm.noOfDecimalsForRate);
        	$rootScope.noOfDecimalsForAmountForOrder = angular.copy(vm.noOfDecimalsForAmount);
        	$rootScope.noOfDecimalsForPercentForOrder = angular.copy(vm.noOfDecimalsForPercent);
        	$rootScope.defaultInstrumentIdForOrder = angular.copy(vm.defaultInstrumentId);
            $rootScope.tradeSettlementDateFororder = angular.copy(vm.tradeSettlementDate);
            //$rootScope.remarksForOrder = angular.copy(vm.remarks);
            $rootScope.remarksForOrder1 = angular.copy(vm.remarks1);
            $rootScope.remarksForOrder2 = angular.copy(vm.remarks2);
            $rootScope.remarksForOrder3 = angular.copy(vm.remarks3);
            $rootScope.remarksForOrder4 = angular.copy(vm.remarks4);
            $rootScope.clickedForOrder = angular.copy(vm.clicked);
            $rootScope.account = angular.copy(vm.account);
            $rootScope.instrumentTypesForOrder = angular.copy(vm.instrumentTypes);
            $rootScope.exposureTypeForOrder = angular.copy(vm.exposureType);
            $rootScope.exposureSubTypeForOrder = angular.copy(vm.exposureSubType);
            $rootScope.noOfSplit = angular.copy(vm.noOfSplits);

        	var valuelimitExchangeRate = angular.copy(vm.orderDTO.limitExchangeRate);
        	var valueStopExchangeRate = angular.copy(vm.orderDTO.stopExchangeRate);
            var selectedBankSpread = null;
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=undefined
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.tradingBank.id);

            }
            const validateOptionStartSettlementDate = OrderUtil.validateOptionSettlementDate(vm.defaultInstrumentCode,vm.orderDTO.isOptionPeriod, vm.tradeSettlementDateFrom, 'Start');
            const validateOptionEndSettlementDate = OrderUtil.validateOptionSettlementDate(vm.defaultInstrumentCode,vm.orderDTO.isOptionPeriod, vm.tradeSettlementDate, 'End');
            const validateOptionPeriods = OrderUtil.validateOptionPeriods(vm.defaultInstrumentCode,vm.orderDTO.isOptionPeriod,vm.tradeSettlementDateFrom,vm.tradeSettlementDate);
          if(validateOptionStartSettlementDate.isValid && validateOptionEndSettlementDate.isValid && validateOptionPeriods.isValid){
            	
            if(validateSession(vm.defaultInstrumentCode, vm.defaultPriceCondition) && validateCancellationLegSessionStatus(vm.activityIndicator , vm.orderDTO.cancelledLegInstrumentCode)){
        		if(validateLimitAvailability(vm.buySellFlag, vm.defaultPriceCondition, vm.activityIndicator, vm.defaultInstrumentCode, vm.orderDTO.orderAmount, vm.orderDTO.oldOrderAmount, vm.orderDTO.eventType, vm.tradeSettlementDate, vm.orderDTO)){
        			const validateOrderAmount = OrderUtil.validateOrderAmount(vm.orderDTO.orderAmount);
                    if(validateOrderAmount.isValid){
                		if(validateOrderAmountSoftCheck(vm.orderDTO.orderAmount)){
                            const validateLimitExchangeRateHardCheck = OrderUtil.validateLimitExchangeRateHardCheck(vm.buySellFlag, vm.defaultPriceCondition, selectedBankSpread, vm.defaultInstrumentCode, vm.activityIndicator, vm.orderDTO.orderAmount, vm.orderDTO.oldOrderAmount,vm.orderDTO.limitExchangeRate,vm.orderDTO.limitExchangeRateInSpot,vm.orderDTO.buyLimitExchangeRate,vm.orderDTO.buyLimitExchangeRateInSpot,vm.orderDTO.sellLimitExchangeRate,vm.orderDTO.sellLimitExchangeRateInSpot);
                			if(validateLimitExchangeRateHardCheck.isValid){
                				if(validateLimitExchangeRateSoftCheck(vm.buySellFlag)){
                                     if (!splitTheOrderQuntity()) {
                					executeOrder();
                                    }else{
                                        vm.clicked = false;
                                        MessageBox.openMessageBox("Alert", "Orders should be Split evenly", "Ok", "homepage");
                				}
                            }else{
                					$rootScope.orderExecuted = false;
                					MessageBox.openAlertMessageBox("Alert", vm.limitPriceDeviationWarning, "YesNo", "continueorderpricesoftcheck","Price Alert","warning");
                				}
                			}else{
        		        		vm.clicked = false;
        		        		MessageBox.openAlertMessageBox("Alert", validateLimitExchangeRateHardCheck.errorString, "Ok", "homepage","Price Alert","danger");
        		        	} 
                		}else{
                			$rootScope.orderExecutedForAmountSoftCheck = false;
                    		MessageBox.openAlertMessageBox("Alert", 'Order quantity exceeds the maximum order value soft check </br> <span class="softcheck-value"> (USD ' + $filter('number')(vm.userPreference.userMaximumOrderValueSoftCheck, vm.noOfDecimalsForAmount) + ') </span> in “My preference” setting.</br> Do you want to proceed anyway?', "YesNo", "continueorderamountsoftcheck","Quantity Alert","warning");
                		}
                	}else{
                		vm.clicked = false;
                		MessageBox.openAlertMessageBox("Alert", validateOrderAmount.errorString, "Ok", "homepage","Quantity Alert","danger");
                	}
        		}else{
        			vm.clicked = false;
        			MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        		}
        	}else{
        		vm.clicked = false;
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }else{
        	vm.clicked = false;
            if(!validateOptionStartSettlementDate.isValid)
        	    MessageBox.openMessageBox("Alert", validateOptionStartSettlementDate.errorString, "Ok", "homepage");
            else if(!validateOptionEndSettlementDate.isValid)
                 MessageBox.openMessageBox("Alert", validateOptionEndSettlementDate.errorString, "Ok", "homepage");    
            else
                MessageBox.openMessageBox("Alert", validateOptionPeriods.errorString, "Ok", "homepage");
        }
      }
        
        function executeOrder(){
        	var valuelimitExchangeRate = angular.copy($rootScope.orderDTOForOrder.limitExchangeRate);
        	var valueStopExchangeRate = angular.copy($rootScope.orderDTOForOrder.stopExchangeRate);
            var selectedBankSpread = null;
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=undefined
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, $rootScope.tradingBankForOrder.id);

            }
        	//var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, $rootScope.tradingBankForOrder.id);
        	if(validateOrder(vm.buySellFlag, vm.defaultPriceCondition, selectedBankSpread, vm.defaultInstrumentCode, vm.activityIndicator, vm.orderDTO.orderAmount, vm.orderDTO.oldOrderAmount)){
            	if(vm.activityIndicator=='MDFY' && vm.defaultPriceCondition=='MRKT'){
            		vm.defaultPriceCondition = 'LIMT';
            	}
            	if(vm.defaultPriceCondition=='MRKT' && vm.buySellFlag!='SELLBUY'){
            		vm.orderDTO.limitExchangeRate=0;
            		vm.orderDTO.stopExchangeRate=0;
            	}else if(vm.defaultPriceCondition=='MRKT' && vm.buySellFlag=='SELLBUY'){
            		vm.orderDTO.buyLimitExchangeRate=Number(0);
            		vm.orderDTO.sellLimitExchangeRate=Number(0);
            		vm.orderDTO.buyStopExchangeRate=0;
            		vm.orderDTO.sellStopExchangeRate=0;
            	}
            	if(vm.defaultPriceCondition=='LIMT' && vm.buySellFlag!='SELLBUY'){
            		vm.orderDTO.stopExchangeRate=0;
            	}else if(vm.defaultPriceCondition=='LIMT' && vm.buySellFlag=='SELLBUY'){
            		vm.orderDTO.buyStopExchangeRate=0;
            		vm.orderDTO.sellStopExchangeRate=0;
            	}
            	//this is set to display the settlement date in pre order confirmation
            	vm.orderDTO.rebookingSettlementDate = angular.copy(vm.orderDTO.tradeSettlementDate);
                if(vm.defaultInstrumentCode!='Forward'){
                    vm.orderDTO.tradeSettlementDate = null;
                    vm.orderDTO.tradeSettlementDateFrom = null;
                }else{
                    vm.orderDTO.tradeSettlementDate = DateUtils.convertLocalDateToServer(vm.tradeSettlementDate);
                    if(vm.orderDTO.tradeSettlementDateFrom != null){
                    	vm.orderDTO.tradeSettlementDateFrom = DateUtils.convertLocalDateToServer(angular.copy(vm.orderDTO.tradeSettlementDateFrom ) );
                    }
                }

                if(vm.activityIndicator=='PLAC' || vm.activityIndicator=='TRAD' || vm.activityIndicator=='ROLL' || vm.activityIndicator=='EARL'){
                    vm.orderDTO.typeOfOrder = vm.defaultPriceCondition;
                    if(vm.defaultTimeCondition == null || vm.defaultTimeCondition == undefined || vm.defaultTimeCondition ==''){
                        vm.orderDTO.timeLimitIndicator = 'DAYY'
                    }else{
                        vm.orderDTO.timeLimitIndicator = vm.defaultTimeCondition;
                    }
                    if(vm.defaultInstrumentCode=='Forward'){
                        vm.orderDTO.exposureTypeId = vm.exposureType.id;
                        vm.orderDTO.exposureSubTypeId = vm.exposureSubType.id;
                        vm.orderDTO.exposureTypeName = vm.exposureType.name;
                        vm.orderDTO.exposureSubTypeName = vm.exposureSubType.name;
                    }
                }
        		if(vm.activityIndicator=='PLAC'){
        			vm.orderDTO.activityIndicator = vm.activityIndicator;
                	vm.orderDTO.instrumentCode = vm.defaultInstrumentCode;
                    console.log('instrument id before - ' + vm.defaultInstrumentId);
                    console.log('instrument code before - ' + vm.defaultInstrumentCode);
                    //console.log('instrument types');
                    console.log(vm.instrumentTypes);
                	//if(vm.defaultInstrumentId==null || vm.defaultInstrumentId==undefined || vm.defaultInstrumentId==''){
                    fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
                	//}
                    console.log('instrument id after - ' + vm.defaultInstrumentId);
                	vm.orderDTO.instrumentId = vm.defaultInstrumentId;
                    console.log(vm.orderDTO.instrumentId);
                	vm.orderDTO.buySellIndicator = vm.buySellFlag;
		            if(vm.orderDTO.contractCancellation!=null && vm.orderDTO.contractCancellation!=undefined && vm.orderDTO.contractCancellation==true){
                        var eventType = getEventTypeForShortName('ContractCancellation');
                        vm.orderDTO.eventTypeId = eventType.id;
                        vm.orderDTO.eventType = eventType.shortName;
                    }else{
                        var eventType = getEventTypeForShortName('Booking');
                        vm.orderDTO.eventTypeId = eventType.id;
                        vm.orderDTO.eventType = eventType.shortName;
                        vm.orderDTO.settlementLegId = null;
                    }
                	// fill bank, branch, customer details into order request
					// dto
                	fillBankBranchCustomerDetails()
        		}

            	
            	vm.orderDTO.userId = vm.globalValues.getUserId();
            	vm.orderDTO.retailUserCode = vm.globalValues.getUser().login;
            	vm.orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
            	//vm.orderDTO.remarks = angular.copy(vm.remarks);
                vm.orderDTO.remarks = angular.copy(vm.remarks1);
                vm.orderDTO.remarks2 = angular.copy(vm.remarks2);
                vm.orderDTO.remarks3 = angular.copy(vm.remarks3);
                vm.orderDTO.remarks4 = angular.copy(vm.remarks4);
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.orderDTO.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(selectedBankSpread, vm.defaultInstrumentCode, vm.buySellFlag, 
                        DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            	//vm.orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, vm.buySellFlag, vm.defaultInstrumentCode);

            	if(vm.orderDTO.timeLimitIndicator!='GTTM'){
            		vm.orderDTO.expiryTime = 0;
            	}
            	// format exchange rates to four decimals before sending it to
				// order server
            	formattingRates();
            	vm.submitting = true;
            	if(vm.userPreference.showPreOrderConfirmation=='yes' || vm.defaultTimeCondition=='GTTM' || vm.globalValues.getSessionTimedOut()){
                	$rootScope.orderDTO = vm.orderDTO;
                    console.log(vm.orderDTO);
                	var closeTime = getCloseTimeForInstrumentCode(vm.orderDTO.instrumentCode);
        			//User.get({userId: vm.globalValues.getUser().id}, function(result){
                       
                    $state.go('pre-order-confirmation', {closeTime: closeTime,showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation, sessionTimedOut: vm.globalValues.getSessionTimedOut(),showOrderConfirmationForSplitOrder:vm.userPreference.showOrderConfirmationForSplitOrder,noOfSplit:vm.noOfSplits});
        			//});
       /*  if pre order not show and split order confirmation shoud show and it is split order */
                }else if(vm.userPreference.showPreOrderConfirmation=='no' && vm.userPreference.showOrderConfirmationForSplitOrder=='yes'&& vm.orderDTO.isSplitOrder==true  && vm.activityIndicator !='MDFY'){
                    vm.orderDTOList=[];
                    splitTheOrder(vm.orderDTO,vm.noOfSplits);
                    workflowSharedService.viewFrom = 'SplitOrder';
                    $state.go('split-order-confirmation',{orderDTOList:vm.orderDTOList,showOrderConfirmationForSplitOrder:vm.showOrderConfirmationForSplitOrder})
      /*  if pre order and order confirmation  not show but split  it is split order*/ 
                }else if(vm.userPreference.showPreOrderConfirmation=='no' && vm.userPreference.showOrderConfirmationForSplitOrder=='no'&& vm.orderDTO.isSplitOrder==true && vm.activityIndicator !='MDFY'){
                    vm.orderDTOList=[];
                    splitTheOrder(vm.orderDTO,vm.noOfSplits);
                    workflowSharedService.viewFrom = 'SplitOrder';
                    $state.go('split-order-status',{orderDTOList:vm.orderDTOList})
            	}else{
                    //to remove focus from order entry before placing order
                    //after double enter on buy or sell, the focus can be removed after the first enter execution
                    //code added on 07-Sep-2020 for double enter issue fix. 
                    setTimeout(function(){document.getElementById('ibspotMktSell').focus()},10);
                    //on 18-Nov-2020. db writer null issue - added call back procedures while sending orders
            		if(vm.activityIndicator=='PLAC' || vm.activityIndicator=='TRAD' || vm.activityIndicator=='ROLL' || vm.activityIndicator=='EARL'){
                        vm.orderDTO.activityIndicator='PLAC';
                        placeOrderRequest(vm.orderDTO);
            		}else if(vm.activityIndicator=='MDFY'){
						sendModifyOrder(vm.orderDTO);
            		}else if(vm.activityIndicator=='CNCL'){
						sendCancelOrder(vm.orderDTO);
            		}
        			// defaultOrderEntry();
        			
                	// remove foucs on order entry screen
                	// removeOrderEntryFocus();
                	// clear numeric controls
                	// clearNumericControls();
                	// setLTPPriceAsDefaultValueForLimitOrder();
                	//$rootScope.orderPlacedSuccessCount = 0;
                	//$scope.$emit('orderplacedsuccess');
            	}            	
            	resetPendingOrderCheckboxSelection(false);
                resetTradesOutstandingCheckboxSelection();
        	}else{
        		vm.clicked = false;
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }

		function sendModifyOrder(orderDTO){			
			if(OrderUtil.validateOrderDTOForNull(orderDTO)){
				Market.modifyOrder(orderDTO, onOrderSuccess, onOrderFailure);
			}else{
				MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
			}
		}

		function sendCancelOrder(orderDTO){
			if(OrderUtil.validateOrderDTOForNull(orderDTO)){
				Market.cancelOrder(orderDTO, onOrderSuccess, onOrderFailure);
			}else{
				MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
			}
		}

        function onOrderSuccess(result){
            $rootScope.orderPlacedSuccessCount = 0;
            $scope.$emit('orderplacedsuccess');
        }

        function onOrderFailure(){
            $rootScope.orderPlacedSuccessCount = 0;
            $scope.$emit('orderplacedsuccess');
        }

        function executeMarketOrder(){
        	var buySellFlag = angular.copy($rootScope.buySellFlagForOrder);
        	vm.defaultPriceCondition = angular.copy($rootScope.defaultPriceConditionForOrder);
        	vm.tradingBank = angular.copy($rootScope.tradingBankForOrder);
        	var instrumentType = angular.copy($rootScope.defaultInstrumentCodeForOrder);
        	vm.activityIndicator = angular.copy($rootScope.activityIndicatorForOrder);
        	var orderDTO = angular.copy($rootScope.orderDTOForOrder);
        	vm.tradingCustomer = angular.copy($rootScope.tradingCustomerForOrder);
        	$rootScope.tickSize = angular.copy($rootScope.tickSizeForOrder);
        	vm.userPreference = angular.copy($rootScope.userPreferenceForOrder);
        	vm.configSettings = angular.copy($rootScope.configurationSettingsForOrder);
        	vm.tradingBranch = angular.copy($rootScope.tradingBranchForOrder);
        	vm.defaultTimeCondition = angular.copy($rootScope.defaultTimeConditionForOrder);
        	vm.noOfDecimalsForRate = angular.copy($rootScope.noOfDecimalsForRateForOrder);
        	vm.noOfDecimalsForAmount = angular.copy($rootScope.noOfDecimalsForAmountForOrder);
        	vm.noOfDecimalsForPercent = angular.copy($rootScope.noOfDecimalsForPercentForOrder);
           // vm.remarks = angular.copy($rootScope.remarksForOrder);
            vm.remarks1 = angular.copy($rootScope.remarksForOrder1);
            vm.remarks2 = angular.copy($rootScope.remarksForOrder2);
            vm.remarks3 = angular.copy($rootScope.remarksForOrder3);
            vm.remarks4 = angular.copy($rootScope.remarksForOrder4);
            vm.clicked = angular.copy($rootScope.clickedForOrder);

        	var valuelimitExchangeRate = angular.copy(vm.orderDTO.limitExchangeRate);
        	var valueStopExchangeRate = angular.copy(vm.orderDTO.stopExchangeRate);
        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.tradingBank.id);
        	if(validateOrder(buySellFlag,orderDTO.typeOfOrder, selectedBankSpread, instrumentType, orderDTO.activityIndicator, orderDTO.orderAmount,0) && validateMarketOrder()){
            	if(vm.userPreference.showPreOrderConfirmation=='yes' || vm.globalValues.getSessionTimedOut()){
                	$rootScope.orderDTO = orderDTO;
                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation, sessionTimedOut: vm.globalValues.getSessionTimedOut()});
            	}else{
            		// send order request to server
                    //on 18-Nov-2020. db writer null issue - added call back procedures while sending orders
					if(OrderUtil.validateOrderDTOForNull(orderDTO)){
						Market.placeOrder(orderDTO, onOrderFromMktSuccess, onOrderFromMktFailure);
					}else{
						MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
					}
            	}
               /*
				 * $timeout(function(){ vm.orderDTO.limitExchangeRate =
				 * valuelimitExchangeRate; vm.orderDTO.stopExchangeRate =
				 * valueStopExchangeRate; },100)
				 */
            	//resetPendingOrderCheckboxSelection(false);
                //resetTradesOutstandingCheckboxSelection();
    			//defaultOrderEntry();			
            	// remove foucs on order entry screen
            	//removeOrderEntryFocus();
            	// clear numeric controls
            	//clearNumericControls();
            	
            	//removeFocusFromMarketWatch();

        	}else{
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }
        

        function onOrderFromMktSuccess(result){
            resetPendingOrderCheckboxSelection(false);
            resetTradesOutstandingCheckboxSelection();
            defaultOrderEntry();            
            // remove foucs on order entry screen
            removeOrderEntryFocus();
            // clear numeric controls
            clearNumericControls();
            
            removeFocusFromMarketWatch();
        }

        function onOrderFromMktFailure(){
            resetPendingOrderCheckboxSelection(false);
            resetTradesOutstandingCheckboxSelection();
            defaultOrderEntry();            
            // remove foucs on order entry screen
            removeOrderEntryFocus();
            // clear numeric controls
            clearNumericControls();
            
            removeFocusFromMarketWatch();
        }

        function validateOrderAmountSoftCheck(orderAmount){
        	if(Number(orderAmount) > Number(vm.userPreference.userMaximumOrderValueSoftCheck)){
        		return false;
        	}
        	return true;
        }
		$rootScope.$on('gatewayApp.messagebox.yes.continueorderamountsoftcheck', function (event, data) {
			if(!$rootScope.orderExecutedForAmountSoftCheck){
	        	if(!$rootScope.executeOrderFromMktWatch){
	            	// get value from $rootScope variables
	            	vm.buySellFlag = angular.copy($rootScope.buySellFlagForOrder);
	            	vm.defaultPriceCondition = angular.copy($rootScope.defaultPriceConditionForOrder);
	            	vm.tradingBank = angular.copy($rootScope.tradingBankForOrder);
	            	vm.defaultInstrumentCode = angular.copy($rootScope.defaultInstrumentCodeForOrder);
	            	vm.activityIndicator = angular.copy($rootScope.activityIndicatorForOrder);
	            	vm.orderDTO = angular.copy($rootScope.orderDTOForOrder);
	            	vm.tradingCustomer = angular.copy($rootScope.tradingCustomerForOrder);
	            	$rootScope.tickSize = angular.copy($rootScope.tickSizeForOrder);
	            	vm.userPreference = angular.copy($rootScope.userPreferenceForOrder);
	            	vm.configSettings = angular.copy($rootScope.configurationSettingsForOrder);
	            	vm.tradingBranch = angular.copy($rootScope.tradingBranchForOrder);
	            	vm.defaultTimeCondition = angular.copy($rootScope.defaultTimeConditionForOrder);
	            	vm.noOfDecimalsForRate = angular.copy($rootScope.noOfDecimalsForRateForOrder);
	            	vm.noOfDecimalsForAmount = angular.copy($rootScope.noOfDecimalsForAmountForOrder);
	            	vm.noOfDecimalsForPercent = angular.copy($rootScope.noOfDecimalsForPercentForOrder);
	            	vm.defaultInstrumentId = angular.copy($rootScope.defaultInstrumentIdForOrder);
                    vm.tradeSettlementDate = angular.copy($rootScope.tradeSettlementDateFororder);
                    //vm.remarks = angular.copy($rootScope.remarksForOrder);
                    vm.remarks1 = angular.copy($rootScope.remarksForOrder1);
                    vm.remarks2 = angular.copy($rootScope.remarksForOrder2);
                    vm.remarks3 = angular.copy($rootScope.remarksForOrder3);
                    vm.remarks4 = angular.copy($rootScope.remarksForOrder4);
                    vm.clicked = angular.copy($rootScope.clickedForOrder);
                    vm.account = angular.copy($rootScope.account);
                    vm.instrumentTypes = angular.copy($rootScope.instrumentTypesForOrder);
                    vm.exposureType = angular.copy($rootScope.exposureTypeForOrder);
                    vm.exposureSubType = angular.copy($rootScope.exposureSubTypeForOrder);
                    vm.globalValues = GlobalValues;
                    vm.noOfSplits = angular.copy($rootScope.noOfSplit);
                    vm.tradeSettlementDateFrom = new Date(angular.copy($rootScope.orderDTOForOrder.tradeSettlementDateFrom));
                    changeTenor(vm.tradeSettlementDateFrom, $rootScope.orderDTOForOrder.isOptionPeriod);
	            	var valuelimitExchangeRate = angular.copy($rootScope.orderDTOForOrder.limitExchangeRate);
	            	var valueStopExchangeRate = angular.copy($rootScope.orderDTOForOrder.stopExchangeRate);
	            	var selectedBankSpread = null;

                    if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                        && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=undefined
                        && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                        selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, $rootScope.tradingBankForOrder.id);

                    }
                    //var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, $rootScope.tradingBankForOrder.id);
        			if(validateLimitExchangeRateHardCheck(vm.buySellFlag, vm.defaultPriceCondition, selectedBankSpread, vm.defaultInstrumentCode, vm.activityIndicator, vm.orderDTO.orderAmount, vm.orderDTO.oldOrderAmount)){
        				if(validateLimitExchangeRateSoftCheck(vm.buySellFlag)){
        					executeOrder();
        				}else{
        					$rootScope.orderExecuted = false;
        					MessageBox.openAlertMessageBox("Alert", vm.limitPriceDeviationWarning, "YesNo", "continueorderpricesoftcheck","Price Alert","warning");
        				}
        			}else{
        				vm.clicked = false;
		        		MessageBox.openAlertMessageBox("Alert", vm.errorString, "Ok", "homepage","Price Alert","danger");
		        	} 
        		}else{
	        		executeMarketOrder();
	        	}
	        	$rootScope.orderExecutedForAmountSoftCheck = true;
			}
    	});
		
		$rootScope.$on('gatewayApp.messagebox.no.continueorderamountsoftcheck', function (event, data) {
			vm.clicked = false;
		});
		
		$rootScope.$on('gatewayApp.messagebox.clear.continueorderamountsoftcheck', function (event, data) {
			vm.clicked = false;
		});
		
		$rootScope.$on('gatewayApp.messagebox.ok.homepage', function (event, data) {
			vm.clicked = false;
		});
		
		$rootScope.$on('gatewayApp.messagebox.clear.homepage', function (event, data) {
			vm.clicked = false;
		});
		
		$rootScope.$on('gatewayApp.messagebox.yes.continueorderpricesoftcheck', function (event, data) {
			if(!$rootScope.orderExecuted){
            	// get value from $rootScope variables
            	vm.buySellFlag = angular.copy($rootScope.buySellFlagForOrder);
            	vm.defaultPriceCondition = angular.copy($rootScope.defaultPriceConditionForOrder);
            	vm.tradingBank = angular.copy($rootScope.tradingBankForOrder);
            	vm.tradingBranch = angular.copy($rootScope.tradingBranchForOrder);
            	vm.defaultInstrumentCode = angular.copy($rootScope.defaultInstrumentCodeForOrder);
            	vm.activityIndicator = angular.copy($rootScope.activityIndicatorForOrder);
            	vm.orderDTO = angular.copy($rootScope.orderDTOForOrder);
            	vm.tradingCustomer = angular.copy($rootScope.tradingCustomerForOrder);
            	$rootScope.tickSize = angular.copy($rootScope.tickSizeForOrder);
            	vm.userPreference = angular.copy($rootScope.userPreferenceForOrder);
            	vm.configSettings = angular.copy($rootScope.configurationSettingsForOrder);
            	vm.defaultTimeCondition = angular.copy($rootScope.defaultTimeConditionForOrder);
            	vm.noOfDecimalsForRate = angular.copy($rootScope.noOfDecimalsForRateForOrder);
            	vm.noOfDecimalsForAmount = angular.copy($rootScope.noOfDecimalsForAmountForOrder);
            	vm.noOfDecimalsForPercent = angular.copy($rootScope.noOfDecimalsForPercentForOrder);
            	vm.defaultInstrumentId = angular.copy($rootScope.defaultInstrumentIdForOrder);
                vm.tradeSettlementDate = angular.copy($rootScope.tradeSettlementDateFororder);
                vm.exposureType = angular.copy($rootScope.exposureTypeForOrder);
                vm.exposureSubType = angular.copy($rootScope.exposureSubTypeForOrder);
                //vm.remarks = angular.copy($rootScope.remarksForOrder);
                vm.remarks1 = angular.copy($rootScope.remarksForOrder1);
                vm.remarks2 = angular.copy($rootScope.remarksForOrder2);
                vm.remarks3 = angular.copy($rootScope.remarksForOrder3);
                vm.remarks4 = angular.copy($rootScope.remarksForOrder4);
                vm.clicked = angular.copy($rootScope.clickedForOrder);
                vm.account = angular.copy($rootScope.account);
                vm.instrumentTypes = angular.copy($rootScope.instrumentTypesForOrder);
                vm.exposureType = angular.copy($rootScope.exposureTypeForOrder);
                vm.exposureSubType = angular.copy($rootScope.exposureSubTypeForOrder);
                vm.globalValues = GlobalValues;
                vm.tradeSettlementDateFrom = new Date(angular.copy($rootScope.orderDTOForOrder.tradeSettlementDateFrom));
                vm.noOfSplits = angular.copy($rootScope.noOfSplit);
                changeTenor(vm.tradeSettlementDateFrom, $rootScope.orderDTOForOrder.isOptionPeriod);
				executeOrder();
				$rootScope.orderExecuted = true;
			}
			
    	});
		
		$rootScope.$on('gatewayApp.messagebox.no.continueorderpricesoftcheck', function (event, data) {
			vm.clicked = false;
		});
		
		$rootScope.$on('gatewayApp.messagebox.clear.continueorderpricesoftcheck', function (event, data) {
			vm.clicked = false;
		});

		function validateLimitExchangeRateHardCheck(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode, activityIndicator, orderAmount, oldOrderAmount){
        	var ordertickSize = gettickSizeForGivenInstrument(instrumentCode);
        	var ltp = 0
        	vm.errorString = "";
        	
        	if(buySellFlag!='SELLBUY'){
            	ltp = getLtpValue();
            	if(!validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondtion, vm.orderDTO.limitExchangeRate, vm.orderDTO.limitExchangeRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
            		return false;
            	}
        	}else if(buySellFlag=='SELLBUY'){
            	var ltp = getLtpValue();
            	if(!validateLimitExchangeRate('BUYY', activityIndicator, priceCondtion, vm.orderDTO.buyLimitExchangeRate, vm.orderDTO.buyLimitExchangeRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
            		// return false;
            	}
            	if(!validateLimitExchangeRate('SELL', activityIndicator, priceCondtion, vm.orderDTO.sellLimitExchangeRate, vm.orderDTO.sellLimitExchangeRateInSpot, ordertickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage)){
            		// return false;
            	}
            	if(vm.errorString!='' && vm.errorString!=null && vm.errorString!=undefined){
            		return false;
            	}
        	}
        	return true;
        }
        
        function validateLimitExchangeRateSoftCheck(buySellFlag){
        	vm.limitPriceDeviationWarning = '';
        	if(buySellFlag!='SELLBUY'){
        		vm.limitPriceDeviationWarning = validateLimitPriceSoftCheck(buySellFlag, vm.orderDTO.limitExchangeRateInSpot);
            	if(vm.limitPriceDeviationWarning!=''){
        			vm.limitPriceDeviationWarning = vm.limitPriceDeviationWarning + 'Do you want to proceed anyway?';
        			return false;
            	}
        	}else if(buySellFlag=='SELLBUY'){
        		vm.buyLimitPriceDeviationWarning = validateLimitPriceSoftCheck('BUYY', vm.orderDTO.buyLimitExchangeRateInSpot);
        		vm.sellLimitPriceDeviationWarning = validateLimitPriceSoftCheck('SELL', vm.orderDTO.sellLimitExchangeRateInSpot);
        		vm.limitPriceDeviationWarning = vm.buyLimitPriceDeviationWarning + vm.sellLimitPriceDeviationWarning;
        		if(vm.buyLimitPriceDeviationWarning!='' && vm.sellLimitPriceDeviationWarning==''){
        			vm.limitPriceDeviationWarning = vm.buyLimitPriceDeviationWarning;
        		}else if(vm.buyLimitPriceDeviationWarning=='' && vm.sellLimitPriceDeviationWarning!=''){
        			vm.limitPriceDeviationWarning = vm.sellLimitPriceDeviationWarning;
        		}else if(vm.buyLimitPriceDeviationWarning!='' && vm.sellLimitPriceDeviationWarning!=''){
        			vm.limitPriceDeviationWarning = 'BUY and SELL limit price deviate by more than </br>' +"<span class='softcheck-value'>"+ vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise+"&nbsp;paise"+"</span> " +' from the Last Traded Price (LTP).</br> '
        		}else if(vm.buyLimitPriceDeviationWarning=='' && vm.sellLimitPriceDeviationWarning==''){
        			vm.limitPriceDeviationWarning = '';
        		}
            	if(vm.limitPriceDeviationWarning!=''){
        			vm.limitPriceDeviationWarning = vm.limitPriceDeviationWarning + 'Do you want to proceed anyway?';
        			return false;
            	}
        	}
        	return true;
        }
        
        function validateLimitPriceSoftCheck(buySellFlag, limitExchangeRate){
        	var deviationWarningString = '';
        	if(limitExchangeRate!=null && limitExchangeRate!=undefined && limitExchangeRate!=''){
            	var higherCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot + Number(vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot - Number(vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpValue();
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	if(vm.defaultPriceCondition!='MRKT'){
            		deviationWarningString = softCheckDeviation(limitExchangeRate.toString(), higherCutOffPriceSoft, lowerCutOffPriceSoft, vm.userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPaise);
            	}
        	}
        	if(deviationWarningString!=''){
        		if(buySellFlag=='BUYY'){
        			deviationWarningString = 'BUY limit price ' + deviationWarningString;
        		}else if(buySellFlag=='SELL'){
        			deviationWarningString = 'SELL limit price ' + deviationWarningString;
        		}
        	}
        	return deviationWarningString;
    	}
        
        function formattingRates(){
        	if(vm.orderDTO.buySellIndicator!='SELLBUY'){
	        	vm.orderDTO.limitExchangeRate = $filter('number')(vm.orderDTO.limitExchangeRate, vm.noOfDecimalsForRate);
	        	vm.orderDTO.stopExchangeRate = $filter('number')(vm.orderDTO.stopExchangeRate, vm.noOfDecimalsForRate);
        	}else{
	        	vm.orderDTO.buyLimitExchangeRate = $filter('number')(vm.orderDTO.buyLimitExchangeRate, vm.noOfDecimalsForRate);
	        	vm.orderDTO.sellLimitExchangeRate = $filter('number')(vm.orderDTO.sellLimitExchangeRate, vm.noOfDecimalsForRate);
	        	vm.orderDTO.buyStopExchangeRate = $filter('number')(vm.orderDTO.buyStopExchangeRate, vm.noOfDecimalsForRate);
	        	vm.orderDTO.sellStopExchangeRate = $filter('number')(vm.orderDTO.sellStopExchangeRate, vm.noOfDecimalsForRate);
        	}
        }
        
        function setBuySellFlagAndActivityIndicator(){
        	if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='PLAC'){
        		vm.buySellFlag='SELL';
        		vm.activityIndicator='PLAC';
        	}else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='PLAC'){
        		vm.buySellFlag='BUYY';
        		vm.activityIndicator='PLAC';
        	}else if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='MDFY'){
        		vm.buySellFlag='SELL';
        		vm.activityIndicator='MDFY';
        	}else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='MDFY'){
        		vm.buySellFlag='BUYY';
        		vm.activityIndicator='MDFY';
        	}else if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='TRAD'){
                vm.buySellFlag='SELL';
                vm.activityIndicator='TRAD';
            }else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='TRAD'){
                vm.buySellFlag='BUYY';
                vm.activityIndicator='TRAD';
            }else if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='ROLL'){
                vm.buySellFlag='SELL';
                vm.activityIndicator='ROLL';
            }else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='ROLL'){
                vm.buySellFlag='BUYY';
                vm.activityIndicator='ROLL';
            }else if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='EARL'){
                vm.buySellFlag='SELL';
                vm.activityIndicator='EARL';
            }else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='EARL'){
                vm.buySellFlag='BUYY';
                vm.activityIndicator='EARL';
            }else if(vm.orderType=='SELL' && vm.orderDTO.activityIndicator=='CNCL'){
        		vm.buySellFlag='SELL';
        		vm.activityIndicator='CNCL';
        	}else if(vm.orderType=='BUYY' && vm.orderDTO.activityIndicator=='CNCL'){
        		vm.buySellFlag='BUYY';
        		vm.activityIndicator='CNCL';
        	}else if(vm.orderType=='SELLBUY' && vm.orderDTO.activityIndicator=='PLAC'){
        		vm.buySellFlag='SELLBUY';
        		vm.activityIndicator='PLAC';
        	}
        }

        function validateMarketOrder(){
        	if(vm.account.organisation.organisationType.shortName!='CUSTOMER'){
        		if(vm.defaultTradingCustomerControl!=null && vm.defaultTradingCustomerControl!=undefined){
        			// proceed
        		}else{
        			vm.errorString = "Please select the trading customer to proceed.";
        			return false;
        		}
        	}
        	return true;
        }
        
        function fillBankBranchCustomerDetails(){
        	if(vm.globalValues.getEntityOwnerType().shortName=='Clearcorp'){
            	vm.orderDTO.branchCode = vm.tradingBranch.branchId;
            	vm.orderDTO.branchId = vm.tradingBranch.id;
            	vm.orderDTO.branchName = vm.tradingBranch.branchName;
            	vm.orderDTO.bankId = vm.tradingBank.id;
            	vm.orderDTO.bankName = vm.tradingBank.bankName;
            	vm.orderDTO.bankMemberNumber = vm.tradingBank.bankMemberNo;
            	vm.orderDTO.customerId = vm.tradingCustomer.id;
            	vm.orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	vm.orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.globalValues.getEntityOwnerType().shortName=='Bank'){
            	vm.orderDTO.branchCode = vm.tradingBranch.branchId;
            	vm.orderDTO.branchId = vm.tradingBranch.id;
            	vm.orderDTO.branchName = vm.tradingBranch.branchName;
            	vm.orderDTO.bankId = vm.globalValues.getBank().id;
            	vm.orderDTO.bankName = vm.globalValues.getBank().bankName;
            	vm.orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	vm.orderDTO.customerId = vm.tradingCustomer.id;
            	vm.orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	vm.orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	vm.orderDTO.branchCode = vm.globalValues.getBranch().branchId;
            	vm.orderDTO.branchId = vm.globalValues.getBranch().id;
            	vm.orderDTO.branchName = vm.globalValues.getBranch().branchName;
            	vm.orderDTO.bankId = vm.globalValues.getBank().id;
            	vm.orderDTO.bankName = vm.globalValues.getBank().bankName;
            	vm.orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	vm.orderDTO.customerId = vm.tradingCustomer.id;
            	vm.orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	vm.orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		if($rootScope.tradingBranchForOrder!=null && $rootScope.tradingBranchForOrder!=undefined){
        			vm.tradingBranch = angular.copy($rootScope.tradingBranchForOrder);
        		}
				if(vm.tradingBranch.id==null || vm.tradingBranch.id==undefined){
					MessageBox.openMessageBox("Alert", "Customer bank relationship does not exist...!", "Ok", "homepage");
        		}
            	vm.orderDTO.branchCode = vm.tradingBranch.branchId;
            	vm.orderDTO.branchId = vm.tradingBranch.id;
            	vm.orderDTO.branchName = vm.tradingBranch.branchName;
            	vm.orderDTO.bankId = vm.tradingBank.id;
            	vm.orderDTO.bankName = vm.tradingBank.bankName;
            	vm.orderDTO.bankMemberNumber = vm.tradingBank.bankMemberNo;
            	vm.orderDTO.customerId = vm.globalValues.getCustomer().id;
            	vm.orderDTO.customerName = vm.globalValues.getCustomer().customerFirstName;
            	vm.orderDTO.customerCode = vm.globalValues.getCustomer().customerCode;
        	}
        }
        
        function fillBankBranchCustomerDetailsFromMktWatch(orderDTO){
        	if(vm.globalValues.getEntityOwnerType().shortName=='Clearcorp'){
            	orderDTO.branchCode = vm.tradingBranch.branchId;
            	orderDTO.branchId = vm.tradingBranch.id;
            	orderDTO.branchName = vm.tradingBranch.branchName;
            	orderDTO.bankId = vm.tradingBank.id;
            	orderDTO.bankName = vm.tradingBank.bankName;
            	orderDTO.bankMemberNumber = vm.tradingBank.bankMemberNo;
            	orderDTO.customerId = vm.tradingCustomer.id;
            	orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.globalValues.getEntityOwnerType().shortName=='Bank'){
            	orderDTO.branchCode = vm.tradingBranch.branchId;
            	orderDTO.branchId = vm.tradingBranch.id;
            	orderDTO.branchName = vm.tradingBranch.branchName;
            	orderDTO.bankId = vm.globalValues.getBank().id;
            	orderDTO.bankName = vm.globalValues.getBank().bankName;
            	orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	orderDTO.customerId = vm.tradingCustomer.id;
            	orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	orderDTO.branchCode = vm.globalValues.getBranch().branchId;
            	orderDTO.branchId = vm.globalValues.getBranch().id;
            	orderDTO.branchName = vm.globalValues.getBranch().branchName;
            	orderDTO.bankId = vm.globalValues.getBank().id;
            	orderDTO.bankName = vm.globalValues.getBank().bankName;
            	orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	orderDTO.customerId = vm.tradingCustomer.id;
            	orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
				if(vm.tradingBranch.id==null || vm.tradingBranch.id==undefined){
					MessageBox.openMessageBox("Alert", "Customer bank relationship does not exist...!", "Ok", "homepage");
        		}
            	orderDTO.branchCode = vm.tradingBranch.branchId;
            	orderDTO.branchId = vm.tradingBranch.id;
            	orderDTO.branchName = vm.tradingBranch.branchName;
            	orderDTO.bankId = vm.tradingBank.id;
            	orderDTO.bankName = vm.tradingBank.bankName;
            	orderDTO.bankMemberNumber = vm.tradingBank.bankMemberNo;
            	orderDTO.customerId = vm.globalValues.getCustomer().id;
            	orderDTO.customerName = vm.globalValues.getCustomer().customerFirstName;
            	orderDTO.customerCode = vm.globalValues.getCustomer().customerCode;
        	}
        	
        	return orderDTO;
        }
        
        function getCloseTimeForInstrumentCode(instrument){
        	if(instrument=='SPOT'){
        		return $rootScope.retailSpotMarketSession.sessionTimingsDTO.closeTime;
        	}else if(instrument=='CASH'){
        		return $rootScope.cashMarketSession.sessionTimingsDTO.closeTime;
        	}else if(instrument=='TOM'){
        		return $rootScope.tomMarketSession.sessionTimingsDTO.closeTime;
        	}else if(instrument=='Forward'){
                return $rootScope.forwardMarketSession.sessionTimingsDTO.closeTime;
            }
        }
        
        function validateOrder(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode, activityIndicator, orderAmount, oldOrderAmount){

        	vm.errorString = "";
        	vm.configSettings = vm.globalValues.getConfigSettings();
        	
        	/*
			 * if(!validateSessionStatus(instrumentCode)){ return false; }
			 * 
			 * if(priceCondtion=='STLS'){ if(!isAllowedStopLossOrders()){ return
			 * false; } }
			 * 
			 * if(priceCondtion=='TKPR'){ if(!isAllowedTakeProfitOrders()){
			 * return false; } }
			 */
        	
        	if(activityIndicator!='CNCL'){
        		/*
				 * if(!validateOrderAmount(orderAmount)){ return false; }
				 */
            	
            	// no need to check limits while cancelling orders
            	/*
				 * if(activityIndicator=='CNCL' ||
				 * ($rootScope.account.organisation.organisationType.shortName=='CUSTOMER' &&
				 * vm.tradingCustomer.isProp==true) || (priceCondtion=='MRKT' &&
				 * $rootScope.account.organisation.organisationType.shortName!='CUSTOMER' &&
				 * vm.tradingCustomer.isProp==true) || (priceCondtion!='MRKT' &&
				 * $rootScope.account.organisation.organisationType.shortName!='CUSTOMER' &&
				 * vm.tradingCustomer.isProp==true)){ //proceed }else{
				 * if(!validateLimitCheck(oldOrderAmount, activityIndicator,
				 * orderAmount, instrumentCode, buySellFlag, priceCondtion)){
				 * return false; } }
				 */
            	
            	// ordertickSize
            	// ltp
            	var ordertickSize = gettickSizeForGivenInstrument(instrumentCode);
            	var ltp = 0
            	
            	if(buySellFlag!='SELLBUY'){
                	ltp = getLtpValue();
                	console.log('hard check');
                	console.log($rootScope.retailSpotMarketDepth);
                	console.log(ltp);
                	/*
					 * if(!validateLimitExchangeRate(buySellFlag,
					 * activityIndicator, priceCondtion,
					 * vm.orderDTO.limitExchangeRate,
					 * vm.orderDTO.limitExchangeRateInSpot, ordertickSize, ltp,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
					 * return false; }
					 */
                	if(!validateStopExchangeRate(priceCondtion, buySellFlag, vm.orderDTO.limitExchangeRate, vm.orderDTO.stopExchangeRate, ordertickSize)){
                		return false;
                	}
                  //  const validateSpreadUsed = OrderUtil.validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag);
                	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag)){
                        //vm.errorString = validateSpreadUsed.errorString;
                		return false;
                	}
                	if(vm.errorString!=''){
	               		return false;
	               	}
            	}else if(buySellFlag=='SELLBUY'){
                	var ltp = getLtpValue();
                	/*
					 * if(!validateLimitExchangeRate('BUYY', activityIndicator,
					 * priceCondtion, vm.orderDTO.buyLimitExchangeRate,
					 * vm.orderDTO.buyLimitExchangeRateInSpot, ordertickSize,
					 * ltp,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
					 * return false; } if(!validateLimitExchangeRate('SELL',
					 * activityIndicator, priceCondtion,
					 * vm.orderDTO.sellLimitExchangeRate,
					 * vm.orderDTO.sellLimitExchangeRateInSpot, ordertickSize,
					 * ltp,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage,
					 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
					 * return false; }
					 */
                	if(!validateStopExchangeRate(priceCondtion, 'BUYY', vm.orderDTO.buyLimitExchangeRate, vm.orderDTO.buyStopExchangeRate, ordertickSize)){
                		// return false;
                	}
                	if(!validateStopExchangeRate(priceCondtion, 'SELL', vm.orderDTO.sellLimitExchangeRate, vm.orderDTO.sellStopExchangeRate, ordertickSize)){
                		return false;
                	}
                	if(vm.errorString!=''){
	               		return false;
	               	}
                  //  const validateSpreadUsedBuy = OrderUtil.validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY');
                   // const validateSpreadUsedSell = OrderUtil.validateSpreadUsed(selectedBankSpread, instrumentCode, 'SELL');
                	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY')){
                      //  vm.errorString = validateSpreadUsedBuy.errorString;
                   		return false;
                   	}
                   	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY')){
                      //  vm.errorString = validateSpreadUsedSell.errorString;
                   		return false;
                   	}
            	}
        	}
        	
        	return true;
        }
        
        function isAllowedStopLossOrders(){
        	if(vm.configSettings.isAllowStopLossOrder=='Yes'){
        		return true;
        	}else{
        		vm.errorString = "Stop loss orders are not allowed.";
        		return false;
        	}
        }
        
        function isAllowedTakeProfitOrders(){
        	if(vm.configSettings.isAllowTakeProfitOrder=='Yes'){
        		return true;
        	}else{
        		vm.errorString = "Take profit orders are not allowed.";
        		return false;
        	}
        }
        
        function validateTradingBankStatus(){
        	CustomerBankRelationshipReport.getTradingBanksForCustomer({customerId : vm.globalValues.getCustomer().id}, function(result){
        		if(result.length>0){
        			angular.forEach(result, function(value){
        				if(value.shortName == vm.tradingBank.shortName){
        					return true;
        				}
        			})
        		}
            	return false;
        	})
        }
        
        // validate session status
        function validateSessionStatus(instrumentCode){
        	var status = "";
        	
        	if(instrumentCode == 'SPOT'){
        		status = $rootScope.retailSpotMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'CASH'){
        		status = $rootScope.cashMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'TOM'){
        		status = $rootScope.tomMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'Forward'){
                status = $rootScope.forwardMarketSession.sessionTimingsDTO.status;
            }
        	
        	if(status!='OPEN'){
        		vm.errorString = "Session is closed for " + instrumentCode + " market...!"
        		return false;
        	}
        	
        	return true;
        }
        /**
         * Valdate cancellation leg session status before placing ROLL or EARLY delivery
         */
        function validateCancellationLegSessionStatus(activityIndicator, cancellationLegInstrument){
        	if(activityIndicator=='ROLL' || activityIndicator=='EARL'){
        		var isValidSessionStatus = validateSessionStatus(cancellationLegInstrument);
        		if(!isValidSessionStatus){
        			vm.errorString = "Session is closed for Cancellation Leg instrument " + cancellationLegInstrument + "...!";
        		}
        		return isValidSessionStatus ;
        	}
        	return true;
        }

        function validateAgreedTAC(){
            if($rootScope.account!=null && $rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if($rootScope.agreedTACList!=null && $rootScope.agreedTACList!=undefined && $rootScope.agreedTACList.length>0){
                    //validate clearcorp TAC
                    if($rootScope.agreedTACList[0].isAgreed){
                        //validate selected trading bank TAC
                        if(vm.tradingBank!=null && vm.tradingBank!=undefined){
                            var agreedTACListForBank = _.filter(angular.copy($rootScope.agreedTACList), {'providerId': vm.tradingBank.id});
                            if(agreedTACListForBank!=null && agreedTACListForBank!=undefined && agreedTACListForBank.length>0){
                                if(!agreedTACListForBank[0].isAgreed){
                                   vm.errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                                   return false; 
                                }
                            }else{
                                vm.errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                                return false;
                            }
                        }else{
                            vm.errorString = "Trading bank should be selected to place order...!";
                            return false;
                        }
                    }else{
                        vm.errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                        return false;
                    }

                } 
            }

            return true;
        }

        //get session status for instrument code
        function getSessionStatusForInstrument(instrumentCode){
            var status = 'CLOSED';
            if(instrumentCode == 'SPOT'){
                status = $rootScope.retailSpotMarketSession.sessionTimingsDTO.status;
            }else if(instrumentCode == 'CASH'){
                if($rootScope.cashMarketSession!=null && $rootScope.cashMarketSession!=undefined){
                    status = $rootScope.cashMarketSession.sessionTimingsDTO.status;
                }
            }else if(instrumentCode == 'TOM'){
                status = $rootScope.tomMarketSession.sessionTimingsDTO.status;
            }else if(instrumentCode == 'Forward'){
                status = $rootScope.forwardMarketSession.sessionTimingsDTO.status;
            }
            return status;
        }
        
        // validate limit availability
        function validateLimitAvailability(buySellFlag, priceCondtion, activityIndicator, instrumentCode, orderAmount, oldOrderAmount, eventType, tradeSettlementDate, orderDTO){
        	if(activityIndicator!='CNCL' && eventType!='ContractCancellation' && eventType!='Rollover' && eventType!='EarlyDelivery'){
        		if(activityIndicator=='CNCL' || ($rootScope.account.organisation.organisationType.shortName=='CUSTOMER' && vm.tradingCustomer.isProp==true) 
            			|| (priceCondtion=='MRKT' && $rootScope.account.organisation.organisationType.shortName!='CUSTOMER' && vm.tradingCustomer.isProp==true)
            			|| (priceCondtion!='MRKT' && $rootScope.account.organisation.organisationType.shortName!='CUSTOMER' && vm.tradingCustomer.isProp==true)){
                	// proceed
            	}else{
                    const validateLimitCheck  = OrderUtil.validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag, priceCondtion, tradeSettlementDate);
            		if(!validateLimitCheck.isValid){
                        vm.errorString = validateLimitCheck.errorString;
                		return false;
                	}
            	}	
        	}
            if(activityIndicator=='TRAD' || activityIndicator=='ROLL' || activityIndicator=='EARL' || (activityIndicator=='MDFY' && (eventType=='ContractCancellation' || eventType=='Contract Cancellation' || eventType=='Rollover' || eventType=='Early Delivery'))){
                //old order amount variable contains the amount(outstanding amount - blocked amount)
                //so, if ordered amount greater than that, will not allow to process
                if(orderAmount>oldOrderAmount){
                    vm.errorString = "Order quantity cannot be more than the original trade outstanding quantity...!";
                    return false;
                }
            }

            //validate limit for rollover trade
            if(activityIndicator=='ROLL' && vm.tradingCustomer.isProp==false){
                if(!validateLimitCheckForRollover(orderAmount, instrumentCode, buySellFlag, orderDTO, tradeSettlementDate)){
                    return false;
                }
            }

        	return true;
        }

        function validateLimitCheckForRollover(orderAmount, instrumentCode, buySellFlag, orderDTO, tradeSettlementDate){
        	if($rootScope.limitNotDefined){
    			vm.errorString = "Trading limits are not defined. Order entry not possible...!";
    			return false;
        	}
            //valdiate max tenor date
            if(instrumentCode=='Forward'){
                if(moment(tradeSettlementDate).isAfter($rootScope.maxForwardTenorDate, 'day')){
                    vm.errorString = "Order entry not possible. Settlement date selected is greater than the" +
                    		" maximum tenor/value date defined by the Relationship Bank. Please refer the Limit Tab for more info on maximum tenor/value date allowed.";
                    return false;
                }
            }
            
            if(orderDTO.isExistingTradeConsumesLimit ){
                //find limit to be cancelled from old buket
                //get credit factor value of cancellation bucket
                vm.cancellationLegCF = MarketWatch.getCreditFactorValue(instrumentCode, angular.copy(orderDTO.cancellationTradeSettlementDate), $rootScope.selectedCreditFactor, vm.configSettings.defaultCreditFactorSettingsInPercentage);
                vm.limitToBeCancelled = MarketWatch.computeLimitAmountApplyingCreditFactor(orderAmount, vm.cancellationLegCF);
            }
            else{
                vm.limitToBeCancelled = 0;
            }


            //find limit to be consumed for rebooking bucket
            //get credit factor value of rebooking bucket
            vm.rebookingLegCF = MarketWatch.getCreditFactorValue(instrumentCode, angular.copy(orderDTO.tradeSettlementDate), $rootScope.selectedCreditFactor, vm.configSettings.defaultCreditFactorSettingsInPercentage);

            vm.limitToBeConsumedForRebooking = MarketWatch.computeLimitAmountApplyingCreditFactor(orderAmount, vm.rebookingLegCF);

            vm.limitToBeConsumed = vm.limitToBeConsumedForRebooking - vm.limitToBeCancelled;
            vm.limitToBeConsumed = (Math.round(parseFloat(vm.limitToBeConsumed)*100)/100);

            //check this limitToBeConsumed with available limit

            //if buySellFlag is buy,
            //check limitToBeConsumed is lesser than or equal to $rootScope.forwardBuyLimitWithCF
            //if buySellFlag is sell,
            //check limitToBeConsumed is lesser than or equal to $rootScope.forwardSellLimitWithCF
            if(buySellFlag=='BUYY'){
                if(vm.limitToBeConsumed>$rootScope.forwardBuyLimitWithCF){
                    vm.errorString = "Buy trading limit breached. Order entry not possible...!";
                    return false;
                }
            }else{
                if(vm.limitToBeConsumed>$rootScope.forwardSellLimitWithCF){
                    vm.errorString = "Sell trading limit breached. Order entry not possible...!";
                    return false;
                }
            }

            //if above validation pass, then return true, else return false
            return true;

        }
        
        // get tick size
       function gettickSizeForGivenInstrument(instrumentCode){
        	if(instrumentCode=='SPOT'){
        		return $rootScope.tickSize.spot;
        	}else if(instrumentCode=='CASH'){
        		return $rootScope.tickSize.cash;
        	}else if(instrumentCode=='TOM'){
        		return $rootScope.tickSize.tom;
        	}else if(instrumentCode=='Forward'){
                return $rootScope.tickSize.forward;
            }
        }
       
       $rootScope.gettickSizeForGivenInstrument = gettickSizeForGivenInstrument;
        
        // get ltp for given instrument and buy sell flag
        function getLtpValue(){
        	if($rootScope.ibSpotMarketDepth!=null && $rootScope.ibSpotMarketDepth!=undefined){
    			if($rootScope.ibSpotMarketDepth.ltp!=null && $rootScope.ibSpotMarketDepth.ltp!=undefined && $rootScope.ibSpotMarketDepth.ltp>0){
            		return $rootScope.ibSpotMarketDepth.ltp;
        		}else{
                    return $rootScope.previousDayClosingPriceForIbSpot;
                }
    		}else{
        		return $rootScope.previousDayClosingPriceForIbSpot;
    		}
        }
        
        function getLTPOrPreviousDayLTPForInstrumentAndBuySellFlag(instrumentCode, buySellFlag){
        	if(instrumentCode=='SPOT'){
        		if($rootScope.retailSpotMarketDepth.ltp>0){
            		return $rootScope.retailSpotMarketDepth.ltp;
        		}
        	}else if(instrumentCode=='CASH'){
        		if(vm.cashMarketDepth.ltp>0){
            		return vm.cashMarketDepth.ltp;
        		}
        	}else if(instrumentCode=='TOM'){
        		if(vm.tomMarketDepth.ltp>0){
            		return vm.tomMarketDepth.ltp;
        		}
        	}else if(instrumentCode=='Forward'){
                if(vm.forwardMarketDepth.ltp>0){
                    return vm.forwardMarketDepth.ltp;
                }
            }
        	return $rootScope.previousDayClosingPriceForSpot;
        }
        
        function validateSession(instrumentCode, priceCondtion){
            const validateSessionStatus = OrderUtil.validateSessionStatus(instrumentCode);
        	if(!validateSessionStatus.isValid){
                vm.errorString = validateSessionStatus.errorString;
                return false;
        	}
            const validateAgreedTAC = OrderUtil.validateAgreedTAC(vm.tradingBank);
            if(!validateAgreedTAC.isValid){
                vm.errorString = validateAgreedTAC.errorString;
                return false;
            }
        	
        	if(priceCondtion=='STLS'){
            	if(!isAllowedStopLossOrders()){
            		return false;
            	}
        	}
        	
        	if(priceCondtion=='TKPR'){
            	if(!isAllowedTakeProfitOrders()){
            		return false;
            	}
        	}
        	return true;
        }
        
        // validate order amount
        function validateOrderAmount(orderAmount){
        	// orderAmount = orderAmount.replace(/,/g, '');
        	if(Math.round(parseFloat(orderAmount) * 100) / Math.round(parseFloat(1) * 100) ==0){
        		vm.errorString = 'Order value must be greater than zero...! ';
        		return false;
        	}else if(Number(orderAmount) < 0){
        		vm.errorString = 'Order value must be positive...!';
        		return false;
        	}
        	else if(Number(orderAmount) < Number(vm.configSettings.minimumOrderValue)){
        		vm.errorString = 'Order value should be greater than or equal to CCIL minimum order value (USD ' + $filter('number')(vm.configSettings.minimumOrderValue, vm.noOfDecimalsForAmount) + '). Order entry restricted..!'
        		return false;
        	}else if(Number(orderAmount) > Number(vm.userPreference.userMaximumOrderValueHardCheck)){
        		vm.errorString = 'Breach of user level maximum order value </br> <span class="hardcheck-value">(USD ' + $filter('number')(vm.userPreference.userMaximumOrderValueHardCheck, vm.noOfDecimalsForAmount) + ')</span> hard check.</br> Order entry restricted..!';
        		return false;
        	}else if(Number(orderAmount) > Number(vm.configSettings.maximumOrderValue)){
        		vm.errorString = 'Breach of CCIL maximum order value </br> <span class="hardcheck-value">(USD ' + $filter('number')(vm.configSettings.maximumOrderValue, vm.noOfDecimalsForAmount) + ')</span> hard check.</br> Order entry restricted..!'
        		return false;
        	}
        	else if(((Math.round(parseFloat(orderAmount) * 10000)) % (Math.round(parseFloat(vm.configSettings.orderLotSize) * 10000)))!=0){
        		vm.errorString = "Order value should be in multiples of lot size " + $filter('number')(vm.configSettings.orderLotSize, vm.noOfDecimalsForAmount) + "...!";
        		return false;
        	}
        	return true;
        }
        
        function validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag, priceCondition, tradeSettlementDate){
        	if($rootScope.limitNotDefined){
    			vm.errorString = "Trading limits are not defined. Order entry not possible...!";
    			return false;
        	}
            //valdiate max tenor date
            if(instrumentCode=='Forward'){
                if(moment(tradeSettlementDate).isAfter($rootScope.maxForwardTenorDate, 'day')){
                    //vm.errorString = "Settlement date is beyond the given limit maximum tenor date. Cannot place order...!";
                    vm.errorString = "Order entry not possible. Settlement date selected is greater than the" +
                    " maximum tenor/value date defined by the Relationship Bank. Please refer the Limit Tab for more info on maximum tenor/value date allowed.";

                    return false;
                }
            }
        	if(buySellFlag=='SELL'){
        		if(!validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition)){
        			vm.errorString = "Sell trading limit breached. Order entry not possible...!";
        			return false;
        		}
        	}else if(buySellFlag=='BUYY'){
        		if(!validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition)){
        			vm.errorString = "Buy trading limit breached. Order entry not possible...!";
        			return false;
        		}
        	}else if(buySellFlag=='SELLBUY'){
        		if(priceCondition=='MRKT'){
            		if(instrumentCode=='SPOT'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.retailSpotBuyLimitForMkt, $rootScope.retailSpotSellLimitForMkt)){
            				return false;
            			}
            		}else if(instrumentCode=='CASH'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.cashBuyLimitForMkt, $rootScope.cashSellLimitForMkt)){
            				return false;
            			}
            		}else if(instrumentCode=='TOM'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.tomBuyLimitForMkt, $rootScope.tomSellLimitForMkt)){
            				return false;
            			}
            		}else if(instrumentCode=='Forward'){
                        if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.forwardBuyLimitWithCF, $rootScope.forwardSellLimitWithCF)){
                            return false;
                        }
                    }
        		}else{
            		if(instrumentCode=='SPOT'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.retailSpotBuyLimit, $rootScope.retailSpotSellLimit)){
            				return false;
            			}
            		}else if(instrumentCode=='CASH'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.cashBuyLimit, $rootScope.cashSellLimit)){
            				return false;
            			}
            		}else if(instrumentCode=='TOM'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.tomBuyLimit, $rootScope.tomSellLimit)){
            				return false;
            			}
            		}else if(instrumentCode=='Forward'){
                        if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.forwardBuyLimitWithCF, $rootScope.forwardSellLimitWithCF)){
                            return false;
                        }
                    }
        		}
        	}
        	return true;
        }
        
        /*
		 * validate limits for sell and buy order
		 */
        function validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, buyLimit, sellLimit){
        	if($rootScope.limitType == 'GLOBALGROSS' && orderAmount > (buyLimit/2)){
				vm.errorString = "Global gross limit breached. Order entry is not possible...!";
				return false;
			}
    		if(orderAmount > buyLimit){
    			vm.errorString = "Buy trading limit breached. Order entry is not possible...!";
    			return false;
    		}else if(orderAmount > sellLimit){
    			vm.errorString = "Sell trading limit breached. Order entry is not possible...!";
    			return false;
    		}
    		return true;
        }
        
        /*
		 * validate sell limit
		 */
        function validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition){
        	if(priceCondition=='MRKT'){
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}else{
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}
        	return true;
        }
        
        /*
		 * validate buy limit
		 */
        function validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition){
        	if(priceCondition=='MRKT'){
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}else{
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}
    		
    		return true;
        }
        
        function validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondition, limitExchangeRate,limitExchangeRateInSpot, ordertickSize, ltp, allowedOrderPriceDeviationInPercent, allowedOrderPriceDeviationInPercentUserSettings){
        	ltp = Number(ltp);
        	if(priceCondition!='MRKT'){
            	if(Number(limitExchangeRate)<0){
            		if(buySellFlag=='BUYY'){
                		vm.errorString = 'Buy limit price must be positive...!';
            		}else{
                		vm.errorString = 'Sell limit price must be positive...!';
            		}
            		return false;
            	}
        	}
        	
        	if((priceCondition!='MRKT' && Number(limitExchangeRate)==0) || (priceCondition=='MRKT' && activityIndicator=='MDFY' && Number(limitExchangeRate)==0)){
        		if(buySellFlag=='BUYY'){
            		vm.errorString = 'Buy limit price must be greater than zero...!';
        		}else{
            		vm.errorString = 'Sell limit price must be greater than zero...!';
        		}
        		return false;
        	}
        	var tickSizeRemainder = (Math.round(parseFloat(limitExchangeRate)*10000)) % (Math.round(parseFloat(ordertickSize)*10000));
        	if(tickSizeRemainder!=0 && priceCondition!='MRKT'){
        		if(buySellFlag=='BUYY'){
            		vm.errorString = 'Buy limit price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
        		}else{
            		vm.errorString = 'Sell limit price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
        		}
        		return false;
        	}
        	/*
			 * var higherCutOffPrice =
			 * $rootScope.previousDayClosingPriceForIbSpot +
			 * ($rootScope.previousDayClosingPriceForIbSpot *
			 * Number(allowedOrderPriceDeviationInPercent/100)); var
			 * lowerCutOffPrice = $rootScope.previousDayClosingPriceForIbSpot -
			 * ($rootScope.previousDayClosingPriceForIbSpot *
			 * Number(allowedOrderPriceDeviationInPercent/100));
			 * 
			 * if(ltp>0 && ltp!=undefined && ltp!=null){ higherCutOffPrice = ltp +
			 * (ltp * Number(allowedOrderPriceDeviationInPercent/100));
			 * lowerCutOffPrice = ltp - (ltp *
			 * Number(allowedOrderPriceDeviationInPercent/100)); }
			 * higherCutOffPrice =
			 * (Math.round(parseFloat(higherCutOffPrice)*10000)/10000);
			 * lowerCutOffPrice =
			 * (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000) var
			 * higherCutOffPriceRemainder =
			 * ((Math.round(parseFloat(higherCutOffPrice)*10000)/10000) %
			 * (Math.round(parseFloat(ordertickSize)*10000)/10000));
			 * higherCutOffPrice =
			 * (Math.round(parseFloat(higherCutOffPrice)*10000)/10000) -
			 * (Math.round(parseFloat(higherCutOffPriceRemainder)*10000)/10000);
			 * 
			 * var lowerCutOffPriceRemainder =
			 * ((Math.round(parseFloat(lowerCutOffPrice)*10000)/10000) %
			 * (Math.round(parseFloat(ordertickSize)*10000)c0));
			 * lowerCutOffPrice =
			 * (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000) -
			 * (Math.round(parseFloat(lowerCutOffPriceRemainder)*10000)/10000) +
			 * (Math.round(parseFloat(ordertickSize)*10000)/10000);
			 * 
			 * if(priceCondition!='MRKT' &&
			 * (Math.round(parseFloat(limitExchangeRateInSpot)*10000) <
			 * Math.round(parseFloat(lowerCutOffPrice)*10000) ||
			 * Math.round(parseFloat(limitExchangeRateInSpot)*10000) >
			 * Math.round(parseFloat(higherCutOffPrice)*10000))){
			 * if(buySellFlag=='BUYY'){ vm.errorString = 'Buy limit price range
			 * should be in between ' + $filter('number')(lowerCutOffPrice, 4) + '
			 * and ' + $filter('number')(higherCutOffPrice, 4) + '...!'; }else{
			 * vm.errorString = 'Sell limit price range should be in between ' +
			 * $filter('number')(lowerCutOffPrice, 4) + ' and ' +
			 * $filter('number')(higherCutOffPrice, 4) + '...!'; } return false; }
			 */
        	
        	if(!validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercentUserSettings, ltp, ordertickSize, priceCondition, buySellFlag, 'user')){
        		return false;
        	}
        	
        	if(!validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercent, ltp, ordertickSize, priceCondition, buySellFlag, 'clearcorp')){
        		return false;
        	}
        	
        	return true;
        }
        
        function validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercent, ltp, ordertickSize, priceCondition, buySellFlag, level){
        	var higherCutOffPrice = $rootScope.previousDayClosingPriceForIbSpot + ($rootScope.previousDayClosingPriceForIbSpot * Number(allowedOrderPriceDeviationInPercent/100));
        	var lowerCutOffPrice = $rootScope.previousDayClosingPriceForIbSpot - ($rootScope.previousDayClosingPriceForIbSpot * Number(allowedOrderPriceDeviationInPercent/100));
        	
        	if(ltp>0 && ltp!=undefined && ltp!=null){
            	higherCutOffPrice = ltp + (ltp * Number(allowedOrderPriceDeviationInPercent/100));
            	lowerCutOffPrice = ltp - (ltp * Number(allowedOrderPriceDeviationInPercent/100));
        	}
        	higherCutOffPrice = (Math.round(parseFloat(higherCutOffPrice)*10000)/10000);
        	lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000)
        	var higherCutOffPriceRemainder = (Math.round(parseFloat(((Math.round(parseFloat(higherCutOffPrice)*10000)) % (Math.round(parseFloat(ordertickSize)*10000))/10000))*10000)/10000);
        	higherCutOffPrice = (Math.round(parseFloat(higherCutOffPrice)*10000)/10000) - (Math.round(parseFloat(higherCutOffPriceRemainder)*10000)/10000);
        	
        	var lowerCutOffPriceRemainder = (Math.round(parseFloat(((Math.round(parseFloat(lowerCutOffPrice)*10000)) % (Math.round(parseFloat(ordertickSize)*10000))/10000))*10000)/10000);
        	if(lowerCutOffPriceRemainder!=0){
            	lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000) - (Math.round(parseFloat(lowerCutOffPriceRemainder)*10000)/10000) + (Math.round(parseFloat(ordertickSize)*10000)/10000);
        	}else{
            	lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000);
        	}
        	 
        	if(priceCondition!='MRKT' && (Math.round(parseFloat(limitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPrice)*10000) ||  Math.round(parseFloat(limitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPrice)*10000))){
        		if(buySellFlag=='BUYY'){
        			if(level=='user'){
        				vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> BUY Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>" +  $filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate) +"</span> "+  ' and ' +"<span class='hardcheck-value'>" +  $filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate)+"</span> ";
        			}else{
        				vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> BUY Equivalent Spot Price should be between </br> ' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate)+"</span> " +  ' and ' + "<span class='hardcheck-value'>"+$filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate) +"</span> ";
        			}
        		}else{
        			if(level=='user'){
        				if(vm.errorString!=''){
        					vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> BUY and SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate)+"</span> " +  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate) +"</span> ";
        				}else{
        					vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> SELL Equivalent Spot Price should be between </br>' + "<span class='hardcheck-value'>"+$filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate) +"</span> "+  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate)+"</span> ";
        				}
        			}else{
        				if(vm.errorString!=''){
        					vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> BUY and SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate)+"</span> " +  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate)+"</span> ";
        				}else{
        					vm.errorString = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, vm.noOfDecimalsForRate)+"</span> " +  ' and ' + "<span class='hardcheck-value'>"+$filter('number')(higherCutOffPrice, vm.noOfDecimalsForRate)+"</span> ";
        				}
        			}
        		}
        		return false;
        	}
        	return true;
        }
        
        function validateStopExchangeRate(priceCondition, buySellFlag, limitExchangeRate, stopExchangeRate, ordertickSize){
        	if(Number(stopExchangeRate)<0){
        		vm.errorString = 'Trigger price must be positive...!';
        		return false;
        	}
        	
        	if(priceCondition!='MRKT' && priceCondition!='LIMT' && Number(stopExchangeRate)==0){
        		vm.errorString = 'Trigger price cannot be zero...!';
        		return false;
        	}
        	
        	if(priceCondition=='STLS' || priceCondition=='TKPR'){
        		if(buySellFlag=='BUYY' && Number(stopExchangeRate) > Number(limitExchangeRate)){
        			vm.errorString = 'Buy trigger price should be less than or equal to the buy limit price...!';
        			// return false;
        		}
        		if(buySellFlag=='SELL' && Number(stopExchangeRate) < Number(limitExchangeRate)){
        			if(vm.errorString!=''){
        				vm.errorString = vm.errorString + ' And sell trigger price should be greater than or equal to the sell limit price...!';
        			}else{
        				vm.errorString = 'Sell trigger price should be greater than or equal to the sell limit price...!';	
        			}
        			return false;
        		}
        		var tickSizeRemainder = (Math.round(parseFloat(stopExchangeRate)*10000)) % (Math.round(parseFloat(ordertickSize)*10000));
            	if(tickSizeRemainder!=0){
            		if(buySellFlag=='BUYY'){
                		vm.errorString = 'Buy trigger price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
            		}else if(buySellFlag=='SELL'){
            			if(vm.errorString!=''){
                    		vm.errorString = vm.errorString + ' And sell trigger price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
            			}else{
                    		vm.errorString = 'Sell trigger price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
            			}
                		return false;
            		} 
            	}
        	}
        	return true;
        }
        
        function validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag){
        	if(instrumentCode!='SPOT'){
                var tickSize = gettickSizeForGivenInstrument(instrumentCode);
                var settlementDate = getSettlementDate(instrumentCode, buySellFlag);
                var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(selectedBankSpread, instrumentCode, buySellFlag, 
                    DateUtils.convertLocalDateToServer(settlementDate), $rootScope.retailSpotMarketSession.settlementDate, 
                    tickSize);
        		//var spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
        		if((spreadUsed==null || spreadUsed==undefined || spreadUsed=='') && spreadUsed!=0){
        			if(buySellFlag=='BUYY'){
        				if(instrumentCode=='CASH'){
                			vm.errorString = 'CASH/SPOT bid spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='TOM'){
                			vm.errorString = 'TOM/SPOT bid spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='Forward'){
                            vm.errorString = 'Order entry not possible as FORWARD offer spread for the selected settlement date ('+  $filter('date')(settlementDate,'dd-MMM-yyyy') +') is not provided by your Relationship bank.';
                        }
        			}else if(buySellFlag=='SELL'){
        				if(instrumentCode=='CASH'){
                			vm.errorString = 'CASH/SPOT offer spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='TOM'){
                			vm.errorString = 'TOM/SPOT offer spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='Forward'){
                            vm.errorString = 'Order entry not possible as FORWARD bid spread for the selected settlement date ('+  $filter('date')(settlementDate,'dd-MMM-yyyy') +') is not provided by your Relationship bank.';
                        }
        			}
        			return false;
        		}
        	}
    		return true;
        }
        
        function getSpreadUsed(selectedBankSwapQuote, buySellFlag, instrumentCode){
        	if(buySellFlag=='BUYY'){
        		if(instrumentCode=='CASH'){
        			return selectedBankSwapQuote.cashSpotBidSpread;
        		}else if(instrumentCode=='TOM'){
        			return selectedBankSwapQuote.tomSpotBidSpread;
        		}else if(instrumentCode=='Forward'){
                    return selectedBankSwapQuote.forwardSpotBidSpread;
                }
        	}else if(buySellFlag=='SELL'){
        		if(instrumentCode=='CASH'){
        			return selectedBankSwapQuote.cashSpotOfferSpread;
        		}else if(instrumentCode=='TOM'){
        			return selectedBankSwapQuote.tomSpotOfferSpread;
        		}else if(instrumentCode=='Forward'){
                    return selectedBankSwapQuote.forwardSpotOfferSpread;
                }
        	}
        }
        
        function placeOrderRequest(orderDTO){
            //on 18-Nov-2020. db writer issue - added call back procedures while sending orders
        	if(orderDTO.buySellIndicator!='SELLBUY'){
                addLimitPriceToArray(orderDTO.limitExchangeRate);
				sendNewOrder(orderDTO);
        	}else if(orderDTO.buySellIndicator=='SELLBUY'){
        		var sellOrderDTO = getSellOrderDTO(orderDTO);
				var buyOrderDTO = getBuyOrderDTO(orderDTO);
                addSellLimitPriceToArray(sellOrderDTO.limitExchangeRate);
				if(OrderUtil.validateOrderDTOForNull(sellOrderDTO) && OrderUtil.validateOrderDTOForNull(buyOrderDTO)){
					Market.placeOrder(sellOrderDTO);
					addBuyLimitPriceToArray(buyOrderDTO.limitExchangeRate);
					Market.placeOrder(buyOrderDTO, onOrderSuccess, onOrderFailure);
				}else{
					MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
				}
        	}
        }

		function sendNewOrder(orderDTO){
			if(OrderUtil.validateOrderDTOForNull(orderDTO)){
				Market.placeOrder(orderDTO, onOrderSuccess, onOrderFailure);
			}else{
				MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");	
			}
		}
        
        function onPlaceOrderSuccess(result){
        	//vm.remarks = "";
            setDefaultRemark();
			fillDefaultValuesToControls();
        }
        
        function onPlaceOrderFailure(result){
        	console.log(result);
        	if(result.data=="Access Denied"){
        		
        	}
        }
        
        function getBuyOrderDTO(orderDTO){
        	var buyOrderDTO = angular.copy(orderDTO);
        	buyOrderDTO.buySellIndicator = 'BUYY';
        	buyOrderDTO.limitExchangeRate = orderDTO.buyLimitExchangeRate;
        	buyOrderDTO.stopExchangeRate = orderDTO.buyStopExchangeRate;
        	return buyOrderDTO;
        }
        
        function getSellOrderDTO(orderDTO){
        	var sellOrderDTO = angular.copy(orderDTO);
        	sellOrderDTO.buySellIndicator = 'SELL';
        	sellOrderDTO.limitExchangeRate = orderDTO.sellLimitExchangeRate;
        	sellOrderDTO.stopExchangeRate = orderDTO.sellStopExchangeRate;
        	return sellOrderDTO;
        }
        
        $scope.$watch('vm.tradingBank', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
                vm.defaultTradingBankControl = value;
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		//CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==value.id;});
    					vm.tradingBranch = result[0].tradingBranch;
    				//})
    				$timeout(function(){
    					vm.tradingCustomer = vm.globalValues.getCustomer();
    				}, 100)
            	
                    if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined && $rootScope.bankComparativeDTO!=null &&
                        $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                        $rootScope.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, vm.tradingBank.id);
                    }
                	
                    $rootScope.selectedCreditFactor = MarketWatch.getCreditFactorSettingsForGivenBank($rootScope.creditFactorList, vm.tradingBank.id);

                	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
                		if(vm.defaultTradingCustomer!=null && vm.defaultTradingCustomer!=undefined){
                        	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.defaultTradingCustomer.id);
                		}
                	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
                		if(vm.defaultTradingCustomer!=null && vm.defaultTradingCustomer!=undefined){
                        	getLimitsAvailableForAllInstruments(vm.globalValues.getOrganisation().id, vm.defaultTradingCustomer.id);
                		}
                	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
                    	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.globalValues.getCustomer().id);
                	}
            	}
                //prepare user market watch list online while change of trading bank
                prepareUserMarketWatchOnline();

                //prepareOutrightComparision($rootScope.outrightRateSettlementDate, $rootScope.outrightRateInstrument);
        	}else{
        		vm.tradingBranch = null;
        	}
        })
        
        $scope.$watch('vm.tradingCustomer', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
        		if(vm.account.organisation.organisationType.shortName!='CUSTOMER'){
            		/*
					 * vm.defaultTradingCustomer = vm.tradingCustomer;
					 * $rootScope.defaultTradingCustomer = vm.tradingCustomer;
					 */
                    vm.defaultTradingCustomerControl = vm.tradingCustomer;
    	            $timeout(function(){
    	            	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
    	            		if(vm.defaultTradingCustomer!=null && vm.defaultTradingCustomer!=undefined){
    	                    	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.tradingCustomer.id);
    	            		}
    	            	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
    	            		if(vm.tradingCustomer!=null && vm.tradingCustomer!=undefined){
    	                    	getLimitsAvailableForAllInstruments(vm.globalValues.getOrganisation().id, vm.tradingCustomer.id);
    	            		}
    	            	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
    	                	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.tradingCustomer.id);
    	            	}
    	            },100)
        		}
                if(vm.account.entityOwnerType.shortName=='Bank'){
                    CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: vm.globalValues.getOrganisation().id, customerId: value.id}, function(result){
                        vm.tradingBranch = result;
                    })
                }
        	}
        })
        
        $scope.$watch('vm.bankFilterForLimit', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
        		$rootScope.bankFilterForLimit = value;
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		//CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==value.id;});
                        vm.branchFilterForLimit = result[0].tradingBranch;
                        $rootScope.branchFilterForLimit = result[0].tradingBranch;
                    //});
            		// get the limits when the bakFilterForLimit changes\
                    //this is commented on phase 3 implementation,. to change 'change request' and 'new limit request' buttons' behaviour depend on lookup controls
            		//LimitSettings.getLimitsAvailableForAllInstruments({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, checkLimitOnBankFilterChangeSuccess, onGetLimitsAvailableFailure);
            	}
        	}else{
				vm.branchFilterForLimit = null;
                $rootScope.bankFilterForLimit = null;
				$rootScope.branchFilterForLimit = null;
				// $rootScope.limitAvailableForSlectBankFilterInReport = false;
        	}
        })
        
        function checkLimitOnBankFilterChangeSuccess(result){
        	if(result!=null && result !=undefined && result.length > 0){
        		$rootScope.limitAvailableForSlectBankFilterInReport = true;
        	}else{
        		$rootScope.limitAvailableForSlectBankFilterInReport = false;
        	}
        }
        
        
        $scope.$watch('vm.bankFilterForOT', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
        		$rootScope.bankFilterForOT = value;
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		//CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==value.id;});
                        vm.branchFilterForOT = result[0].tradingBranch;
                        $rootScope.branchFilterForOT = result[0].tradingBranch;
                    //})
            	}
        	}else{
				vm.branchFilterForOT = null;
                $rootScope.bankFilterForOT = null;
				$rootScope.branchFilterForOT = null;
        	}
        })
        
        $scope.$watch('vm.bankFilterForPO', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
        		$rootScope.bankFilterForPO = value;
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		//CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==value.id;});
                        vm.branchFilterForPO = result[0].tradingBranch;
                        $rootScope.branchFilterForPO = result[0].tradingBranch;
                    //})
            	}
        	}else{
				vm.branchFilterForPO = null;
                $rootScope.bankFilterForPO = null;
				$rootScope.branchFilterForPO = null;
        	}
        })
        
         $scope.$watch('vm.bankFilterForTrade', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
        		$rootScope.bankFilterForTrade = value;
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		//CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==value.id;});
                        vm.branchFilterForTrade = result[0].tradingBranch;
                        $rootScope.branchFilterForTrade = result[0].tradingBranch;
                    //})
            	}
        	}else{
				vm.branchFilterForTrade = null;
                $rootScope.bankFilterForTrade = null;
				$rootScope.branchFilterForTrade = null;
        	}
        })
        
        //events emitted from the expanded reports view
        var modifypendingorder = $rootScope.$on('modifypendingorder', function(event, result) {
            if($rootScope.pendingOrderToModify!=null && $rootScope.modifyCount>0){
                console.log('modifypendingorder');
                modifyOrder($rootScope.pendingOrderToModify);
            }
            $rootScope.modifyCount = $rootScope.modifyCount + 1;
        });

        var cancelpendingtrade = $rootScope.$on('cancelpendingtrade', function(event, result) {
            if($rootScope.cancelTradeCount>0){
                console.log('cancelcontractcancellation');
                prepareOrderDTOToCancelTrade($rootScope.outstandingTradeToCancel);
            }
            $rootScope.cancelTradeCount = $rootScope.cancelTradeCount + 1;
        });

        var rolloverpendingtrade = $rootScope.$on('rolloverpendingtrade', function(event, result) {
            if($rootScope.rolloverTradeCount>0){
                console.log('rollovertrade');
                prepareOrderDTOToRollOverTrade($rootScope.outstandingTradeToRollover);
            }
            $rootScope.rolloverTradeCount = $rootScope.rolloverTradeCount + 1;
        });

        var earlydeliverypendingtrade = $rootScope.$on('earlydeliverypendingtrade', function(event, result) {
            if($rootScope.earlyDeliveryTradeCount>0){
                console.log('earlydeliverytrade');
                prepareOrderDTOToEarlyDeliveryTrade($rootScope.outstandingTradeToEarlyDelivery);
            }
            $rootScope.earlyDeliveryTradeCount = $rootScope.earlyDeliveryTradeCount + 1;
        });

        var cancelpendingorder = $rootScope.$on('cancelpendingorder', function(event, result) {
            if($rootScope.pendingOrderToCancel!=null && $rootScope.cancelCount>0){
                console.log('cancelpendingorder');
                setTimeout(function(){
                    cancelOrder($rootScope.pendingOrderToCancel);
                },100);
            }
            $rootScope.cancelCount = $rootScope.cancelCount + 1;
        });

        var cancelselectedpendingorders = $rootScope.$on('cancelselectedpendingorders', function(event, result){
            if($rootScope.cancelSelectedCount>0){
                cancelPendingOrdersSelected();
            }
            $rootScope.cancelSelectedCount = $rootScope.cancelSelectedCount + 1;
        })

        var cancelallpendingorders = $rootScope.$on('cancelallpendingorders', function(event, result){
            if($rootScope.cancelSelectedCount>0){
                cancelAllOrders();
            }
                
                $rootScope.cancelSelectedCount = $rootScope.cancelSelectedCount + 1
        })

        var cancelselectedfrompreorderconfirmation = $rootScope.$on('cancelselectedfrompreorderconfirmation', function(event, result){
            if($rootScope.cancelSelectedOrderCount>0){
                cancelSelectedOrders();
            }
            $rootScope.cancelSelectedOrderCount = $rootScope.cancelSelectedOrderCount + 1;
        })

        var limitchangerequestfromexp = $rootScope.$on('limitchangerequestfromexp', function(event, result){
            if($rootScope.limitChangeRequestCount>0){
                setTimeout(function(){
                    limitChangeRequest($rootScope.selectedLimit);
                },100)
            }
            $rootScope.limitChangeRequestCount = $rootScope.limitChangeRequestCount + 1;
        })

        var newlimitrequestfromexp = $rootScope.$on('newlimitrequestfromexp', function(event, result){
            if($rootScope.newLimitRequestCount>0){
                setTimeout(function(){
                    newLimitRequest();
                },100)
            }
            $rootScope.newLimitRequestCount = $rootScope.newLimitRequestCount + 1;
        })

        var syncinputs = $rootScope.$on('syncinputs', function(event, result) {
            vm.customerFilterForPO=$rootScope.customerFilterForPO;
            vm.customerFilterForLimit=$rootScope.customerFilterForLimit;
            vm.customerFilterForTrade=$rootScope.customerFilterForTrade;
            vm.customerFilterForOT=$rootScope.customerFilterForOT;

            vm.bankFilterForPO=$rootScope.bankFilterForPO;
            vm.bankFilterForLimit=$rootScope.bankFilterForLimit;
            vm.bankFilterForTrade=$rootScope.bankFilterForTrade;
            vm.bankFilterForOT=$rootScope.bankFilterForOT;

            vm.branchFilterForPO=$rootScope.branchFilterForPO;
            vm.branchFilterForLimit=$rootScope.branchFilterForLimit;
            vm.branchFilterForTrade=$rootScope.branchFilterForTrade;
            vm.branchFilterForOT=$rootScope.branchFilterForOT;

            vm.instrumentFilterForPO=$rootScope.instrumentFilterForPO;
            vm.instrumentFilterForLimit=$rootScope.instrumentFilterForLimit;
            vm.instrumentFilterForTrade=$rootScope.instrumentFilterForTrade;
            vm.instrumentFilterForOT=$rootScope.instrumentFilterForOT;

            vm.fromSettlementDate = $rootScope.fromSettlementDate;
            vm.toSettlementDate = $rootScope.toSettlementDate;
            vm.selectAll = $rootScope.selectAll;
            vm.reportTab = $rootScope.reportTab;
        });

        //to refresh home page reports from database after websocket reconnection
        var refreshreports = $rootScope.$on("refreshreports", function(event){
            if($rootScope.refreshreportscount>0){
                setIntradayMarketSessionDetails();
                getPendingOrdersReport();
                getOutstandingTradesReport();
                getTradesReport();
                getLimitUtilisedReport();
                getNotifications();
            }
            $rootScope.refreshreportscount = $rootScope.refreshreportscount + 1;
        });

        $scope.$on('$destroy', function() {
            modifypendingorder(); 
            cancelpendingorder(); 
            syncinputs(); 
            cancelpendingtrade();
            rolloverpendingtrade();
            earlydeliverypendingtrade();
            cancelselectedpendingorders();
            cancelallpendingorders();
            limitchangerequestfromexp();
            newlimitrequestfromexp();    
            cancelselectedfrompreorderconfirmation();
            orderplacedsuccess();
            refreshreports();  
            proceedCancelAllOrders();
            $rootScope.lookupFilled = null;       
        });

        // modify selected order
        function modifyOrder(pendingOrder){
        	//vm.remarks = "";
            setDefaultRemark();
        	prepareOrderDTO(pendingOrder, 'MDFY');
        }
        
        function cancelTrade(){
            //vm.remarks = "";
            setDefaultRemark();
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            if(validateOutstandingTrade(outstandingTrade)){
                prepareOrderDTOToCancelTrade(outstandingTrade[0]);
            }
        }

        function validateOutstandingTrade(outstandingTrade) {
            var isValid = true;
            
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract
            	 if(outstandingTrade[0].instrumentCode!='Forward' ){	//&& outstandingTrade[0].eventTypeName!='Early Delivery'
                     isValid = false;
                     MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be cancelled.", "Ok", "selectedorders");
                 }else{
                    //validate outstanding amount
                	var cancellationSettlementDate = getSettlementDateForContractCancellation(outstandingTrade[0]);
                    var instrumentCode = getInstrumentCodeForSettlement(cancellationSettlementDate);                   
                    if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Cancellation order(s) is already pending for execution, the operation is not permitted...!", "Ok", "selectedorders");   
                    }
                    //validate spread 
                    if(instrumentCode!='SPOT'){
                        var tickSize = gettickSizeForGivenInstrument(instrumentCode);
                        var buySellIndicator = 'BUYY';
                        if(outstandingTrade[0].buySellIndicator=='BUYY'){
                            buySellIndicator = 'SELL';  
                        }
                        var bankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, outstandingTrade[0].bankId);
                        var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(bankSpread, instrumentCode, buySellIndicator, 
                            DateUtils.convertLocalDateToServer(angular.copy(cancellationSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                        if(spreadUsed==null || spreadUsed==undefined){
                            isValid = false;
                            if(buySellIndicator == 'BUYY'){
                               if(instrumentCode=='Forward'){
                                  MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD offer spread for the selected settlement date ("+  $filter('date')(cancellationSettlementDate,'dd-MMM-yyyy') +") is not provided by your Relationship bank.", "Ok", "selectedorders"); 
                               }else{
                                  MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT bid spread is not given by the bank. Order entry not possible...!", "Ok", "selectedorders");   
                               }
                            }else{
                               if(instrumentCode=='Forward'){
                                  MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD bid spread for the selected settlement date ("+  $filter('date')(cancellationSettlementDate,'dd-MMM-yyyy') +") is not provided by your Relationship bank.", "Ok", "selectedorders"); 
                               }else{
                                  MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT offer spread is not given by the bank. Order entry not possible...!", "Ok", "selectedorders");   
                               }
                            }
                        }
                    }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "selectedorders");
            }
            return isValid;
        }

        vm.prepareOrderDTOToCancelTrade = prepareOrderDTOToCancelTrade;
        function prepareOrderDTOToCancelTrade(outstandingTradeObj){
            //on 18-Nov-2020. db writer null issue - intermediate variable introduced to prevent online changes impact on orderDTO.
            var outstandingTrade = angular.copy(outstandingTradeObj);
            //on 18-Nov-2020. filling of bank/branch/customer moved first due to issue found MDFY/CNCL order entry formation when user preferred bank is empty.
            vm.orderFromMarketWatch = false;

            //change bank, branch and customer controls from outstanding trade obj
            changeBankBranchCustomerControlsFromOutstandingTradeObj(outstandingTrade);
            vm.outstandtradedatefrom=angular.copy(outstandingTrade.tradeSettlementDateFrom);
            $timeout(function() {
                vm.orderDTO.activityIndicator = 'TRAD';
                vm.orderDTO.contractCancellation = true;
                
                // outstandingTrade.isOptionPeriod = true
                var cancellationSettlementDate = getSettlementDateForContractCancellation(outstandingTrade);

                vm.orderDTO.instrumentCode = getInstrumentCodeForSettlement(cancellationSettlementDate);
                vm.defaultInstrumentCode = vm.orderDTO.instrumentCode;
                fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
                if(outstandingTrade.buySellIndicator=='BUYY'){
                    vm.orderDTO.buySellIndicator = 'SELL';  
                }else{
                    vm.orderDTO.buySellIndicator = 'BUYY'; 
                }
                vm.orderDTO.timeLimitIndicator = outstandingTrade.timeCondition;
                if(vm.orderDTO.timeCondition=='GTTM'){
                    //vm.orderDTO.expiryTime = outstandingTrade.expiryTime;
                    //vm.orderDTO.expiryTimeString = outstandingTrade.expiryTimeString;
                }
                if(vm.orderDTO.instrumentCode=='Forward'){
                    vm.tradeSettlementDate = new Date(cancellationSettlementDate);
                    vm.orderDTO.tradeSettlementDate = cancellationSettlementDate;
                    // during contract cancellation the option  period is false
                    changeTenor(cancellationSettlementDate,false);
                    //set exposure type properies and fill values in lookup control
                    setExposureTypeFromAllExposureLookup(outstandingTrade.exposureTypeName);
                    setExposureSubTypeFromAllSubExposureLookup(outstandingTrade.exposureSubTypeName);
                }
                //fill option period details, set option period details as null as it is cancel contract
                fillOptionPeriodDetails(outstandingTrade,false);
                vm.orderDTO.instrumentId = vm.defaultInstrumentId;

                //fill bank, branch, customer details in order DTO
                fillBankBranchCustomerDetailsFromOutstandingTrade(outstandingTrade);

                var eventType = getEventTypeForShortName('ContractCancellation');
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;

                //fill order/trade sequence, settlement leg and amount details
                fillOrderSequenceAndAmountDetails(outstandingTrade);

                vm.orderDTO.sourceInstrumentCode = outstandingTrade.instrumentCode;
                vm.orderDTO.sourceInstrumentId = getInstIdForInstCode(vm.orderDTO.sourceInstrumentCode);

                vm.orderDTO.typeOfOrder = 'LIMT';
                vm.defaultPriceCondition = 'LIMT';
                vm.orderDTO.timeLimitIndicator = 'DAYY';
                vm.defaultTimeCondition = 'DAYY';

                setDefaultPriceCondition(vm.defaultPriceCondition);
                // changeInstrument(pendingOrder.instrumentCode);
                selectInstrumentInOrderEntry(vm.orderDTO.instrumentCode);
                selectTimeConditionInOrderEntry(vm.defaultTimeCondition);
                changeOrderType(vm.orderDTO.buySellIndicator, vm.orderDTO.activityIndicator);

                //get limit exchange rate from ltp for instrument code
                getLimitExchangeRateForCancellingOutstandingTrade(vm.defaultInstrumentCode);

                $rootScope.orderDTO = vm.orderDTO;
            }, 300);
        }

        function rolloverTrade(){
           // vm.remarks = "";
           setDefaultRemark();
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            //validate spread is available for cancellation leg
            if(validateOutstandingTradeForRollover(outstandingTrade)){ 
                prepareOrderDTOToRollOverTrade(outstandingTrade[0]);
            }
        }

        function validateOutstandingTradeForRollover(outstandingTrade) {
            var isValid = true;
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract
            	if(outstandingTrade[0].instrumentCode!='Forward' ){//&& outstandingTrade[0].eventTypeName=='Booking'
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be rolled over...!", "Ok", "traderollover");
                    
                }
            	else if(outstandingTrade[0].instrumentCode == 'Forward' && outstandingTrade[0].isOptionPeriod == true ){
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The Forward Option Period contract cannot be rolled over...!", "Ok", "traderollover");
                }
            	else{
                	
                    //validate outstanding amount
                	//get instrument code for cancellation leg to check the session close
                    var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);      
                   /* if(outstandingTrade[0].instrumentCode!='Forward' && outstandingTrade[0].eventTypeName=='Early Delivery'){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "The Early Delivery SPOT/TOM/CASH contract cannot be rolled over...!", "Ok", "traderollover");
                    }else */
                    var cancellationLegSpread = findCancellationLegSpread(instrumentCode, outstandingTrade[0].tradeSettlementDate, findBuyySellIndicatorForCancellationLeg(outstandingTrade[0].buySellIndicator) );
                    if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Order(s) is already pending for execution, the operation is not permitted...!", "Ok", "traderollover");   
                    }else if(!validateCancellationLegSessionStatus('ROLL', instrumentCode)){
                    	isValid = false;
                        MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "traderollover");  
                    }else if(cancellationLegSpread == null || cancellationLegSpread == undefined){
                    	isValid = false;
                    	 MessageBox.openMessageBox("Alert", "Contract cannot be rolled over. Cancellation Leg Swap Point for "+ $filter('date')(outstandingTrade[0].tradeSettlementDate,'dd-MMM-yyyy') +" is not provided by Relationship bank...!", "Ok", "traderollover");
                         
                    }

                    //validate spread 
                    // if(instrumentCode!='SPOT'){
                    //     var tickSize = gettickSizeForGivenInstrument(instrumentCode);
                    //     var buySellIndicator = 'BUYY';
                    //     if(outstandingTrade[0].buySellIndicator=='BUYY'){
                    //         buySellIndicator = 'SELL';  
                    //     }
                    //     var bankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, outstandingTrade[0].bankId);
                    //     var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(bankSpread, instrumentCode, buySellIndicator, 
                    //         DateUtils.convertLocalDateToServer(angular.copy(outstandingTrade[0].tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                    //         tickSize);
                    //     if(spreadUsed==null || spreadUsed==undefined){
                    //         isValid = false;
                    //         if(buySellIndicator == 'BUYY'){
                    //            if(instrumentCode=='Forward'){
                    //               MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD offer spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "traderollover"); 
                    //            }else{
                    //               MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT bid spread is not given by the bank. Order entry not possible...!", "Ok", "traderollover");   
                    //            }
                    //         }else{
                    //            if(instrumentCode=='Forward'){
                    //               MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD bid spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "traderollover"); 
                    //            }else{
                    //               MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT offer spread is not given by the bank. Order entry not possible...!", "Ok", "traderollover");   
                    //            }
                    //         }
                    //     }
                    // }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "traderollover");
            }
            return isValid;
        }

        function prepareOrderDTOToRollOverTrade(outstandingTradeObj){
            //on 18-Nov-2020. db writer null issue - intermediate variable introduced to prevent online changes impact on orderDTO.
            var outstandingTrade = angular.copy(outstandingTradeObj);
            //on 18-Nov-2020. filling of bank/branch/customer moved first due to issue found MDFY/CNCL order entry formation when user preferred bank is empty.
            vm.orderFromMarketWatch = false;

            //change bank, branch and customer controls from outstanding trade obj
            changeBankBranchCustomerControlsFromOutstandingTradeObj(outstandingTrade);

            $timeout(function() {
                vm.orderDTO.activityIndicator = 'ROLL';
                vm.orderDTO.contractCancellation = true;

                //fill trade settlement date in control and get tenor for that settlement date
                //fill default trade rebooking date as trade settlement date + 1 day
                //vm.tradeSettlementDate = MarketWatch.addDaysToGivenDate(angular.copy(outstandingTrade.tradeSettlementDate), 1);
                vm.tradeSettlementDate = new Date(angular.copy(outstandingTrade.tradeSettlementDate));
                vm.orderDTO.tradeSettlementDate = angular.copy(vm.tradeSettlementDate);
                //vm.minimumTradeSettlementDate = angular.copy(vm.tradeSettlementDate);
                vm.minimumTradeSettlementDate = MarketWatch.addDaysToGivenDate(angular.copy(outstandingTrade.tradeSettlementDate), 1);

                //get instrument for the selected trade settlement date
                vm.orderDTO.instrumentCode = getInstrumentCodeForSettlement(DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)));
                vm.defaultInstrumentCode = vm.orderDTO.instrumentCode;
                fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
                vm.orderDTO.instrumentId = vm.defaultInstrumentId;

                //fill source instrument code
                //get instrument for the orginal trade settlement date
                vm.orderDTO.cancellationTradeSettlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
                vm.orderDTO.sourceInstrumentCode = outstandingTrade.instrumentCode;
                vm.orderDTO.sourceInstrumentId = getInstIdForInstCode(vm.orderDTO.sourceInstrumentCode);

                //fill buy/sell indicator, time condition and price condition
                vm.orderDTO.buySellIndicator = outstandingTrade.buySellIndicator;
                vm.defaultPriceCondition = 'LIMT';
                vm.orderDTO.typeOfOrder = vm.defaultPriceCondition;
                vm.defaultTimeCondition = 'IOCC';
                vm.orderDTO.timeLimitIndicator = vm.defaultTimeCondition;

                //change tenor control with rebooking trade settlement date
                // for rolloverover there is no option period
                changeTenor(vm.orderDTO.tradeSettlementDate,false);

                //fill event type
                var eventType = getEventTypeForShortName('Rollover');
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;

                //fill order request DTO from outstanding trade obj
                fillOrderDTOFromOutstandingTradeForRollOver(outstandingTrade);
                //fill option period details, set option period details as null as it is rollover
                fillOptionPeriodDetails(outstandingTrade,false);
                //set price condition, time condition, instrument and buy/sell indicator on controls
                setDefaultPriceCondition(vm.defaultPriceCondition);
                selectInstrumentInOrderEntry(vm.orderDTO.instrumentCode);
                selectTimeConditionInOrderEntry(vm.defaultTimeCondition);
                changeOrderType(vm.orderDTO.buySellIndicator, vm.orderDTO.activityIndicator);

				//fill indicative cancellation/spot/rebooking rates and forward swap point
                fillIndicativeRatesAndSwapPoints();
                
               /* //find whether the existing trade is consuming limit or not
                vm.orderDTO.isExistingTradeConsumesLimit = false;
                LimitSettings.checkIsExistingTradeConsumesLimit(vm.orderDTO, function(result){
                    vm.orderDTO.isExistingTradeConsumesLimit = result.checkIsExistingTradeConsumesLimit;
                })
*/
                $rootScope.orderDTO = vm.orderDTO;
            }, 300);
        }

        function fillOrderDTOFromOutstandingTradeForRollOver(outstandingTrade){

            //fill bank, branch, customer details in order DTO
            fillBankBranchCustomerDetailsFromOutstandingTrade(outstandingTrade);

            //fill exposure type/sub-type - FRWD check is commented because the exposure type is not filled when the cancellation leg is in CASH/TOM/SPOT. 
          //  if(vm.orderDTO.instrumentCode=='Forward'){
                //set exposure type properies and fill values in lookup control
                setExposureTypeFromAllExposureLookup(outstandingTrade.exposureTypeName);
                setExposureSubTypeFromAllSubExposureLookup(outstandingTrade.exposureSubTypeName);
           // }

            //fill order sequence, settlement leg and amount details
            fillOrderSequenceAndAmountDetails(outstandingTrade);

        }
        
        function fillOrderDTOFromOutstandingTradeForEarlyDelivery(outstandingTrade){

            //fill bank, branch, customer details in order DTO
            fillBankBranchCustomerDetailsFromOutstandingTrade(outstandingTrade);

            //fill exposure type/sub-type
            if(vm.orderDTO.instrumentCode=='Forward'){
                //set exposure type properies and fill values in lookup control
                setExposureTypeFromAllExposureLookup(outstandingTrade.exposureTypeName);
                setExposureSubTypeFromAllSubExposureLookup(outstandingTrade.exposureSubTypeName);
            }

            //fill order sequence, settlement leg and amount details
            fillOrderSequenceAndAmountDetails(outstandingTrade);

        }

        function fillBankBranchCustomerDetailsFromOutstandingTrade(outstandingTrade){
            vm.orderDTO.branchCode = outstandingTrade.branchCode;
            vm.orderDTO.branchId = outstandingTrade.branchId;
            vm.orderDTO.branchName = outstandingTrade.branchName;
            vm.orderDTO.bankId = outstandingTrade.bankId;
            vm.orderDTO.bankName = outstandingTrade.bankName;
            vm.orderDTO.bankMemberNumber = outstandingTrade.bankMemberNo;
            vm.orderDTO.customerId = outstandingTrade.customerId;
            vm.orderDTO.customerCode = outstandingTrade.customerCode;
            vm.orderDTO.customerName = outstandingTrade.customerName;
        }

        function fillOrderSequenceAndAmountDetails(outstandingTrade){
            vm.orderDTO.settlementLegId = outstandingTrade.settlementLegId;
            vm.orderDTO.orderSequenceNumber = 0;
            vm.orderDTO.revisionTimeStamp = 0;
            vm.orderDTO.sourceOrderSequenceNumber = outstandingTrade.orderSequenceNumber;
            vm.orderDTO.sourceTradeSequenceNumber = outstandingTrade.tradeSequenceNumber;
            //get order amount
            vm.orderDTO.outStandingAmount = outstandingTrade.outStandingAmount - outstandingTrade.blockedAmount;
            vm.orderDTO.oldOrderAmount = vm.orderDTO.outStandingAmount;
            vm.orderDTO.orderAmount = vm.orderDTO.outStandingAmount;
            //vm.remarks = outstandingTrade.userRemarks; 
            vm.remarksDTO.remarks1 = outstandingTrade.userRemarks; 
            vm.remarksDTO.remarks2 = outstandingTrade.remarks2; 
            vm.remarksDTO.remarks3 = outstandingTrade.remarks3; 
            vm.remarksDTO.remarks4 = outstandingTrade.remarks4; 

            setRemark();
        }

        function fillIndicativeRatesAndSwapPoints(){

            //fill indicative IB spot rate.
            vm.orderDTO.indicativeSpotRate = getLtpValue();

            //compute indicative cancellation rate 
            //fixed issue in RO, when forward trade comes to spot window (cash date), and when it is rolled over,
            //and having spread in cash and tom alone. if sourceInstCode(forward) is used for computing spread, spread will not be calculated properly for this.
            
            /*var instCodeForCancellation = getInstrumentCodeForSettlement(vm.orderDTO.cancellationTradeSettlementDate);
            fillIndicativeCancellationRate(instCodeForCancellation, vm.orderDTO.cancellationTradeSettlementDate, vm.orderDTO.buySellIndicator);*/
            
            fillCancellationLegDetails(vm.orderDTO.cancellationTradeSettlementDate,vm.orderDTO.buySellIndicator);    		
            fillIndicativeCancellationRate(vm.orderDTO.cancelledLegInstrumentCode, vm.orderDTO.cancellationTradeSettlementDate,  vm.orderDTO.cancelledLegBuySellIndicator );

            //compute indicative re-booking rate 
            fillIndicativeRebookingRate(vm.orderDTO.instrumentCode, vm.orderDTO.tradeSettlementDate, vm.orderDTO.buySellIndicator);

            //find forward swap points 
            //cancellation leg spread, rebooking spread and order type will be sent as input
            fillForwardSwapPoints(vm.orderDTO.cancellationLegSpread, vm.orderDTO.rebookingLegSpread);
        }
        // this functionality is invoked during validation and also during the trade date settlement change
        function findCancellationLegSpread(instCode, tradeCancellationSettlementDate, orderTypeOfCancellationLeg){
        	
        	 //get tick size for instcode and stored in variable
            var tickSize = gettickSizeForGivenInstrument(instCode);

        	 if(instCode=='SPOT'){ //if given instrument is SPOT, then set cancellation leg spread as 0
                 return  0;
             }else{
                 //get spread for given settlement date,  order type of cancellation leg
                 return MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, instCode, orderTypeOfCancellationLeg, 
                     DateUtils.convertLocalDateToServer(angular.copy(tradeCancellationSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                     tickSize);
             }
        }

		function fillIndicativeCancellationRate(instCode, tradeCancellationSettlementDate, orderTypeOfCancellationLeg){

           
			//order type for cancellation leg is determined by orderTypeOfTrade. 
			//if orderTypeOfTrade is buy, then order type of cancellation leg will be sell, and vice versa
            /*if(orderTypeOfTrade=='BUYY'){
                vm.orderTypeOfCancellationLeg = 'SELL';
            }else{
                vm.orderTypeOfCancellationLeg = 'BUYY';
            }*/
            vm.orderDTO.cancellationLegSpread = findCancellationLegSpread(instCode, tradeCancellationSettlementDate, orderTypeOfCancellationLeg);
           
            if(vm.orderDTO.cancellationLegSpread!=null && vm.orderDTO.cancellationLegSpread!=undefined){
                vm.orderDTO.indicativeCancellationRate = convertSpotRateToLimitExchangeRate(vm.orderDTO.indicativeSpotRate, vm.orderDTO.cancellationLegSpread, instCode);
            }else{
                vm.orderDTO.indicativeCancellationRate = null;
            }

		}
		function findBuyySellIndicatorForCancellationLeg(orderTypeOfTrade){
			//order type for cancellation leg is determined by orderTypeOfTrade. 
			//if orderTypeOfTrade is buy, then order type of cancellation leg will be sell, and vice versa
		   if(orderTypeOfTrade=='BUYY'){
		       return 'SELL';
		   }else{
		       return 'BUYY';
		   }
		}
		$rootScope.findBuyySellIndicatorForCancellationLeg = findBuyySellIndicatorForCancellationLeg;
		function fillCancellationLegDetails(tradeCancellationSettlementDate, orderTypeOfTrade){
			
		  var instCodeForCancellation = getInstrumentCodeForSettlement(tradeCancellationSettlementDate);
		    
		   vm.orderTypeOfCancellationLeg = findBuyySellIndicatorForCancellationLeg(orderTypeOfTrade);
		   //set Cencellation leg buy sell indicator to display in pre order confirmation
		   vm.orderDTO.cancelledLegBuySellIndicator = vm.orderTypeOfCancellationLeg;
		   //set Instrument Code for cancellation leg to display in pre order confirmation
		   vm.orderDTO.cancelledLegInstrumentCode = instCodeForCancellation;
		   
		}

		function fillIndicativeRebookingRate(instCode, tradeCancellationSettlementDate, orderTypeOfTrade){
            //get tick size for instcode and stored in variable
            var tickSize = gettickSizeForGivenInstrument(instCode);

            if(instCode=='SPOT'){ //if given instrument is SPOT, then set rebooking leg spread as 0
                vm.orderDTO.rebookingLegSpread = 0;
            }else{
                //get spread for given settlement date,  order type of trade to be rolled over
                vm.orderDTO.rebookingLegSpread = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, instCode, orderTypeOfTrade, 
                    DateUtils.convertLocalDateToServer(angular.copy(tradeCancellationSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                    tickSize);
            }

            if(vm.orderDTO.rebookingLegSpread!=null && vm.orderDTO.rebookingLegSpread!=undefined){
                vm.orderDTO.limitExchangeRate = convertSpotRateToLimitExchangeRate(vm.orderDTO.indicativeSpotRate, vm.orderDTO.rebookingLegSpread, instCode);
            }else{ //if rebooking leg spread is not defined or unable to compute, then rebooking limit exchange rate will be null and cleared.
                vm.orderDTO.limitExchangeRate = null;
                if(vm.orderDTO.activityIndicator=='ROLL'){
                    setFieldValues("txtlimitorderprice3", null);
                }else if(vm.orderDTO.activityIndicator=='EARL'){
                    setFieldValues("txtlimitorderprice4", null);
                }
            }
		}

		function fillForwardSwapPoints(cancellationLegSpread, rebookingLegSpread){
            //if rebookingLegSpread is not defined, forward swap point is returned as null
            //else forward swap point = rebookingLegSpread - cancellationLegSpread;
            if(rebookingLegSpread!=null && rebookingLegSpread!=undefined && cancellationLegSpread!=null && cancellationLegSpread!=undefined){
                vm.orderDTO.forwardSwapPoints = rebookingLegSpread - cancellationLegSpread;
                vm.orderDTO.forwardSwapPoints = (Math.round(parseFloat(vm.orderDTO.forwardSwapPoints)*100)/100);
            }else{
                vm.orderDTO.forwardSwapPoints = null;
            }
		}

        function changeBankBranchCustomerControlsFromOutstandingTradeObj(outstandingTrade){
            //fill bank control
            if((vm.tradingBank!=null && vm.tradingBank!=undefined && vm.tradingBank.id != outstandingTrade.bankId) || (vm.tradingBank==null || vm.tradingBank==undefined)){
                changeTradingBank(outstandingTrade.bankId);
            }

            //fill branch control
            if((vm.tradingBranch!=null && vm.tradingBranch!=undefined && vm.tradingBranch.id != outstandingTrade.branchId) || (vm.tradingBranch==null || vm.tradingBranch==undefined)){
                $timeout(function(){
                    changeTradingBranch(outstandingTrade.branchId);    
                },100)
            }

            //fill customer control
            if((vm.tradingCustomer!=null && vm.tradingCustomer!=undefined && vm.tradingCustomer.id != outstandingTrade.customerId) || (vm.tradingCustomer==null || vm.tradingCustomer==undefined)){
                $timeout(function(){
                    changeTradingCustomer(outstandingTrade.customerId);
                },100)
            }
        }

        $scope.$watchGroup(['vm.tradeSettlementDate', 'vm.tenor', 'defaultBankSpread', 'ibSpotMarketDepth'], function(value){
            if(value!=null && value!=undefined){
                if(vm.orderDTO.activityIndicator=='ROLL' || vm.orderDTO.activityIndicator=='EARL'){
                    //if vm.orderDTO.activityIndicator is roll over, 
                    //then find indicative rebooking rate and forward swap points for tradeSettlement Date
               	                	
                    //get instrument for the selected trade settlement date
                    //depending on insturment found, change instrument on control also.
                    vm.orderDTO.instrumentCode = getInstrumentCodeForSettlement(DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)));
                    vm.defaultInstrumentCode = vm.orderDTO.instrumentCode;
                    fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
                    vm.orderDTO.instrumentId = vm.defaultInstrumentId;
                    vm.orderDTO.tradeSettlementDate = angular.copy(vm.tradeSettlementDate);

                    fillIndicativeRatesAndSwapPoints();
                }
            }
        })

        function earlyDeliveryTrade(){
            //vm.remarks = "";
            setDefaultRemark();
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            //validate spread is available for cancellation leg
            if(validateOutstandingTradeForEarlyDelivery(outstandingTrade)){ 
                prepareOrderDTOToEarlyDeliveryTrade(outstandingTrade[0]);
            }
        }

        function validateOutstandingTradeForEarlyDelivery(outstandingTrade) {
            var isValid = true;
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract
            	 if(outstandingTrade[0].instrumentCode!='Forward' ){ //&& outstandingTrade[0].eventTypeName=='Booking'
                     isValid = false;
                     MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be early delivered...!", "Ok", "tradeearlydelivery");
                 }else if(outstandingTrade[0].instrumentCode == 'Forward' && outstandingTrade[0].isOptionPeriod == true && !isEventAppliedBeforeOptionPeriod(outstandingTrade[0]) ){
                	 isValid = false;
                	 MessageBox.openMessageBox("Alert", "The Forward Option Period contract cannot be early delivered during the option period...!", "Ok", "tradeearlydelivery");
                 } else{
                     //validate outstanding amount
                     //var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);
                     
                    /* if(outstandingTrade[0].instrumentCode!='Forward' && outstandingTrade[0].eventTypeName=='Early Delivery'){
                         isValid = false;
                         MessageBox.openMessageBox("Alert", "The Early Delivery SPOT/TOM/CASH contract cannot be early delivered...!", "Ok", "tradeearlydelivery");
                     }else*/ 
                	 
                	 if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Order(s) is already pending for execution, the operation is not permitted...!", "Ok", "tradeearlydelivery");   
                    }
                     
                    var tradeSettlementDateForCancellation = getSettlementDateForContractCancellation( outstandingTrade[0]);
                    var cancellationLegInstrumentCode = getInstrumentCodeForSettlement(tradeSettlementDateForCancellation);
                    var cancellationLegSpread = findCancellationLegSpread(cancellationLegInstrumentCode, tradeSettlementDateForCancellation, findBuyySellIndicatorForCancellationLeg(outstandingTrade[0].buySellIndicator) );
                    
                    var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);
                    if(instrumentCode=='CASH'){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Contract cannot be early delivered as the contract entered into CASH instrument...!", "Ok", "tradeearlydelivery");
                    }else if(!validateCancellationLegSessionStatus('EARL', cancellationLegInstrumentCode)){
                    	 isValid = false;
                        MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "tradeearlydelivery");  
                    }else if(cancellationLegSpread == null || cancellationLegSpread == undefined){
                    	isValid = false;
                    	 MessageBox.openMessageBox("Alert", "Contract cannot be early delivered. Cancellation Leg Swap Point for "+ $filter('date')(tradeSettlementDateForCancellation,'dd-MMM-yyyy') +" is not provided by Relationship bank...!", "Ok", "tradeearlydelivery");
                         
                    }
                    
                    //validate spread 
                    // if(instrumentCode!='SPOT'){
                    //     var buySellIndicator = 'BUYY';
                    //     if(outstandingTrade[0].buySellIndicator=='BUYY'){
                    //         buySellIndicator = 'SELL';  
                    //     }
                    //     var spreadUsed = getSpreadForOutstandingTrade(outstandingTrade, instrumentCode);
                    //     if(spreadUsed==null || spreadUsed==undefined){
                    //         isValid = false;
                    //         throwExceptionForSpreadNotDefined(buySellIndicator, instrumentCode);
                    //     }
                    // }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "tradeearlydelivery");
            }
            return isValid;
        }
        /* this method is not in use */
        function getSpreadForOutstandingTrade(outstandingTrade, instrumentCode){
            var tickSize = gettickSizeForGivenInstrument(instrumentCode);
            var buySellIndicator = 'BUYY';
            if(outstandingTrade[0].buySellIndicator=='BUYY'){
                buySellIndicator = 'SELL';  
            }
            var bankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, outstandingTrade[0].bankId);
            var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(bankSpread, instrumentCode, buySellIndicator, 
                DateUtils.convertLocalDateToServer(angular.copy(outstandingTrade[0].tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                tickSize);
            return spreadUsed;
        }

        function throwExceptionForSpreadNotDefined(buySellIndicator, instrumentCode){
            if(buySellIndicator == 'BUYY'){
                if(instrumentCode=='Forward'){
                   MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD offer spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "traderollover"); 
                }else{
                   MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT bid spread is not given by the bank. Order entry not possible...!", "Ok", "traderollover");   
                }
             }else{
                if(instrumentCode=='Forward'){
                   MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD bid spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "traderollover"); 
                }else{
                   MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT offer spread is not given by the bank. Order entry not possible...!", "Ok", "traderollover");   
                }
             }
        }

        function prepareOrderDTOToEarlyDeliveryTrade(outstandingTradeObj){
            //on 18-Nov-2020. db writer null issue - intermediate variable introduced to prevent online changes impact on orderDTO.
            var outstandingTrade = angular.copy(outstandingTradeObj);
            //on 18-Nov-2020. filling of bank/branch/customer moved first due to issue found MDFY/CNCL order entry formation when user preferred bank is empty.
            vm.orderFromMarketWatch = false;
            
            //change bank, branch and customer controls from outstanding trade obj
            changeBankBranchCustomerControlsFromOutstandingTradeObj(outstandingTrade);

            $timeout(function() {
                vm.orderDTO.activityIndicator = 'EARL';
                vm.orderDTO.contractCancellation = true;
                //fill option period details, set option period details as null as it is cancel contract
                fillOptionPeriodDetails(outstandingTrade,false);
                //fill trade settlement date in control and get tenor for that settlement date
                //fill default trade rebooking date as trade settlement date - 1 day
                //vm.tradeSettlementDate = MarketWatch.addDaysToGivenDate(angular.copy(outstandingTrade.tradeSettlementDate), -1);
               //set tradeSettlementDate from outstanding trade settlement start date
                if(outstandingTrade.tradeSettlementDateFrom !=null){
                    vm.tradeSettlementDate = new Date(angular.copy(outstandingTrade.tradeSettlementDateFrom));
                }else{
                    vm.tradeSettlementDate = new Date(angular.copy(outstandingTrade.tradeSettlementDate));
                }
                vm.outstandtradedatefrom=angular.copy(outstandingTrade.tradeSettlementDateFrom);
              //  vm.tradeSettlementDate = new Date(angular.copy(outstandingTrade.tradeSettlementDate));
                vm.orderDTO.tradeSettlementDate = angular.copy(vm.tradeSettlementDate);
                

                //set maximum trade settlement date from outstanding trade settlement start date
                            
                if(outstandingTrade.tradeSettlementDateFrom !=null)
                vm.maximumTradeSettlementDate = MarketWatch.addDaysToGivenDate(angular.copy(outstandingTrade.tradeSettlementDateFrom), -1);
                else
                vm.maximumTradeSettlementDate = MarketWatch.addDaysToGivenDate(angular.copy(outstandingTrade.tradeSettlementDate), -1);  

                //cash date will be minimum trade settlement date to be given for early delvery 
                //if cash is holiday, then tom date will be minimum date, 
                //if tom is also holiday, then spot date will be minimum date
                if(!$rootScope.cashMarketSession.isSettlementDateHoliday){
                    vm.minimumTradeSettlementDate = new Date($rootScope.cashMarketSession.settlementDate);
                }else if(!$rootScope.tomMarketSession.isSettlementDateHoliday){
                    vm.minimumTradeSettlementDate = new Date($rootScope.tomMarketSession.settlementDate);
                }else{
                    vm.minimumTradeSettlementDate = new Date($rootScope.retailSpotMarketSession.settlementDate);
                }

                //get instrument for the selected trade settlement date
                vm.orderDTO.instrumentCode = getInstrumentCodeForSettlement(DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)));
                vm.defaultInstrumentCode = vm.orderDTO.instrumentCode;
                fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
                vm.orderDTO.instrumentId = vm.defaultInstrumentId;

                //fill source instrument code
                //get instrument for the orginal trade settlement date
                //vm.orderDTO.cancellationTradeSettlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
                vm.orderDTO.cancellationTradeSettlementDate = angular.copy(getSettlementDateForContractCancellation(outstandingTrade));
                vm.orderDTO.sourceInstrumentCode = outstandingTrade.instrumentCode;
                vm.orderDTO.sourceInstrumentId = getInstIdForInstCode(vm.orderDTO.sourceInstrumentCode);

                //fill buy/sell indicator, time condition and price condition
                vm.orderDTO.buySellIndicator = outstandingTrade.buySellIndicator;
                vm.defaultPriceCondition = 'LIMT';
                vm.orderDTO.typeOfOrder = vm.defaultPriceCondition;
                vm.defaultTimeCondition = 'IOCC';
                vm.orderDTO.timeLimitIndicator = vm.defaultTimeCondition;

                //change tenor control with rebooking trade settlement date
                //for ealry delivery there is no 
                changeTenor(vm.orderDTO.tradeSettlementDate,false);

                //fill event type
                var eventType = getEventTypeForShortName('EarlyDelivery');
                vm.orderDTO.eventTypeId = eventType.id;
                vm.orderDTO.eventType = eventType.shortName;

                //fill order request DTO from outstanding trade obj
                fillOrderDTOFromOutstandingTradeForEarlyDelivery(outstandingTrade);

                //set price condition, time condition, instrument and buy/sell indicator on controls
                setDefaultPriceCondition(vm.defaultPriceCondition);
                selectInstrumentInOrderEntry(vm.orderDTO.instrumentCode);
                selectTimeConditionInOrderEntry(vm.defaultTimeCondition);
                changeOrderType(vm.orderDTO.buySellIndicator, vm.orderDTO.activityIndicator);

				//fill indicative cancellation/spot/rebooking rates and forward swap point
                
                $rootScope.orderDTO = vm.orderDTO;
            }, 300);
        }

        vm.prepareOrderDTOFromMarketWatch = prepareOrderDTOFromMarketWatch;
        function prepareOrderDTOFromMarketWatch(buySellFlag, instrumentType, limitExcahngeRate, settlementDate, sessionStatus){
            if(limitExcahngeRate!=null && limitExcahngeRate!=undefined && limitExcahngeRate!='' && limitExcahngeRate>0 && sessionStatus=='OPEN'){
                vm.orderFromMarketWatch = false;
                vm.errorString = '';
                //vm.orderDTO = {};
                vm.orderDTO.typeOfOrder = 'LIMT';
                vm.orderDTO.activityIndicator = 'PLAC';
                vm.orderDTO.instrumentCode = instrumentType;
                vm.orderDTO.buySellIndicator = buySellFlag;
                var orderVolume = vm.userPreference.userOrderValue;
                vm.orderDTO.orderAmount = parseFloat(orderVolume.replace(/,/g, ''));
                fillDefaultInstIdForInstCode(instrumentType);
                //vm.orderDTO.instrumentId = fillDefaultInstIdForInstCode(instrumentType);
                vm.orderDTO.timeLimitIndicator = 'DAYY';  //'IOCC';
                vm.orderDTO.expiryTime = 0;

                // fill bank, branch, customer details into order request dto
                vm.orderDTO = fillBankBranchCustomerDetailsFromMktWatch(vm.orderDTO)
                
                if(vm.account.organisation.organisationType.shortName!='CUSTOMER'){
                    if(vm.defaultTradingCustomerControl!=null && vm.defaultTradingCustomerControl!=undefined){
                        vm.orderDTO.customerId = vm.defaultTradingCustomerControl.id;
                        vm.orderDTO.customerName = vm.defaultTradingCustomerControl.customerFirstName;
                        vm.orderDTO.customerCode = vm.defaultTradingCustomerControl.customerCode;
                    }else{
                        // MessageBox.openMessageBox("Alert", "Please select the
                        // trading customer to proceed.", "Ok",
                        // "selectdefaultcustomer");
                    }
                }
                
                // vm.orderDTO.customerName =
                // vm.globalValues.getCustomer().customerFirstName;
                vm.orderDTO.userId = vm.globalValues.getUserId();
                vm.orderDTO.retailUserCode = vm.globalValues.getUser().login;
                vm.orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
                vm.orderDTO.orderSequenceNumber = 0;
                vm.orderDTO.revisionTimeStamp = 0;
                vm.orderDTO.sourceOrderSequenceNumber = 0;
                vm.orderDTO.sourceTradeSequenceNumber = 0;
                vm.orderDTO.contractCancellation = false;
                var valuelimitExchangeRate = angular.copy(vm.orderDTO.limitExchangeRate);
                var valueStopExchangeRate = angular.copy(vm.orderDTO.stopExchangeRate);
                vm.orderDTO.limitExchangeRate = angular.copy(limitExcahngeRate);
                vm.orderDTO.limitExchangeRate = $filter('number')(vm.orderDTO.limitExchangeRate, vm.noOfDecimalsForRate);
                vm.orderDTO.stopExchangeRate = 0;
                vm.orderDTO.stopExchangeRate = 0;

                vm.defaultPriceCondition = 'LIMT';

                setDefaultPriceCondition(vm.defaultPriceCondition);
                // changeInstrument(pendingOrder.instrumentCode);
                selectInstrumentInOrderEntry(vm.orderDTO.instrumentCode);
                selectTimeConditionInOrderEntry(vm.orderDTO.timeLimitIndicator);
                changeOrderType(vm.orderDTO.buySellIndicator, vm.orderDTO.activityIndicator);
                if((vm.tradingBank!=null && vm.tradingBank!=undefined && vm.tradingBank.id != vm.orderDTO.bankId) || (vm.tradingBank==null || vm.tradingBank==undefined)){
                    changeTradingBank(vm.orderDTO.bankId);
                }

                if((vm.tradingBranch!=null && vm.tradingBranch!=undefined && vm.tradingBranch.id != vm.orderDTO.branchId) || (vm.tradingBranch==null || vm.tradingBranch==undefined)){
                    changeTradingBranch(vm.orderDTO.branchId);
                }

                if((vm.tradingCustomer!=null && vm.tradingCustomer!=undefined && vm.tradingCustomer.id != vm.orderDTO.customerId) || (vm.tradingCustomer==null || vm.tradingCustomer==undefined)){
                    $timeout(function(){
                        changeTradingCustomer(vm.orderDTO.customerId);
                    },100)
                }

                if(instrumentType=='Forward'){
                	//from market watch the forward cannot be option period initially
                    changeTenor(settlementDate,false);
                    vm.tradeSettlementDate = new Date(settlementDate);
                    //set exposure type properies and fill values in lookup control
                    setExposureTypeFromAllExposureLookup(vm.userPreference.userExposureType);
                    setExposureSubTypeFromAllSubExposureLookup(vm.userPreference.userExposureSubType);
                    vm.orderDTO.exposureTypeId = vm.exposureType.id;
                    vm.orderDTO.exposureSubTypeId = vm.exposureSubType.id;
                    vm.orderDTO.exposureTypeName = vm.exposureType.name;
                    vm.orderDTO.exposureSubTypeName = vm.exposureSubType.name;

                }
                //fill option period details, set option period details as null as it is order from market watch
                fillOptionPeriodDetails(null,false);
                setTimeout(function() {
                    vm.orderFromMarketWatch = true;
                }, 20);
            }
        }

        vm.prepareOrderDTOFromOutrights = prepareOrderDTOFromOutrights;
        function prepareOrderDTOFromOutrights(bank, buySellFlag, instrumentType, limitExcahngeRate, settlementDate, sessionStatus){
            if(vm.tradingBank!=null && vm.tradingBank!=undefined && vm.tradingBank.id!=bank.id){
                //CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: bank.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                    var result = _.filter(angular.copy($rootScope.tradingRelationsForCustomer), function(item){return item.bank.id==bank.id;});
                    vm.tradingBank = bank;
                    vm.tradingBranch = result[0].tradingBranch;
                    //prepare order entry screen from outright as orderEntry from market watch
                    prepareOrderDTOFromMarketWatch(buySellFlag, instrumentType, limitExcahngeRate, settlementDate, sessionStatus);
                //})
            }else{
                //prepare order entry screen from outright as orderEntry from market watch
                prepareOrderDTOFromMarketWatch(buySellFlag, instrumentType, limitExcahngeRate, settlementDate, sessionStatus);
            }
        }

        function getEventTypeForShortName(eventTypeShortName){
            var eventType = {};
            angular.forEach($rootScope.eventTypes, function(value){
                if(value.shortName==eventTypeShortName){
                    eventType = value;
                }
            })
            return eventType;
        }

        // re-assing default values to controls
        function cancelModifyOrderAction(){
        	//vm.remarks = "";
            setDefaultRemark();
			defaultOrderEntry();
        	// remove foucs on order entry screen
        	removeOrderEntryFocus();
        	clearNumericControls();
        	// setLTPPriceAsDefaultValueForLimitOrder();
        }
        
        function getCustomerId(){
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
            	return vm.defaultTradingCustomer.id;
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
            	return vm.defaultTradingCustomer.id;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		vm.globalValues.getCustomer().id
        	}
        }
       
       
        function fillOptionPeriodDetailsForCancelOrder(poOrOsTradeObj, optionPeriodCheckRequired , orderDTO){
        	if(poOrOsTradeObj != null && poOrOsTradeObj !=undefined && optionPeriodCheckRequired == true ){
        		if(orderDTO.instrumentCode=='Forward' && poOrOsTradeObj.isOptionPeriod == true ){
        			orderDTO.isOptionPeriod = true;                    	
        			orderDTO.tradeSettlementDateFrom = poOrOsTradeObj.tradeSettlementDateFrom;
        			
                }
        		else{
        			orderDTO.isOptionPeriod = false;
        			orderDTO.tradeSettlementDateFrom = null;
        			
               }
        	}else{
        		orderDTO.isOptionPeriod = false;
        		orderDTO.tradeSettlementDateFrom = null;
        		
        	}
        }
        
        function fillOptionPeriodDetails(poOrOsTradeObj, optionPeriodCheckRequired  ){
        	if(poOrOsTradeObj != null && poOrOsTradeObj !=undefined && optionPeriodCheckRequired == true ){
        		if(vm.orderDTO.instrumentCode=='Forward' && poOrOsTradeObj.isOptionPeriod == true ){
        			vm.orderDTO.isOptionPeriod = true;                    	
        			vm.orderDTO.tradeSettlementDateFrom = poOrOsTradeObj.tradeSettlementDateFrom;
        			vm.tradeSettlementDateFrom = new Date(poOrOsTradeObj.tradeSettlementDateFrom);
                }
        		else{
        			vm.orderDTO.isOptionPeriod = false;
        			vm.orderDTO.tradeSettlementDateFrom = null;
        			//vm.tradeSettlementDateFrom = null;
               }
        	}else{
        		vm.orderDTO.isOptionPeriod = false;
        		vm.orderDTO.tradeSettlementDateFrom = null;
        		//vm.tradeSettlementDateFrom = null;
        	}
        }
        
        // prepare orderDTO to modify/cancel order from pending order details
        function prepareOrderDTO(pendingOrderObj, activityIndicator){
            //on 18-Nov-2020. db writer null issue - intermediate variable introduced to prevent online changes impact on orderDTO.
            var pendingOrder = angular.copy(pendingOrderObj);
            //on 18-Nov-2020. filling of bank/branch/customer moved first due to issue found MDFY/CNCL order entry formation when user preferred bank is empty.
            if((vm.tradingBank!=null && vm.tradingBank!=undefined && vm.tradingBank.id != pendingOrder.bankId) || (vm.tradingBank==null || vm.tradingBank==undefined)){
                changeTradingBank(pendingOrder.bankId);
            }

            if((vm.tradingBranch!=null && vm.tradingBranch!=undefined && vm.tradingBranch.id != pendingOrder.branchId) || (vm.tradingBranch==null || vm.tradingBranch==undefined)){
                $timeout(function(){
                    changeTradingBranch(pendingOrder.branchId);    
                },100)
            }

            changeTenor(pendingOrder.tradeSettlementDate,pendingOrder.isOptionPeriod );
            setExposureTypeFromEnabledExposureLookup(pendingOrder.exposureTypeName);
            setExposureSubTypeFromEnabledSubExposureLookup(pendingOrder.exposureSubTypeName);

            if((vm.tradingCustomer!=null && vm.tradingCustomer!=undefined && vm.tradingCustomer.id != pendingOrder.customerId) || (vm.tradingCustomer==null || vm.tradingCustomer==undefined)){
                $timeout(function(){
                    changeTradingCustomer(pendingOrder.customerId);
                },100)
            }

            vm.orderFromMarketWatch = false;
            $rootScope.outstandingQuantity = 0;
            //vm.orderDTO = {};

            $timeout(function(){
                vm.orderDTO.contractCancellation = false;
                vm.orderDTO.activityIndicator = activityIndicator;
                vm.orderDTO.orderAmount = pendingOrder.pendingOrderAmount;
                vm.orderDTO.typeOfOrder = pendingOrder.typeOfOrder;
                vm.orderDTO.instrumentCode = pendingOrder.instrumentCode;
                vm.orderDTO.buySellIndicator = pendingOrder.buySellInd;
                vm.orderDTO.timeLimitIndicator = pendingOrder.timeLimitIndicator;
               
                if(vm.orderDTO.timeLimitIndicator=='GTTM'){
                    vm.orderDTO.expiryTime = pendingOrder.expiryTime;
                    vm.orderDTO.expiryTimeString = pendingOrder.expiryTimeString;
                }
                if(vm.orderDTO.instrumentCode=='Forward'){
                    vm.tradeSettlementDate = new Date(pendingOrder.tradeSettlementDate);
                    vm.orderDTO.tradeSettlementDate = pendingOrder.tradeSettlementDate;
                    //set exposure type properies
                    vm.orderDTO.exposureTypeId = pendingOrder.exposureTypeId;
                    vm.orderDTO.exposureSubTypeId = pendingOrder.exposureSubTypeId;
                    vm.orderDTO.exposureTypeName = pendingOrder.exposureTypeName;
                    vm.orderDTO.exposureSubTypeName = pendingOrder.exposureSubTypeName;                    
                }
                //fill option period details
                fillOptionPeriodDetails(pendingOrder,true);
                vm.orderDTO.instrumentId = pendingOrder.retailOrderInstID;
                vm.orderDTO.branchCode = pendingOrder.branchCode;
                vm.orderDTO.branchId = pendingOrder.branchId;
                vm.orderDTO.branchName = pendingOrder.branchName;
                vm.orderDTO.bankId = pendingOrder.bankId;
                vm.orderDTO.bankName = pendingOrder.bankName;
                vm.orderDTO.bankMemberNumber = pendingOrder.bankMemberNumber;
                vm.orderDTO.customerId = pendingOrder.customerId;
                vm.orderDTO.customerCode = pendingOrder.customerCode;
                vm.orderDTO.customerName = pendingOrder.customerName;
                vm.orderDTO.limitExchangeRate = pendingOrder.limitExchangeRate;
                vm.orderDTO.stopExchangeRate = pendingOrder.stopExchangeRate;
                vm.orderDTO.orderSequenceNumber = pendingOrder.orderSequenceNumber;
                vm.orderDTO.sourceTradeSequenceNumber = pendingOrder.sourceTradeSequenceNumber;
                vm.orderDTO.sourceOrderSequenceNumber = pendingOrder.sourceOrderSequenceNumber;
                vm.orderDTO.sourceInstrumentId = pendingOrder.sourceInstrumentId;
                vm.orderDTO.sourceInstrumentCode = pendingOrder.sourceInstrumentCode;
                vm.orderDTO.oldOrderAmount = pendingOrder.oldOrderAmount;
                vm.orderDTO.isSplitOrder=pendingOrder.isSplitOrder;
                vm.regularAndSplitOrder='Regular';
                vm.noOfSplits='';
               
                if(activityIndicator!='PLAC'){
                    vm.orderDTO.revisionTimeStamp = pendingOrder.revisiontTimestampString;
                    vm.orderDTO.orderStatusId = pendingOrder.orderStatusId;
                    vm.orderDTO.eventTypeId = pendingOrder.eventTypeId;
                    vm.orderDTO.eventType = pendingOrder.eventTypeName;
                    if(vm.orderDTO.eventType=='ContractCancellation' || vm.orderDTO.eventType=='Contract Cancellation'){
                        vm.orderDTO.contractCancellation = true;
                        vm.orderDTO.oldOrderAmount = 0;
                        getOutstandingQuantityFromOrderServer(pendingOrder);
                    }
                    vm.orderDTO.settlementLegId = pendingOrder.settlementLegId;
                }
               
                if(pendingOrder.typeOfOrder=='MRKT'){
                    vm.orderDTO.typeOfOrder = 'LIMT';
                    vm.defaultPriceCondition = 'LIMT';
                    vm.orderDTO.limitExchangeRate = getDefaultLimitPriceForModifyingMarketOrder(pendingOrder.instrumentCode, pendingOrder.buySellInd);
                }
               // vm.remarks = pendingOrder.remarks;
                vm.remarksDTO.remarks1 = pendingOrder.remarks;
                vm.remarksDTO.remarks2 = pendingOrder.remarks2;
                vm.remarksDTO.remarks3 = pendingOrder.remarks3;
                vm.remarksDTO.remarks4 = pendingOrder.remarks4;

                setRemark();
                setDefaultPriceCondition(pendingOrder.typeOfOrder);
                // changeInstrument(pendingOrder.instrumentCode);
                selectInstrumentInOrderEntry(pendingOrder.instrumentCode);
                selectTimeConditionInOrderEntry(pendingOrder.timeLimitIndicator);
                changeOrderType(pendingOrder.buySellInd, vm.orderDTO.activityIndicator);
                
                //changeFocusToOrderEntry(pendingOrder.typeOfOrder);
                $rootScope.orderDTO = vm.orderDTO;
            },300)
        }

        function changeFocusToOrderEntry(priceCondition){
            if(priceCondition=='LIMT'){
                setTimeout(function(){document.getElementById('txtlimitorderprice1').focus()},10);
            }else if(priceCondition=='STLS'){
                setTimeout(function(){document.getElementById('txtsllimitprice1').focus()},10);
            }else if(priceCondition=='TKPR'){
                setTimeout(function(){document.getElementById('txttplimitprice1').focus()},10);
            }
        }

        function getOutstandingQuantityFromOrderServer(pendingOrder){
            $rootScope.outstandingQuantity = 0;
            LimitSettings.getOutstandingQuantityForPendingOrder(pendingOrder, function(result1){
                $rootScope.outstandingQuantity = result1.outstandingQuantity;
                vm.orderDTO.oldOrderAmount = $rootScope.outstandingQuantity;
            });
        }

        function onGetOutstandingQuantitySuccess(result){
            $rootScope.outstandingQuantity = result;
            console.log(result);
        }

        function onGetOutstandingQuantityFailure(){

        }
        
        function getDefaultLimitPriceForModifyingMarketOrder(instrumentCode, buySellFlag){
    		if(buySellFlag=='BUYY'){
            	if(instrumentCode=='SPOT'){
            		return $rootScope.retailSpotMarketWatch.bidPrice;
            	}else if(instrumentCode=='CASH'){
            		return $rootScope.cashMarketWatch.bidPrice;
            	}else if(instrumentCode=='TOM'){
            		return $rootScope.tomMarketWatch.bidPrice;
            	}else if(instrumentCode=='Forward'){
                    return $rootScope.forwardMarketWatch.bidPrice;
                }
    			
    		}else if(buySellFlag=='SELL'){
    			if(instrumentCode=='SPOT'){
            		return $rootScope.retailSpotMarketWatch.offerPrice;
            	}else if(instrumentCode=='CASH'){
            		return $rootScope.cashMarketWatch.offerPrice;
            	}else if(instrumentCode=='TOM'){
            		return $rootScope.tomMarketWatch.offerPrice;
            	}else if(instrumentCode=='Forward'){
                    return $rootScope.forwardMarketWatch.offerPrice;
                }
    		}
        	
        }
        
        function prepareOrderDTOToCancel(pendingOrderObj){
            //on 18-Nov-2020. db writer null issue - intermediate variable introduced to prevent online changes impact on orderDTO.
            var pendingOrder = angular.copy(pendingOrderObj);
            vm.orderFromMarketWatch = false;
        	var orderDTO = {};
        	orderDTO.activityIndicator = 'CNCL';
        	orderDTO.orderAmount = pendingOrder.pendingOrderAmount;
            orderDTO.originalOrderAmount = pendingOrder.orderAmount;
            orderDTO.tradedAmount = pendingOrder.tradedAmount;
        	orderDTO.typeOfOrder = pendingOrder.typeOfOrder;
        	orderDTO.instrumentCode = pendingOrder.instrumentCode;
        	orderDTO.instrumentId = pendingOrder.retailOrderInstID;
        	orderDTO.buySellIndicator = pendingOrder.buySellInd;
        	orderDTO.timeLimitIndicator = pendingOrder.timeLimitIndicator;
            orderDTO.orderStatus = pendingOrder.orderStatus;
            orderDTO.isSplitOrder=pendingOrder.isSplitOrder;
        	if(orderDTO.timeLimitIndicator=='GTTM'){
            	// orderDTO.expiryTime = pendingOrder.expiryTime;
        		/*
				 * var localDateFromServer =
				 * DateUtils.convertDateTimeFromServer(
				 * pendingOrder.expiryTime); orderDTO.expiryTime =
				 * moment(localDateFromServer,'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss') +
				 * '00';
				 */
        		orderDTO.expiryTime = pendingOrder.expiryTime;
        		orderDTO.expiryTimeString = pendingOrder.expiryTimeString;
        	}else{
        		orderDTO.expiryTime = 0;
        		orderDTO.expiryTimeString = "";
        	}
        	orderDTO.branchCode = pendingOrder.branchCode;
        	orderDTO.branchName = pendingOrder.branchName;
        	orderDTO.branchId = pendingOrder.branchId;
        	orderDTO.bankId = pendingOrder.bankId;
        	orderDTO.bankMemberNumber = pendingOrder.bankMemberNumber;
        	orderDTO.bankName = pendingOrder.bankName;
        	orderDTO.customerId = pendingOrder.customerId;
        	orderDTO.customerName = pendingOrder.customerName;
        	orderDTO.customerCode = pendingOrder.customerCode;
            orderDTO.spotLimitPrice = pendingOrder.spotlimitExchangeRate;
        	orderDTO.limitExchangeRate = pendingOrder.limitExchangeRate;
        	orderDTO.stopExchangeRate = pendingOrder.stopExchangeRate;
        	orderDTO.orderSequenceNumber = pendingOrder.orderSequenceNumber;
        	orderDTO.userId = vm.globalValues.getUserId();
        	orderDTO.retailUserCode = vm.globalValues.getUser().login;
        	orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
            orderDTO.eventTypeId = pendingOrder.eventTypeId;
            orderDTO.eventType = pendingOrder.eventTypeName;
            orderDTO.settlementLegId = pendingOrder.settlementLegId;
            orderDTO.sourceOrderSequenceNumber = pendingOrder.sourceOrderSequenceNumber;
            orderDTO.sourceTradeSequenceNumber = pendingOrder.sourceTradeSequenceNumber;
            orderDTO.sourceInstrumentId = pendingOrder.sourceInstrumentId;
            orderDTO.sourceInstrumentCode = pendingOrder.sourceInstrumentCode;
            orderDTO.tradeSettlementDate =  DateUtils.convertLocalDateToServer(angular.copy(pendingOrder.tradeSettlementDate));
            if(orderDTO.instrumentCode=='Forward'){
                //set exposure type properies
                orderDTO.exposureTypeId = pendingOrder.exposureTypeId;
                orderDTO.exposureSubTypeId = pendingOrder.exposureSubTypeId;
                orderDTO.exposureTypeName = pendingOrder.exposureTypeName;
                orderDTO.exposureSubTypeName = pendingOrder.exposureSubTypeName;
            }
            //fill option period details - 
            fillOptionPeriodDetailsForCancelOrder(pendingOrder,true, orderDTO);
        	console.log('prepare order to cancel');
        	console.log(vm.tradingBank);
        	var selectedBankSpread = null;
            if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=undefined
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, pendingOrder.bankId);

            }
            //var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, pendingOrder.bankId);
            if(orderDTO.instrumentCode!='SPOT'){
                var tickSize = gettickSizeForGivenInstrument(pendingOrder.instrumentCode);
                orderDTO.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(selectedBankSpread, pendingOrder.instrumentCode, pendingOrder.buySellInd, 
                    DateUtils.convertLocalDateToServer(angular.copy(orderDTO.tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                    tickSize);
            }
        	//orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, pendingOrder.buySellInd, pendingOrder.instrumentCode);

        	orderDTO.revisionTimeStamp = pendingOrder.revisiontTimestampString;        	
        	return orderDTO;
        }
        
        function changeTradingBank(bankId){
            if((vm.tradingBank!=null && vm.tradingBank!=undefined && vm.tradingBank.id!=bankId) || (vm.tradingBank==null || vm.tradingBank==undefined)){
                BankRegistration.get({id: bankId}, function(result){
                    vm.tradingBank = result;
                })
            }
        }
        
        function changeTradingCustomer(customerId){
        	CustomerRegistration.getCustomer({id: customerId}, function(result){
        		vm.tradingCustomer = result;
        	})
        }
        
        function changeTradingBranch(branchId){
        	Branch.get({id: branchId}, function(result){
        		vm.tradingBranch = result;
        	})
        }

      /*  old method , upgraded below for option period
       function changeTenor(tradeSettlementDate){
            vm.tenor = null;
            angular.forEach(vm.tenorsLookup, function(value){
            	//2414 - mantis issue fix, tenor name is broken date always even if the tradesettlement date is end point, this occurs for rollover and earlydelivery
            	// this method is invoked in pending order modification, cancelTrade , early delivery, rollover, order form loading from user  market watch
                if(value.settlementDate==DateUtils.convertLocalDateToServer(tradeSettlementDate)){
                    vm.tenor = value;
                }
            })
            if(vm.tenor==null || vm.tenor == undefined){
                vm.tenor = vm.tenorsLookup[0];
            }
        }*/
        /* if optios period is true then tenor is shown as Option period and settlement date will be empty if plac order else settlement dates from and to taken from pending order */ 
        function changeTenor(tradeSettlementDate,isOptionPeriod){
            vm.tenor = null;
            if(isOptionPeriod == false || isOptionPeriod == undefined || isOptionPeriod == null ){
	            angular.forEach(vm.tenorsLookup, function(value){
	            	//2414 - mantis issue fix, tenor name is broken date always even if the tradesettlement date is end point, this occurs for rollover and earlydelivery
	            	// this method is invoked in pending order modification, cancelTrade , early delivery, rollover, order form loading from user  market watch
	                if(value.settlementDate==DateUtils.convertLocalDateToServer(tradeSettlementDate)){
	                    vm.tenor = value;
	                }
	            })
	            if(vm.tenor==null || vm.tenor == undefined){
	                vm.tenor = vm.tenorsLookup[0];
	            }
            }else{
            	var filteredTenor =  $filter('filter')(vm.tenorsLookup, {tenorName: 'Option Period'});
            	if(filteredTenor !=null && filteredTenor != undefined )
            		vm.tenor = filteredTenor[0];
            }
            $rootScope.tenor = vm.tenor;
        }
        
        function getInstrumentCodeForSettlement(tradeSettlementDate){
            var tenor = null;
            angular.forEach(vm.tenors, function(value){
                if(value.settlementDate==tradeSettlementDate){
                    tenor = value;
                }
            })
            if(tenor==null || tenor == undefined){
                if(tradeSettlementDate==$rootScope.retailSpotMarketSession.settlementDate){
                    return 'SPOT';
                }else if(tradeSettlementDate==$rootScope.cashMarketSession.settlementDate){
                    return 'CASH';
                }else if(tradeSettlementDate==$rootScope.tomMarketSession.settlementDate){
                    return 'TOM';
                }else {
                    tenor = vm.tenors[0];
                    return 'Forward';
                }
            }else{
                return 'Forward';
            }
        }

        // to cancel order
        function cancelOrder(pendingOrder){
        	// prepareOrderDTO(pendingOrder, 'CNCL');
        	//vm.remarks = "";
            setDefaultRemark();
        	var orderDTO = {};
        	orderDTO = prepareOrderDTOToCancel(pendingOrder);
        	
        	orderDTO.userId = vm.globalValues.getUserId();
        	orderDTO.retailUserCode = vm.globalValues.getUser().login;
        	orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
        	
        	if(vm.userPreference.showPreOrderConfirmation=='yes' ||  vm.globalValues.getSessionTimedOut()){
            	$rootScope.orderDTO = orderDTO;
            	var closeTime = getCloseTimeForInstrumentCode(orderDTO.instrumentCode);
                //to remove focus from delete icon in pending orders report before pre-order confirmation.
                //after double enter on pre-order confirmation, the focus can be removed
                //code added on 07-Sep-2020 for double enter issue fix. 
            	setTimeout(function(){document.getElementById('ibspotMktSell').focus()},10);
            	$state.go('pre-order-confirmation', {closeTime: closeTime, showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation, sessionTimedOut: vm.globalValues.getSessionTimedOut()});
        	}else{
                //on 18-Nov-2020. db writer null issue - added call back procedures while sending orders
				if(OrderUtil.validateOrderDTOForNull(orderDTO)){
					Market.cancelOrder(orderDTO, onOrderCancelSuccess, onOrderCancelFailure);
				}else{
					MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");	
				}
    			//defaultOrderEntry();
    			// clear numeric controls
            	//clearNumericControls();
            	// setLTPPriceAsDefaultValueForLimitOrder();
        	}
        }

        function onOrderCancelSuccess(result){
            defaultOrderEntry();
            // clear numeric controls
            clearNumericControls();
        }

        function onOrderCancelFailure(){
            defaultOrderEntry();
            // clear numeric controls
            clearNumericControls();
        }

       /*
		 * $scope.$watch('$rootScope.selectAll', function(value){
		 * if(value==true){ angular.forEach($rootScope.pendingOrders,
		 * function(value, index){ if(value.orderStatus!="Risk Validated"){
		 * $rootScope.pendingOrders[index].select = true; } }) }else{
		 * angular.forEach($rootScope.pendingOrders, function(value, index){
		 * $rootScope.pendingOrders[index].select = false; }) } })
		 */
        
        /*function checkOrUncheckOutstandingTrades(event){
        	
        	changeOutstandingTradesSelection(event.target.checked);
               
        }*/
        function checkOrUncheckPendingOrders(event){
        	
        	changePendingOrderItemSelection(event.target.checked);
               
        }
        /*function changeOutstandingTradesSelection(value){
        	if(value){
        		angular.forEach($rootScope.outstandingTrades, function(value, index){    			
        				$rootScope.pendingOrders[index].select = true;
        		});
    		}
        }*/
        function changePendingOrderItemSelection(value){
    	 	if(value){
        		angular.forEach($rootScope.pendingOrders, function(value, index){
        			if(value.orderStatus!="Risk Validated"){
        				$rootScope.pendingOrders[index].select = true;
        			}
        		});
    		}else{
    			angular.forEach($rootScope.pendingOrders, function(value, index){
    				$rootScope.pendingOrders[index].select = false;
    			});
    		}
        }
        
        vm.checkOrUncheckOutstandingTradesItem= checkOrUncheckOutstandingTradesItem;
        function checkOrUncheckOutstandingTradesItem(outstandingValue, event){
            var selectedValue = event.target.checked;
            angular.forEach($rootScope.outstandingTrades, function(value, index){                
                    value.select = false;                
            })
            //$rootScope.outstandingTradesMemory = $rootScope.outstandingTrades;
            outstandingValue.select = selectedValue;
            // if(selectedValue==true && outstandingValue.instrumentCode!='Forward'){
            //     outstandingValue.select = false;
            //     MessageBox.openMessageBox("Alert", outstandingValue.instrumentCode + " contract cannot be selected for cancellation...!", "Ok", "selectedorders");
            // }
        }

        function checkOrUncheckPendingOrderItem(){
        	var allSelected = true;
        
        	angular.forEach($rootScope.pendingOrders, function(value, index){
        		if (!$rootScope.pendingOrders[index].select){
        			allSelected = false
        		}
			});
        	
        	if(allSelected){
        		vm.selectAll= true;
        		// if(document.getElementById("selectAll")!=null ||
				// document.getElementById("selectAll")!=undefined)
        		document.getElementById("selectAll").checked = true;
        	}else{
        		vm.selectAll= false;
        		// if(document.getElementById("selectAll")!=null ||
				// document.getElementById("selectAll")!=undefined)
        		document.getElementById("selectAll").checked = false;
        	}
        }
        
        $scope.$watch('vm.selectAll', function(value){
            $rootScope.selectAll = value;
        })

        $scope.$watch('vm.reportTab', function(value){
            $rootScope.reportTab = vm.reportTab;
        })

        function cancelOutStandingTrade(){

        	$rootScope.selectedTradesOutstanding = [];
        	$rootScope.selectedTradesOutstanding = $filter('filter')($rootScope.outstandingTrades, {select: true});
        	if($rootScope.outstandingTrades.length>0){            	
        		if(vm.globalValues.getSessionTimedOut()){
                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: 'no', sessionTimedOut: vm.globalValues.getSessionTimedOut(), cancelSelected: true});
        		}else{
                	MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel selected Outstanding Trade?", "ProceedCancel", "cancelselectedoutstandingtrades");
        		}
        	}else{
            	MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "selectedorders");
        	}
        }
        function cancelPendingOrdersSelected(){
        	var ordersSelected = false;
        	$rootScope.selectedPendingOrders = [];
        	$rootScope.selectedPendingOrders = $filter('filter')($rootScope.pendingOrders, {select: true});
        	if($rootScope.selectedPendingOrders.length>0){
            	// $scope.pendingOrders = $rootScope.pendingOrders;
        		if(vm.globalValues.getSessionTimedOut()){
                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: 'no', sessionTimedOut: vm.globalValues.getSessionTimedOut(), cancelSelected: true});
        		}else{
                	MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel selected order(s)?", "ProceedCancel", "cancelselectedorders");
        		}
        	}else{
            	MessageBox.openMessageBox("Alert", "Please select the order(s) to proceed.", "Ok", "selectedorders");
        	}
        }

        function cancelAllOrders(){
            // vm.selectAll = true;
            // angular.forEach($rootScope.pendingOrders, function(value, index){
            //     if(value.orderStatus!="Risk Validated"){
            //         $rootScope.pendingOrders[index].select = true;
            //     }
            // });
        //	$rootScope.selectedPendingOrders = [];
        //	$rootScope.selectedPendingOrders = $rootScope.pendingOrders;
        //	if($rootScope.selectedPendingOrders.length>0){
            	// $scope.pendingOrders = $rootScope.pendingOrders;
        		if(vm.globalValues.getSessionTimedOut()){
                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: 'no', sessionTimedOut: vm.globalValues.getSessionTimedOut(), cancelSelected: true});
        		}else{
                    $rootScope.proceedCancelAllOrders=0;
                	MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel all order(s)?", "ProceedCancel", "cancelallorders");
        		}
        //	} else{
            // 	MessageBox.openMessageBox("Alert", "Please select the order(s) to proceed.", "Ok", "allorders");
        	// }
        }

        function cancelAllPendingOrders(){
            // if($rootScope.selectedPendingOrders.length>0){
            //     var orderDTOList = [];
            //     angular.forEach($rootScope.selectedPendingOrders, function(value, index){
            //         if(value){
            //             var orderDTO = {};
            //             orderDTO = prepareOrderDTOToCancel(value);
            //             orderDTOList.push(orderDTO);
            //             // place cancel order
            //         }
            //     })
            //     var orderRequestListDTO = {};
            //     orderRequestListDTO.orderRequestList = orderDTOList;
                if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
                    Market.cancelAllPendingOrdersForClearCorp(onCancelSelectedOrdersSuccess, onCancelSelectedOrdersFailure);
                }else{
                    Market.cancelOrderListWithSingleObject(orderRequestListDTO, onCancelSelectedOrdersSuccess, onCancelSelectedOrdersFailure);
                }
                vm.selectAll = false;
                $rootScope.selectAll = false;
                $rootScope.selectedPendingOrders = [];
                defaultOrderEntry();            
                // remove foucs on order entry screen
                removeOrderEntryFocus();
                // clear numeric controls
                clearNumericControls();
          //  }
        }
        function cancelSelectedOrders(){
            if($rootScope.selectedPendingOrders.length>0){
                var orderDTOList = [];
                angular.forEach($rootScope.selectedPendingOrders, function(value, index){
                    if(value.select){
                        var orderDTO = {};
                        orderDTO = prepareOrderDTOToCancel(value);
                        orderDTOList.push(orderDTO);
                        // place cancel order
                    }
                })
                var orderRequestListDTO = {};
                orderRequestListDTO.orderRequestList = orderDTOList;
                if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
                    Market.cancelOrderListWithSingleObjectForClearCorp(orderRequestListDTO, onCancelSelectedOrdersSuccess, onCancelSelectedOrdersFailure);
                }else{
                    Market.cancelOrderListWithSingleObject(orderRequestListDTO, onCancelSelectedOrdersSuccess, onCancelSelectedOrdersFailure);
                }
                vm.selectAll = false;
                $rootScope.selectAll = false;
                $rootScope.selectedPendingOrders = [];
                defaultOrderEntry();            
                // remove foucs on order entry screen
                removeOrderEntryFocus();
                // clear numeric controls
                clearNumericControls();
            }
        }
        function onCancelSelectedOrdersSuccess(result){
            resetPendingOrderCheckboxSelection(false);
            if(vm.account.organisation.organisationType.shortName=='CLEARCORP' && result.clientId=="Assigned"){
                MessageBox.openMessageBox("Alert", "A task has been submitted for approval", "Ok", "cancelorders")
            }
           
        }

        function onCancelSelectedOrdersFailure(){
            //do nothing
        }
        
        $rootScope.$on('gatewayApp.messagebox.proceed.cancelselectedoutstandingtrades', function (event, data) {
            if($rootScope.selectedTradesOutstanding.length>0){
                var selectedTrades = angular.copy($rootScope.selectedTradesOutstanding);
                cancelTrade(selectedTrades);          
                $rootScope.selectedTradesOutstanding = [];
            }
        })
        
		$rootScope.$on('gatewayApp.messagebox.proceed.cancelselectedorders', function (event, data) {
            if(document.getElementById('btnCancelSelected')!=null){
                setTimeout(function(){document.getElementById('btnCancelSelected').blur()});    
            }
            cancelSelectedOrders();
    	});
		
        $rootScope.$on('gatewayApp.messagebox.ok.selectedorders', function (event, data) {
            if(document.getElementById('btnCancelSelected')!=null){
                setTimeout(function(){document.getElementById('btnCancelSelected').blur()});    
            }
        });
        
		$rootScope.$on('gatewayApp.messagebox.cancel.cancelselectedorders', function (event, data) {
			// do not proceed
        	defaultOrderEntry();			
        	// clear numeric controls
        	clearNumericControls();
		});

        var proceedCancelAllOrders = $rootScope.$on('gatewayApp.messagebox.proceed.cancelallorders', function (event, data) {
            if($rootScope.proceedCancelAllOrders>0){
                if(document.getElementById('btnCancelAll')!=null){
                    setTimeout(function(){document.getElementById('btnCancelAll').blur()});    
                }
                cancelAllPendingOrders();
            }
            $rootScope.proceedCancelAllOrders = $rootScope.proceedCancelAllOrders + 1;
            
            
    	});
		
        $rootScope.$on('gatewayApp.messagebox.ok.allorders', function (event, data) {
            if(document.getElementById('btnCancelAll')!=null){
                setTimeout(function(){document.getElementById('btnCancelAll').blur()});    
            }
        });
        
		$rootScope.$on('gatewayApp.messagebox.cancel.cancelallorders', function (event, data) {
			// do not proceed
        	defaultOrderEntry();			
        	// clear numeric controls
        	clearNumericControls();
		});
        
        // get equivalent spot price for limit and trigger prices
        $scope.$watchGroup(['vm.orderDTO.limitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate', 'vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                && vm.orderDTO.limitExchangeRate!=null && vm.orderDTO.limitExchangeRate!=undefined && vm.orderDTO.limitExchangeRate>0){
            	if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    /*vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                        DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);*/
                    vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                            DateUtils.convertLocalDateToServer(getSettlementDate( vm.defaultInstrumentCode, vm.orderType)), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                    if(vm.defaultInstrumentCode!='Forward'){
                        vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.spreadUsed);
                    }else{
                        vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, (vm.spreadUsed * -1));
                    }
                }else{
                   vm.orderDTO.limitExchangeRateInSpot = vm.orderDTO.limitExchangeRate; 
                }
        	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                        DateUtils.convertLocalDateToServer(getSettlementDate( vm.defaultInstrumentCode, vm.orderType)), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            }
        })
        
        $scope.$watchGroup(['vm.orderDTO.limitExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.limitExchangeRate==null || vm.orderDTO.limitExchangeRate==undefined || vm.orderDTO.limitExchangeRate==''){
        		setFieldValues("txtlimitorderprice", "");
        		setFieldValues("txtsllimitprice", "");
        		setFieldValues("txttplimitprice", "");
        		setFieldValues("txtlimitorderprice1", "");
        		setFieldValues("txtsllimitprice1", "");
        		setFieldValues("txttplimitprice1", "");
                setFieldValues("txtlimitorderprice2", "");
                setFieldValues("txtlimitorderprice3", "");
                setFieldValues("txtlimitorderprice4", "");
                setFieldValues("txtsllimitprice2", "");
                setFieldValues("txttplimitprice2", "");
        		if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtlimitorderprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txttplimitprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtlimitorderprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txttplimitprice1" + "txtrequired", true);
        			}
        			if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                		$scope.tradeOrderForm.$setValidity("txtlimitorderprice2" + "txtrequired", true);
                        $scope.tradeOrderForm.$setValidity("txttplimitprice2" + "txtrequired", true);
        			}
                    if($scope.rolloverOrderForm!=null && $scope.rolloverOrderForm!=undefined){
                		$scope.rolloverOrderForm.$setValidity("txtlimitorderprice3" + "txtrequired", true);
        			}
                    if($scope.earlyDeliveryOrderForm!=null && $scope.earlyDeliveryOrderForm!=undefined){
                		$scope.earlyDeliveryOrderForm.$setValidity("txtlimitorderprice4" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtlimitorderprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsllimitprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txtlimitorderprice1" + "txtrequired", true);
        			}
        			if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.mdfyOrderForm.$setValidity("txtsllimitprice2" + "txtrequired", true);
                		$scope.tradeOrderForm.$setValidity("txtlimitorderprice2" + "txtrequired", true);
        			}
                    if($scope.rolloverOrderForm!=null && $scope.rolloverOrderForm!=undefined){
                		$scope.rolloverOrderForm.$setValidity("txtlimitorderprice3" + "txtrequired", true);
        			}
                    if($scope.earlyDeliveryOrderForm!=null && $scope.earlyDeliveryOrderForm!=undefined){
                		$scope.earlyDeliveryOrderForm.$setValidity("txtlimitorderprice4" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txttplimitprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsllimitprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txttplimitprice1" + "txtrequired", true);
        			}
        			if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                		$scope.tradeOrderForm.$setValidity("txtsllimitprice2" + "txtrequired", true);
                		$scope.tradeOrderForm.$setValidity("txttplimitprice2" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtlimitorderprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txttplimitprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsllimitprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txtlimitorderprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txttplimitprice1" + "txtrequired", true);
        			}
        			if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.tradeOrderForm.$setValidity("txtsllimitprice2" + "txtrequired", true);
                		$scope.tradeOrderForm.$setValidity("txtlimitorderprice2" + "txtrequired", true);
                        $scope.tradeOrderForm.$setValidity("txttplimitprice2" + "txtrequired", true);
        			}
                    if($scope.rolloverOrderForm!=null && $scope.rolloverOrderForm!=undefined){
                		$scope.rolloverOrderForm.$setValidity("txtlimitorderprice3" + "txtrequired", true);
        			}
                    if($scope.earlyDeliveryOrderForm!=null && $scope.earlyDeliveryOrderForm!=undefined){
                		$scope.earlyDeliveryOrderForm.$setValidity("txtlimitorderprice4" + "txtrequired", true);
        			}
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.buyLimitExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.buyLimitExchangeRate==null || vm.orderDTO.buyLimitExchangeRate==undefined || vm.orderDTO.buyLimitExchangeRate==''){
        		setFieldValues("txtsellbuyslbuylimitprice", "");
        		setFieldValues("txtsellbuytpbuylimitprice", "");
        		setFieldValues("txtsellbuylimitbuyorderprice", "");
        		if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuylimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpbuylimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitbuyorderprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuytpbuylimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitbuyorderprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuylimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpbuylimitprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuylimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitbuyorderprice" + "txtrequired", true);
        			}
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.sellLimitExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.sellLimitExchangeRate==null || vm.orderDTO.sellLimitExchangeRate==undefined || vm.orderDTO.sellLimitExchangeRate==''){
        		setFieldValues("txtsellbuyslselllimitprice", "");
        		setFieldValues("txtsellbuytpselllimitprice", "");
        		setFieldValues("txtsellbuylimitsellorderprice", "");
        		if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpselllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitsellorderprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuytpselllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitsellorderprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpselllimitprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselllimitprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuylimitsellorderprice" + "txtrequired", true);
        			}
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.stopExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.stopExchangeRate==null || vm.orderDTO.stopExchangeRate==undefined || vm.orderDTO.stopExchangeRate==''){
        		setFieldValues("txtsltriggerprice", "");
        		setFieldValues("txttptriggerprice", "");
        		setFieldValues("txtsltriggerprice1", "");
        		setFieldValues("txttptriggerprice1", "");
                setFieldValues("txtsltriggerprice2", "");
                setFieldValues("txttptriggerprice2", "");
        		if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsltriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txttptriggerprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsltriggerprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txttptriggerprice1" + "txtrequired", true);
        			}
                    if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.tradeOrderForm.$setValidity("txtsltriggerprice2" + "txtrequired", true);
                        $scope.tradeOrderForm.$setValidity("txttptriggerprice2" + "txtrequired", true);
                    }
        		}else if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txttptriggerprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txttptriggerprice1" + "txtrequired", true);
        			}
                    if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.tradeOrderForm.$setValidity("txttptriggerprice2" + "txtrequired", true);
                    }
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsltriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txttptriggerprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsltriggerprice1" + "txtrequired", true);
                		$scope.mdfyOrderForm.$setValidity("txttptriggerprice1" + "txtrequired", true);
        			}
                    if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.tradeOrderForm.$setValidity("txtsltriggerprice2" + "txtrequired", true);
                        $scope.tradeOrderForm.$setValidity("txttptriggerprice2" + "txtrequired", true);
                    }
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsltriggerprice" + "txtrequired", true);
        			}
        			if($scope.mdfyOrderForm!=null && $scope.mdfyOrderForm!=undefined){
                		$scope.mdfyOrderForm.$setValidity("txtsltriggerprice1" + "txtrequired", true);
        			}
                    if($scope.tradeOrderForm!=null && $scope.tradeOrderForm!=undefined){
                        $scope.tradeOrderForm.$setValidity("txtsltriggerprice2" + "txtrequired", true);
                    }
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.buyStopExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.buyStopExchangeRate==null || vm.orderDTO.buyStopExchangeRate==undefined || vm.orderDTO.buyStopExchangeRate==''){
        		setFieldValues("txtsellbuyslbuytriggerprice", "");
        		setFieldValues("txtsellbuytpbuytriggerprice", "");
        		if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuytriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpbuytriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuytpbuytriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuytriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpbuytriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslbuytriggerprice" + "txtrequired", true);
        			}
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.sellStopExchangeRate', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.sellStopExchangeRate==null || vm.orderDTO.sellStopExchangeRate==undefined || vm.orderDTO.sellStopExchangeRate==''){
        		setFieldValues("txtsellbuyslselltriggerprice", "");
        		setFieldValues("txtsellbuytpselltriggerprice", "");
        		if(vm.defaultPriceCondition=='MRKT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselltriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpselltriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='STLS'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuytpselltriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='LIMT'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselltriggerprice" + "txtrequired", true);
                		$scope.orderForm.$setValidity("txtsellbuytpselltriggerprice" + "txtrequired", true);
        			}
        		}else if(vm.defaultPriceCondition=='TKPR'){
        			if($scope.orderForm!=null && $scope.orderForm!=undefined){
                		$scope.orderForm.$setValidity("txtsellbuyslselltriggerprice" + "txtrequired", true);
        			}
        		}
        	}
        })
        
        $scope.$watchGroup(['vm.orderDTO.orderAmount', 'vm.defaultPriceCondition'], function(value){
        	if(vm.orderDTO.orderAmount==null || vm.orderDTO.orderAmount==undefined || vm.orderDTO.orderAmount==''){
            	setFieldValues("txtlimitordervalue", "");
            	setFieldValues("txtsellbuylimitordervalue", "");
            	setFieldValues("txtmktordervalue", "");
            	setFieldValues("txtsellbuymktordervalue", "");
            	setFieldValues("txtslordervalue", "");
            	setFieldValues("txtsellbuyslordervalue", "");
            	setFieldValues("txttpordervalue", "");
            	setFieldValues("txtsellbuytpordervalue", "");
    			if($scope.orderForm!=null && $scope.orderForm!=undefined){
            		$scope.orderForm.$setValidity("txtsellbuyslselltriggerprice" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtsellbuylimitordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtmktordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtsellbuymktordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtslordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtsellbuyslordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txttpordervalue" + "txtrequired", true);
            		$scope.orderForm.$setValidity("txtsellbuytpordervalue" + "txtrequired", true);
    			}
        	}
            else if (vm.regularAndSplitOrder == 'Split' && vm.orderDTO.orderAmount != null && vm.orderDTO.orderAmount != undefined && vm.orderDTO.orderAmount != ''  ) {
                splitorderValidation(vm.orderDTO.orderAmount,vm.noOfSplits);
            }
        })

        $scope.$watchGroup(['vm.defaultInstrumentCode', 'vm.tradeSettlementDate'], function(value){
            if(vm.defaultInstrumentCode!='Forward'){
                if($scope.orderForm!=null && $scope.orderForm!=undefined){
                    $scope.orderForm.$setValidity("dtpTradeSettlementDate1" + "txtrequired", true);    
                    $scope.orderForm.$setValidity("dtpTradeSettlementDate2" + "txtrequired", true);    
                    $scope.orderForm.$setValidity("dtpTradeSettlementDate3" + "txtrequired", true);
                    $scope.orderForm.$setValidity("dtpTradeSettlementDate4" + "txtrequired", true);
                }
            }
            $rootScope.defaultInstrumentCode = vm.defaultInstrumentCode;
            $rootScope.tradeSettlementDate = vm.tradeSettlementDate;
        })
//if it is split order 
        $scope.$watch('vm.noOfSplits',function(value) {
           if(value==null || value==undefined || value==''){
            $scope.errorTextNoOfSplits = "";
             if ($scope.orderForm!=null && $scope.orderForm!=undefined) {
                setFieldValues("txtNoOfSplitValue", "");
                setFieldValues("txtsellbuylimitNoOfSplitValue", "");
                $scope.orderForm.$setValidity("txtNoOfSplitValue" + "txtrequired", true);
                $scope.orderForm.$setValidity("txtsellbuylimitNoOfSplitValue" + "txtrequired", true); 
              }  
            }else if(value != null && value != undefined && value != "" ) {
                splitorderValidation(vm.orderDTO.orderAmount,vm.noOfSplits);
            }
        })
//if it is regular order empty to all split order action 
        $scope.$watch('vm.regularAndSplitOrder',function (value) {
            if(vm.regularAndSplitOrder=='Regular'){
              /*   $scope.orderForm.$setValidity("txtNoOfSplitValue" + "txtrequired", true);
                $scope.orderForm.$setValidity("txtsellbuylimitNoOfSplitValue" + "txtrequired", true);  */
                vm.clicked = false;
                $rootScope.splitedOrderAmount="";
                vm.noOfSplits="";
                $scope.errorTextNoOfSplits = "";
                $scope.errorTextOrderAmount = "";
                vm.orderDTO.isSplitOrder=false;
            }else{
                $scope.orderForm.$setValidity("txtNoOfSplitValue" + "txtrequired", true);
                $scope.orderForm.$setValidity("txtsellbuylimitNoOfSplitValue" + "txtrequired", true);
            }

        }) 
        
        $scope.setForm = function (form) {
            $scope.orderForm = form;
        }
        
        $scope.setMdfyForm = function (form) {
            $scope.mdfyOrderForm = form;
        }
        
        $scope.setTradeForm = function (form){
        	$scope.tradeOrderForm = form;
        }
        
        $scope.$watchGroup(['vm.orderDTO.limitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.defaultPriceCondition', 
        	'vm.orderDTO.limitExchangeRate', 'vm.orderDTO.stopExchangeRate', 'vm.orderDTO.buyLimitExchangeRate', 'vm.orderDTO.sellLimitExchangeRate',
        	'vm.orderDTO.buyStopExchangeRate', 'vm.orderDTO.sellStopExchangeRate'], function(value){
        	if(vm.account!=null && vm.account!=undefined){
            	if(vm.account.organisation.organisationType.shortName=='BANK'){
            		if(vm.tradingCustomer!=null && vm.tradingCustomer!=undefined){
                    	getLimitsAvailableForAllInstruments(vm.globalValues.getOrganisation().id, vm.tradingCustomer.id);
            		}
            	}
        	}
        })
        
        function setOrderEntryFocus(){
        	// $timeout(function(){
        		if(vm.account.organisation.organisationType.shortName=='BANK'){
	            	if(vm.tradingCustomer!=null && vm.tradingCustomer!=undefined){
	                	getLimitsAvailableForAllInstruments(vm.globalValues.getOrganisation().id, vm.tradingCustomer.id);
	        		}
        		}
            	vm.showLA = true;
            	
        	// }, 1000)
        }
        
        function removeOrderEntryFocus(){
        	if(vm.account.organisation.organisationType.shortName=='BANK'){
            	vm.showLA = false;
        	}
        }
        
        function softCheckDeviationForLimitExchangeRate(value){
        	vm.limitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpValue();
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	/*
				 * if(vm.defaultPriceCondition!='MRKT' &&
				 * (Math.round(parseFloat(vm.orderDTO.limitExchangeRateInSpot)*10000) <
				 * Math.round(parseFloat(lowerCutOffPriceSoft)*10000) ||
				 * Math.round(parseFloat(vm.orderDTO.limitExchangeRateInSpot)*10000) >
				 * Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
				 * vm.limitPriceDeviationWarning = 'Deviates by more than ' +
				 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + '
				 * paise from the LTP'; }
				 */
            	if(vm.defaultPriceCondition!='MRKT'){
            		vm.limitPriceDeviationWarning = softCheckDeviation(vm.orderDTO.limitExchangeRateInSpot, higherCutOffPriceSoft, lowerCutOffPriceSoft, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise);
            	}
        	}
        }
        
        function softCheckDeviationForBuyLimitExchangeRate(value){
        	vm.buyLimitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpValue();
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	/*
				 * if(vm.defaultPriceCondition!='MRKT' &&
				 * (Math.round(parseFloat(vm.orderDTO.buyLimitExchangeRateInSpot)*10000) <
				 * Math.round(parseFloat(lowerCutOffPriceSoft)*10000) ||
				 * Math.round(parseFloat(vm.orderDTO.buyLimitExchangeRateInSpot)*10000) >
				 * Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
				 * vm.buyLimitPriceDeviationWarning = 'Deviates by more than ' +
				 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + '
				 * paise from the LTP'; }
				 */
            	if(vm.defaultPriceCondition!='MRKT'){
            		vm.buyLimitPriceDeviationWarning = softCheckDeviation(vm.orderDTO.buyLimitExchangeRateInSpot, higherCutOffPriceSoft, lowerCutOffPriceSoft, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise);
            	}
        	}
        }
        
        function softCheckDeviationForSellLimitExchangeRate(value){
        	vm.sellLimitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = $rootScope.previousDayClosingPriceForIbSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpValue();
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	/*
				 * if(vm.defaultPriceCondition!='MRKT' &&
				 * (Math.round(parseFloat(vm.orderDTO.sellLimitExchangeRateInSpot)*10000) <
				 * Math.round(parseFloat(lowerCutOffPriceSoft)*10000) ||
				 * Math.round(parseFloat(vm.orderDTO.sellLimitExchangeRateInSpot)*10000) >
				 * Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
				 * vm.sellLimitPriceDeviationWarning = 'Deviates by more than ' +
				 * vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + '
				 * paise from the LTP'; }
				 */
            	if(vm.defaultPriceCondition!='MRKT'){
            		vm.sellLimitPriceDeviationWarning = softCheckDeviation(vm.orderDTO.sellLimitExchangeRateInSpot, higherCutOffPriceSoft, lowerCutOffPriceSoft, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise);
            	}
        	}
        }
        
        vm.configSettings = vm.globalValues.getConfigSettings();
        function softCheckDeviation(limitExchangeRate, higherCutOff, lowerCutOff, deviationPriceInPaise){
        	var errorString = '';
        	var firstLeg = 0;
//        	var secondLeg = 0;
        	if(limitExchangeRate != null && !isNaN(limitExchangeRate)){
        		firstLeg = Number(limitExchangeRate);
//            	if(Number(limitExchangeRate) < 10){
//            		firstLeg = Number(limitExchangeRate) * Number(10);
//            		secondLeg = Number(firstLeg) + Number(9.9999);
//            	}else{
//            		firstLeg = Number(limitExchangeRate);
//            		var splitByFloat = limitExchangeRate.split(".");
//            		
//            		var rx = /[0-9]/gi;
//            		if(splitByFloat.length>1){
//            			var decimalDigit = splitByFloat[1];
//            			if(decimalDigit.match(rx).length>3){
//            				secondLeg = Number(limitExchangeRate);
//            			}else if(decimalDigit.match(rx).length>2){
//            				secondLeg = Number(limitExchangeRate) + Number(0.0000);
//            			}else if(decimalDigit.match(rx).length>1){
//            				secondLeg = Number(limitExchangeRate) + Number(0.0000);
//            			}else if(decimalDigit.match(rx).length>0){
//            				secondLeg = Number(limitExchangeRate) + Number(0.0000);
//            			}
//            		}else if(splitByFloat.length==1){
//            			secondLeg = Number(limitExchangeRate) + Number(0.0000);
//            		}else{
//            			secondLeg = Number(limitExchangeRate) + Number(0.0000);	
//            		}
//            	}
            	
            /*	if((Math.round(parseFloat(firstLeg)*10000) < Math.round(parseFloat(lowerCutOff)*10000) && Math.round(parseFloat(secondLeg)*10000) < Math.round(parseFloat(lowerCutOff)*10000)) 
            			|| (Math.round(parseFloat(firstLeg)*10000) > Math.round(parseFloat(higherCutOff)*10000) && Math.round(parseFloat(secondLeg)*10000) > Math.round(parseFloat(higherCutOff)*10000))){
            		errorString = 'deviates by more than ' + deviationPriceInPaise + ' paise from the LTP. ';
            	}
            	*/
            	//The above set of coding is commented since there is an issue with soft check when given in whole numbers like 69, 70. 
        		//Only low softcheck gets failed due to secondleg calc. Therefore, only first leg calc is ok to check soft check.

            	if((Math.round(parseFloat(firstLeg)*10000) < Math.round(parseFloat(lowerCutOff)*10000) ) 
            			|| (Math.round(parseFloat(firstLeg)*10000) > Math.round(parseFloat(higherCutOff)*10000))){
                            errorString = 'deviates by more than </br>' +"<span class='softcheck-value'>"+deviationPriceInPaise+"&nbsp;paise"+"</span> " + ' from the Last Traded Price (LTP).</br> ';
                        }
        	}
        	return errorString;
        }
        
        // stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.stopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate', 'vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                && vm.orderDTO.stopExchangeRate!=null && vm.orderDTO.stopExchangeRate!=undefined && vm.orderDTO.stopExchangeRate>0){
        		
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                  //angular.copy(vm.tradeSettlementDate)
                    vm.stopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, vm.orderType)), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                    if(vm.defaultInstrumentCode!='Forward'){
                        vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, vm.stopSpreadUsed);
                    }else{
                        vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, (vm.stopSpreadUsed * -1));
                    }
                }else{
                   vm.orderDTO.stopExchangeRateInSpot = vm.orderDTO.stopExchangeRate; 
                }
        	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.stopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, vm.orderType)), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            }
            //get credit factor for settlement date apply with limit
            getCreditFactorAndApplyOnLimits();
        })
        
        // buy limit exchange rate
        $scope.$watchGroup(['vm.orderDTO.buyLimitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate','vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if(!vm.submitting){
            	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                    && vm.orderDTO.buyLimitExchangeRate!=null && vm.orderDTO.buyLimitExchangeRate!=undefined && vm.orderDTO.buyLimitExchangeRate>0){

                    if(vm.defaultInstrumentCode!='SPOT'){
                        var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                        vm.buySpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'BUYY', 
                            DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'BUYY')), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                        if(vm.defaultInstrumentCode!='Forward'){
                            vm.orderDTO.buyLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyLimitExchangeRate, vm.buySpreadUsed);
                        }else{
                            vm.orderDTO.buyLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyLimitExchangeRate, (vm.buySpreadUsed * -1));
                        }
                    }else{
                       vm.orderDTO.buyLimitExchangeRateInSpot = vm.orderDTO.buyLimitExchangeRate; 
                    }

            	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                    if(vm.defaultInstrumentCode!='SPOT'){
                        var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                        vm.buySpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'BUYY', 
                            DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'BUYY')), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize); 
                    }
                }
            	
            	if(vm.userPreference!=null && vm.userPreference!=undefined){
            		if(vm.userPreference.userBidSpread!=null && vm.userPreference.userBidSpread!=undefined && value[0]!=null && value[0]!=undefined){
                		vm.orderDTO.sellLimitExchangeRate = (Math.round(parseFloat(value[0])*10000) + Math.round(parseFloat(vm.userPreference.userBidSpread)*10000))/10000;
                	}else{
                        vm.orderDTO.sellLimitExchangeRate = null;
                    }
            	}
        	}
        })
        
        function setOptionPeriodForPlaceOrder(){
        	//when forward set the options perid
            if(vm.defaultInstrumentCode=='Forward' && vm.tenor !=null && vm.tenor.tenorName == 'Option Period'){
            	vm.orderDTO.tradeSettlementDateFrom  = vm.tradeSettlementDateFrom;
            	vm.orderDTO.isOptionPeriod  = true;
            }else{
            	//vm.tradeSettlementDateFrom  = null;
            	vm.orderDTO.tradeSettlementDateFrom  = null;
            	vm.orderDTO.isOptionPeriod  = false;
            }
        }
        
        // buy stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.buyStopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate', 'vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                && vm.orderDTO.buyStopExchangeRate!=null && vm.orderDTO.buyStopExchangeRate!=undefined && vm.orderDTO.buyStopExchangeRate>0){

                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.buyStopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'BUYY', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'BUYY')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                    if(vm.defaultInstrumentCode!='Forward'){
                        vm.orderDTO.buyStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyStopExchangeRate, vm.buyStopSpreadUsed);
                    }else{
                        vm.orderDTO.buyStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyStopExchangeRate, (vm.buyStopSpreadUsed * -1));                        
                    }
                }else{
                   vm.orderDTO.buyStopExchangeRateInSpot = vm.orderDTO.buyStopExchangeRate; 
                }

        	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.buyStopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'BUYY', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'BUYY')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            }
        })
        
        // sell limit exchange rate
        $scope.$watchGroup(['vm.orderDTO.sellLimitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate', 'vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                && vm.orderDTO.sellLimitExchangeRate!=null && vm.orderDTO.sellLimitExchangeRate!=undefined && vm.orderDTO.sellLimitExchangeRate>0){

                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.sellSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'SELL', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'SELL')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                    if(vm.defaultInstrumentCode!='Forward'){
                        vm.orderDTO.sellLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellLimitExchangeRate, vm.sellSpreadUsed);
                    }else{
                        vm.orderDTO.sellLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellLimitExchangeRate, (vm.sellSpreadUsed * -1));
                    }
                }else{
                   vm.orderDTO.sellLimitExchangeRateInSpot = vm.orderDTO.sellLimitExchangeRate; 
                }

        	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.sellSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'SELL', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'SELL')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            }
        })
        
        // sell stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.sellStopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank', 'vm.tradeSettlementDate', 'vm.tradeSettlementDateFrom', 'vm.tenor', 'defaultBankSpread', 'marketSessionSettings'], function(value){
        	if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined 
                && vm.orderDTO.sellStopExchangeRate!=null && vm.orderDTO.sellStopExchangeRate!=undefined && vm.orderDTO.sellStopExchangeRate>0){

                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.sellStopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'SELL', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'SELL')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                    if(vm.defaultInstrumentCode!='Forward'){
                        vm.orderDTO.sellStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellStopExchangeRate, vm.sellStopSpreadUsed);
                    }else{
                        vm.orderDTO.sellStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellStopExchangeRate, (vm.sellStopSpreadUsed * -1));
                    }
                }else{
                   vm.orderDTO.sellStopExchangeRateInSpot = vm.orderDTO.sellStopExchangeRate; 
                }
            	
        	}else if($rootScope.defaultBankSpread!=null && $rootScope.defaultBankSpread!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                if(vm.defaultInstrumentCode!='SPOT'){
                    var tickSize = gettickSizeForGivenInstrument(vm.defaultInstrumentCode);
                    vm.sellStopSpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'SELL', 
                        DateUtils.convertLocalDateToServer(getSettlementDate(vm.defaultInstrumentCode, 'SELL')), $rootScope.retailSpotMarketSession.settlementDate, 
                        tickSize);
                }
            }
        })

        vm.onChangeTradeSettlementDate = onChangeTradeSettlementDate;
        function onChangeTradeSettlementDate(settlementDate){
            if(vm.tenor.tenorName != 'Option Period' && settlementDate!=null && settlementDate!=undefined ){
            	vm.maxFromSettlementDate = moment(settlementDate).add(1, 'days');
                angular.forEach(vm.tenorsLookup, function(value){
                    if(value.settlementDate==DateUtils.convertLocalDateToServer(settlementDate)){
                        vm.tenor = value;
                        $rootScope.tenor = vm.tenor;
                    }
                })
            }
        }
     /*   vm.onChangeTradeSettlementDateFrom = onChangeTradeSettlementDateFrom;
        function onChangeTradeSettlementDateFrom(fromSettlementDate){
        	vm.minToTradeSettlementDate = moment(settlementDate).add(1, 'days');
        }*/

        function setTenorForSettlementDate(settlementDate){
            if(settlementDate!=null && settlementDate!=undefined){
                angular.forEach(vm.tenors, function(value){
                    if(value.settlementDate==DateUtils.convertLocalDateToServer(settlementDate)){
                        vm.tenor = value;
                    }
                })
            }
        }
        
        function getSpotRateForGivenRateAndSpread(rate, spread){
        	if(rate>0){
            	var spotRate = Number(rate) + Number(spread)/100;
            	return spotRate;
        	}else{
        		return null;
        	}
        }
        
        //convert spot rate to limit exchange rate for givne rate, spread and instrument code
        function convertSpotRateToLimitExchangeRate(rate, spread, instrumentCode){
            if(rate>0){
                var spotRate = 0;
                if(instrumentCode=='Forward'){
                    spotRate = Number(rate) + Number(spread)/100;
                }else{
                    spotRate = Number(rate) - Number(spread)/100;
                }
                return spotRate;
            }else{
                return null;
            }
        }
        
        // filter values chanages
        $scope.$watch('vm.instrumentFilterForPO', function(value){
        	$rootScope.instrumentFilterForPO = value;
        })
        // filter values chanages
        $scope.$watch('vm.instrumentFilterForLimit', function(value){
        	$rootScope.instrumentFilterForLimit = value;        	
        })
        // filter values chanages
        $scope.$watch('vm.instrumentFilterForTrade', function(value){
        	$rootScope.instrumentFilterForTrade = value;
        	// vm.expandTradePosition = false;
        })
         $scope.$watch('vm.instrumentFilterForOT', function(value){
        	$rootScope.instrumentFilterForOT = value;
        	
        })
        
        $scope.$watch('vm.branchFilterForOT', function(value){
        	$rootScope.branchFilterForOT = value;
        })
        $scope.$watch('vm.branchFilterForPO', function(value){
        	$rootScope.branchFilterForPO = value;
        })
        // filter values chanages
        $scope.$watch('vm.branchFilterForLimit', function(value){
        	$rootScope.branchFilterForLimit = value;
        })
        // filter values chanages
        $scope.$watch('vm.branchFilterForTrade', function(value){
        	$rootScope.branchFilterForTrade = value;
        })
        
        $scope.$watch('vm.customerFilterForPO', function(value){
        	$rootScope.customerFilterForPO = value;
        })
         $scope.$watch('vm.customerFilterForOT', function(value){
        	$rootScope.customerFilterForOT = value;
        })
        // filter values chanages
        $scope.$watch('vm.customerFilterForLimit', function(value){
        	$rootScope.customerFilterForLimit = value;
        })
        // filter values chanages
        $scope.$watch('vm.customerFilterForTrade', function(value){
        	$rootScope.customerFilterForTrade = value;
        })
        
        $scope.$watch('vm.fromSettlementDate', function(value){
            $rootScope.fromSettlementDate = value;
        })

        $scope.$watch('vm.toSettlementDate', function(value){
            $rootScope.toSettlementDate = value;
        })

        $scope.$watch('vm.selectAll', function(value){
            $rootScope.selectAll = value;
        })

        $rootScope.initialString="";
        $rootScope.messageString="";
        $rootScope.rejectionReason="";
        $rootScope.rejectionString="";
        $rootScope.acceptanceMessage="";
        $rootScope.rejectionMessage="";
        
        vm.initialTradeMessage="";
        
        
        // add remarks for order request
        function addRemarks(){
        	$state.go('add-remarks', {remarks: vm.remarksDTO});
        }
        
        $rootScope.$on('addRemarks', function(event, result) {
        	if($rootScope.remarks==null){
        		//vm.remarks = "";
                setDefaultRemark();
        	}else{
        		vm.remarksDTO = $rootScope.remarks;
                setRemark();
        	}
		});

        $scope.$watch('vm.showPriceIcon', function(value){
        	if(value==true){
                if(document.getElementById('comparitive_tab1')!=null){
                    setTimeout(function(){document.getElementById('comparitive_tab1').click()},100);    
                }
        	}
        })
        
        var orderplacedsuccess = $rootScope.$on('orderplacedsuccess', function(event, result) {
        	if($rootScope.orderPlacedSuccessCount>0){
	        	//vm.remarks = "";
                setDefaultRemark();
	        	vm.clicked = false;
	        	resetPendingOrderCheckboxSelection(false);
	            resetTradesOutstandingCheckboxSelection();
				defaultOrderEntry();			
	        	// remove foucs on order entry screen
	        	removeOrderEntryFocus();
	        	// clear numeric controls
	        	clearNumericControls();
	        	// setLTPPriceAsDefaultValueForLimitOrder();
	        	
	        	removeFocusFromMarketWatch();

	            getLimitExchangeRateForInstrument(vm.defaultInstrumentCode);
        	}
        	$rootScope.orderPlacedSuccessCount = $rootScope.orderPlacedSuccessCount + 1;
		});
        
        function removeFocusFromMarketWatch(){
            setTimeout(function(){document.getElementById('ibspotMktSell').focus()},10);
            setTimeout(function(){document.getElementById('ibspotMktSell').blur()},10);
            setTimeout(function(){document.getElementById('ibspotMktBuy').blur()},10);
        	//setTimeout(function(){document.getElementById('spotMktSell').focus()},10);
        	setTimeout(function(){document.getElementById('spotMktSell').blur()},10);
        	setTimeout(function(){document.getElementById('spotMktBuy').blur()},10);
        	// setTimeout(function(){document.getElementById('cashMktSell').blur()},10);
        	// setTimeout(function(){document.getElementById('cashMktBuy').blur()},10);
        	// setTimeout(function(){document.getElementById('tomMktSell').blur()},10);
        	// setTimeout(function(){document.getElementById('tomMktBuy').blur()},10);
        	
        }
        
        var cancelselecteedsuccess = $rootScope.$on('cancelselectedsuccess', function(event, result) {
        	$rootScope.selectedPendingOrders = [];
        	resetPendingOrderCheckboxSelection(false);
		});
        
        // expand market depth tab
        function expandMarketDepth(instrumentCode, marketDepth){
        	// $rootScope.marketDepth = marketDepth;
        	// $rootScope.instrument = instrumentCode;
        	$state.go('market-depth-expand', {instrument: instrumentCode, marketDepth: marketDepth});
        }
        
        function expandMarketDepthForBothInstrument(instrumentCode, marketDepth, ibMarketDepth){
        	// $rootScope.marketDepth = marketDepth;
        	// $rootScope.instrument = instrumentCode;
        	$state.go('market-depth-expand', {instrument: instrumentCode, marketDepth: marketDepth, ibMarketDepth: ibMarketDepth});
        }
        
      // open market statistics tab
        function openMarketStatistics(instrumentCode){
        	// $rootScope.instrument = instrumentCode;
        	var state = 'market-statistics';
        	$rootScope.instrumentForStats = instrumentCode;
        	$state.go(state,{instrument:instrumentCode,currentBusinessDate : vm.currentDate});
        }

        function expandReports(){
            var state = 'home-page-reports';
            $state.go(state);
        }

        function expandSwapQuote(){
            var state = 'swap-quotes-home-page';
            if($rootScope.bankComparativeDTO!=null &&$rootScope.bankComparativeDTO!=undefined
             && $rootScope.retailSpotMarketWatch!=null && $rootScope.retailSpotMarketWatch!=undefined){
                prepareBankComparativeQuotesInPrice($rootScope.bankComparativeDTO, $rootScope.retailSpotMarketWatch);    
            }
            $state.go(state, {account: vm.account, defaultTradingBank: vm.defaultTradingBank});
        }
        
        function openOrderPreference(){
        	$state.go('order-config');
        }
        
        
        function onGetswapQuotesFeedFailure(){
        	
        }
        
        function changeRequest(){
            var limits = angular.copy($rootScope.limitsAvailableForSelectedInstrument);
            var selectedLimits = _.filter(limits, {'select': true});
            if(selectedLimits.length<=0){
                MessageBox.openMessageBox("Alert", "Please select a limit to proceed.", "Ok", "limitchangerequest");
            }else{
                limitChangeRequest(selectedLimits[0]);
            }
        }

        function limitChangeRequest(selectedLimit){
         	if($rootScope.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
        		workflowSharedService.viewFrom = "customer_limitSettings";
        		var customerId = selectedLimit.customerId ;
        		var bankId =  selectedLimit.bankId;
				var limitInstrumentGroup = "";
				if(selectedLimit.instrumentCode=='UptoSPOT' || selectedLimit.instrumentCode=='SPOT' || selectedLimit.instrumentCode=='TOM' || selectedLimit.instrumentCode=='CASH'){
					limitInstrumentGroup = "SPOTWINDOW";
				}else if(selectedLimit.instrumentCode=='Forward'){
					limitInstrumentGroup = "FORWARD";
				}else{
					limitInstrumentGroup = "ALL";
				}
     			var state = 'customer_limit_Settings';
     			$state.go(state,{customerId:customerId, bankId : bankId, limitInstrumentGroup: limitInstrumentGroup});
        	}
        }

        function newLimitRequest(){
        	if($rootScope.bankFilterForLimit!=null && $rootScope.bankFilterForLimit!=undefined && $rootScope.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
        		workflowSharedService.$rootScope = "";
        		var customerId = $rootScope.globalValues.getOrganisation().id ;
        		var bankId = $rootScope.bankFilterForLimit.id;
                var instrumentCode = 'ALL';
                if($rootScope.instrumentFilterForLimit!=null){
                    instrumentCode = $rootScope.instrumentFilterForLimit.instrumentCode;
                }
     			var state = 'create-customer-limit-settings-limit-report';
     			$state.go(state,{ bankId : bankId, instrumentCode: instrumentCode});
        	}
        }
        
        function getBuyingPower(limit){
        	var buyingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
        	var totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
        	if(limit.limitType=='GROSSSELL'){
        		buyingPower = 0;
        	}else if(limit.limitType=='GROSSBUY'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy);
        	}else if(limit.limitType=='GLOBALGROSS'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue  + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		buyingPower = limit.limitAllocatedInUSD - totalBuy;
        	}
    		buyingPower = (Math.round(parseFloat(buyingPower)*100)/100);
            if(buyingPower<0){
                buyingPower=0;
            }
        	return buyingPower;
        }
        
        vm.getExposure = function getExposure(limit){
        	var exposure = 0;
        	var totalBuy = 0;
        	var totalSell = 0;
        	if(limit.limitType!='NET'){
            	totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
            	totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
            	exposure = (limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue + limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue) * 100/limit.limitAllocatedInUSD;
        	}else{
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));

            	var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		exposure =  Math.max.apply(Math, xVals) * 100/limit.limitAllocatedInUSD;
        	}
        	exposure = (Math.round(parseFloat(exposure)*100)/100);
        	return exposure;
        }
        
        function getSellingPower(limit){
        	var sellingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue);
        	var totalSell = limit.totalPendingSellOrderValue + (limit.totalSellTradeValue + limit.previousDaySellTradeValue);
        	if(limit.limitType=='GROSSSELL'){
        		sellingPower = limit.limitAllocatedInUSD - (totalSell);
        	}else if(limit.limitType=='GROSSBUY'){
        		sellingPower = 0;
        	}else if(limit.limitType=='GLOBALGROSS'){
        		sellingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		sellingPower = limit.limitAllocatedInUSD - totalSell;
        	}
        	sellingPower = (Math.round(parseFloat(sellingPower)*100)/100);
            if(sellingPower<0){
                sellingPower=0;
            }
        	return sellingPower;
        }
        
        function printDealTicket(trade){
        	vm.tradeResponse = angular.copy(trade);
        	console.log(typeof vm.tradeResponse.tradeTime);
        	vm.tradeResponse.tradeTime = moment(vm.tradeResponse.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
        	GenerateDealTicket.generate(vm.tradeResponse,function(result){
	        	var file = new Blob([result.data], {type: 'application/pdf'});
	      		// var fileURL = URL.createObjectURL(file);
	     		// window.open(fileURL, "_blank", "fullscreen=yes");
                var fileURL = URL.createObjectURL(file);
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "DealTicket.pdf");
                }
                else {
                     window.open(fileURL, "_blank", "fullscreen=yes");    
                }
        	});
        }
        
        function printDealTicketOutstandingTrade(outstandingTrade){
        	vm.tradeResponse = angular.copy(outstandingTrade);
        	console.log(typeof vm.tradeResponse.tradeTime);
        	vm.tradeResponse.tradeTime = moment(vm.tradeResponse.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
        	GenerateDealTicket.generateOutstandingTrades(vm.tradeResponse,function(result){
	        	var file = new Blob([result.data], {type: 'application/pdf'});
	      		var fileURL = URL.createObjectURL(file);
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "DealTicket.pdf");
                }
                else {
                     window.open(fileURL, "_blank", "fullscreen=yes");    
                }
        	});
        }
        function resetPendingOrderCheckboxSelection(value){
        	vm.selectAll = value;
        	if(document.getElementById("selectAll")!=null && document.getElementById("selectAll")!= undefined){
        		document.getElementById("selectAll").checked = value;
        	}
        	changePendingOrderItemSelection(value);
        }

        function resetTradesOutstandingCheckboxSelection(){
            angular.forEach($rootScope.outstandingTrades,function(value){
                value.select = false;
            });
            //$rootScope.outstandingTradesMemory = $rootScope.outstandingTrades;
        }

        function setLTPPriceAsDefaultValueForLimitOrder(){
        	
        	vm.orderDTO.limitExchangeRate = $rootScope.tickerPrice.retailSpot;
        	vm.orderDTO.buyLimitExchangeRate = $rootScope.tickerPrice.retailSpot;
        	vm.orderDTO.sellLimitExchangeRate = $rootScope.tickerPrice.retailSpot;
        }
        
        $('#myDropdown .dropdown-menu').on({
        	"click":function(e){
              e.stopPropagation();
            }
        });
        /*
		 * $('#broadcastmsg .dropdown-menu').on({ "click":function(e){
		 * e.stopPropagation(); } });
		 */
        
        function broadcastMessage(){
        	var message = {};
        	message.message = vm.message;
        	Notifications.broadcastMessage(message);
        	vm.message = "";
        }
        
        vm.exportOTToExcelFile = exportOTToExcelFile;
        vm.exportOTToCsvFile = exportOTToCsvFile;
        vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm_ss';
        function exportOTToCsvFile(){
        	if($rootScope.outstandingTrades!=null && $rootScope.outstandingTrades.length >0){
        		vm.mimeType = 'text/csv';

        		var baseString = 'OUTSTANDING_TRADES_'+ moment().format(vm.reportFileTimestampFormat) ;  
        		vm.baseString = baseString+ ".csv";
        		var selectedTrades = angular.copy($rootScope.outstandingTrades);
        		angular.forEach(selectedTrades,function(value,index){        		
        			value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
        		});

        		TradesDownload.getTradesOutstandingCSV(selectedTrades, processResponse);

        	}
        }
        function exportOTToExcelFile(){
        	if($rootScope.outstandingTrades!=null && $rootScope.outstandingTrades.length >0){           	
        		vm.mimeType = 'application/vnd.ms-excel';
        		var baseString = 'OUTSTANDING_TRADES_'+ moment().format(vm.reportFileTimestampFormat);  
        		vm.baseString = baseString+ ".xls";  
        		var selectedTrades = angular.copy($rootScope.outstandingTrades);
        		angular.forEach(selectedTrades,function(value,index){        		
        			value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
        		});

        		TradesDownload.getTradesOutstandingExcel(selectedTrades, processResponse);


        	}
        }
        
        function exportTradesToCsvFile(){
            if($rootScope.trades!=null && $rootScope.trades.length >0){

	        	vm.mimeType = 'text/csv';
	        	 var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
	   			 vm.baseString = baseString+ ".csv";  
	        	var selectedTrades = angular.copy($rootScope.trades);
	        	angular.forEach(selectedTrades,function(value,index){        		
	        		value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
	        		console.log("tradetime " +  value.tradeTime);
	        		
	        	});
	        	TradesDownload.getCSVForTrades(selectedTrades , processResponse );
            }
        }
        function exportTradesToExcelFile(){
            if($rootScope.trades!=null && $rootScope.trades.length >0){

	        	  vm.mimeType = 'application/vnd.ms-excel';
	        	  var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
				  vm.baseString = baseString+ ".xls";  
				  var selectedTrades = angular.copy($rootScope.trades);
		        	angular.forEach(selectedTrades,function(value,index){        		
		        		value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
		        		console.log("tradetime " +  value.tradeTime);
		        		
		        	});
				  TradesDownload.getExcelForTrades(selectedTrades , processResponse );
            }
        }
        function processResponse(result){
      	  var fileData = null;
  		  fileData = result.data;
  		  var link = document.createElement("a");   
  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary
													// array from arraybuffer
  		  
  		  
  	      var blob = new Blob([bytes], {type: vm.mimeType});
  	      if(window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
  	      }else{
  	    	  link.href =  window.URL.createObjectURL(blob);      
  	    	  link.download = vm.baseString;
  	    	  document.body.appendChild(link);
  	    	  link.click();
  	    	  document.body.removeChild(link);
  	      }
  	  }
        function getConfigForGenerate(){
        	
        	  if(vm.account.entityOwnerType.shortName=='Clearcorp'){
            	  DealingAdminApplicationWideSettings.getConfigValueForConfigName(
                  		{"configName": "BillingEndDate"}, function(result){
                  			$rootScope.billingDate = result.value;
                  			 DealingAdminApplicationWideSettings.getConfigValueForConfigName(
                             		{"configName": "ShowGenerateTransactionCharges"}, function(result){
                             			$rootScope.showGenerateTransactionButton = result.value;
                             	});
                  	});
            	 
    		  }
        }
        
        function generateInvocie(){
        	
        	EODProcess.generateInvoice( {"billingDate" : $rootScope.billingDate
				} , generateInvocieSuccess,generateInvocieFailed);
        }
        function generateInvocieSuccess(result){
        	// alert("Transaction Invoice Generated Sucessfully");
        	$rootScope.invoiceGenerated = true;
        	$.growl.notice({title: "Generate Invoice", message: "Invoice Generated Sucessfully." });
        }
        function generateInvocieFailed(error){
        	// alert("Generation of Transaction Invoice Failed!!!");
        	$rootScope.invoiceGenerated = false;
        	$.growl.error({title: "Generate Transaction Invoice", message: "Generation of Transaction Invoice Failed!!!" });
        }

        function asyncGenerateInvoice(){
        	
        	EODProcess.aysncgenerateInvoice( {"billingDate" : $rootScope.billingDate
				} , regenerateInvocieSuccess,regenerateInvocieFailed);
        }
        function regenerateInvocieSuccess(result){
        	$rootScope.asyncInvoiceGenerated = true;
        	$.growl.notice({title: "Async Generate Invoice", message: "Async Invoice Generated Sucessfully." });
        }
        function regenerateInvocieFailed(error){
        	$rootScope.asyncInvoiceGenerated = false;
        	$.growl.error({title: "Async Generate Invoice", message: "Async Generation Invoice Failed!!!" });
        }
        
        vm.getMomentFromOrderRevisionTimeStamp =  function(timestampString){
        	return moment(timestampString,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        }

        //when tenor lookup is changed, change settlement date also.
        // $scope.$watch('vm.tenor', function(value){
        //     if(value!=null && value!=undefined){
        //         if(value.tenorName=='Broken Date'){
        //             vm.tradeSettlementDate = new Date(vm.tenors[0].settlementDate);
        //         }else{
        //             vm.tradeSettlementDate = new Date(value.settlementDate);
        //         }
        //         if(vm.orderDTO.activityIndicator=='PLAC'){
        //             getLimitExchangeRateForInstrument('Forward');    
        //         }
        //     }
        // })

        vm.onChangeTenor = onChangeTenor;
        function onChangeTenor(value){
            if(value!=null && value!=undefined){
                if(value.tenorName=='Broken Date'){
                    vm.tradeSettlementDate = new Date(vm.tenors[0].settlementDate);
                }else if(value.tenorName=='Option Period'){
                    vm.orderDTO.forwardType='Option Period';
                	vm.tradeSettlementDateFrom = null;
                    vm.tradeSettlementDate = null;
                }
                else{
                    vm.tradeSettlementDate = new Date(value.settlementDate);
                }
                if(vm.orderDTO.activityIndicator=='PLAC'){
                    getLimitExchangeRateForInstrument('Forward');    
                }
            }
        }

        /* get settlemnt date based on the order type, instrument and tenor name*/
        function getSettlementDate( instrumentCode, orderType){
        	var settlementDate = angular.copy(vm.tradeSettlementDate);
            if(vm.tenor !=null){
                console.log('instrumentCode - ' + instrumentCode + ' orderType - ' + orderType  + ' tenorName - ' + vm.tenor.tenorName);  
            }          
        	if(instrumentCode == 'Forward' && vm.tenor !=null && vm.tenor != undefined && vm.tenor.tenorName == 'Option Period'){
        		if( orderType =="SELL" ){
        			settlementDate = angular.copy(vm.tradeSettlementDateFrom);
        		}else //if(orderType =="BUYY"  )
        		{
        			settlementDate = angular.copy(vm.tradeSettlementDate);
        		}
        	}
            //console.log('settlementDate - ' + settlementDate);
        	return settlementDate;
        }
        function getSettlementDateForContractCancellation( outstandingTrade){
        	
        	var settlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
        	if(outstandingTrade.instrumentCode == 'Forward' && outstandingTrade.isOptionPeriod == true){
        		//if ccancellation is before theoption period from then the settlement date is calculated based on orginal trade buysellindicator
        		if(isEventAppliedBeforeOptionPeriod(outstandingTrade)){
        			//for both cancellation the original terade buysell indicator is taken not the cancellation buysellidnicaor
            		if( outstandingTrade.buySellIndicator =="SELL" ){
            			settlementDate = angular.copy(outstandingTrade.tradeSettlementDateFrom);
            		}else //if(orderType =="BUYY"  )
            		{
            			settlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
            		}
        		}else{// if ccancellation is during the option period then always it is cash 
        			settlementDate = angular.copy($rootScope.cashMarketSession.settlementDate);
        		}
        		
        	}
        	return settlementDate;
        }
        $rootScope.getSettlementDateForContractCancellation = getSettlementDateForContractCancellation;
       /* function getSettlementDateForEarlyDelivery( outstandingTrade){
        	
        	var settlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
        	if(outstandingTrade.instrumentCode == 'Forward' && getSettlementDateFromOutstandingTrade.isOptionPeriod){
        		//if ccancellation is before theoption period from then the settlement date is calculated based on orginal trade buysellindicator
        		if(isEventAppliedBeforeOptionPeriod(outstandingTrade)){
        			//for both cancellation the original terade buysell indicator is taken not the cancellation buysellidnicaor
            		if( outstandingTrade.buySellIndicator =="SELL" ){
            			settlementDate = angular.copy(outstandingTrade.tradeSettlementDateFrom);
            		}else //if(orderType =="BUYY"  )
            		{
            			settlementDate = angular.copy(outstandingTrade.tradeSettlementDate);
            		}
        		}else{// if ccancellation is during the option period then always it is cash 
        			settlementDate = angular.copy($rootScope.cashMarketSession.settlementDate);
        		}
        		
        	}
        	return settlementDate;
        }*/
        
        function isEventAppliedBeforeOptionPeriod(outstandingTrade){
        	if(moment(angular.copy($rootScope.cashMarketSession.settlementDate)).isBefore(moment(angular.copy(outstandingTrade.tradeSettlementDateFrom)),'day')){
        		return true;
        	}else return false;
            	
        }
        $rootScope.isEventAppliedBeforeOptionPeriod = isEventAppliedBeforeOptionPeriod;
        
        vm.getLimitExchangeRateForInstrument = getLimitExchangeRateForInstrument;
        function getLimitExchangeRateForInstrument(instrumentCode){
            //setTimeout(function() {
                if($rootScope.retailSpotMarketWatch!=null && $rootScope.retailSpotMarketWatch!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                    var tickSize = gettickSizeForGivenInstrument(instrumentCode);
                    if(vm.orderType!="SELLBUY"){
                        vm.orderDTO.limitExchangeRate = null;
                        vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                            DateUtils.convertLocalDateToServer(getSettlementDate(instrumentCode, vm.orderType) ), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                    }else{
                        vm.orderDTO.buyLimitExchangeRate = null;
                        vm.buySpreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, 'BUYY', 
                            DateUtils.convertLocalDateToServer(getSettlementDate(instrumentCode, 'BUYY')), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                    }

                    if(instrumentCode!='SPOT'){
                        if(instrumentCode!='Forward'){
                            if(vm.orderType=='BUYY'){
                                if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                                    vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.offerPrice, (vm.spreadUsed * -1));
                                }else{
                                	vm.orderDTO.limitExchangeRate = null;
                                }
                            }else if(vm.orderType=='SELL'){
                                if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                                    vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.bidPrice, (vm.spreadUsed * -1));
                                }else{
                                	vm.orderDTO.limitExchangeRate = null;
                                }
                            }else{
                                if(vm.buySpreadUsed!=null && vm.buySpreadUsed!=undefined){
                                    vm.orderDTO.buyLimitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.offerPrice, (vm.buySpreadUsed * -1));    
                                }else{
                                	vm.orderDTO.buyLimitExchangeRate = null;
                                    vm.orderDTO.sellLimitExchangeRate = null;
                                }
                            }
                        }else{
                            if(vm.orderType=='BUYY'){
                                if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                                    vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.offerPrice, vm.spreadUsed);    
                                }else{
                                	vm.orderDTO.limitExchangeRate = null;
                                }
                            }else if(vm.orderType=='SELL'){
                                if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                                    vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.bidPrice, vm.spreadUsed);
                                }else{
                                	vm.orderDTO.limitExchangeRate = null;
                                }
                            }else{
                                if(vm.buySpreadUsed!=null && vm.buySpreadUsed!=undefined){
                                    vm.orderDTO.buyLimitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.retailSpotMarketWatch.offerPrice, vm.buySpreadUsed);    
                                }else{
                                	vm.orderDTO.buyLimitExchangeRate = null;
                                    vm.orderDTO.sellLimitExchangeRate = null;
                                }
                            }
                        }
                    }else{
                        if(vm.orderType=='BUYY'){
                            vm.orderDTO.limitExchangeRate = $rootScope.retailSpotMarketWatch.offerPrice;
                        }else if(vm.orderType=='SELL'){
                            vm.orderDTO.limitExchangeRate = $rootScope.retailSpotMarketWatch.bidPrice;
                        }else{
                            vm.orderDTO.buyLimitExchangeRate = $rootScope.retailSpotMarketWatch.offerPrice;
                        }
                    }
                }
            //}, 100);
        }

        function getLimitExchangeRateForCancellingOutstandingTrade(instrumentCode){
            if($rootScope.tickerPrice!=null && $rootScope.tickerPrice!=undefined && $rootScope.retailSpotMarketSession!=null && $rootScope.retailSpotMarketSession!=undefined){
                var tickSize = gettickSizeForGivenInstrument(instrumentCode);
                vm.orderDTO.limitExchangeRate = null;
                vm.spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, vm.defaultInstrumentCode, vm.orderType, 
                    DateUtils.convertLocalDateToServer(angular.copy(vm.tradeSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                    tickSize);

                if(instrumentCode!='SPOT'){
                    if(instrumentCode!='Forward'){
                        if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                            vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.tickerPrice.retailSpot, (vm.spreadUsed * -1));
                        }
                    }else{
                        if(vm.spreadUsed!=null && vm.spreadUsed!=undefined){
                            vm.orderDTO.limitExchangeRate = getSpotRateForGivenRateAndSpread($rootScope.tickerPrice.retailSpot, vm.spreadUsed);    
                        }
                    }
                }else{
                    vm.orderDTO.limitExchangeRate = $rootScope.tickerPrice.retailSpot;
                }
            }
        }

        vm.checkOrUncheckLimitItem= checkOrUncheckLimitItem;
        function checkOrUncheckLimitItem(limitValue, event){
            var selectedValue = event.target.checked;
            angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(value, index){                
                value.select = false;                
            })
            limitValue.select = selectedValue;
        }

        function getCreditFactorAndApplyOnLimits(){
            if(vm.configSettings!=null && vm.configSettings!=undefined){
                var creditFactor = MarketWatch.getCreditFactorValue(vm.defaultInstrumentCode, angular.copy(vm.tradeSettlementDate), $rootScope.selectedCreditFactor, vm.configSettings.defaultCreditFactorSettingsInPercentage);
                $rootScope.forwardBuyLimitWithCF = MarketWatch.computeLimitAmountApplyingCreditFactor(angular.copy($rootScope.forwardBuyLimit), creditFactor);
                $rootScope.forwardSellLimitWithCF = MarketWatch.computeLimitAmountApplyingCreditFactor(angular.copy($rootScope.forwardSellLimit), creditFactor);
            }
        }

        vm.getSwapPointsForTradesReport = function getSwapPointsForTradesReport(spotExchangeRate, tradeExchangeRate){
            var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
            swapPts = (Math.round(parseFloat(swapPts)*100)/100);
            return swapPts;
        }

        vm.enableDisableInfoIcon = function enableDisableInfoIcon(yesOrNo){
            $rootScope.userPreference.showInfoIcon = yesOrNo;
            //vm.userPreference.showInfoIcon = yesOrNo;
            //update preference in db
            UserProfile.updateGivenPreference({userId: vm.globalValues.getUserId(), settingsName: 'ShowInfoIcon', value: yesOrNo});
        }
        
        vm.gotoSupport = function gotoSupport(){
        	$state.go('fx-support');
        	//TACViewForEntitiesService.sendemail();
        }
        function getMinAndMaxNoOfSplit(){
           
                DealingAdminApplicationWideSettings.getConfigValueForConfigName(
                        {"configName": "MinimumNoOFSplits"}, function(result){
                            vm.minNoOfSplit = result.value;
                             DealingAdminApplicationWideSettings.getConfigValueForConfigName(
                                   {"configName": "MaximumNoOFSplits"}, function(result){
                                    vm.maxNoOfSplit = result.value;
                               });
                    });

        }

         function setRemark(){
            if(vm.remarksDTO !='' && vm.remarksDTO != null && vm.remarksDTO != undefined){
                            vm.remarks1=vm.remarksDTO.remarks1;
                    
                            vm.remarks2=vm.remarksDTO.remarks2;
                       
                            vm.remarks3=vm.remarksDTO.remarks3;
                       
                            vm.remarks4=vm.remarksDTO.remarks4;
                    }
        } 
        function setDefaultRemark(){
            vm.remarks1="";
            vm.remarks2=null;
            vm.remarks3=null;
            vm.remarks4=null;
            vm.remarksDTO.remarks1="";
            vm.remarksDTO.remarks2=null;
            vm.remarksDTO.remarks3=null;
            vm.remarksDTO.remarks4=null;

        }

        //split order validatiomn
        function splitorderValidation(OrderAmount, noOfSplits) {
            vm.orderDTO.isSplitOrder = true;
            if (OrderAmount != null && OrderAmount != undefined && OrderAmount != "") {
                var orderAmountNo = Number(OrderAmount);
                if (orderAmountNo % 1 != 0) {
                    vm.clicked = true;
                    $scope.errorTextOrderAmount = "This field cannot be decimal value";
                } else {
                    vm.clicked = false;
                    $scope.errorTextOrderAmount = "";
                }
            }
            if (noOfSplits != null && noOfSplits != undefined && noOfSplits != "") {
                var noOfSplitsCount = Number(noOfSplits);

                if (noOfSplitsCount < vm.minNoOfSplit || noOfSplitsCount > vm.maxNoOfSplit) {
                    vm.clicked = true;
                    $scope.errorTextNoOfSplits = "Split value should be between " + vm.minNoOfSplit + " and " + vm.maxNoOfSplit;
                } else if (noOfSplitsCount % 1 != 0) {
                    vm.clicked = true;
                    $scope.errorTextNoOfSplits = "This field cannot be decimal value";

                } else if (orderAmountNo < noOfSplitsCount) {
                    vm.clicked = true;
                    $scope.errorTextNoOfSplits = "Split value cannot be greater than order quantity";
                } else if (splitTheOrderQuntity()) {
                    vm.clicked = true;
                    $scope.errorTextNoOfSplits = "Orders should be Split evenly";
                } else {
                    vm.clicked = false;
                    $scope.errorTextNoOfSplits = "";
                }
            }

        }
        // split the order quntity and check spliding order decimal
        function splitTheOrderQuntity() {
            if (vm.regularAndSplitOrder == 'Split' && vm.orderDTO.orderAmount != null && vm.orderDTO.orderAmount != undefined && vm.orderDTO.orderAmount != '') {
                var splitedOrderAmount = null;
                if (vm.noOfSplits != null && vm.noOfSplits != undefined && vm.noOfSplits != "") {
                    splitedOrderAmount = vm.orderDTO.orderAmount / vm.noOfSplits;
                    if (splitedOrderAmount % 1 != 0) {
                        return true;
                    } else {
                        vm.showSplitedOrderAmount = splitedOrderAmount;
                        return false;
                    }

                }
            }
        }
        //divide arder amount then split the order based on no of split and store to list  ; 
        function splitTheOrder(orderDTO, noOfsplit) {
            vm.showSplitedOrderAmount = null;
            if (orderDTO.orderAmount != null && orderDTO.orderAmount != undefined && orderDTO.orderAmount != '') {
                if (noOfsplit != null && noOfsplit != undefined && noOfsplit != "") {
                    vm.splitedOrderAmount = orderDTO.orderAmount / noOfsplit;
                    for (var splitOrder = 0; splitOrder <= noOfsplit - 1; splitOrder++) {
                        vm.orderDTO.orderAmount = vm.splitedOrderAmount;
                        vm.orderDTO.SystemUniqueSNo = angular.copy(splitOrder);
                        orderDTO.uploadStatus='SUCCESS';
                        vm.orderDTOList.push(angular.copy(vm.orderDTO));
                    }
                }
            }
        }

} 

})();
