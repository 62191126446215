(function() {
	'use strict';

	angular.module('gatewayApp')

	.controller('CustomerNewLimitController', CustomerNewLimitController);

	CustomerNewLimitController.$inject = [ '$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','LimitSettings', 
		 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow','entity','StaticLookUp','MessageBox', '$stateParams',  'CustomerTradingBankDetails','DateUtils','DealingAdminApplicationWideSettings'];

	function CustomerNewLimitController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,LimitSettings,
			ScreenLog,entityConstants,AlertService,$filter, Workflow,entity,StaticLookUp,MessageBox,$stateParams,CustomerTradingBankDetails,DateUtils,DealingAdminApplicationWideSettings) {
		
		 var vm=this;
		 vm.globalValues = GlobalValues;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
         $scope.showOkButton = false;
         if($state.current.name=="create-customer-limit-settings" || $state.current.name=="create-customer-limit-settings-limit-report"){
        	 workflowSharedService.viewFrom="";
         }
              var systemSettings = vm.globalValues.getSystemSettings();
         if(systemSettings!=null && systemSettings!=undefined){
        	 var now = moment();
        	 vm.momentBusinessDate = moment(systemSettings.businessDate, 'YYYY-MM-DD');        	
        	 vm.momentBusinessDate.set(
        			 { 	'hour':now.get('hour'),
        				'minute':now.get('minute'),
        				'second':now.get('second')
        				
        			 });
        	 vm.currentDate = vm.momentBusinessDate.toDate();
        	
        	 vm.effectiveToMinimumDate = systemSettings.businessDate;
        	 //vm.tillDate = new Date(vm.currentDate);
        	 //vm.tillDate.setDate(vm.currentDate.getDate() + parseInt(vm.maxLimitPeriod));
         }
         // set maximum limit period 
        DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'MaximumLimitPeriod'}, function(result){
            vm.maxLimitPeriod = result.value;
            vm.tillDate = new Date(vm.currentDate);
            vm.tillDate.setDate(vm.currentDate.getDate() + parseInt(vm.maxLimitPeriod));
            })
         
         vm.clear = clear;
         vm.resubmitLimitSettings= resubmitLimitSettings;
        
         vm.discardLimitSettings = discardLimitSettings;
         vm.save=save;
         vm.clicked = false;
        
         vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
         vm.defaultLimitType = 'GLOBALGROSS';
         vm.selectedInstrument = 'all';
         vm.defaultCurrencyCode = 'USD'
         vm.defaultLimitCurrency = null;
         vm.disableSave = false;
         vm.onChangeLimitInstrumentGroup =onChangeLimitInstrumentGroup;
         vm.showLimitInfo = false;
        // loadInstrumentType();
        
       
        
         loadCurrency();
         loadLimitTypes();
         loadTradingBankDetails();
         loadLimitInstrumentGroup();
         loadLimitReinstateType();
         loadLimitTenorType();
         loadInstrumentTypes();
         loadDefaultEffectiveToDatesFromConfig();
       
         vm.tradingBankDetails = [];
         function loadTradingBankDetails(){
        	 //get trading bank details whos status is not in (suspended, terminated and pendingtradingactivation) 
        	CustomerTradingBankDetails.getValidTradingBankDetails( {'customerId': vm.globalValues.getOrganisation().id }, getTradingBankDetailsSuccess, getTradingBankDetailsFailure)
        	
         }    
         function getTradingBankDetailsSuccess(result){
        	
        	 vm.tradingBankDetails = result;
        	//load default trading bank and branch when new request from menu
 	        if($state.current.name=="create-customer-limit-settings" && $rootScope.defaultTradingBank!=null && $rootScope.defaultTradingBank!=undefined){
 	        	checkForBankAndBranch($rootScope.defaultTradingBank.id) ;
 			}else if($state.current.name=="create-customer-limit-settings-limit-report"){
 				checkForBankAndBranch($stateParams.bankId);
 	        }
         }
         function getTradingBankDetailsFailure(error){
        	 
         }
         function loadCurrency(){
        	 StaticLookUp.getCurrency(currencySuccess,currencyFailure);
         }
         function currencySuccess(result){
        	vm.currencies = result;
        	angular.forEach(vm.currencies, function(item){
        		if(item.code==vm.defaultCurrencyCode){
           		 	vm.defaultLimitCurrency =item;
           		 	if(	vm.customerLimit.customerLimitRequest.limitCurrency ==null 
           		 			|| 	vm.customerLimit.customerLimitRequest.limitCurrency == undefined
           		 			||  vm.customerLimit.customerLimitRequest.limitCurrency == ''){
           		 		vm.customerLimit.customerLimitRequest.limitCurrency = vm.defaultLimitCurrency;
           		 	}
           		}
           	});
        	
         }
         function currencyFailure(error){
        	 
         }
         function loadLimitTypes(){
        	 StaticLookUp.getLimitType(limitTypeSuccess, limitTypeFailure);
         }
         function limitTypeSuccess(result){
        	 vm.limitTypes = result;
        	 prepareLimitTypeToView();
         }
         function limitTypeFailure(error){
        	 
         }

         
         function loadLimitInstrumentGroup(){
             StaticLookUp.getLimitInstrumentGroupList(onGetLimitInstrumentGroupSuccess, onGetLimitInstrumentGroupFailure);
         }

         function onGetLimitInstrumentGroupSuccess(result){
             vm.limitInstrumentGroupList = result;
            
             if(result!=null && result.length>0 && $state.current.name=="create-customer-limit-settings"){
            	 var defaultLimitForCreateCustomer = {};
            	 angular.forEach(result, function(item){
            		 if(item.shortName == 'ALL'){
            			 defaultLimitForCreateCustomer = item;
            		 }
            	 })
                vm.limitInstrumentGroup = defaultLimitForCreateCustomer;
                if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
                    vm.customerLimit.limitSettingsHeader.limitInstrumentGroup = vm.limitInstrumentGroup;
                }
             }else if(workflowSharedService.viewFrom == 'create_customer_limitSettings_maker'||workflowSharedService.viewFrom == 'create_customer_limitSettings_resubmit' || workflowSharedService.viewFrom == 'create_customer_limitSettings_auditLog'){
                if(vm.customerLimit.limitSettingsHeader.effectiveToDateTime!=null && vm.customerLimit.limitSettingsHeader.limitInstrumentGroup!=null){
                    vm.effectiveToDate =  DateUtils.convertDateTimeFromServer(vm.customerLimit.limitSettingsHeader.effectiveToDateTime);
                    vm.customerLimit.limitSettingsHeader.limitInstrumentGroup = angular.copy(vm.customerLimit.limitSettingsHeader.limitInstrumentGroup);
                }else{
                    setLimitInstrumentGroupForGivenInstrument(vm.customerLimit.limitSettingsHeader.limitInstrumentGroup.shortName);
                }

               
             }
             else{
                setLimitInstrumentGroupForGivenInstrument($stateParams.instrumentCode);
             }
                
             }
         

         function onGetLimitInstrumentGroupFailure(){

         }
         

         function setLimitInstrumentGroupForGivenInstrument(instrumentCode){
            var limitInstrumentGroup = ""
            if(instrumentCode=='ALL'){
                limitInstrumentGroup = 'ALL';

            }else if(instrumentCode=='Forward'){
                limitInstrumentGroup = 'FORWARD';
            }else /* if(instrumentCode=='CASh' || instrumentCode=='TOM'|| instrumentCode=='SPOT') */{
                limitInstrumentGroup = 'SPOTWINDOW';

            }
            angular.forEach(vm.limitInstrumentGroupList, function(value){
                if(value.shortName==limitInstrumentGroup){
                    vm.limitInstrumentGroup = value;
                }
            })

            if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
                vm.customerLimit.limitSettingsHeader.limitInstrumentGroup = vm.limitInstrumentGroup;
            }
         }

        

        // vm.onChangeLimitInstrumentGroup = function onChangeLimitInstrumentGroup(value){
        //     if(value!=null && value!=undefined){
        //         if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
        //             vm.customerLimit.limitSettingsHeader.limitInstrumentGroup = value;
        //         }
        //     }
        // }

        function loadLimitReinstateType(){
            StaticLookUp.getLimitReinstateTypeList(onGetLimitReinstateTypeSuccess, onGetLimitReinstateTypeFailure);
        }

        function onGetLimitReinstateTypeSuccess(result){
            vm.limitReinstateTypeList = result;
            if(result!=null && result.length>0){
               vm.limitReinstateType = result[0];
            }
            if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
                vm.customerLimit.limitSettingsHeader.limitReinstateType = vm.limitReinstateType;
            }
        }

        function onGetLimitReinstateTypeFailure(){

        }

        function loadLimitTenorType(){
            StaticLookUp.getLimitTenorTypeList(onGetLimitTenorTypeSuccess, onGetLimitTenorTypeFailure);
        }

        function onGetLimitTenorTypeSuccess(result){
            vm.limitTenorTypeList = result;
            if(result!=null && result.length>0){
               vm.limitTenorType = result[0];
            }
            if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
                vm.customerLimit.limitSettingsHeader.limitTenorType = vm.limitTenorType;
            }
        }

        function onGetLimitTenorTypeFailure(){

        }

        function loadInstrumentTypes(){
            StaticLookUp.getInstrumentType(onGetInstrumentSuccess, onGetInstrumentFailure);
        }

        function onGetInstrumentSuccess(result){
            vm.instrumentTypes = result;
        }

        function onGetInstrumentFailure(){

        }
     
       
        function loadDefaultEffectiveToDatesFromConfig(){
            //get default effective to date for forward or all window
          LimitSettings.getDefaultEffectiveToDateForForward(function(result){
           vm.effecitveToDateForForwards = DateUtils.convertDateTimeFromServer(result.effectiveToDate);
           //get default effective to date for spotwindo
           LimitSettings.getDefaultEffectiveToDate(function(result){
            vm.effecitveToDateForSpotWindow = DateUtils.convertDateTimeFromServer(result.effectiveToDate);
         setEffectiveToDateFromGivenInstrument(vm.customerLimit.limitSettingsHeader.limitInstrumentGroup.shortName);
        })
       })     
       }
       
       function loadDefaultEffectiveToDate(){
           vm.effectiveToDate = angular.copy(vm.effecitveToDateForForwards);
          
       }
       function loadSpotwindowSetEffectivetodate(){
        vm.effectiveToDate = angular.copy(vm.effecitveToDateForSpotWindow);
    }
       // set effective to date from given instrument group
       function setEffectiveToDateFromGivenInstrument(instrumentCode){
       if($state.current.name=="create-customer-limit-settings"){
        loadDefaultEffectiveToDate();
       }else if(workflowSharedService.viewFrom == 'create_customer_limitSettings_maker'||workflowSharedService.viewFrom == 'create_customer_limitSettings_resubmit' || workflowSharedService.viewFrom == 'create_customer_limitSettings_auditLog'){
        if(vm.customerLimit.limitSettingsHeader.effectiveToDateTime!=null){
            vm.effectiveToDate =  DateUtils.convertDateTimeFromServer(vm.customerLimit.limitSettingsHeader.effectiveToDateTime);
        }
       }
       else if(instrumentCode=='ALL'){
            loadDefaultEffectiveToDate();

        }else if(instrumentCode=='FORWARD'){
            loadDefaultEffectiveToDate();

        }else{
            loadSpotwindowSetEffectivetodate();

        }
     }

         $scope.$watch('vm.customerLimit.limitSettingsHeader.bank' , function(value){
        	 angular.forEach(vm.tradingBankDetails, function(item){
        		 if(value !=null && value!=undefined && value !=""){
	        		 if(item.bank.id == value.id ){
	        			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
	        			 
	        		 }
        	 	}
        	 });
         });
         function prepareLimitInfoConent(value) {
			if(value!=null){
				vm.showLimitInfo=true;
				if(value=='GROSSBUY'){
					vm.limitTypeInfo = "Limit available to Buy only";
				}else if(value=='GROSSSELL'){
					vm.limitTypeInfo = "Limit available to Sell only";
				}else if(value=='GLOBALGROSS'){
					vm.limitTypeInfo = "TOTAL  limit available to both  Buy and Sell";
				}else{
					vm.limitTypeInfo = "NET limit available to both Buy and Sell";
				}
			}
			
		}
       
         function prepareLimitTypeToView(){
        	
        	
        	angular.forEach(vm.limitTypes, function(item){
            		 if(item.shortName == vm.defaultLimitType ){
            			 vm.selectedLimitType = item;
            		 }
                     prepareLimitInfoConent(value.shortName);
            })
        	
         }
         function checkInstrumentValue(instrumentValue){
        	 
        	 if(instrumentValue == "" || instrumentValue == undefined || instrumentValue == null){
        		 return 0.00;
        	 }else{
        		 return instrumentValue;
        	 }
         }
         
         function checkDefaultTradingBranch(giveBankId){
         	var matchFound = false;
        	 angular.forEach(vm.tradingBankDetails, function(item){
        		 if(item.bank.id == giveBankId ){
        			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
        			 matchFound = true;
        			 
        		 }
        	 });
         }
         
         function checkForBankAndBranch(giveBankId){
          	var matchFound = false;
         	 angular.forEach(vm.tradingBankDetails, function(item){
         		 if(item.bank.id == giveBankId ){
         			 vm.customerLimit.limitSettingsHeader.bank = item.bank;
         			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
         			 matchFound = true;
         		 }
         	 });
         	 if(!matchFound){ // if no match found from the given trading bank then disable the save button
         		 vm.disableSave = true;
         	 }
          }
      
         function prepareLimitDetailsToSaveToDB(){
        	 if( vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined ){
        		 
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList=[];
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0]={};
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0].instrumentType=checkAndSetInstrumentType();
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0].value=checkInstrumentValue( vm.customerLimit.customerLimitRequest.limitAmount);
        		 vm.customerLimit.limitSettingsHeader.limitReinstateMethod = null;
        		 vm.customerLimit.limitSettingsHeader.isLimitToBeReinstated = false;
        		 vm.customerLimit.limitSettingsHeader.userRemarks= vm.customerLimit.customerLimitRequest.userRemarks;
                 vm.customerLimit.limitSettingsHeader.customerRemarks= vm.customerLimit.customerLimitRequest.customerRemarks;
        		 vm.customerLimit.limitSettingsHeader.limitCurrency= vm.customerLimit.customerLimitRequest.limitCurrency;
        		 vm.customerLimit.limitSettingsHeader.limitType = vm.selectedLimitType.shortName;
        		 vm.customerLimit.limitSettingsHeader.effectiveFromDateTime = null;
        		 vm.customerLimit.limitSettingsHeader.effectiveToDateTime = vm.effectiveToDate;
        	 }
         }

         function checkAndSetInstrumentType(){
            var instrumentType = null;
            if(vm.limitInstrumentGroup.shortName=='SPOTWINDOW'){
                var uptoSpotInstrumentType = $filter('filter')(vm.instrumentTypes, {shortName: 'UptoSPOT'});
                instrumentType = uptoSpotInstrumentType[0];
            }else if(vm.limitInstrumentGroup.shortName=='FORWARD'){
                var forwardInstrumentType = $filter('filter')(vm.instrumentTypes, {shortName: 'Forward'});
                instrumentType= forwardInstrumentType[0];
            }
            return instrumentType;
         }

         function prepateLimitDetailsToView(){
        	 
        	
        	
         }
         function loadBranch(){
        	 
         }
     
		
		if (workflowSharedService.viewFrom == 'create_customer_limitSettings_maker') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.effectiveToDate = angular.copy(vm.customerLimit.limitSettingsHeader.effectiveToDateTime);
	   
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;		
			$scope.showDiscardButton = true;
			vm.disableForm = false;
			
			enableControls(true,true,true,true,true);
			
			
		} else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_resubmit') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));


			 vm.limitInstrumentGroup=vm.customerLimit.limitSettingsHeader.limitInstrumentGroup;
			prepareLimitTypeToView();
             
			vm.screenLog.screenLog(vm.screenName, vm.customerLimit.limitSettingsHeader.id == null ? 0
					: vm.customerLimit.limitSettingsHeader.id,
				     entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);

			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;			
			$scope.showDiscardButton = false;			
			//disable bank
			enableControls(true,false,false,false,false);
			
		} else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_auditLog') {
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitInstrumentGroup=vm.customerLimit.limitSettingsHeader.limitInstrumentGroup;
			   
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;		
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.disableForm = false;			
			enableControls(true,true,true,true,true);
			
		}		
		else {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit={}; 
	        vm.customerLimit.limitSettingsHeader = {};
	        vm.customerLimit.customerLimitRequest = {};;	
	        	        	
	        vm.customerLimit.limitSettingsHeader.customer = vm.globalValues.getCustomer();
			prepareLimitTypeToView();
			ScreenLog.screenLog(vm.screenName,0, vm.entityConstants.ENTITY_ACTION_TYPE.CREATE);
						
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			
			
			if($state.current.name=="create-customer-limit-settings"){
				enableControls(false,false,false,false,false);
			}else{
				//disable bank when request is from limit report
				enableControls(true,false,false,false,false);
			}
			
		} 


		function enableControls(disableBank , disableValues,disableLimitType,disableEffectiveToDate,disableInstrumentGroupField){
			vm.disableBank = disableBank
			vm.disableBranch = true;
			vm.disableCustomer = true;
			vm.disableLimitType= disableLimitType;	
          	vm.diableFields = disableValues;
            vm.disableEffectiveToDate = disableEffectiveToDate;
            vm.disableInstrumentGroupField=disableInstrumentGroupField;
		}
		  
		 function clear() {
        	 workflowSharedService.viewFrom="";
             $uibModalInstance.dismiss('cancel');
         }
    
 	
        function prepareDatesforServer(){
           // vm.customerLimit.effectiveFromDateTime = vm.effectiveFromDate;
           vm.customerLimit.limitSettingsHeader.effectiveToDateTime = vm.effectiveToDate;
        }	



        
        function save () {  
            prepareLimitDetailsToSaveToDB();   
            prepareDatesforServer();    	    		
       		
        	
            var comparision = moment(vm.currentDate).isAfter( vm.customerLimit.limitSettingsHeader.effectiveToDateTime, 'day');
            if(comparision){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
                vm.clicked=false;
            }else if(moment( vm.customerLimit.limitSettingsHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
             AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
             vm.clicked=false

             
            }else{ 
                LimitSettings.createLimitSettingsByCustomer(vm.customerLimit, onCustomerSaveSuccess, onCustomerSaveError);
            
            }		
        	
         }
     
              
        function onCustomerSaveSuccess(result){
        	//$scope.clicked = true;
        	 $uibModalInstance.close(result); 
        	 MessageBox.openMessageBox('Alert', 'The request has been sent for bank approval. You will be notified by email on the request status...!', 'Ok', 'homepage');
        }
        function onCustomerSaveError(error){
        	vm.clicked = false;
        }
        function resubmitLimitSettings() {  
            prepareLimitDetailsToSaveToDB();
            prepareDatesforServer();        	
         
            var comparision = moment(vm.currentDate).isAfter(vm.customerLimit.limitSettingsHeader.effectiveToDateTime, 'day');
            if(comparision){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
                vm.clicked=false;
            }else if(moment(vm.customerLimit.limitSettingsHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
             AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
             vm.clicked=false

             
            }else{  
        	 LimitSettings.resubmitLimitChangeByCustomer({"taskId" : $rootScope.taskID}, vm.customerLimit, onResubmitSuccess, onResubmitError);
            }
        }
        
        function onResubmitSuccess(result) {
        	$uibModalInstance.close(result); 
        	$rootScope.removeRow($rootScope.taskID);
        	
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
      

        function discardLimitSettings() {
        	LimitSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
 

        function onChangeLimitInstrumentGroup(value){
			if(value!=null && value!=undefined){
                
                if(vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined){
                                vm.customerLimit.limitSettingsHeader.limitInstrumentGroup = value;
                                vm.limitInstrumentGroup = value;
                
				setFieldValues("txtspot", "");
				setFieldValues("txtcash", "");
				setFieldValues("txttom", "");
				setFieldValues("txtforward", "");
				setFieldValues("txtuptospot", "");
				setFieldValues("txtall", "");
				setFieldValues("txtMaxTenorValue", "");

				if(value.shortName=='SPOTWINDOW'){
					vm.selectedInstrument = 'individual';
					vm.effectiveToDate = angular.copy(vm.effecitveToDateForSpotWindow);
				}else{
					vm.effectiveToDate = angular.copy(vm.effecitveToDateForForwards);
				}

			}
		}
    }
    function setFieldValues(fieldName, value){
        if( document.getElementById(fieldName) !=null && document.getElementById(fieldName)!= undefined){
            document.getElementById(fieldName).value = value;
        }
    }
      
        
        
	}
})();
